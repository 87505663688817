import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

import ActivateMonthly from './ActivateMonthly';
import React from 'react';
import useDialog from '../../Hooks/useDialog';
import useTypeStyles from '../Common/Ui/useTypeStyles';

type MonthlyProps = {
    navigateTo: (target: string) => void,
    active: boolean,
    isMainUser: boolean,
    isUser: boolean,
    classes: { centerCard: string, innerCardHeader: string },
};

const Monthly = ({
    navigateTo,
    active,
    isMainUser,
    isUser,
    classes,
}: MonthlyProps) => {
    const typeClasses = useTypeStyles();
    const { open, close, isOpen } = useDialog();

    return (
        <Card>
            <CardContent className={[classes.centerCard, typeClasses.monthly].join(' ')}>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.innerCardHeader}
                >
                    ApoStar Tara Report
                </Typography>
                <Box mb={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigateTo('/about')}
                        fullWidth
                        className={typeClasses.monthlyButton}
                    >
                        Mehr Informationen
                    </Button>
                </Box>
                {active
                    ? (
                        <>
                            <Box mb={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => navigateTo('/monatlich/vergleichsgruppe')}
                                    disabled={!(isMainUser || isUser)}
                                    className={typeClasses.monthlyButton}
                                >
                                    Vergleichsgruppe festlegen/ändern
                                </Button>
                            </Box>
                            <Box mb={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={!(isMainUser || isUser)}
                                    onClick={() => navigateTo('/berichte')}
                                    className={typeClasses.monthlyButton}
                                >
                                    Archivierte Berichte öffnen
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box mb={1} style={{ height: 48.5 }}>
                                <Typography variant="body2" color="textSecondary">
                                    Sie nehmen derzeit nicht am monatlichen
                                    ApoStar Tara Report teil. Aktivieren Sie
                                    diesen für Vergleiche auf monatlicher Basis.
                                </Typography>
                            </Box>
                            <Box mb={1}>
                                { isMainUser && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={open}
                                        className={typeClasses.monthlyButton}
                                    >
                                        monatlichen ATR aktivieren
                                    </Button>
                                )}
                            </Box>
                        </>
                    )
                }
            </CardContent>
            <ActivateMonthly onClose={close} open={isOpen} />
        </Card>
    );
};

export default Monthly;
