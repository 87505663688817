import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = new UserValdiation(
    ['general.A7_LJ', '-', 'compare.general.A7_LJ'],
    { type: 'compare', compare: '=', value: 0 },
    'Ihre Angabe zur Filialapotheke erscheint nicht konsistent zum Vorjahr.',
    undefined,
    'Struktur_07',
);

export default rule;
