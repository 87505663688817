import {
    Box,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import {
    Search as SearchIcon,
    Warning as WarningIcon,
} from '@material-ui/icons';

import React from 'react';

/**
 * Empty state props
 */
export type TableEmptyStateProps = {
    /**
     * Optional state. If not given always shows, otherwise takes the state into account.
     */
    state?: { isLoading: boolean, data?: Array<any>, error?: string },

    /**
     * The column count to span
     */
    colCount?: number,

    /**
     * The empty message, defaults to {@value "keine Daten gefunden"}
     */
    message?: string,

    /**
     * Icon to display along message
     */
    icon?: React.ReactElement,
};

/**
 * A table empty state to display
 */
const TableEmptyState: React.FC<TableEmptyStateProps> = ({
    message = 'keine Daten gefunden',
    icon = (<SearchIcon color="disabled" fontSize="large" />),
    colCount,
    state,
}) => {
    if (state && (state.isLoading || (state.data && state.data?.length > 0))) {
        return <></>;
    }

    const Wrapper = !colCount
        ? ({ children }: React.PropsWithChildren<{}>) => (
            <Box textAlign="center" p={3}>{children}</Box>
        )
        : ({ children }: React.PropsWithChildren<{}>) => (
            <TableRow>
                <TableCell colSpan={colCount} align="center">
                    {children}
                </TableCell>
            </TableRow>
        );

    if (state && state.error !== undefined) {
        return (
            <Wrapper>
                <WarningIcon color="error" fontSize="large" />
                <Typography variant="body1" color="error">{state.error}</Typography>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {icon}
            <Typography variant="body1">{message}</Typography>
        </Wrapper>
    );
};

export default TableEmptyState;
