import { FieldInputProps, GenericFieldHTMLAttributes } from 'formik';
import React, { useMemo } from 'react';

import FormSelect from '../Common/FormSelect';
import Pharmacy from '../../Lib/Pharmacy';
import PharmacyService from '../../Lib/PharmacyService';
import RawPharmacy from '../../Lib/RawPharmacy';
import { Skeleton } from '@material-ui/lab';
import useLoad from '../../Hooks/useLoad';

export type PharmacyBranchSelectProps = Partial<FieldInputProps<number>> & GenericFieldHTMLAttributes & {
    label?: string,
    error?: string,
    value: RawPharmacy,
};

const PharmacyBranchSelect = ({
    onChange = () => {},
    label = 'Filiale wählen',
    name = 'branch',
    value,
    disabled,
    error,
}: PharmacyBranchSelectProps) => {
    const { isLoading, data } = useLoad<PharmacyService, Array<Pharmacy>, never>(
        'pharmacy', (service: PharmacyService) => service.getBranches(value.id || 0),
    );
    const displayedData = useMemo(() => [value, ...(data || [])] || [], [value, data]);

    if (displayedData.length < 2) {
        return isLoading ? (<Skeleton />) : null;
    }

    return (
        <FormSelect
            label={label}
            options={displayedData}
            valueKey="id"
            labelKey="title"
            defaultValue={value.id!}
            onChange={(value) => onChange({
                target: { value, name },
            })}
            disabled={isLoading || disabled}
            error={error}
        />
    );
};

export default PharmacyBranchSelect;
