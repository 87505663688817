import { makeStyles } from '@material-ui/core/styles';

export const mainColorYearly = '#8D68A0';
export const mainColorMonthly = '#7195E4';

const useStyles = makeStyles(() => ({
    monthly: {
        borderTop: '12px solid #7195E4',
        '& .MuiTypography-h5': {
            color: '#7195E4',
        },
    },
    yearly: {
        borderTop: '12px solid #8D68A0',
        '& .MuiTypography-h5': {
            color: '#8D68A0',
        },
    },
    monthlyButton: {
        backgroundColor: '#7195E4',
        '&:hover': {
            backgroundColor: '#526da9',
        },
    },
    yearlyButton: {
        backgroundColor: '#8D68A0',
        '&:hover': {
            backgroundColor: '#674b76',
        },
    },
    yearlyButtonOutlined: {
        borderColor: '#8D68A0',
        color: '#8D68A0',
        '&:hover': {
            borderColor: '#674b76',
            backgroundColor: '#d0c1d9',
        },
    },
    yearlyBackgroundLight: {
        backgroundColor: '#d0c1d9',
    },
    yearlyText: {
        color: '#8D68A0',
    },
    monthlyText: {
        color: '#7195E4',
    },
    em: {
        color: '#f44336',
    },
}));

export default useStyles;
