import { useEffect } from 'react';

/**
 * Use a warning on webpage leave
 */
const useLeaveWarning = (confirmationMessage: string, enabled: boolean = true) => {
    useEffect(() => {
        if (enabled) {
            // Handle it only when enabled
            const handleUnload = (e: any) => {
                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
            };

            // Add as window listener
            window.addEventListener('beforeunload', handleUnload);

            // Also remove it on cleanup
            return () => window.removeEventListener('beforeunload', handleUnload);
        }
    }, [confirmationMessage, enabled]);
};

export default useLeaveWarning;
