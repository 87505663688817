import {
    Fab,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import ActivateMonthly from '../Dashboard/ActivateMonthly';
import { Add } from '@material-ui/icons';
import Pharmacy from '../../Lib/Pharmacy';
import PharmacyService from '../../Lib/PharmacyService';
import Table from './Table';
import TableEmptyState from '../Common/Ui/TableEmptyState';
import TableRowLoader from '../Common/Ui/TableRowLoader';
import useAuth from '../../Hooks/useAuth';
import useFabStyles from '../Common/useFabStyles';
import { useHistory } from 'react-router-dom';
import useLoad from '../../Hooks/useLoad';

const BranchList = () => {
    const classes = useFabStyles();
    const { push: navigate } = useHistory();
    const { authentication } = useAuth();
    const { isLoading, data, error } = useLoad<PharmacyService, Array<Pharmacy>, never>(
        'pharmacy', (service: PharmacyService) => service.getBranches(authentication?.pharmacyId || 0),
    );
    const [activatePharmacy, setActivatePharmacy] = useState<Pharmacy>();

    return (
        <Paper elevation={0}>
            <Toolbar disableGutters>
                <Typography variant="h6" component="div">
                    Filialapotheken
                </Typography>
            </Toolbar>
            {
                isLoading
                    ? <TableRowLoader rowCount={10} colCount={5} withTable />
                    : (
                        <Table
                            data={data || []}
                            emptyView={<TableEmptyState state={{ isLoading, data, error }} colCount={5} />}
                            onActivate={setActivatePharmacy}
                        />
                    )
            }
            <ActivateMonthly
                onClose={() => setActivatePharmacy(undefined)}
                open={activatePharmacy !== undefined}
                pharmacy={activatePharmacy?.getRaw()}
            />
            <Tooltip title="Neue Filialapotheke registrieren">
                <Fab
                    color="secondary"
                    aria-label="add"
                    className={classes.fab}
                    onClick={() => navigate('/filiale')}
                >
                    <Add className={classes.fabIcon} />
                </Fab>
            </Tooltip>
        </Paper>
    );
};

export default BranchList;
