import { RuleParamaters, ruleParameters } from '../../Lib/YearlyDataValidation';

export type RuleParamaterInput = {
    bounds?: { low?: number, high?: number },
    boundsCritical?: { low?: number, high?: number },
};
export type RuleParamatersInput = Record<typeof ruleParameters[number], RuleParamaterInput>;

export type ParamType = {
    id: keyof RuleParamaters,
    label: string,
    numType: 'pct' | 'abs' | 'quota',
}

/**
 * Structure for input of validation params
 */
const VALIDATION_PARAMS: ReadonlyArray<Readonly<ParamType>> = [{
    id: 'legalSocialExpense',
    label: 'Gesetzlicher Sozialaufwand',
    numType: 'pct',
}, {
    id: 'salesRelationLastYear',
    label: 'Gesamtumsatz im Verhältnis zum VJ Umsatz',
    numType: 'pct',
}, {
    id: 'privateSalesRelationLastYear',
    label: 'Privatumsatz im Verhältnis zum VJ Umsatz',
    numType: 'pct',
}, {
    id: 'privateSalesRelationOfficin',
    label: 'Privatumsatz im Verhältnis zum Offizinumsatz',
    numType: 'pct',
}, {
    id: 'insuranceSalesRelationOfficin',
    label: 'KK Umsatz im Verhältnis zum Offizinumsatz',
    numType: 'pct',
}, {
    id: 'insuranceSalesRelationLastYear',
    label: 'KK Umsatz im Verhältnis zum VJ Umsatz',
    numType: 'pct',
}, {
    id: 'workingAreasRelationLastYear',
    label: 'Betriebsräumlichkeiten Veränderung zu VJ',
    numType: 'pct',
}, {
    id: 'officeAreasRelationLastYear',
    label: 'Offizinräumlichkeiten Veränderung zu VJ',
    numType: 'pct',
}, {
    id: 'salesBuyingSyndicate',
    label: 'Range Rohertrag Einkaufsgemeinschaft',
    numType: 'pct',
}, {
    id: 'rentalExpense',
    label: 'Mietaufwand im Vergleich zu VJ',
    numType: 'pct',
}, {
    id: 'tenureExpense',
    label: 'Check Pachtaufwand zu VJ',
    numType: 'pct',
}, {
    id: 'materialExpenseRelationLastYear',
    label: 'Wareneinsatz im Vergleich zum VJ',
    numType: 'pct',
}, {
    id: 'materialExpenseRelationSales',
    label: 'Wareneinsatz im Verhältnis zum Gesamtumsatz',
    numType: 'pct',
}, {
    id: 'staffExpenseRelationSales',
    label: 'Personalaufwand im Verhältnis zum Gesamtumsatz',
    numType: 'pct',
}, {
    id: 'staffExpenseRelationLastYear',
    label: 'Personalaufwand Veränderung zum VJ',
    numType: 'pct',
}, {
    id: 'expensesRelationSales',
    label: 'Betriebsaufwand im Verhältnis zum Gesamtumsatz',
    numType: 'pct',
}, {
    id: 'profitRelationSales',
    label: 'Bruttogewinn im Verhältnis zum Gesamtumsatz unplausibel',
    numType: 'pct',
}, {
    id: 'profitRelationLastYear',
    label: 'Bruttogewinnveränderung im Verhältnis zum VJ plausibel',
    numType: 'pct',
}, {
    id: 'profit',
    label: 'Jahresüberschuss korrekt erfasst?',
    numType: 'pct',
}, {
    id: 'tenureExpenseRelationSales',
    label: 'Pächter ohne Pachtaufwand (Pachtaufwand/Umsatzerlös)',
    numType: 'pct',
}, {
    id: 'tenureExpenseWithoutTenure',
    label: 'Pachtaufwand erfasst, aber Eingabe Pächter nicht ausgewählt',
    numType: 'pct',
}, {
    id: 'rentalExpenseOwnership',
    label: 'Eigenimmobilie, aber hoher Mietaufwand',
    numType: 'pct',
}, {
    id: 'stockRelationLastYear',
    label: 'Differenz Warenlager Anfang vs Endbestand',
    numType: 'pct',
}, {
    id: 'balanceEquality',
    label: 'Soll- und Haben Gleichheit',
    numType: 'pct',
}, {
    id: 'customerCountRelationLastYear',
    label: 'Kundenzahl pro Jahr plausibel im Vergleich zum VJ?',
    numType: 'pct',
}, {
    id: 'prescriptionCountRelationLastYear',
    label: 'Rezeptzahl pro Jahr plausibel im Vergleich zum VJ?',
    numType: 'pct',
}, {
    id: 'privatePackageSalesRelationLastYear',
    label: 'Pro Jahr abgesetzte Privatpackungen plausibel im Vergleich zum VJ?',
    numType: 'pct',
}, {
    id: 'insurancePackageSalesRelationLastYear',
    label: 'Pro Jahr abgesetzte Krankenkassenpackungen plausibel im Vergleich zum VJ?',
    numType: 'pct',
}, {
    id: 'quotaConcessionaire',
    label: 'Eingabefaktor Konzessionär',
    numType: 'pct',
}, {
    id: 'familiaryTotalQuota',
    label: 'Eingabe  10tel Familiäre Mittätigkeit',
    numType: 'quota',
}, {
    id: 'apirantTotalQuota',
    label: 'Zehntel Aspiranten',
    numType: 'quota',
}, {
    id: 'staffExpenseRelationProfit',
    label: 'Personalaufwand zu Bruttoertrag unplausibel',
    numType: 'pct',
}, {
    id: 'salaryDirector',
    label: 'Jahresgehalt Apothekenleiter min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryPharmacist',
    label: 'Jahresgehalt Pharmazeutische Fachkraft min-max in EUR',
    numType: 'abs',
}, {
    id: 'salaryAspirant',
    label: 'Jahresgehalt Aspirant min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryMerchant',
    label: 'Jahresgehalt PKA min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryTrainee',
    label: 'Jahresgehalt PKA-Lehrlinge min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryEmployeeSkilled',
    label: 'Jahresgehalt Sonstige Angestellte mit Berufsausbildung min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryEmployeeUnskilled',
    label: 'Jahresgehalt Sonstige Angestellte ohne Berufsausbildung min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryCleaner',
    label: 'Jahresgehalt Raumpfleger min-max EUR',
    numType: 'abs',
}, {
    id: 'salaryOtherEmployee',
    label: 'Jahresgehalt Sonstige Arbeiter min-max EUR',
    numType: 'abs',
}] as const;


export default VALIDATION_PARAMS;
