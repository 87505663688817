import JSONFetch from './JSONFetch';
import Pharmacy from './Pharmacy';
import PharmacyData from './PharmacyData';
import PharmacyMapper from './PharmacyMapper';
import RawPharmacy from './RawPharmacy';
import RawPharmacyMapper from './RawPharmacyMapper';
import User from './User';
import UserMapper from './UserMapper';
import { urify } from './Helper';

type RegistrationCode = {
    pharmacyNumber: string,
    registrationCode: string,
};

export default class PharmacyService {
    // Mapper to use
    mapper: PharmacyMapper;

    // The constructor
    constructor(mapper: PharmacyMapper) {
        this.mapper = mapper;
    }

    checkRegistrationCode(pharmacyNumber: string, code: string): Promise<PharmacyData> {
        return JSONFetch({
            url: urify('/CheckApoRegCode', { apoBetrNr: pharmacyNumber, regCode: code }),
            bodySerializer: () => '',
            responseParser: this.mapper.infoParser,
        })
            .then((parsed) => this.mapper.mapToDataModel(parsed));
    }

    requestRegistrationCode(pharmacyNumber: string): Promise<boolean> {
        return JSONFetch({
            url: urify('/SendApoRegCode', { apoBetrNr: pharmacyNumber }),
            bodySerializer: () => '',
            responseParser: () => true,
        });
    }

    register(data: [RegistrationCode, Pharmacy, User?]): Promise<void> {
        return JSONFetch({
            method: 'post',
            url: urify('/Registration', { ApoBetrNr: data[0].pharmacyNumber, apoRegCode: data[0].registrationCode }),
            body: data,
            bodySerializer: () => JSON.stringify({
                Apotheke: this.mapper.mapToData(data[1]),
                Benutzer: data[2] ? new UserMapper().mapToData(data[2]) : null,
            }),
            responseParser: (newId: string) => parseInt(newId, 10),
        })
            .then((parsed) => {});
    }

    getBranches(id: number): Promise<Array<Pharmacy>> {
        return JSONFetch({
            url: `/FilAposByApoID/${id}`,
            bodySerializer: () => '',
            responseParser: this.mapper.parseArray,
        });
    }

    get(id: number): Promise<Pharmacy> {
        return JSONFetch({
            url: `/ApothekeByID/${id}`,
            bodySerializer: () => '',
            responseParser: this.mapper.parse,
        });
    }

    save(pharmacy: Pharmacy): Promise<Pharmacy> {
        return JSONFetch({
            method: 'put',
            url: '/UpdateApotheke',
            body: pharmacy,
            bodySerializer: (data) => this.mapper.serialize(data),
        })
            .then(() => pharmacy);
    }

    saveRaw(pharmacy: RawPharmacy): Promise<RawPharmacy> {
        return JSONFetch({
            method: 'put',
            url: '/UpdateApotheke',
            body: pharmacy,
            bodySerializer: (data) => (new RawPharmacyMapper()).serialize(data),
        })
            .then(() => pharmacy);
    }
}
