import { CheckCircle, RemoveCircle } from '@material-ui/icons';

import React from 'react';

export type ItemStatusProps = {
    status: boolean,
};

const ItemStatus: React.FC<ItemStatusProps> = ({ status }) => (
    status
        ? <CheckCircle color="primary" />
        : <RemoveCircle color="action" />
);

export default ItemStatus;
