import JSONFetch, { JSONFetchOptions } from './JSONFetch';

import ComparisonGroup from './ComparisonGroup';
import ComparisonGroupMapper from './ComparisonGroupMapper';
import { urify } from './Helper';

export type CompType = 'monthly' | 'yearly';

export default class ComparisonGroupService {
    // Mapper to use
    mapper: ComparisonGroupMapper;

    // The constructor
    constructor(mapper: ComparisonGroupMapper) {
        this.mapper = mapper;
    }

    getAll = (): Promise<Array<ComparisonGroup>> => {
        return JSONFetch({
            url: '/BenchmarkingKombiListGueltig',
            bodySerializer: () => '',
            responseParser: this.mapper.parseArray,
        });
    };

    getCurrent(userId: number, type: CompType = 'yearly'): Promise<number> {
        const baseUrl = type === 'yearly'
            ? 'BenchKombiJahrByBenID'
            : 'BenchGrpByBenID';

        return JSONFetch({
            url: `/${baseUrl}/${userId}`,
            bodySerializer: () => '',
            responseParser: this.mapper.getUserComparisonCombinationParser(),
        })
            .then((data) => (data.benchKombiNr));
    }

    setCurrent = (userId: number, group: ComparisonGroup, type: CompType = 'yearly'): Promise<void> => {
        const apiUrl = type === 'yearly'
            ? '/UpdateBenchKombiJahrByBenID'
            : '/UpdateBenchKombiByBenID';

        return JSONFetch({
            method: 'put',
            url: urify(apiUrl, { benID: userId, benchGrpNr: group.id }),
            bodySerializer: () => '',
        }).then(() => {});
    };

    setActive = (group: ComparisonGroup, active = true): Promise<void> => {
        return JSONFetch({
            method: 'put',
            url: `SetBenchKombiActiveByNr/${group.id}`,
            body: group,
            bodySerializer: () => this.mapper.serializeSetActive(active),
            responseParser: () => true,
        }).then(() => {});
    };

    save = (group: ComparisonGroup): Promise<ComparisonGroup> => {
        const method: Pick<JSONFetchOptions<ComparisonGroup, ComparisonGroup>, 'url' | 'method'> = group.id ? {
            url: `/UpdateBenchKombiByNr/${group.id}`,
            method: 'put',
        }: {
            url: '/InsertBenchKombi',
            method: 'post',
        };

        return JSONFetch({
            ...method,
            body: group,
            bodySerializer: (data) => this.mapper.serializeForSave(data),
            responseParser: (raw) => (group.id ? group : this.mapper.parse(raw)),
        });
    };
}
