import {
    Card,
    CardContent,
    Grid,
} from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

const NarrowContainer: React.FC<PropsWithChildren<{}>> = ({ children }) => (
    <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
            <Card elevation={0}>
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default NarrowContainer;
