import { signIn, signOut } from '../Lib/AuthService';

import { AuthContextProvider } from '../Hooks/useAuth';
import DataProvider from '../Providers/DataProvider';
import NotificationProvider from '../Providers/NotificationProvider';
import Notifications from './Notification/Notifications';

/**
 * All the providers
 */
const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <NotificationProvider>
        <AuthContextProvider
            onSignIn={signIn}
            onSignOut={signOut}
        >
            <DataProvider>
                {children}
                <Notifications />
            </DataProvider>
        </AuthContextProvider>
    </NotificationProvider>
);

export default Providers;
