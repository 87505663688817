import { Chip, Tooltip } from '@material-ui/core';
// @ts-ignore
import { applyThousandSeparator, splitDecimal } from 'react-number-format/lib/utils';

import { CellProps } from 'react-table';
import { CustomResponseError } from './JSONFetch';
import { GroupTypeEntity } from './ComparisonGroup';
import React from 'react';
import { uriEncode } from './Encoding';

// Helper used later
// @see https://stackoverflow.com/a/49752227/1171541
export type KeysOfType<T, TProp> = { [P in keyof T]: T[P] extends TProp? P : never }[keyof T];

/**
 * Access value of a nested object
 */
export function nestedBenchmarkingGroupAccessor({ value }: { value?: GroupTypeEntity }) {
    return (
        value
        && value.begBezeichnung
        && value.begBezeichnung.replace(/\(\d+\)/, '')
    ) || (<span className="table-no-value">-</span>);
}

/**
 * Access value of a nested object
 */
export function locationCellRender({ value }: CellProps<Array<GroupTypeEntity>>) {
    const count = value.length;
    const title = value.map((inner: GroupTypeEntity) => nestedBenchmarkingGroupAccessor({ value: inner })).join(', ');
    return (
        <Tooltip title={title} aria-label="locations" placement="left">
            <Chip label={count} />
        </Tooltip>
    );
}

export const debounce = <T extends Function>(callback: T, timeout = 300): (...args: Array<any>) => void => {
    let timer: ReturnType<typeof setTimeout>;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { callback.apply(this, args); }, timeout);
    };
}

/**
 * Update a model in a dataset
 */
export const updateData = <T extends Record<string, any>>(
    toUpdate: T, dataSet: Array<T>, idKey: keyof T = 'id',
): Array<T> => {
    const foundIndex = dataSet.findIndex((model: T) => (model[idKey] === toUpdate[idKey]));

    if (foundIndex < 0) {
        dataSet.push(toUpdate);
    } else {
        dataSet.splice(foundIndex, 1, toUpdate);
    }

    // Some nescessary for new insert
    return [...dataSet];
};

// Nullable type
export const nullable = <T,>(value: T): Exclude<T, undefined> | null =>
    // @ts-ignore
    (value === undefined ? null : value);

// Null to undefined
export const nullifined = <T,>(value: T): Exclude<T, null> | undefined =>
    // @ts-ignore
    (value === null ? undefined : value);

// Boolean to API J/N conversion
export const booleanToYN = (flag: boolean): 'J' | 'N' => (flag ? 'J' : 'N');

// ...ith nullable check
export const booleanToYNNullable = (flag?: boolean): 'J' | 'N' | null =>
    (flag === undefined ? null : (flag ? 'J' : 'N'));


// Check if an object has a null value in it
export const hasNullValue = (value: Record<string, any>): boolean =>
    Object.values(value).includes(null);

/**
 * Wrap and unwrap model values helper
 */
export const getModelValueArray = <T,>(
    model: T | null,
    key: KeysOfType<T, GroupTypeEntity[]>,
): Array<number> => {
    const value = (model && Array.isArray(model[key])) ? model[key] : null;
    // We need to cast the given value, check for array was executed on the line above.
    return (value && (value as unknown as GroupTypeEntity[]).map((obj) => (obj.begID))) || [];
}

/**
 * Wrap and unwrap model values helper
 */
export const getModelValue = <T,>(
    model: T | null,
    key: KeysOfType<T, Nullable<GroupTypeEntity>>,
) => {
    const value = model && model[key];
    return (value && (value as unknown as GroupTypeEntity).begID) || null;
};

/**

/**
 * Find suiting model to id number
 */
export function getModelByValue(models: Array<Record<string, any>>, id: Array<number>): Array<GroupTypeEntity>;
export function getModelByValue(models: Array<Record<string, any>>, id: number): GroupTypeEntity;

export function getModelByValue(models: Array<Record<string, any>>, id: Array<number> | number) {
    if (Array.isArray(id)) {
        return id.map((single) => getModelByValue(models, single));
    }
    return models.find((model) => (model.begID === id));
}
/**
 * Generate a uri with query params
 */
export const urify = (url: string, params: Record<string, string | number | null | undefined | boolean> = {}) => {
    const queryString = Object.keys(params)
        .map((key) => (`${key}=${uriEncode(params[key])}`))
        .join('&');
    const queryStart = url.includes('?') ? '&' : '?';
    return `${url}${queryStart}${queryString}`
};
/**
 * Convert to iso date format but adjust timezone difference to have local value
 */
export const localDate = (date?: Date | null) => {
    // Adjust timezoneoffset (multiply by seconds and ms)
    return date ? new Date(
        date.getTime() - date.getTimezoneOffset() * 60 * 1000,
    ).toISOString().substring(0, 10) : undefined;
};

// Date to api date, which is an iso string without time zone information for current local date
export const toApiDate = (date?: Date | null): string | null => (
    date ? `${localDate(date)}T00:00:00` : null
);

/**
 * Simple date formatting utitity
 */
export const dateFormat = (date: Date) => localDate(date)!.split('-').reverse().join('.');

/**
 * Number formatting for display. Same as in used input formatting lib.
 * @see https://github.com/s-yadav/react-number-format/blob/960ad2bcec58a812d5e0c1abd84c9a3e9771bc83/src/number_format.js#L473
 */
export const formatNumber = (number?: number) => {
    if (undefined === number) {
        return '';
    }

    const numStr = number.toString();
    const thousandSeparator = '.';
    const decimalSeparator = ',';

    const hasDecimalSeparator = numStr.indexOf('.') !== -1;
    let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr, true);

    if (thousandSeparator) {
        beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator, 'thousand');
    }

    //restore negation sign
    if (addNegation) {
        beforeDecimal = '-' + beforeDecimal;
    }

    // Build new number string
    return beforeDecimal + ((hasDecimalSeparator && decimalSeparator) || '') + afterDecimal;
}

/**
 * Month names
 */
export const MONTH_NAMES = ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

/**
 * Simple date formatting utitity
 */
export const monthFormat = (date: Date, separator: string = ' ') => {
    const parts = localDate(date)!.split('-');
    const monthNum = parseInt(parts[1]) - 1;
    return `${MONTH_NAMES[monthNum]}${separator}${parts[0]}`;
};

/**
 * Unwrap a message with a default fallback from an error. Intended to work with custom server
 * errors to bring those messages to the screen.
 */
export const serverErrorMessage = (e: Error, defaultMessage: string, append: boolean = false) => {
    if (e instanceof CustomResponseError) {
        return append ? `${defaultMessage}: ${e.message}` : e.message;
    }
    return defaultMessage;
};
