import ComparisonGroup from './ComparisonGroup';
import { ComparisonGroupData } from '../Schemas/ComparisonGroup';

export default class ComparisonGroupFilter {
    search:string  = '';

    pageNum: number = 1;

    pageSize: number = 10;
}

/**
 * Filter items by specified details
 */
export const filterDetailed = (items: Array<ComparisonGroup>, filter: ComparisonGroupData) => {
    return items.filter((item) => {
        // Simple filters
        if (filter.offizin && item.offizin?.begID !== filter.offizin) {
            return false;
        }

        // Simple filters
        if (filter.privateShare && item.privateShare?.begID !== filter.privateShare) {
            return false;
        }

        // Simple filters
        if (filter.highPriceShare && item.highPriceShare?.begID !== filter.highPriceShare) {
            return false;
        }

        // Simple filters
        if (filter.openingHours && item.openingHours?.begID !== filter.openingHours) {
            return false;
        }

        // Simple filters
        if (filter.pharmacyCount && item.pharmacyCount?.begID !== filter.pharmacyCount) {
            return false;
        }

        // Simple filters
        if (filter.population && item.population?.begID !== filter.population) {
            return false;
        }

        // Simple filters
        if (filter.state && item.state?.begID !== filter.state) {
            return false;
        }

        // Complex filter
        if (filter.location.length) {
            const filtered = filter.location.filter(
                (required) => (item.location.find(
                    (haystack) => (haystack.begID === required)
                ) !== undefined)
            );
            return filtered.length === filter.location.length;
        }

        return true;
    });
}
