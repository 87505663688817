import {
    Chip,
    Theme,
} from '@material-ui/core';
import {
    AllInclusive as InfinityIcon,
    Remove as MinusIcon,
    Add as PlusIcon,
} from '@material-ui/icons';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    chip: ({ value }: { value: number }) => ({
        backgroundColor: value >= 0 ? '#CDEAC7' : '#F2B6B8',
        color: value >= 0 ? '#78C769' : '#D71F25',
        borderRadius: 3,
    }),
    icon: ({ value }: { value: number }) => ({
        height: 12,
        width: 12,
        color: value >= 0 ? '#78C769' : '#D71F25',
    }),
}));

type TrendIndicatorProps = {
    value: number,
    unit?: string,
};

const TrendIndicator: React.FC<TrendIndicatorProps> = ({ value, unit = '' }) => {
    const classes = useStyles({ value });
    const stringValue = Math.abs(value) === Infinity
        ? (<InfinityIcon />)
        : `${Math.abs(value).toFixed(2)}${unit}`;

    return (
        <Chip
            size="small"
            icon={value >= 0 ? <PlusIcon className={classes.icon} /> : <MinusIcon className={classes.icon} />}
            label={stringValue}
            className={classes.chip}
        />
    );
}

export default TrendIndicator;
