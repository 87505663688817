import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { Divider } from '@material-ui/core';
import FormikTouchErrors from '../Common/Form/FormikTouchErrors';
import LoadingButton from '../Common/LoadingButton';
import NarrowContainer from '../Common/Ui/NarrowContainer';
import Pharmacy from '../../Lib/Pharmacy';
import PharmacyBranchSelect from './PharmacyBranchSelect';
import PharmacyDataForm from './PharmacyDataForm';
import PharmacyService from '../../Lib/PharmacyService';
import { Skeleton } from '@material-ui/lab';
import { localDate } from '../../Lib/Helper';
import useAuth from '../../Hooks/useAuth';
import useSaveWithNotification from '../../Hooks/useSaveWithNotification';
import useService from '../../Hooks/useService';
import validate from '../../Lib/FormValidations/Pharmacy';

const PharmacyData : React.FC<{}> = () => {
    const service = useService<PharmacyService>('pharmacy');
    const { authentication } = useAuth();
    const [data, setData] = useState<Pharmacy>();
    const [pharmacyId, setPharmacyId] = useState<number>(authentication!.pharmacyId);

    useEffect(() => {
        service.get(pharmacyId).then((pharmacy) => setData(pharmacy));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pharmacyId]);
    const savePharmacy = useSaveWithNotification((formData) => service.save(
        Object.assign(data || {}, formData)
    ));

    return (
        <NarrowContainer>
            {authentication?.pharmacy && (
                <>
                    <PharmacyBranchSelect
                        // @ts-ignore
                        onChange={({ target: { value } }) => {
                            setData(undefined);
                            setPharmacyId(value?.id)
                        }}
                        // @ts-ignore
                        value={authentication?.pharmacy}
                    />
                    <Divider style={{ marginBottom: 40, marginTop: 20 }} />
                </>
            )}
            {!data
                ? <Skeleton />
                : (
                    <Formik
                        initialValues={{
                            openingHours: data.openingHours,
                            pharmacyCount: data.pharmacyCount,
                            software: data.software,
                            population: data.population,
                            location: data.location,
                            foundingYear: data.foundingYear || '',
                            dateOfBalance: localDate(data.dateOfBalance) || '',
                            transferGK: data.transferGK || false,
                            monthlySubscribed: data.monthlySubscribed || false,
                        }}
                        validateOnChange={false}
                        validate={values => validate(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            savePharmacy({
                                ...(values as unknown as Pharmacy),
                                dateOfBalance: values.dateOfBalance ?
                                    new Date(Date.parse(values.dateOfBalance)) : null,
                            })
                                .finally(() => setSubmitting(false))
                        }}
                    >
                        {({ isSubmitting, submitForm }) => (
                            <Form>
                                <FormikTouchErrors />
                                <PharmacyDataForm
                                    isSignUp={false}
                                    isMain={!data.branch}
                                    displaySoftware={data.monthlySubscribed}
                                />
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                    fullWidth
                                >
                                    speichern
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                )
            }
        </NarrowContainer>
    );
};

export default PharmacyData;
