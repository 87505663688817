/**
 * Raw pharmacy with no foreign data
 */
export default class Pharmacy {
    id: Nullable<number> = null;
    number: string = '';
    title: string = '';
    foundingYear: Nullable<number>;
    openingHours: Nullable<number> = null;
    pharmacyCount: Nullable<number> = null;
    population: Nullable<number> = null;
    software: Nullable<number> = null;
    state: Nullable<number> = null;
    location: Array<number> = [];
    active: boolean = false;
    monthlySubscribed: boolean = false;
    transferGK: boolean = false;
    dateOfBalance: Nullable<string> = null;

    constructor(
        number: string,
        title: string,
        foundingYear: Nullable<number> = null,
        openingHours: Nullable<number> = null,
        pharmacyCount: Nullable<number> = null,
        population: Nullable<number> = null,
        state: Nullable<number> = null,
        software: Nullable<number> = null,
        location: Array<number> = [],
        active: boolean = true,
        monthlySubscribed: boolean = false,
        transferGK: boolean = false,
        dateOfBalance: Nullable<string> = null,
        id: Nullable<number> = null,
    ) {
        this.number = number;
        this.title = title;
        this.foundingYear = foundingYear;
        this.openingHours = openingHours;
        this.pharmacyCount = pharmacyCount;
        this.population = population;
        this.state = state;
        this.software = software;
        this.location = location;
        this.active = active;
        this.monthlySubscribed = monthlySubscribed;
        this.transferGK = transferGK;
        this.dateOfBalance = dateOfBalance;
        this.id = id;
    }
}
