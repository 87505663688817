import {
    Link,
    Typography,
} from '@material-ui/core';

import {
    Link as LinkWrap,
} from 'react-router-dom';
import NarrowBox from '../Common/Ui/NarrowBox';
import React from 'react';

const Imprint = () => {
    return (
        <>
            <NarrowBox>
                <Typography variant="h1">IMPRESSUM</Typography>
                <Typography variant="body2" gutterBottom>
                    Offenlegung nach § 25 Mediengesetz, Angaben nach E-Commerce Gesetz
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Österreichischer Apothekerverband
                    <br />
                    Interessenvertretung der selbständigen Apotheker
                    <br />
                    Spitalgasse 31
                    <br />
                    1090 Wien
                    <br />
                    T. +43 1 404 14-300
                    <br />
                    F. +43 1 408 62 80
                    <br />
                    M. service@apothekerverband.at
                    <br />
                    www.apothekerverband.at
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Der Österreichische Apothekerverband unterliegt als Verein der Aufsicht des Bundesministeriums für Inneres; Vertretungs- und zeichnungsberechtigt für den Österreichischen Apothekerverband ist der Präsident, in seiner Verhinderung der 1. Vizepräsident und bei deren Verhinderung der 2. Vizepräsident.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die Mitglieder des Vereinsvorstands finden Sie hier.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    UID Nummer: ATU 37632102;
                </Typography>
                <Typography variant="body1">
                    Tätigkeitsbereich:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Vertretung der Interessen sowie Information, Beratung und Unterstützung der selbständigen
                    Apotheker als deren Interessenvertretung. Der auf freiwilliger Mitgliedschaft basierende
                    Verein bezweckt gemäß seiner Satzung.
                </Typography>
                <Typography variant="body1">
                    Inhalt des Onlineangebotes:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Diese Website des Österreichischen Apothekerverbandes wird mit Sorgfalt bearbeitet und
                    laufend aktualisiert. Der Österreichische Apothekerverband übernimmt jedoch keine Haftung
                    für die Richtigkeit, Vollständigkeit, Aktualität oder Qualität der bereitgestellten
                    Informationen. Haftungsansprüche, welche sich auf Schäden materieller oder ideeller Art
                    beziehen, die durch die Nutzung der gebotenen Informationen bzw. durch die Nutzung
                    fehlerhafter und unvollständiger Informationen verursacht wurden, sind ausgeschlossen.
                </Typography>
                <Typography variant="h2" gutterBottom>
                    Datenschutzerklärung
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Datenschutz
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß der Vorgaben der EUDatenschutz-Grundverordnung zu erklären, welche Informationen wir sammeln, wie wir
                    Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite
                    haben.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir
                    haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar zu
                    beschreiben. Für nähere Details zur Website empfehlen wir die Durchsicht der
                    allgemeinen <LinkWrap component={Link} to="/agb" target="_blank">Nutzungsbedingungen</LinkWrap>.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Automatische Datenspeicherung
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch
                    erstellt und gespeichert, so auch auf dieser Webseite.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver
                    (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie
                </Typography>
                <Typography variant="body1" component="ul" gutterBottom>
                    <li>die Adresse (URL) der aufgerufenen Webseite</li>
                    <li>Browser und Browserversion</li>
                    <li>das verwendete Betriebssystem</li>
                    <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
                    <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
                    <li>Datum und Uhrzeit</li>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch
                    gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese
                    Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Speicherung persönlicher Daten
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel
                    Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der
                    Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit
                    dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher
                    verwahrt und nicht an Dritte weitergegeben.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern,
                    die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite
                    angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne
                    Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
                    von rechtswidrigem Verhalten eingesehen werden.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite –
                    können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir
                    empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Rechte laut Datenschutzgrundverordnung
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen
                    Datenschutzgesetzes (DSG) grundsätzlich die folgende Rechte zu:
                </Typography>
                <Typography variant="body1" component="ul" gutterBottom>
                    <li>Recht auf Berichtung (Artikel 16 DSGVO)</li>
                    <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
                    <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                    <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
                    <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                    <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
                    <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
                    oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind,
                    können Sie sich bei der Aufsichtsbehörde beschweren, welche in Österreich die
                    Datenschutzbehörde ist, deren Webseite Sie unter <Link href="https://www.dsb.gv.at/" target="_blank">https://www.dsb.gv.at/</Link> finden.
                </Typography>
            </NarrowBox>
        </>
    );
}

export default Imprint;
