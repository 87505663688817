import {
    CircularProgress,
} from '@material-ui/core';

const LoadingIndicator = ({ isLoading }: { isLoading: boolean }) => (
    isLoading ? (
        <div style={{ position: 'absolute', left: 20, bottom: 20 }}>
            {isLoading && <CircularProgress />}
        </div>
    ) : null
);

export default LoadingIndicator;
