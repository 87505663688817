import {
    Column,
    useTable,
} from 'react-table';
import React, { useMemo } from 'react';

import Involvement from '../Common/Involvement';
import ItemStatus from '../Common/ItemStatus';
import ResponsiveTable from '../Common/Ui/ResponsiveTable';
import User from '../../Lib/User';
import UserMenu from './UserMenu';

export type UsersListProps = {
    onEditRow: (model: any) => void,
    onActiveChange: (model: User, active: boolean) => void,
    data: Array<User>,
};

/**
 * Table of comparison groups
 */
const UsersList = ({
    onEditRow,
    onActiveChange,
    data,
}: UsersListProps) => {
    // Memoize data used, important
    const tableData = useMemo(
        () => data,
        [data],
    );

    // Memoized column definitions
    const columns: Column<User>[] = useMemo(
        () => [
            {
                Header: 'Titel',
                accessor: 'title', // accessor is the "key" in the data
            },
            {
                Header: 'Vorname',
                accessor: 'firstName',
            },
            {
                Header: 'Nachname',
                accessor: 'lastName',
            },
            {
                Header: 'Benutzer',
                accessor: 'username',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Funktion',
                accessor: 'involvement',
                Cell: ({ value }) => (
                    <Involvement value={value} />
                )
            },
            {
                id: 'active',
                accessor: 'active',
                align: 'center' ,
                Cell: ({ value }) => (
                    <ItemStatus status={value} />
                ),
                Header: () => (
                    <div style={{ textAlign: 'center' }}>
                        aktiv
                    </div>
                ),
            },
        ],
        [],
    );

    // Instance with table hook
    const tableInstance = useTable<User>({
        columns,
        data: tableData,
    });

    // Unwrap some properties
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = tableInstance;

    return (
        <ResponsiveTable<User>
            tableProps={getTableProps()}
            tableBodyProps={getTableBodyProps()}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            mobileView={[[3], [4, 6, 5]]}
            rows={rows}
            menu={({ original }) => (
                <UserMenu
                    onEdit={() => onEditRow(original)}
                    onActiveChange={(active) => onActiveChange(original, active)}
                    active={original.active}
                    canChangeActive={!original.mainUser}
                />
            )}
        />
    );
};

export default UsersList;
