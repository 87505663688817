import {
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

import React from 'react';
import {
    Skeleton,
} from '@material-ui/lab';

export type ListLoaderProps = {
    rowCount: number,
    withList?: boolean,
    content?: 1 | 2,
};

/**
 * A table row loading item
 */
const ListLoader: React.FC<ListLoaderProps> = ({
    rowCount,
    content = 2,
    withList = false,
}) => {
    const rows = Array(rowCount).fill(0).map((_v, rowIndex) => (
        <ListItem key={`loader_row_${rowIndex}`}>
            <ListItemText
                primary={<Skeleton />}
                secondary={content > 1 ? <Skeleton /> : undefined}
            />
        </ListItem>
    ));

    return withList ? (
        <List>
            {rows}
        </List>
    ) : (
        <>
            {rows}
        </>
    );
};

export default ListLoader;
