import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

type RequiredParams = {
    salaryDirector: RuleParamater,
    salaryPharmacist: RuleParamater,
    salaryAspirant: RuleParamater,
};

const messages = {
    high: 'Der ausgewiesene Gehalt beim Fachpersonal ist zu hoch bzw. der Personalstand zu niedrig. Bitte prüfen Sie die Angaben in der Personalstatistik sowie Ihren Personalaufwand in der GuV.',
    low: 'Der ausgewiesene Gehalt beim Fachpersonal ist zu niedrig bzw. der Personalstand zu hoch. Bitte prüfen Sie die Angaben in der Personalstatistik sowie Ihren Personalaufwand in der GuV.',
};

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RequiredParams, bound: 'low' | 'high') => (
    new UserValdiation(
        [
            [['staff.director', '*', params.salaryDirector.bounds[bound]],
                '+', [['staff.pharmacist', '*', params.salaryPharmacist.bounds[bound]],
                    '+', ['staff.aspirant', '*', params.salaryAspirant.bounds[bound]]
                ]],
            '/',
            'income.E7_LJ'
        ],
        // Compare this relatively means first part is higher or lower than second part of divison
        { type: 'compare', compare: bound === 'low' ? '<=' : '>=', value: 1 },
        messages[bound],
        undefined,
        bound === 'low' ? 'Personal_18' : 'Personal_19',
    )
);

export default rule;
