import { GroupTypeEntity } from '../../../Lib/ComparisonGroup';
import MultiSelect from '../MultiSelect';
import useCachedData from '../../../Hooks/useCachedData';

type LocationMultiSelectProps = {
    name: string,
    onChange: React.ChangeEventHandler<any>,
    initialValues?: Array<GroupTypeEntity>,
    value?: Array<GroupTypeEntity>,
}

const LocationMultiSelect = ({
    name,
    onChange,
    initialValues = [],
    value = [],
}: LocationMultiSelectProps) => {
    const selectData = useCachedData(['location']);

    const handleOnChange = (values: GroupTypeEntity[]) => {
        // @ts-ignore
        onChange({
            target: {
                name,
                value: values,
            },
        });
    };
    const initial = (initialValues.length ? initialValues : value).map((item) => (item.begID));

    return (
        <MultiSelect<GroupTypeEntity>
            title="Lage"
            items={selectData.location}
            labelKey="begBezeichnung"
            initialChecked={selectData.location.filter((loc: GroupTypeEntity) => initial.includes(loc.begID))}
            onChange={handleOnChange}
            exclusive={{
                19: [20, 25],
                20: [19, 26],
                21: [26],
                22: [26],
                23: [26],
                24: [26],
                25: [26],
                26: [19, 20, 21, 22, 23, 24, 25],
            }}
        />
    );
};

export default LocationMultiSelect;
