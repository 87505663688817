import {
    SvgIcon,
    SvgIconProps,
} from '@material-ui/core';

import { ReactComponent as ArrowIcon } from '../../../assets/apostar_pfeil.svg';
import React from 'react';

const defaultStyles = { verticalAlign: 'middle' };

const CreateIconComponent = (IconSvg: React.ElementType<any>, styles: any = {}): React.FC<Exclude<SvgIconProps, 'component'>> => (
    (props: Exclude<SvgIconProps, 'component'>) => (
        <SvgIcon
            component={IconSvg}
            viewBox="0 -14 60 60"
            fill="red"
            style={{ ...defaultStyles, ...styles }}
            {...props}
        />
    )
);

const ArrowRight = CreateIconComponent(ArrowIcon, { marginRight: '1rem' });
const ArrowLeft = CreateIconComponent(ArrowIcon, { marginRight: '1rem', transform: 'rotate(180deg)' });

export {
    ArrowRight,
    ArrowLeft,
}
