import React from 'react';
import {
    Table,
    TableRow,
    TableCell,
} from '@material-ui/core';
import {
    Skeleton,
} from '@material-ui/lab';


export type TableRowLoaderProps = {
    rowCount: number,
    colCount: number,
    withTable?: boolean,
};

/**
 * A table row loading item
 */
const TableRowLoader: React.FC<TableRowLoaderProps> = ({
    rowCount,
    colCount,
    withTable = false,
}) => {
    const rows = Array(rowCount).fill(0).map((_v, rowIndex) => (
        <TableRow key={`loader_row_${rowIndex}`}>
            {Array(colCount).fill(0).map((_w, colIndex) => (
                <TableCell key={`loader_cell_${rowIndex}_${colIndex}`}>
                    <Skeleton />
                </TableCell>
            ))}
        </TableRow>
    ));

    return withTable ? (
        <Table>
            {rows}
        </Table>
    ) : (
        <>
            {rows}
        </>
    );
};

export default TableRowLoader;
