import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';

import {
    Alert,
} from '@material-ui/lab';
import { Error } from '../../Lib/YearlyDataValidation';
import React from 'react';
import useTypeStyles from '../Common/Ui/useTypeStyles';

export type ErrorMessagesProps = {
    errors: Array<Error>,
    headerAction?: React.ReactNode,
};

const ErrorMessages: React.FC<ErrorMessagesProps> = ({
    errors,
    headerAction,
}) => {
    const classes = useTypeStyles();
    return (
        <Card>
            <CardHeader
                title={`${errors.length} potenzielle Auffälligkeiten gefunden`}
                action={headerAction}
                titleTypographyProps={{ className: classes.yearlyText }}
            />
            <List>
                {errors.map((error, i) => (
                    <ListItem key={`error_${i}`}>
                        <ListItemText
                            primary={(<Alert severity="warning" className={error.emphasized ? classes.em : ''}>{error.message}</Alert>)}
                        />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <CardHeader title="Info" titleTypographyProps={{ className: classes.yearlyText }} />
            <CardContent>
                <Typography variant="body1">
                    Bitte prüfen Sie noch einmal die Eingabe zu den oben ausgewiesenen Hinweisen.
                    Möglicherweise hat sich bei der Eingabe der Daten ein Fehler eingeschlichen.
                    Sollte dem nicht so sein, bitte den Hinweis ignorieren, Ihre Daten werden so wie
                    von Ihnen eingeben gespeichert und für die Auswertung übernommen.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ErrorMessages;
