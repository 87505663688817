import {
    Grid,
    InputAdornment,
    ListItem,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { RuleParamaterInput } from './Definitions';
import {
    Timelapse,
} from '@material-ui/icons';
import useChangeEffect from '../../Hooks/useChangeEffect';

/**
 * Properties of component
 */
type ParamProps = {
    label: string,
    id: string,
    numType: 'pct' | 'abs' | 'quota',
    initialValue?: RuleParamaterInput,
    onChange: (value: RuleParamaterInput) => void,
};

function pctConvert(value: number, fromPct: boolean = false) {
    return fromPct ? (value / 100) : Math.round(value * 100);
}

function pctConvertBounds(value: RuleParamaterInput['bounds'], fromPct: boolean = false) {
    return {
        low: value?.low !== undefined ? pctConvert(value.low, fromPct) : null,
        high: value?.high !== undefined ? pctConvert(value.high, fromPct) : null,
    };
}

function convertPctBounds(value: RuleParamaterInput, fromPct: boolean = false) {
    return {
        bounds: pctConvertBounds(value.bounds, fromPct),
        boundsCritical: pctConvertBounds(value.boundsCritical, fromPct),
    };
}

/**
 * Income sheet form component
 */
const Param: React.FC<ParamProps> = ({
    label,
    id,
    numType,
    initialValue,
    onChange,
}) => {
    // Use the display value internally if pct is used
    const [value, setValue] = useState(
        initialValue && numType === 'pct'
            ? convertPctBounds(initialValue)
            : (initialValue || {})
    );

    // Handle change
    const onInputChange = ({ target: { name, valueAsNumber } }: React.ChangeEvent<HTMLInputElement>) => {
        setValue((prev) => {
            const value = isNaN(valueAsNumber) ? undefined : valueAsNumber;
            const newValue = { ...prev };
            const [outer, inner] = name.split('_');
            // @ts-ignore
            if (!newValue[outer]) {
                // @ts-ignore
                newValue[outer] = {};
            }
            // @ts-ignore
            newValue[outer][inner] = value;

            console.log("Change", name, value, newValue);
            return newValue;
        });
    };

    useChangeEffect(() => {
        // @ts-ignore
        onChange((numType === 'pct' ? convertPctBounds(value, true) : value));
    }, [value]);

    const icon = numType === 'abs' ? 'EUR' :
        (numType === 'quota' ? <Timelapse color="disabled"/> : '%');
    const adornment = (
        <InputAdornment position="start">{icon}</InputAdornment>
    );

    return (
        <ListItem disableGutters dense>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <Typography>{label}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        inputProps={{ type: 'number', style: { textAlign: 'right' } }}
                        name="bounds_low"
                        onChange={onInputChange}
                        defaultValue={value?.bounds?.low}
                        InputProps={{
                            startAdornment: adornment,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        inputProps={{ type: 'number', style: { textAlign: 'right' } }}
                        name="bounds_high"
                        onChange={onInputChange}
                        defaultValue={value?.bounds?.high}
                        InputProps={{
                            startAdornment: adornment,
                        }}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <TextField
                        inputProps={{ type: 'number', style: { textAlign: 'right' } }}
                        name="boundsCritical_low"
                        onChange={onInputChange}
                        defaultValue={value?.boundsCritical?.low}
                        InputProps={{
                            startAdornment: adornment,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        inputProps={{ type: 'number', style: { textAlign: 'right' } }}
                        name="boundsCritical_high"
                        onChange={onInputChange}
                        defaultValue={value?.boundsCritical?.high}
                        InputProps={{
                            startAdornment: adornment,
                        }}
                    />
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default Param;
