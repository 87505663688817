import {
    FormGroup,
    Grid,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

import { localDate } from '../../Lib/Helper';
import useChangeEffect from '../../Hooks/useChangeEffect';

export type DateInputProps = {
    id?: string,
    label: string,
    initialValue?: Date,
    value?: Date,
    onChange?: (value?: Date, id?: string) => void,
    min?: string,
    max?: string,
    type?: 'full' | 'start' | 'end',
};

// Convert to the correct value string including days or not
const toValue = (date?: Date, type?: 'full' | 'start' | 'end', monthSupport?: boolean) => (
    localDate(date)?.substring(0, !monthSupport || type === 'full' ? 10 : 7)
);

// Wee need this intermediate function
const dateWithDay = (day: number, value?: Date) => {
    if (!value) return value;
    value.setDate(day);
    return value;
}

// Convert to a correct date
const toDate = (value?: Date, type?: 'full' | 'start' | 'end') => {
    return (
        type === 'full' ? value : (
            dateWithDay(!value || type === 'start'
                ? 1
                : new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate(), value)
        )
    );
};

// Convert to correct date with fallback
const toDateWithFallback = (
    target: React.ChangeEvent<HTMLInputElement>['target'],
    type?: 'full' | 'start' | 'end',
) => {
    if (target.valueAsDate) {
        return toDate(target.valueAsDate, type);
    }

    const usedDate = Date.parse(target.value);
    return toDate(isNaN(usedDate) ? new Date() : new Date(usedDate), type);
};

// Check if month is supported by browser
const supportsMonth = () => {
    var input = document.createElement('input');
    input.setAttribute('type', 'month');
    return input.type === 'month';
};

/**
 * Number input with a comparison and derived trend to a previous value
 */
const DateInput: React.FC<DateInputProps> = ({
    id,
    label,
    onChange,
    initialValue,
    value: propValue,
    min,
    max,
    type = 'full',
}) => {
    // Value state reference
    const [value, setValue] = useState<Date | undefined>(initialValue);
    useEffect(() => {
        if (undefined === propValue && initialValue) {
            return;
        }

        setValue(propValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propValue]);

    // Check month support, fixefox does not support it!
    const monthSupport = useMemo(() => supportsMonth(), []);
    const inputTypeDate = !monthSupport || type === 'full';

    // Notify change
    useChangeEffect(() => onChange?.(value, id), [value]);

    return (
        <FormGroup style={{ marginBottom: '2em' }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={8}>
                    <InputLabel style={{ color: 'black' }}>
                        {label}
                    </InputLabel>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OutlinedInput
                        type="number"
                        inputProps={{
                            type: inputTypeDate ? 'date' : 'month',
                            min: inputTypeDate ? min : min?.substring(0, 7),
                            max: inputTypeDate ? max : max?.substring(0, 7),
                        }}
                        value={toValue(value, type, monthSupport)}
                        defaultValue={toValue(initialValue, type, monthSupport)}
                        placeholder=""
                        // @ts-ignore
                        onChange={({ target }) => setValue(toDateWithFallback(target, type))}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </FormGroup>
    );
};

export default DateInput;
