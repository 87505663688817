import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useFabStyles = makeStyles((theme: Theme) => ({
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    fabIcon: {
        fontSize: 40,
    },
    globalLoader: {
        position: 'absolute',
        left: 20,
        bottom: 20,
    },
}));

export default useFabStyles;
