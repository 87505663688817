import { KeysOfType, booleanToYN, getModelValue, toApiDate } from './Helper';
import apiSchema, { PharmacyApiEntity } from '../Schemas/Api/Pharmacy';
import infoApiSchema, { PharmacyInfoApiEntity } from '../Schemas/Api/PharmacyInfo';

import { AjvJTD as Ajv } from './Ajv';
import { GroupTypeEntity } from './ComparisonGroup';
import { JTDParser } from "ajv/dist/jtd"
import Pharmacy from './Pharmacy';
import PharmacyData from './PharmacyData';
import SchemaMapper from './SchemaMapper';

export default class PharmacyMapper extends SchemaMapper<Pharmacy, PharmacyApiEntity> {
    schema = apiSchema;
    infoParser: JTDParser<PharmacyInfoApiEntity>;

    openingHoursData: Array<GroupTypeEntity>;
    pharmacyCountData: Array<GroupTypeEntity>;
    populationData: Array<GroupTypeEntity>;
    stateData: Array<GroupTypeEntity>;
    locationData: Array<GroupTypeEntity>;
    softwareData: Array<GroupTypeEntity>;

    dataKeys = {
        openingHours: 'openingHoursData',
        pharmacyCount: 'pharmacyCountData',
        population: 'populationData',
        software: 'softwareData',
        state: 'stateData',
        location: 'locationData',
    } as const;

    constructor(
        openingHoursData: Array<GroupTypeEntity>,
        pharmacyCountData: Array<GroupTypeEntity>,
        populationData: Array<GroupTypeEntity>,
        stateData: Array<GroupTypeEntity>,
        locationData: Array<GroupTypeEntity>,
        softwareData: Array<GroupTypeEntity>,
    ) {
        super();
        this.openingHoursData = openingHoursData;
        this.pharmacyCountData = pharmacyCountData;
        this.populationData = populationData;
        this.stateData = stateData;
        this.locationData = locationData;
        this.softwareData = softwareData;

        this.infoParser = Ajv.compileParser(infoApiSchema);
    }

    /**
     * Map json data to a Pharmacy model
     */
    mapToModel(data: PharmacyApiEntity) {
        const pharmacy = new Pharmacy(
            data.apoBetrnr,
            data.apoBezeichnung,
            data.apoGruendungsjahr,
            this.single(this.findValueFromData('openingHours', data.apoOeffnungsdauerID)),
            this.single(this.findValueFromData('pharmacyCount', data.apoApoAnzID)),
            this.single(this.findValueFromData('population', data.apoEinwAnzID)),
            this.single(this.findValueFromData('state', data.apoBundeslandID)),
            this.single(this.findValueFromData('software', data.apoSoftwareID)),
            this.findValueFromData('location', data.apoLageIds),
            data.apoAktiv === "J",
            data.apoAbvMonatlich === "J",
            data.apoGhkTransfer === "J",
            data.apoBilanzstichtag ? new Date(Date.parse(data.apoBilanzstichtag)) : null,
            data.apoID || null,
        );

        if (data.apoFilapo === 'J' && data.apoHauptApoID) {
            pharmacy.setIsBranchOf(data.apoHauptApoID);
        }

        return pharmacy;
    }

    mapToDataModel(data: PharmacyInfoApiEntity) {
        return new PharmacyData(
            data.apoBetrnr,
            data.apoBezeichnung,
            data.apoBundesland,
            data.apoAktiv === "J",
        );
    }

    /**
     * Map Pharmacy model to json data
     */
    mapToData = (model: Pharmacy): PharmacyApiEntity => {
        return {
            apoBetrnr: model.number,
            apoBezeichnung: model.title,
            apoGruendungsjahr: model.foundingYear || null,
            apoID: model.id || 0,
            apoOeffnungsdauerID: getModelValue(model, 'openingHours'),
            apoApoAnzID: getModelValue(model, 'pharmacyCount'),
            apoEinwAnzID: getModelValue(model, 'population'),
            apoBundeslandID: getModelValue(model, 'state'),
            apoSoftwareID: getModelValue(model, 'software'),
            apoAktiv: booleanToYN(model.active),
            apoLageIds: model.location.map((location) => (location.begID)).join(';'),
            apoAbvMonatlich: booleanToYN(model.monthlySubscribed),
            apoGhkTransfer: booleanToYN(model.transferGK),
            apoBilanzstichtag: toApiDate(model.dateOfBalance),
            apoHauptApoID: model.mainPharmacyId,
            apoFilapo: booleanToYN(model.branch),
        };
    };

    findValueFromData(
        key: KeysOfType<Pharmacy, Nullable<GroupTypeEntity>|GroupTypeEntity[]>,
        dataValue: number | string | null,
    ): Array<GroupTypeEntity> {
        if (!dataValue) { return []; }

        const searchIn = typeof dataValue === 'string' ? dataValue.split(';').map((i) => parseInt(i, 10)) : [dataValue];

        const data = this[this.dataKeys[key]];
        const matchedItems = searchIn.map((id) => (data.find((item) => (item.begID === id))))
            .filter((value) => (value !== undefined));

        // Undefined from data.find in map is filtered out in the last step, TS does not infer this,
        // so we need to cast here.
        return matchedItems as unknown as GroupTypeEntity[];
    }

    single(items: Array<GroupTypeEntity>): GroupTypeEntity | null {
        return items.length > 0 ? items[0] : null;
    }
}
