import { useCallback, useState } from 'react';

const useDialog = (openInitially: boolean = false) => {
    const [isOpen, setOpen] = useState<boolean>(openInitially);
    const open = useCallback(() => setOpen(true), [setOpen]);
    const close = useCallback(() => setOpen(false), [setOpen]);

    return {
        open,
        close,
        isOpen,
    };
};


export default useDialog;
