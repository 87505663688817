import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for pharmacy info entity types.
 */

/**
 * The typed entity from api
 */
export type PharmacyInfoApiEntity = {
    apoBetrnr: string,
    apoBezeichnung: string,
    apoBundesland: string,
    apoAktiv: 'J' | 'N',
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<PharmacyInfoApiEntity> = {
    properties: {
        apoBetrnr: { type: 'string' },
        apoBezeichnung: { type: 'string' },
        apoBundesland: { type: 'string' },
        apoAktiv: { enum: ['J', 'N'] }
    },
};
export default schema;
