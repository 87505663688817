import { Link, Typography } from '@material-ui/core/';
import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

export default function Footer() {
    // History is used
    const history = useHistory();
    const goToTerms = useCallback(() => history.push('/agb'), [history]);
    const goToFaq = useCallback(() => history.push('/faq'), [history]);
    const goToContact = useCallback(() => history.push('/kontakt'), [history]);
    const goToImprint = useCallback(() => history.push('/impressum'), [history]);

    return (
        <div className="App-footer">
            <Typography variant="body2" color="textSecondary" align="center">
                Copyright ©
                {' '}
                <Link color="inherit" href="https://www.apothekerverband.at/">
                    Apothekerverband
                </Link>
                {' '}
                {new Date().getFullYear()}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                <Link component="button" onClick={goToTerms}>
                    Nutzungsbedingungen
                </Link>
                {' | '}
                <Link component="button" onClick={goToFaq}>
                    FAQ
                </Link>
                {' | '}
                <Link component="button" onClick={goToContact}>
                    Kontakt
                </Link>
                {' | '}
                <Link component="button" onClick={goToImprint}>
                    Impressum
                </Link>
            </Typography>
            <Typography
                variant="caption"
                color="textSecondary"
                align="center"
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 10,
                    zIndex: -1,
                }}
            >
                {process.env.REACT_APP_VERSION}-{process.env.REACT_APP_GIT_SHA}
            </Typography>
        </div>
    );
}
