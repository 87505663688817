import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type YearlyPharmacyApiEntity = {
    ApoABVJahrKurzBez: string,
    ApoABVJahrApoID: number,
    ApoABVJahrWj: number,
    ApoABVJahrBegID: number,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<YearlyPharmacyApiEntity> = {
    properties: {
        ApoABVJahrKurzBez: { type: 'string' },
        ApoABVJahrApoID: { type: 'int32' },
        ApoABVJahrWj: { type: 'int32' },
        ApoABVJahrBegID: { type: 'int32' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const YearlyPharmacyArray: JTDSchemaType<Array<YearlyPharmacyApiEntity>> = {
    elements: schema,
};
