import { RuleParamaters, UserValdiation } from '../YearlyDataValidation';

import AspirantCountCreator from './AspirantCount';
import BalanceEquality from './BalanceEquality';
import BranchPharmacyTrend from './BranchPharmacyTrend';
import BuyingSyndicateCreator from './BuyingSyndicate';
import BuyingSyndicateTrend from './BuyingSyndicateTrend';
import ConcessionaireCreator from './Concessionaire';
import CustomerCountTrendCreator from './CustomerCountTrend';
import ExepensesToSalesCreator from './ExepensesToSales';
import FamiliaryWorkCreator from './FamiliaryWork';
import GrossProfitToSalesCreator from './GrossProfitToSales';
import GrossProfitTrendCreator from './GrossProfitTrend';
import HighPriceMoreThanInsuranceShare from './HighPriceMoreThanInsuranceShare';
import InsurancePackagesTrendCreator from './InsurancePackagesTrend';
import InsuranceShareCreator from './InsuranceShare';
import InsuranceShareTrendCreator from './InsuranceShareTrend';
import LegalSocialExpensesCreator from './LegalSocialExpenses';
import MaterialExpenseCreator from './MaterialExpense';
import MaterialExpenseTrendCreator from './MaterialExpenseTrend';
import OfficinAreaTrendCreator from './OfficinAreaTrend';
import PrescriptionTrendCreator from './PrescriptionTrend';
import PrivatePackagesTrendCreator from './PrivatePackagesTrend';
import PrivateShareTrendCreator from './PrivateShareTrend';
import ProfitCreator from './Profit';
import PropertyOwnerCreator from './PropertyOwner';
import PropertyOwnerTrend from './PropertyOwnerTrend';
import SalesAreaTrendCreator from './SalesAreaTrend';
import SkilledStaffMissing from './SkilledStaffMissing';
import SkilledStaffWithoutExpenses from './SkilledStaffWithoutExpenses';
import StaffExpenseCreator from './StaffExpense';
import StaffExpenseToProfitCreator from './StaffExpenseToProfit';
import StaffExpenseTrendCreator from './StaffExpenseTrend';
import StaffNonPharmacistExpenseCreator from './StaffNonPharmacistExpense';
import StaffPharmacistExpenseCreator from './StaffPharmacistExpense';
import StockDifferenceCreator from './StockDifference';
import TenureExpenseCreator from './TenureExpense';
import TenureExpenseTrendCreator from './TenureExpenseTrend';
import TenureMissingCreator from './TenureMissing';
import UnskilledStaffMissing from './UnskilledStaffMissing';
import UnskilledStaffWithoutExpenses from './UnskilledStaffWithoutExpenses';

/**
 * All validations combined in one array
 */
const createValidationsWithParams = (params: RuleParamaters): Array<UserValdiation> => ([
    BalanceEquality(params.balanceEquality),
    StockDifferenceCreator(params.stockRelationLastYear),
    InsuranceShareCreator(params.insuranceSalesRelationOfficin),
    HighPriceMoreThanInsuranceShare,
    BuyingSyndicateCreator(params.salesBuyingSyndicate),
    LegalSocialExpensesCreator(params.legalSocialExpense),
    ProfitCreator(params.profit),
    PrivateShareTrendCreator(params.privateSalesRelationLastYear),
    InsuranceShareTrendCreator(params.insuranceSalesRelationLastYear),
    TenureExpenseTrendCreator(params.tenureExpense),
    MaterialExpenseCreator(params.materialExpenseRelationSales),
    MaterialExpenseTrendCreator(params.materialExpenseRelationLastYear),
    StaffExpenseCreator(params.staffExpenseRelationSales),
    StaffExpenseTrendCreator(params.staffExpenseRelationLastYear),
    StaffExpenseToProfitCreator(params.staffExpenseRelationProfit),
    ExepensesToSalesCreator(params.expensesRelationSales),
    GrossProfitToSalesCreator(params.profitRelationSales),
    GrossProfitTrendCreator(params.profitRelationLastYear),
    AspirantCountCreator(params.apirantTotalQuota),
    ConcessionaireCreator(params.quotaConcessionaire),
    CustomerCountTrendCreator(params.customerCountRelationLastYear),
    FamiliaryWorkCreator(params.familiaryTotalQuota),
    InsurancePackagesTrendCreator(params.insurancePackageSalesRelationLastYear),
    OfficinAreaTrendCreator(params.officeAreasRelationLastYear),
    PrescriptionTrendCreator(params.prescriptionCountRelationLastYear),
    PrivatePackagesTrendCreator(params.privatePackageSalesRelationLastYear),
    PropertyOwnerCreator(params.rentalExpenseOwnership),
    PropertyOwnerTrend,
    SalesAreaTrendCreator(params.workingAreasRelationLastYear),
    SkilledStaffMissing,
    SkilledStaffWithoutExpenses,
    TenureExpenseCreator(params.tenureExpenseRelationSales),
    TenureMissingCreator(params.tenureExpenseWithoutTenure),
    UnskilledStaffMissing,
    UnskilledStaffWithoutExpenses,
    BranchPharmacyTrend,
    BuyingSyndicateTrend,
    StaffPharmacistExpenseCreator(params, 'low'),
    StaffPharmacistExpenseCreator(params, 'high'),
    StaffNonPharmacistExpenseCreator(params, 'low'),
    StaffNonPharmacistExpenseCreator(params, 'high'),
]);

export default createValidationsWithParams;
