import {
    AppBar,
    IconButton,
    Link,
    Toolbar,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import CubeLogo from './CubeLogo';
import Menu from './Menu';
import {
    Menu as MenuIcon,
} from '@material-ui/icons';
import useAuth from '../Hooks/useAuth';
import useCachedData from '../Hooks/useCachedData';
import {
    useHistory,
} from 'react-router-dom';

/**
 * Layouts header, display an app bar and a global menu.
 */
export default function Header() {
    // The state of drawer
    const [open, setOpen] = useState(false);

    // Use authentication
    const { authentication, signOut } = useAuth();
    const { role } = useCachedData(['role']);

    // Drawer states management
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // History is used
    const history = useHistory();

    // Go to home
    const goToHome = useCallback(() => history.push('/'), [history]);

    // Close drawer on navigation, pass unlisten as return argument (return value from listen())
    useEffect(() => history.listen(() => {
        handleDrawerClose();
    }));

    // Drawer is conditional if authentication is present
    const drawer = !authentication ? null : (
        <Menu
            open={open}
            onNavigate={(path) => history.push(path)}
            onSignOut={() => signOut()}
            onClose={() => handleDrawerClose()}
            authentication={authentication}
            roles={role}
        />
    );

    // We display either a menu drawer icon or a button to go to the sign in page
    const menuButton = !authentication ? (
        <Link onClick={goToHome} color="inherit" component="button">
            <Typography component="span" variant="body1">Zum Login</Typography>
        </Link>
    ) : (
        <IconButton onClick={handleDrawerOpen} color="inherit">
            <MenuIcon
                color="inherit"
                style={{ fontSize: 40 }}
            />
        </IconButton>
    );

    // Main UI
    return (
        <>
            <AppBar position="absolute" color="primary">
                <Toolbar>
                    <CubeLogo onClick={goToHome} />
                    {menuButton}
                </Toolbar>
            </AppBar>
            {drawer}
        </>
    );
}
