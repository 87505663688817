import {
    Column,
    useTable,
} from 'react-table';
import React, { useMemo } from 'react';

import MainPharmacyIndicator from './MainPharmacyIndicator';
import MonthlyControl from './MonthlyControl';
import Pharmacy from '../../Lib/Pharmacy';
import ResponsiveTable from '../Common/Ui/ResponsiveTable';

export type TableProps = {
    data: Array<Pharmacy>,
    emptyView?: React.ReactElement,
    onActivate: (pharmacy: Pharmacy) => void,
};

/**
 * Table of comparison groups
 */
const Table = ({
    data,
    emptyView,
    onActivate,
}: TableProps) => {
    // Memoize data used, important
    const tableData = useMemo(
        () => data,
        [data],
    );

    // Memoized column definitions
    const columns: Column<Pharmacy>[] = useMemo(
        () => [
            {
                Header: 'Nummer',
                id: 'type',
                accessor: 'number',
            },
            {
                Header: 'Name',
                accessor: 'title',
            },
            {
                Header: 'Typ',
                accessor: 'branch',
                Cell: ({ value }) => (<MainPharmacyIndicator value={!value} />),
            },
            {
                Header: 'monatl. Betriebsvergleich',
                accessor: 'monthlySubscribed',
                Cell: ({ value, row: { original }}) => (<MonthlyControl isActive={value} onActivate={() => onActivate(original)} />),
            },
        ],
        [onActivate],
    );

    // Instance with table hook
    const tableInstance = useTable<Pharmacy>({
        columns,
        data: tableData,
    });

    // Unwrap some properties
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = tableInstance;

    return (
        <ResponsiveTable<Pharmacy>
            tableProps={getTableProps()}
            tableBodyProps={getTableBodyProps()}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            mobileView={[[3], [0, 1, 2]]}
            rows={rows}
            menuHeader="Download"
            emptyView={emptyView}
        />
    );
};

export default Table;
