import UserMapper, { parseJSON } from '../Lib/UserMapper';

import { Credentials } from '../Providers/DataProvider';
import JSONFetch from '../Lib/JSONFetch';
import RawPharmacyMapper from '../Lib/RawPharmacyMapper';
import User from '../Lib/User';
import UserWithPharmacy from '../Lib/UserWithPharmacy';
import {
    getDefaultCredentialStore,
} from '../Lib/CredentialStore';

/**
 * Sign in method, stores credentials on success
 */
const signIn = ({ username, password }: Credentials): Promise<UserWithPharmacy> => {
    return JSONFetch({
        method: 'post',
        url: '/CheckLogin',
        body: { benUsername: username, benPwd: password },
        bodySerializer: JSON.stringify,
        responseParser: parseJSON,
    })
        .then((userData) => {
            // Invalid response
            if (!userData) {
                throw new Error('Invalid');
            }

            // Store the credentials
            getDefaultCredentialStore()?.storeCredentials(
                username, password,
            );

            // Map the user object
            return (new UserMapper()).mapToModel(userData);
        })
        .then((user: User) => (
            JSONFetch({
                method: 'get',
                url: `/ApothekeByID/${encodeURIComponent(user.pharmacyId)}`,
                bodySerializer: () => '',
                // We use an empty pharmacy mapper that's enough for a flat incomplete entity
                responseParser: (new RawPharmacyMapper()).parse,
            })
                .then((pharmacy) => (
                    new UserWithPharmacy(user, pharmacy)
                ))
        ));
};

/**
 * Sign out
 */
const signOut = (): Promise<void> => {
    getDefaultCredentialStore()?.clear();
    return Promise.resolve();
};

// Export
export {
    signIn,
    signOut,
};
