import {
    CircularProgress,
    Fab,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useMemo, useRef } from 'react';

import { Add } from '@material-ui/icons';
import EditDialog from '../Common/Dialogs/EditDialog';
import Form from './UserForm';
import { FormikProps } from 'formik';
import TableEmptyState from '../Common/Ui/TableEmptyState';
import User from '../../Lib/User';
import UserService from '../../Lib/UserService';
import UsersList from './UsersList';
import { getDefaults } from '../../Lib/Roles';
import { updateData as mergeData } from '../../Lib/Helper';
import useAuth from '../../Hooks/useAuth';
import useEditDialog from '../../Hooks/useEditDialog';
import useFabStyles from '../Common/useFabStyles';
import useLoad from '../../Hooks/useLoad';
import useSaveWithNotification from '../../Hooks/useSaveWithNotification';
import useService from '../../Hooks/useService';

const { accountantRole } = getDefaults();

const Users : React.FC<{}> = () => {
    // Authentication required
    const { authentication } = useAuth();

    // Load once
    const { data: dataState, isLoading, error, updateData } = useLoad<UserService, User[], number>(
        'user', (service, filterBranch) => service.getAll(filterBranch || 0), authentication?.pharmacyId
    );
    console.log(error);

    // State used
    const Service = useService<UserService>('user');
    const setActive = useSaveWithNotification((model: User, active: boolean) => {
        if (model.mainUser) {
            return Promise.reject('Hauptbenutzer kann nicht deaktiviert werden');
        }
        model.active = active;
        return Service.setActive(model, active).then(() => updateData(mergeData(model, dataState || [])));
    }, { error: 'Speichern fehlgeschlagen. Bitte beachten Sie: Hauptbenutzer kann nicht deaktiviert werden'});

    // Check wheter an accountant already exists
    const nextAccountantNumber = useMemo(
        () => (
            dataState && dataState.length >= 1
                ? dataState.filter(({ role }) => (role === accountantRole)).length + 1
                : false
        ),
        [dataState],
    );

    // For now we use a trick to submit the form within modal
    const formRef = useRef<FormikProps<any>>();
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current?.handleSubmit();
        }
    };

    // Manage dialog state
    const {
        open: showDialog,
        close: closeDialog,
        model: editModel,
        getEditDialogProps,
        // changeHandler,
    } = useEditDialog<User>({
        title: 'Neuer Benutzer',
        editTitle: 'Benutzer bearbeiten',
        closeOnSave: false,
        // This is not typed yet correctly because of complexities in wrapping functions. However
        // this is most likely to change significantly so typing will be done when rework will be
        // progressed
        // @ts-ignore
        onSave: handleSubmit,
    });

    // Custom save handlers with notifications
    const save = useSaveWithNotification((data: User) => {
        closeDialog();
        return Service.save(data).then((updatedUser) => updateData(mergeData(updatedUser, dataState || [])))
    });

    // Stylings
    const classes = useFabStyles();

    return (
        <>
            <Paper elevation={0}>
                <Toolbar disableGutters>
                    <Typography variant="h6" component="div">
                        Benutzer
                    </Typography>
                </Toolbar>
                <UsersList
                    onEditRow={showDialog}
                    onActiveChange={setActive}
                    data={dataState || []}
                />
                <TableEmptyState
                    state={{ data: dataState, error, isLoading }}
                />
            </Paper>
            <EditDialog {...getEditDialogProps()}>
                <Form
                    model={editModel ?? undefined}
                    // @ts-ignore
                    formikRef={formRef}
                    onSubmit={save}
                    nextAccountantNumber={nextAccountantNumber}
                />
            </EditDialog>
            {nextAccountantNumber !== false && (
                <Tooltip title="neuen Benutzer anlegen">
                    <Fab
                        color="secondary"
                        aria-label="add"
                        className={classes.fab}
                        onClick={() => showDialog()}
                    >
                        <Add className={classes.fabIcon} />
                    </Fab>
                </Tooltip>
            )}
            <div className={classes.globalLoader}>
                {isLoading && <CircularProgress />}
            </div>
        </>
    );
};

export default Users;
