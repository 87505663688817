import {
    Column,
    TableInstance,
} from 'react-table';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {
    locationCellRender,
    nestedBenchmarkingGroupAccessor,
} from '../../Lib/Helper';

import ComparisonGroup from '../../Lib/ComparisonGroup';
import ItemMenu from './ComparisonGroupMenu';
import ItemStatus from '../Common/ItemStatus';

/**
 * Column definitions for the table
 */
export const columnDefinitions: Column<ComparisonGroup>[] =
    [
        {
            Header: 'Offizinumsatz',
            accessor: 'offizin', // accessor is the "key" in the data
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Privatanteil',
            accessor: 'privateShare',
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Hochpreis',
            accessor: 'highPriceShare',
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Öffnungszeiten',
            accessor: 'openingHours',
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Anzahl',
            accessor: 'pharmacyCount',
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Einwohner',
            accessor: 'population',
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Bundesland',
            accessor: 'state',
            Cell: nestedBenchmarkingGroupAccessor,
        },
        {
            Header: 'Lage',
            accessor: 'location',
            Cell: locationCellRender,
        },
        {
            id: 'col3',
            accessor: 'active',
            Cell: ({ value }) => (
                <div style={{ textAlign: 'center' }}>
                    <ItemStatus status={value} />
                </div>
            ),
            Header: () => (
                <div style={{ textAlign: 'center' }}>
                    aktiv
                </div>
            ),
        },
        {
            id: 'col4',
            accessor: 'valid',
            Cell: ({ value }) => (
                <div style={{ textAlign: 'center' }}>
                    <ItemStatus status={value} />
                </div>
            ),
            Header: () => (
                <div style={{ textAlign: 'center' }}>
                    gültig
                </div>
            ),
        },
    ];

export type TableRenderProps = Pick<
    TableInstance<ComparisonGroup>,
    'getTableProps'
    | 'getTableBodyProps'
    | 'headerGroups'
    | 'prepareRow'
    | 'page'
    | 'setPageSize'
    | 'setGlobalFilter'
    | 'onEditRow'
    | 'onQuickEditRow'
>;

/**
 * Function to display table
 */
const tableRender = ({
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    page,
    onEditRow,
    onQuickEditRow
}: TableRenderProps) => (
    <Table {...getTableProps()}>
        <TableHead>
            {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <TableCell {...column.getHeaderProps()}>
                            {column.render('Header')}
                        </TableCell>
                    ))}
                    <TableCell />
                </TableRow>
            ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
                prepareRow(row);
                return (
                    <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                            <TableCell {...cell.getCellProps()}>
                                {cell.render('Cell')}
                            </TableCell>
                        ))}
                        <TableCell style={{ width: 50 }} align="right">
                            <ItemMenu
                                active={row.original.active}
                                onEdit={() => onEditRow(row.original)}
                                onActiveChange={(isActive) => onQuickEditRow(row.original, isActive)}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    </Table>
);

export default tableRender;
