import { Form, Formik, useFormikContext } from 'formik';

import Pharmacy from '../../Lib/Pharmacy';
import PharmacyDataForm from '../User/PharmacyDataForm';
import React from 'react';
import { localDate } from '../../Lib/Helper';
import useChangeEffect from '../../Hooks/useChangeEffect';

type PharmacyDataProps = {
    onChange?: (values: Pharmacy | undefined) => void,
    initialValues?: Pharmacy,
};

const EmbeddedPharmacyDataForm = ({
    onChange = () => {},
}: PharmacyDataProps) => {
    const { values } = useFormikContext();
    useChangeEffect(() => onChange({
        // @ts-ignore
        ...values,
        // @ts-ignore
        dateOfBalance: values.dateOfBalance ?
        // @ts-ignore
            new Date(Date.parse(values.dateOfBalance)) : null,
    }), [values]);

    return (<PharmacyDataForm isSignUp={false} displayDates={false} displaySoftware={false} />);
};

const PharmacyData : React.FC<PharmacyDataProps> = ({
    onChange = () => {},
    initialValues,
}) => {
    return (
        <Formik
            initialValues={{
                openingHours: initialValues?.openingHours,
                pharmacyCount: initialValues?.pharmacyCount,
                software: initialValues?.software,
                population: initialValues?.population,
                location: initialValues?.location,
                foundingYear: initialValues?.foundingYear || '',
                dateOfBalance: initialValues?.dateOfBalance instanceof Date
                    ? localDate(initialValues.dateOfBalance) : '',
            }}
            validateOnChange={false}
            onSubmit={() => {}}
        >
            <Form>
                <EmbeddedPharmacyDataForm
                    onChange={(formData) => onChange(Object.assign({}, initialValues || {}, formData))}
                />
            </Form>
        </Formik>
    );
};

export default PharmacyData;
