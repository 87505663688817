import {
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from '@material-ui/core';

import { Close } from '@material-ui/icons';
import React from 'react';

export type EditDialogProps = {
    onClose: () => void,
    onAction?: (action: string) => void,
    actions?: Array<string>,
    title?: string,
    children: React.ReactNode,
    open?: boolean,
    actionProps?: Record<string, Partial<ButtonProps>>,
    stackedActions?: boolean,
};

const EditDialog: React.FC<EditDialogProps> = ({
    onClose,
    children,
    title = null,
    open = true,
    onAction = () => {},
    actions = [],
    actionProps = {},
    stackedActions = false,
}) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        {title && (
            <DialogTitle>
                {title}
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
        )}
        <DialogContent>
            {children}
        </DialogContent>
        {actions && actions.length > 0 && (
            <DialogActions {...(stackedActions ? { style: { flexDirection: 'column' } } : {})}>
                {actions.map((label) => (
                    <Button
                        {...(actionProps[label] || {
                            color: 'primary'
                        })}
                        key={label}
                        onClick={() => onAction(label)}
                    >
                        {label}
                    </Button>
                ))}
            </DialogActions>
        )}
    </Dialog>
);

export default EditDialog;
