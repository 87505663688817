import DataSelect, { DataSelectProps } from './DataSelect';
import { FieldProps, getIn } from 'formik';

import React from 'react';

export interface FormikFieldProps
  extends FieldProps,
    Omit<DataSelectProps, keyof FieldProps> {
};

export function fieldToSelect({
    field,
    form: { isSubmitting, errors, touched },
    type,
    label,
    exclude,
    disabled,
}: FormikFieldProps): DataSelectProps {
    const fieldError = getIn(errors, field.name);
    const showError = !!fieldError && getIn(touched, field.name);

    const { value, ...fieldProps } = field;
    const finalValue = typeof value === 'number' ? value : (value?.begID ?? null);

    return {
        type,
        label,
        exclude,
        disabled,
        ...fieldProps,
        value: finalValue,
        // @ts-ignore
        error: showError ? (fieldError as string) : undefined,
    };
}

function FormikField(props: FormikFieldProps) {
    // @ts-ignore
    return <DataSelect {...fieldToSelect(props)} />;
}

export default FormikField;
