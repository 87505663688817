import { JTDParser, JTDSchemaType } from 'ajv/dist/jtd';

import { AjvJTD as Ajv } from './Ajv';

/**
 * Custom basic mapper, that provides parser and serializer methods from a given schema.
 */
abstract class SchemaMapper<T extends {}, U extends {}>{
    /**
     * Given schema, must be provided by implementing classes
     */
    abstract schema: JTDSchemaType<U>;

    // Parser for single entities
    parser?: JTDParser<U>;

    // Parser for array of entities
    arrayParser?: JTDParser<Array<U>>;

    // Serializer for a single entity
    serializer?: (data: U) => string;

    // Abstract mapping methods must still be done in implementations
    abstract mapToModel(input: U): T;
    abstract mapToData(input: T): U;

    /**
     * Get a parser for a single entity json string
     */
    getParser = (): JTDParser<U> => {
        if (!this.parser) {
            this.parser = Ajv.compileParser(this.schema);
        }
        return this.parser;
    }

    /**
     * Get a parser for a array of entities as json string
     */
    getArrayParser = (): JTDParser<Array<U>> => {
        if (!this.arrayParser) {
            this.arrayParser = Ajv.compileParser({ elements: this.schema });
        }
        return this.arrayParser;
    }

    /**
     * Get a serializer for an entity
     */
    getSerializer = () => {
        if (!this.serializer) {
            this.serializer = Ajv.compileSerializer(this.schema);
        }
        return this.serializer;
    }

    /**
     * Directly serialize a model to a json string represenation
     */
    serialize = (model: T) => {
        return this.getSerializer()(this.mapToData(model));
    }

    /**
     * Directly serialize a model to a json string represenation
     */
    serializeArray = (models: T[]) => {
        return `[${models.map((model) => this.serialize(model)).join(',')}]`;
    }

    /**
     * Directly parse a string to the destination model
     */
    parse = (data: string) => {
        const parser = this.getParser();
        const parsed = parser(data);
        if (!parsed) {
            const detail = data.substring((parser.position || 0) - 10, (parser.position || 0) + 10);
            throw new Error(`Parsing failed, ${parser.message} at ${parser.position}: ${detail}`);
        }
        return this.mapToModel(parsed);
    }

    /**
     * Directly parse a string to the destination array of models
     */
    parseArray = (data: string) => {
        const parser = this.getArrayParser();
        const parsed = parser(data);
        if (!parsed) {
            const detail = data.substring((parser.position || 0) - 10, (parser.position || 0) + 10);
            throw new Error(`Parsing failed, ${parser.message} at ${parser.position}: ${detail}`);
        }
        return parsed.map((item) => this.mapToModel(item));
    }
};

export default SchemaMapper;
