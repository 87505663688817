import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['general.A15_LJ', '/', 'compare.general.A15_LJ'],
        { type: 'bounds', ...params },
        'Die Veränderung der Kundenzahl pro Jahr weicht stark vom Vorjahr ab. Bitte prüfen Sie Ihre Angaben!',
        undefined,
        'Struktur_03',
    )
);

export default rule;
