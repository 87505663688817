import { Chip, makeStyles } from '@material-ui/core';

import { PeriodType } from '../../Lib/Report';

export type PeriodProps = {
    period: PeriodType,
};

const useStyles = makeStyles(() => ({
    monthly: {
        backgroundColor: '#7195E4',
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '1em',
    },
    yearly: {
        backgroundColor: '#8D68A0',
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '1em',
    },
    all: {
        backgroundColor: '#999999',
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '1em',
    },
}));

export const labels: Record<PeriodType, string> = {
    monthly: 'Monatlicher ATR',
    yearly: 'Jährlicher ABV',
    all: 'Gesamt ABV',
};

const PeriodTag = ({ period }: PeriodProps) => {
    const classes = useStyles();
    return (
        <Chip className={classes[period]} label={labels[period]} />
    );
};

export default PeriodTag;
