import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

import { Close } from '@material-ui/icons';

export type EditDialogProps = PropsWithChildren<{
    onClose: () => void,
    onSave: () => void,
    title: string,
    open?: boolean,
    saveText?: string,
    cancelText?: string,
}>;

const EditDialog: React.FC<EditDialogProps> = ({
    onClose,
    title,
    children,
    open = true,
    saveText = 'speichern',
    cancelText = 'abbrechen',
    onSave = () => {},
}) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
            {title}
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                {cancelText}
            </Button>
            <Button onClick={onSave} color="primary">
                {saveText}
            </Button>
        </DialogActions>
    </Dialog>
);

export default EditDialog;
