import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for equality in balance sheet on assets and liabilities
 */
const rule = new UserValdiation(
    ['income.E2_LJ', '-', 'income.E3_LJ'],
    { type: 'compare', value: 0, compare: '>' },
    'Bitte prüfen Sie die Umsatzangaben im Krankenkassenbereich.',
    undefined,
    'GuV_24',
);
export default rule;
