import IntermediateResultSchema, {
    IntermediateResultApiEntity,
} from '../Schemas/Api/IntermediateResult';

import { AjvJTD as Ajv } from './Ajv';
import Mapping from './Mappings/Intermediates';

type IntermediateResult = Record<string, number | undefined>;

export default class IntermediateResultMapper {
    // Serializer for a single entity
    serializer?: (data: Array<IntermediateResultApiEntity>) => string;

    pharmacyId: number;

    year: number;

    constructor(pharmacyId: number, year: number) {
        this.pharmacyId = pharmacyId;
        this.year = year;
    }

    /**
     * Get a serializer for an entity
     */
    getSerializer = () => {
        if (!this.serializer) {
            this.serializer = Ajv.compileSerializer({ elements: IntermediateResultSchema });
        }
        return this.serializer;
    }



    /**
     * Directly serialize a model to a json string represenation
     */
    serialize = (model: IntermediateResult) => {
        const data = (Object.keys(model).map((key) => {
            if (!Mapping[key]) {
                return null;
            }

            return {
                CalcergKurzBez: Mapping[key],
                CalcergApoID: this.pharmacyId,
                CalcergWj: this.year,
                CalcergWert: model[key], 
            };
        }) as Array<IntermediateResultApiEntity>)
            .filter(({ CalcergWert }) => (CalcergWert !== undefined));

        return this.getSerializer()(data);
    }
}
