import React, { useState } from 'react';

import {
    Autocomplete,
} from '@material-ui/lab';
import {
    TextField,
} from '@material-ui/core';
import useChangeEffect from '../../Hooks/useChangeEffect';

export type FormSelectProps<T extends Record<string, any>> = {
    /**
     * A label to display on the form field
     */
    label: string,

    /**
     * Options passed to select
     */
    options: Array<T>,

    /**
     * The initial or default value
     */
    defaultValue: number | string | null,

    /**
     * A key from T to use as a value
     */
    valueKey?: keyof T,

    /**
     * A key from T to use as display label
     */
    labelKey?: keyof T,

    /**
     * Optional change listener
     */
    onChange?: (newValue: T | undefined) => void,

    /**
     * Disable bottom gutter
     */
    noGutter?: boolean,

    /**
     * If the field is disabled
     */
    disabled?: boolean,

    /**
     * If there is an error
     */
    error?: string,

    /**
     * If the clear is disabled
     */
    disableClearable?: boolean,

    /**
     * The margin
     */
    margin?: "none" | "dense" | "normal",

    /**
     * Custom option label
     */
    renderOption?: (option: T) => React.ReactNode,
};

/**
 * Form select component.
 */
const FormSelect = <T extends Record<string, any>>({
    label,
    options,
    defaultValue,
    valueKey = 'ID',
    labelKey = 'name',
    onChange = () => {},
    noGutter = false,
    disabled = false,
    disableClearable = false,
    renderOption,
    margin,
    error,
}: FormSelectProps<T>): React.ReactElement => {
    // Manage internal value state
    const [value, setValue] = useState(defaultValue
        ? options.find((opt) => (defaultValue === opt[valueKey]))
        : options[0]);

    // Compose an id for the label, required for aria
    const labelId = `${label.toLowerCase()}-label`;

    // Listen to changes in value and notify listener
    useChangeEffect(() => {
        onChange(value?.[labelKey] ? value : undefined);
    }, [value]);

    const errorProps = error ? { error: true, helperText: error } : {};
    const extraProps = renderOption ? { renderOption } : {};

    return (
        <Autocomplete
            id={labelId}
            defaultValue={value?.[labelKey] ? value : undefined}
            options={options}
            getOptionSelected={(a, b) => (a[valueKey] === b[valueKey])}
            getOptionLabel={(option) => option[labelKey] || 'keine Auswahl'}
            // @ts-ignore
            onChange={(e, selected) => setValue(selected)}
            disabled={disabled}
            disableClearable={disableClearable}
            {...extraProps}
            renderInput={(params) => <TextField {...params} {...errorProps} label={label} variant="outlined" margin={margin} />}
        />
    );
}

export default FormSelect;
