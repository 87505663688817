import {
    Grid,
    ListItemText,
    Typography,
} from '@material-ui/core';

import ComparisonGroup from '../../Lib/ComparisonGroup';
import ItemStatus from '../Common/ItemStatus';
import React from 'react';

const ListItem = ({
    title,
    active,
    valid
}: Pick<ComparisonGroup, 'title' | 'active' | 'valid'>) => (
    <ListItemText
        primary={title}
        secondary={(
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">aktiv:</Typography>
                </Grid>
                <Grid item>
                    <ItemStatus status={active} />
                </Grid>
                <Grid item xs={3}> - </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">gültig:</Typography>
                </Grid>
                <Grid item>
                    <ItemStatus status={valid} />
                </Grid>
            </Grid>
        )}
    />
);

export default ListItem;
