import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RoleType, hasAnyRole } from '../Lib/Roles';

import React from 'react';
import useAuth from '../Hooks/useAuth';
import useCachedData from '../Hooks/useCachedData';

export type RestrictedRouteProps = RouteProps & {
    roles?: Array<RoleType>,
};

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({ children, roles, ...rest }) => {
    const { authentication, isAuthenticated } = useAuth();
    const missesTerms = authentication && !authentication.pharmacy.transferGK;
    const { role } = useCachedData(['role']);

    // Role could be required
    const hasRole = !roles ||
        (authentication !== null && hasAnyRole(authentication, roles, role));

    return (
        <Route {...rest}>
            {
                (missesTerms && rest.path !== '/terms')
                    ? <Redirect to={{ pathname: "/terms" }} />
                    : (isAuthenticated && hasRole)
                        ? children
                        : <Redirect to={{ pathname: "/" }} />
            }
        </Route>
    );
};

export default RestrictedRoute;
