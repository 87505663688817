import {
    List,
    ListItem,
    ListSubheader,
    Typography,
} from '@material-ui/core';
import {
    ParserErrorCode,
    WarningsData,
} from '../../Lib/XlsxDataReader';
import React, { useEffect, useState } from 'react';
import { YearlyData, staffTypeByKey } from '../../Lib/YearlyData';

import {
    Alert,
} from '@material-ui/lab';
import { BalanceFields } from '../../Components/YearlyData/BalanceSheet';
import { CheckCircle } from '@material-ui/icons';
import { GeneralFields } from '../../Components/YearlyData/GeneralForm';
import { IncomeFields } from '../../Components/YearlyData/IncomeSheet';

const parserErrorMessages: Record<ParserErrorCode, string> = {
    [ParserErrorCode.Missing]: 'Kein Wert angegeben für Feld "%s"',
    [ParserErrorCode.DataType]: 'Falscher Datentyp im Feld "%s", wird ignoriert'
};
const getFieldName = (code: any): string => {
    // @ts-ignore
    return GeneralFields[code]
        // @ts-ignore
        || BalanceFields[code]
        // @ts-ignore
        || IncomeFields[code]
        // @ts-ignore
        || staffTypeByKey[code]
        || 'unbekannt';
};

/**
 * Properties for {@link UploadFileStatus} component
 */
export type UploadFileStatusProps = {
    /**
     * Current data
     */
    data?: YearlyData & WarningsData,

    /**
     * An error processing
     */
    error?: string,

    /**
     * The filename
     */
    filename?: string,

    /**
     * Uploaded timestamp for success
     */
    uploaded?: number,
};

/**
 * UploadFileStatus component
 */
const UploadFileStatus: React.FC<UploadFileStatusProps> = ({
    error,
    data,
    filename,
    uploaded,
}) => {
    const [successVisible, setSuccessVisible] = useState(false);
    useEffect(() => {
        if (!successVisible && uploaded && uploaded > Date.now() - 2000) {
            setSuccessVisible(true);
            setTimeout(() => setSuccessVisible(false), 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploaded]);


    if (successVisible) {
        return (
            <Typography variant="body1">
                <CheckCircle color="primary" />
                Daten wurden erfolgreich importiert, geladen und gespeichert.
            </Typography>
        );
    }

    if (error) {
        return (
            <Typography variant="body1" color="error">
                Datei abgelehnt: {error}
            </Typography>
        );
    }

    if (data == null) {
        return (
            <Typography variant="body1">
                Noch keine Datei empfangen
            </Typography>
        );
    }

    return (
        <>
            <Typography variant="body1" gutterBottom>
                Empfangen: {filename}
            </Typography>
            {data.warnings.length > 0
                ? (
                    <List
                        disablePadding
                        subheader={(
                            <ListSubheader>
                                Achtung: {data.warnings.length} Warnungen
                            </ListSubheader>
                        )}
                    >
                        {
                            data.warnings.map(({ code, key }) => (
                                <ListItem key={key} disableGutters>
                                    <Alert severity="warning">
                                        Fehler: {parserErrorMessages[code].replace('%s', getFieldName(key))}
                                    </Alert>
                                </ListItem>
                            ))
                        }
                    </List>
                )
                : (
                    <Alert severity="success">
                        Die Datei beinhaltet keine Fehler
                    </Alert>
                )
            }
        </>
    );
};

export default UploadFileStatus;
