import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E1_LJ', '/', 'compare.income.E1_LJ'],
        { type: 'bounds', ...params },
        'Die Schwankung im Privatumsatz erscheint nicht konsistent zum Vorjahr.',
        undefined,
        'GuV_04',
    )
);

export default rule;
