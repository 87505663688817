import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for user entity types.
 */

/**
 * The typed entity from api
 */
export type UserApiEntity = {
    benID: number,
    benUsername: string,
    benTitel: string | null,
    benVName: string | null,
    benNName: string | null,
    benFunktionID: number,
    benEmail: string,
    benApoID: number,
    benHauptbenutzer: string | null,
    benRolleID: number,
    benPW: string | null,
    benAktiv: 'J' | 'N',
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<UserApiEntity> = {
    properties: {
        benID: { type: 'int32' },
        benUsername: { type: 'string' },
        benTitel: { type: 'string', nullable: true },
        benVName: { type: 'string', nullable: true },
        benNName: { type: 'string', nullable: true },
        benFunktionID: { type: 'int32' },
        benEmail: { type: 'string' },
        benApoID: { type: 'int32' },
        benHauptbenutzer: { type: 'string', nullable: true },
        benRolleID: { type: 'int32' },
        benPW: { type: 'string', nullable: true },
        benAktiv: { enum: ['J', 'N'] }
    },
};
export default schema;

/**
 * Export array form as well
 */
export const UserArray: JTDSchemaType<Array<UserApiEntity>> = {
    elements: schema,
};
