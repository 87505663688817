import RawPharmacy from './RawPharmacy';
import User from './User';

/**
 * Extended user model with pharmacy
 */
export default class UserWithPharmacy extends User {
    /**
     * The pharmacy
     */
    pharmacy: RawPharmacy;

    /**
     * Construct from user with pharmacy
     */
    constructor(
        user: User,
        pharmacy: RawPharmacy,
    ) {
        super(
            user.title,
            user.firstName,
            user.lastName,
            user.username,
            user.email,
            user.involvement,
            user.role,
            user.active,
            user.mainUser,
            user.pharmacyId,
            user.id,
        );
        this.pharmacy = pharmacy;
    }
}
