import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

export const staffTypeMarks = ['A52' , 'A53' , 'A54' , 'A55' , 'A56' , 'A57' , 'A58' , 'A60' , 'A61' , 'A63', 'A64', 'A65'] as const;
export type StaffMarkType = typeof staffTypeMarks[number];

export const skilledStaffMarks = ['AL' , 'PH' , 'AS' , 'SP'] as const;
export type SkilledStaffMarkType = typeof skilledStaffMarks[number];

/**
 * The typed entity from api
 */
export type StaffDataApiEntity = {
    persApoID: number,
    persWj: number,
    persKurzBez: StaffMarkType,
    persLfdnr: number,
    persDGDN: 'DN' | 'DG' | 'ME',
    persFPers: SkilledStaffMarkType,
    persSatzart: 'J' | 'M' | 'A',
    persJahreswert: number,
    persAnzMon: number,
    persMon1: number,
    persMon2: number,
    persMon3: number,
    persMon4: number,
    persMon5: number,
    persMon6: number,
    persMon7: number,
    persMon8: number,
    persMon9: number,
    persMon10: number,
    persMon11: number,
    persMon12: number,
};



/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<StaffDataApiEntity> = {
    properties: {
        persApoID: { type: 'int32' },
        persWj: { type: 'int32' },
        persKurzBez: { enum: [...staffTypeMarks]},
        persLfdnr: { type: 'int32' },
        persDGDN: { enum: ['DG', 'DN', 'ME'] },
        persFPers: { enum: [...skilledStaffMarks] },
        persSatzart: { enum: ['J', 'M', 'A'] },
        persJahreswert: { type: 'float32' },
        persAnzMon: { type: 'int32' },
        persMon1: { type: 'int32' },
        persMon2: { type: 'int32' },
        persMon3: { type: 'int32' },
        persMon4: { type: 'int32' },
        persMon5: { type: 'int32' },
        persMon6: { type: 'int32' },
        persMon7: { type: 'int32' },
        persMon8: { type: 'int32' },
        persMon9: { type: 'int32' },
        persMon10: { type: 'int32' },
        persMon11: { type: 'int32' },
        persMon12: { type: 'int32' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const StaffDataArray: JTDSchemaType<Array<StaffDataApiEntity>> = {
    elements: schema,
};
