import Pharmacy from './Pharmacy';

export const PharmacyLeaderOccupation = {
    owner: 'Konzessionär / (Mit-)Eigentümer',
    tenure: 'Pächter',
    employed: 'angestellter Leiter',
} as const;

export const PharmacyNightDutiesPerYear = {
    to19: '0 bis 19',
    to29: '20 bis 29',
    to39: '30 bis 39',
    to49: '40 bis 49',
    to59: '50 bis 59',
    to69: '60 bis 69',
    to79: '70 bis 79',
    to99: '80 bis 99',
    to159: '100 bis 159',
    to199: '160 bis 199',
    more: 'Mehr als 200',
} as const;

export interface BalanceSheet {
    B1_LJ?: number,
    B2_LJ?: number,
    B3_LJ?: number,
    B4_LJ?: number,
    B5_LJ?: number,
    B6_LJ?: number,
    B7_LJ?: number,
    B8_LJ?: number,
    B9_LJ?: number,
    B10_LJ?: number,
    B11_LJ?: number,
    B12_LJ?: number,
};

export interface IncomeSheet {
    E1_LJ?: number,
    E2_LJ?: number,
    E3_LJ?: number,
    E4_LJ?: number,
    E5_LJ?: number,
    E6_LJ?: number,
    E7_LJ?: number,
    E8_LJ?: number,
    E9_LJ?: number,
    E10_LJ?: number,
    E11_LJ?: number,
    E13_LJ?: number,
    E14_LJ?: number,
    E15_LJ?: number,
    E16_LJ?: number,
    E17_LJ?: number,
    E18_LJ?: number,
    E19_LJ?: number,
    E20_LJ?: number,
    E21_LJ?: number,
    E22_LJ?: number,
    E23_LJ?: number,
    E24_LJ?: number,
    E25_LJ?: number,
    E26_LJ?: number,
    E27_LJ?: number,
    E28_LJ?: number,
    E29_LJ?: number,
    E30_LJ?: number,
    E50_LJ?: number,
    E99_LJ?: number,
};

export interface General {
    A2_LJ?: Date,
    A3_LJ?: Date,
    A4_LJ?: boolean,
    A5_LJ?: keyof typeof PharmacyLeaderOccupation,
    A9_LJ?: keyof typeof PharmacyNightDutiesPerYear,
    A6_LJ?: boolean,
    A7_LJ?: boolean,
    A19_LJ?: boolean,
    A13_LJ?: number,
    A14_LJ?: number,
    A15_LJ?: number,
    A16_LJ?: number,
    A17_LJ?: number,
    A18_LJ?: number,
};

export const staffTypeOptions = {
    employer: {
        concessionaire: 'Konzessionär',
        ownerPharmacist: 'Miteigentümer Apotheker/Aspirant',
        ownerNonPharmacist: 'Miteigentümer Keine Pharmazeutische Fachkraft',
    },
    employee: {
        merchant: 'PKA',
        employeeSkilled: 'sonstige Angestellte',
        cleaner: 'Raumpfleger',
        trainee: 'PKA-Lehrling',
        director: 'Apothekenleiter',
        pharmacist: 'Vertretungsberechtigter Apotheker',
        aspirant: 'Aspirant',
    },
    family: {
        familyPharmacist: 'Pharmazeutische Fachkraft',
        familyNonPharmacist: 'Nicht Pharmazeutische Fachkraft',
    },
} as const;

export const staffTypeCategories = {
    employer: 'Dienstgeber',
    employee: 'Dienstnehmer',
    family: 'Familiäre Mittätigkeit',
} as const;

export const staffTypeByKey = {
    ...staffTypeOptions.employer,
    ...staffTypeOptions.employee,
    ...staffTypeOptions.family,
} as const;

// Helper type
export type KeysUnder<T> = T extends object ? {
    [P in keyof T]-?: keyof T[P] | never
}[keyof T] : never;

export type Tuple<TItem, TLength extends number> = [TItem, ...TItem[]] & { length: TLength };

/**
 * Staff type is all available options from staff type struct
 */
export type StaffType = KeysUnder<typeof staffTypeOptions>;

/**
 * Staff row object
 */
export interface StaffRow {
    type: StaffType,
    transmissionType: 'J' | 'M' | 'A',
    staffId: number,
    sum: {
        months: number,
        quota: number,
    },
    monthly?: Tuple<number, 12>,
};

/**
 * Staff row with id
 */
export type StaffRowWithId = StaffRow & {
    id: number,
};

/**
 * Staff data is entered rowwise
 */
type Counts<Type> = {
    [Property in keyof Type as `${string & Property}Count`]: number
};
export interface StaffData
    extends Partial<Record<StaffType, number>>, Partial<Counts<Record<StaffType, number>>> {
    rows: Array<StaffRow>,
};

/**
 * The whole object of yearly data storage
 */
export interface YearlyData {
    year?: number,
    pharmacy?: Pharmacy,
    general: General,
    income: IncomeSheet,
    balance: BalanceSheet,
    staff: StaffData,
    isEmpty?: true,
    validationResult?: 'none' | 'warn' | 'critical',
};

/**
 * An object with multiple data to be saved
 */
export interface YearlySaveData {
    pharmacy: Pharmacy,
    general: General,
    income: IncomeSheet,
    balance: BalanceSheet,
    staff: StaffData,
    validationResult?: 'none' | 'warn' | 'critical',
    validationResults: Array<{ rule: string, result: [number | undefined, boolean, boolean] }>,
    intermediates: Record<string, number | undefined>,
};
