import {
    Column,
    useTable,
} from 'react-table';
import React, { useMemo } from 'react';

import DownloadIcon from '../../assets/apostar_icons_download.svg';
import FileIcon from '../../assets/apostar_icons_meineapotheke.svg';
import {
    IconButton,
} from '@material-ui/core';
import PeriodTag from './PeriodTag';
import Report from '../../Lib/Report';
import ResponsiveTable from '../Common/Ui/ResponsiveTable';
import { dateFormat } from '../../Lib/Helper';

export type TableProps = {
    data: Array<Report>,
    emptyView?: React.ReactElement,
    pharmacyId?: number,
};

/**
 * Table of comparison groups
 */
const Table = ({
    data,
    emptyView,
    pharmacyId,
}: TableProps) => {
    // Memoize data used, important
    const tableData = useMemo(
        () => data,
        [data],
    );

    // Memoized column definitions
    const columns: Column<Report>[] = useMemo(
        () => [
            {
                Header: 'Berichtstyp',
                id: 'type',
                accessor: 'filename',
                Cell: ({ value }) => (
                    value.startsWith(`A${pharmacyId}`)
                        ? <img src={FileIcon} alt="icon" style={{ height: 32, verticalAlign: 'middle' }} />
                        : ''
                ),
                align: 'center',
            },
            {
                Header: 'Quelle',
                accessor: 'period',
                Cell: ({ value }) => <PeriodTag period={value} />,
                align: 'center',
            },
            {
                Header: 'Datum',
                accessor: 'timestamp',
                Cell: ({ value }) => dateFormat(value),
            },
            {
                Header: 'Datei',
                accessor: 'filename',
            },
        ],
        [pharmacyId],
    );

    // Instance with table hook
    const tableInstance = useTable<Report>({
        columns,
        data: tableData,
    });

    // Unwrap some properties
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = tableInstance;

    return (
        <ResponsiveTable<Report>
            tableProps={getTableProps()}
            tableBodyProps={getTableBodyProps()}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            mobileView={[[3], [0, 1, 2]]}
            rows={rows}
            menu={({ original: { filepath }}) => (
                <IconButton color="primary" href={filepath} target="_blank" style={{ marginRight: '-1rem' }}>
                    <img src={DownloadIcon} alt="icon" style={{ height: 32 }} />
                </IconButton>
            )}
            menuHeader="Download"
            emptyView={emptyView}
        />
    );
};

export default Table;
