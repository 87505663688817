import {
    ListItemIcon,
    ListItemSecondaryAction,
    MenuItem,
    Typography,
} from '@material-ui/core';

import AnimatedNumber from '../Common/AnimatedNumber';
import React from 'react';
import {
    Warning as WarningIcon,
} from '@material-ui/icons';

export type FormMenuItemProps = {
    icon: React.ReactNode,
    onClick: () => void,
    label: string,
    complete?: number,
    warning?: boolean,
    active?: boolean,
};

const FormMenuItem: React.FC<FormMenuItemProps> = ({
    onClick,
    label,
    icon,
    complete,
    warning = false,
    active = false,
}) => {
    const completeLabel = complete ? (
        <span>
            <AnimatedNumber
                number={complete}
                format={(val) => `${Math.round(val)}%`}
            />
        </span>
    ) : '';



    return (
        <MenuItem onClick={onClick} style={{ padding: '1em', whiteSpace: 'normal' }} selected={active}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <Typography variant="body1" color="textPrimary" style={{ fontWeight: 700 }}>
                {label}
            </Typography>
            <ListItemSecondaryAction>
                <Typography variant="body2" color="textSecondary">
                    {warning && <WarningIcon color="error" />}
                    {completeLabel}
                </Typography>
            </ListItemSecondaryAction>
        </MenuItem>
    );
};

export default FormMenuItem;
