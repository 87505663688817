import { JTDSchemaType } from 'ajv/dist/jtd';

/**
 * The typed entity from api
 */
export type ErrorResponse = {
    Fehlercode: number,
    Fehlermessage: string,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<ErrorResponse> = {
    properties: {
        Fehlercode: { type: 'int32' },
        Fehlermessage: { type: 'string' }
    },
};
export default schema;
