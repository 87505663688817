import intermediates from '../Intermediates';

const intermediateMapping: Record<keyof typeof intermediates, string> = {
    assets_LJ: 'Calc_01',
    liabilities_LJ: 'Calc_03',
    operating_expenses_LJ: 'Calc_11',
    total_output_LJ: 'Calc_14',
    offizin_revenue_LJ: 'Calc_17',
    gross_yield_LJ: 'Calc_19',
    staff_cost_LJ: 'Calc_22',
    yearly_profit_LJ: 'Calc_05',
    yearly_profit_after_annuity_LJ: 'Calc_08',
    cf_LJ: 'Calc_25',
};

export default intermediateMapping;
