import {
    Grid,
    Link,
    StepIcon,
    Typography,
} from '@material-ui/core';

import {
    Alert,
} from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type InstructionsProps = {
    missingLastYear: boolean,
    onRequestLastYear?: () => void,
};

const useStyles = makeStyles(() => ({
    stepNumber: {
        justifyContent: 'flex-end',
        display: 'flex',
        marginTop: '-0.5rem',
    },
    stepContainer: {
        marginTop: '2em',
    },
}));

const Instructions: React.FC<InstructionsProps> = ({
    missingLastYear,
    onRequestLastYear = () => {},
}) => {
    const classes = useStyles();
    return (
        <>
            {missingLastYear ? (
                <Grid container spacing={1} style={{ marginTop: '4em' }}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Alert color="info">
                            Sie haben noch keine Vorjahresdaten erfasst. Diese werden als
                            Vergleichswerte herangezogen. Möchten Sie die Vorjahresdaten erfassen?
                            <Link onClick={onRequestLastYear} component="button">Zur Vorjahresansicht wechseln</Link>!
                        </Alert>
                    </Grid>
                </Grid>
            ) : null}
            <Grid container spacing={1} className={classes.stepContainer}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <Typography variant="h3" color="textPrimary" gutterBottom>
                        So einfach funktioniert's
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.stepContainer}>
                <Grid item xs={2} className={classes.stepNumber}>
                    <StepIcon icon="1" active />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Daten erfassen
                    </Typography>
                    <Typography>
                        Bitte tragen Sie in den links angefügten Bereichen (ApothekenDaten,
                        Allgemeine Daten, Personalstatistik, Gewinn- und Verlustrechnung,
                        Bilanzpositionen) die Daten Ihrer Apotheke ein. Bitte beenden Sie die
                        Eingabe in den jeweiligen Bereichen durch das Drücken des „Speicher Buttons“.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: '4em' }}>
                <Grid item xs={2} className={classes.stepNumber}>
                    <StepIcon icon="2" active />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h5" color="textPrimary" gutterBottom>
                        Daten auf Korrektheit prüfen
                    </Typography>
                    <Typography>
                        Nach erfolgter Speicherung werden die Eingaben auf Plausibilität geprüft.
                        Sie erhalten einen Hinweis, wenn eine der Eingaben unplausibel erscheint.
                        In der GuV und Bilanz bitte eine „0“ erfassen, wenn bei einem Eingabefeld
                        ein Saldo von null zu erfassen ist.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default Instructions;
