import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for user entity types.
 */

/**
 * The typed entity from api
 */
export type UserComparisonCombinationApiEntity = {
    benID: number,
    benchKombiNr: number,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<UserComparisonCombinationApiEntity> = {
    properties: {
        benID: { type: 'int32' },
        benchKombiNr: { type: 'int32' },
    },
};
export default schema;
