import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

import React from 'react';
import useTypeStyles from '../Common/Ui/useTypeStyles';

type YearlyProps = {
    navigateTo: (target: string) => void,
    isMainUser: boolean,
    isAccountant: boolean,
    isUser: boolean,
    classes: { centerCard: string, innerCardHeader: string },
};

const Yearly = ({
    navigateTo,
    isMainUser,
    isAccountant,
    isUser,
    classes,
}: YearlyProps) => {
    const typeClasses = useTypeStyles();

    return (
        <Card>
            <CardContent className={[classes.centerCard, typeClasses.yearly].join(' ')}>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.innerCardHeader}
                >
                    ApoStar Bilanz Vergleich
                </Typography>
                <Box mb={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => navigateTo('/jaehrlich')}
                        disabled={!(isMainUser || isAccountant)}
                        className={typeClasses.yearlyButton}
                    >
                        Daten eingeben
                    </Button>
                </Box>
                <Box mb={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => navigateTo('/jaehrlich/vergleichsgruppe')}
                        disabled={!(isMainUser || isUser)}
                        className={typeClasses.yearlyButton}
                    >
                        Vergleichsgruppe festlegen/ändern
                    </Button>
                </Box>
                <Box mb={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!(isMainUser || isUser || isAccountant)}
                        onClick={() => navigateTo('/berichte')}
                        className={typeClasses.yearlyButton}
                    >
                        Archivierte Berichte öffnen
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Yearly;
