import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['balance.B3_LJ', '/', 'compare.balance.B3_LJ'],
        { type: 'bounds', ...params },
        'Bitte prüfen Sie die Eingabe im Warenbestand. Die Abweichung zum Vorjahr erscheint unplausibel.',
        undefined,
        'Bilanz_01',
    )
);

export default rule;
