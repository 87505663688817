import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.gross_yield_LJ', '/', 'intermediates.total_output_LJ'],
        { type: 'bounds', ...params },
        {
            high: 'Der Bruttogewinn ist im Verhältnis zum Gesamtumsatz unplausibel hoch',
            low: 'Der Bruttogewinn ist im Verhältnis zum Gesamtumsatz unplausibel niedrig',
        },
        undefined,
        'GuV_18',
    )
);

export default rule;
