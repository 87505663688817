import {
    Button,
    Card,
    CardContent,
    Container,
    Link,
    Typography,
} from '@material-ui/core';

import {
    Healing as Icon,
} from '@material-ui/icons';

type ErrorProps = {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
};

const hideErrorMessage = process.env.NODE_ENV === 'production';

function AppError({
    error,
    clearError,
}: ErrorProps) {
    const containerStyles = {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <Container maxWidth="sm" style={containerStyles}>
            <Card raised={false}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        Leider ist ein Fehler aufgetreten
                    </Typography>
                    {!hideErrorMessage && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {error.message}
                        </Typography>
                    )}
                    <Icon style={{ fontSize: 120, marginBottom: 20 }} color="action" />
                    <Typography gutterBottom>
                        Es tut uns leid, die Seite ist leider abgestürzt. Versuchen sie bitte die
                        Seite neu zu laden und die Aktion noch einmal auszuführen.
                    </Typography>
                    <Typography gutterBottom>
                        Sollte der Fehler bestehen bleiben versuchen Sie die Seite noch einmal im
                        Inkognito-Modus bzw. Privaten Modus zu besuchen. Siehe:
                    </Typography>
                    <Typography gutterBottom>
                        <Link href="https://support.mozilla.org/de/kb/privater-modus">
                            Firefox: Privater Modus
                        </Link>
                        {' | '}
                        <Link href="https://support.google.com/chrome/answer/95464?hl=de&co=GENIE.Platform%3DDesktop">
                            Chrome: Inkognito Modus
                        </Link>
                        {' | '}
                        <Link href="https://support.microsoft.com/de-de/microsoft-edge/inprivate-browsen-in-microsoft-edge-cd2c9a48-0bc4-b98e-5e46-ac40c84e27e2">
                            Edge: InPrivate Modus
                        </Link>
                    </Typography>
                    <Button onClick={clearError} variant="contained" color="primary">
                        Zurück zur Webseite
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
}

export default AppError;
