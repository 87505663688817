import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        'staff.concessionaire',
        { type: 'bounds', ...params },
        'Die Arbeitsleistung als Konzessionär ist entweder zu hoch oder zu niedrig erfasst. Eingabe in 10tel. Wenn Sie zB. 44h/Woche arbeiten, dann erfassen Sie bitte 11/10tel.',
        undefined,
        'Personal_01',
    )
);

export default rule;
