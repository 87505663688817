import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type SalesDataApiEntity = {
    kpiA16: number,
    kpiA18: number,
};



/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<SalesDataApiEntity> = {
    properties: {
        kpiA16: { type: 'int32' },
        kpiA18: { type: 'int32' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const SalesDataArray: JTDSchemaType<Array<SalesDataApiEntity>> = {
    elements: schema,
};
