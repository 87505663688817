import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

type RequiredParams = {
    salaryMerchant: RuleParamater,
    salaryTrainee: RuleParamater,
    salaryEmployeeSkilled: RuleParamater,
    salaryEmployeeUnskilled: RuleParamater,
    salaryCleaner: RuleParamater,
    salaryOtherEmployee: RuleParamater,
};

const messages = {
    high: 'Der ausgewiesene Gehalt beim Hilfspersonal ist zu hoch bzw. der Personalstand zu niedrig. Bitte prüfen Sie die Angaben in der Personalstatistik sowie Ihren Personalaufwand in der GuV.',
    low: 'Der ausgewiesene Gehalt beim Hilfspersonal ist zu niedrig bzw. der Personalstand zu hoch. Bitte prüfen Sie die Angaben in der Personalstatistik sowie Ihren Personalaufwand in der GuV.',
};

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RequiredParams, bound: 'low' | 'high') => (
    new UserValdiation(
        [
            [
                [
                    [
                        ['staff.merchant', '*', params.salaryMerchant.bounds[bound]],
                        '+',
                        ['staff.trainee', '*', params.salaryTrainee.bounds[bound]],
                    ],
                    '+',
                    [
                        ['staff.employeeSkilled', '*', params.salaryEmployeeSkilled.bounds[bound]],
                        '+',
                        ['staff.employeeUnskilled', '*', params.salaryEmployeeUnskilled.bounds[bound]],
                    ],
                ],
                '+',
                [
                    ['staff.cleaner', '*', params.salaryCleaner.bounds[bound]],
                    '+',
                    ['staff.otherEmployee', '*', params.salaryOtherEmployee.bounds[bound]],
                ],
            ],
            '/',
            'income.E8_LJ'
        ],
        // Compare this relatively means first part is higher or lower than second part of divison
        { type: 'compare', compare: bound === 'low' ? '<=' : '>=', value: 1 },
        messages[bound],
        ['income.E8_LJ', 0, '>'],
        bound === 'low' ? 'Personal_20' : 'Personal_21',
    )
);

export default rule;
