import {
    Box,
    Divider,
    Theme,
    Typography,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {
    StaffData,
    StaffRow as StaffRowType,
    StaffRowWithId,
    staffTypeCategories,
    staffTypeOptions,
} from '../../Lib/YearlyData';
import useTypeStyles, { mainColorYearly } from '../Common/Ui/useTypeStyles';

import AddStaff from './AddStaff';
import {
    Alert,
} from '@material-ui/lab';
import { Error } from '../../Lib/YearlyDataValidation';
import StaffDataLoad from './StaffDataLoad';
import StaffRow from './StaffRow';
import { getErrorsForGroup } from '../../Lib/ValidationHelpers';
import { makeStyles } from '@material-ui/core/styles';

/**
 * The properties
 */
type StaffFormProps = {
    year: number,
    pharmacyId: number,
    fiscalYear: [Date?, Date?],
    isCurrentYear: boolean,
    onChange?: (values: StaffData) => void,
    initialValues?: StaffData,
    errors?: Array<Error>,
};

// All category keys
const categoryKeys = Object.keys(staffTypeOptions);

// Filtering function, could inject the index to the data tuple to be able to reference in updates
const filterByCategory = (rows: Array<StaffRowType>, category: keyof typeof staffTypeCategories): Array<StaffRowWithId> => {
    return rows.map((row, i) => ({ ...row, id: i })).filter(({ type }) => (
        // @ts-ignore
        Object.keys(staffTypeOptions[category]).includes(type)
    ));
};

// Helper for array without index
const without = <T,>(rows: Array<T>, index: number): Array<T> => {
    const arr = rows.slice();
    arr.splice(index, 1);
    return arr;
}

// Pristine totals to use
const pristineTotals = {
    concessionaire: 0,
    concessionaireCount: 0,
    ownerPharmacist: 0,
    ownerPharmacistCount: 0,
    ownerNonPharmacist: 0,
    ownerNonPharmacistCount: 0,
    director: 0,
    directorCount: 0,
    pharmacist: 0,
    pharmacistCount: 0,
    aspirant: 0,
    aspirantCount: 0,
    trainee: 0,
    traineeCount: 0,
    employeeSkilled: 0,
    employeeSkilledCount: 0,
    merchant: 0,
    merchantCount: 0,
    cleaner: 0,
    cleanerCount: 0,
    familyPharmacist: 0,
    familyPharmacistCount: 0,
    familyNonPharmacist: 0,
    familyNonPharmacistCount: 0,
};
const pristineValue = { rows: [], totals: pristineTotals };

// Get the yearly quota from a row, 120 is because it is a yearly tenth but the input is in full numbers
const getYearlyQuota = (row: StaffRowType) => {
    // Either monthly
    if (row.monthly !== undefined) {
        return row.monthly.reduce((memo, quota) => (memo + (quota || 0)), 0) / 120;
    }

    // Total quota
    return row.sum.quota * (row.sum.months / 120);
}

// Calculate total values from set of rows
export const calculateTotals = (rows: Array<StaffRowType>) => {
    // They are not set, values are considered untouched if none given
    if (rows.length === 0) {
        return {};
    }

    const totals = { ...pristineTotals };
    rows.forEach((row) => {
        // @ts-ignore
        totals[`${row.type}Count`]++;
        totals[row.type] += getYearlyQuota(row);
    });
    return totals;
}


const useStyles = makeStyles((theme: Theme) => ({
    error: {
        marginBottom: theme.spacing(1),
    },
    row: {
        backgroundColor: theme.palette.grey[100],
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    labelCol: {
        textAlign: 'right',
        paddingRight: theme.spacing(0.5),
    },
    monthLabel: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize * 0.9,
    },
    monthItems: {
        '& > .MuiGrid-item': {
            padding: theme.spacing(0.2),
        },
    },
    rowActions: {
        textAlign: 'right',
        width: '100%',
    },
    rowAction: {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize * 0.7,
        marginLeft: theme.spacing(2),
    },
    numberInput: {
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2,
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    divider: {
        backgroundColor: mainColorYearly,
        marginBottom: theme.spacing(1),
    },
}));

/**
 * Enter staff related data
 */
const StaffForm: React.FC<StaffFormProps> = ({
    initialValues,
    fiscalYear,
    pharmacyId,
    onChange,
    errors,
    year,
}) => {
    const initial = initialValues || pristineValue;
    const [values, setValues] = useState<StaffData>({
        rows: initial.rows,
        ...calculateTotals(initial.rows),
    });
    const rowsByCatgeory = useMemo(() => {
        const rows = values.rows || [];
        return {
            employer: filterByCategory(rows, 'employer'),
            employee: filterByCategory(rows, 'employee'),
            family: filterByCategory(rows, 'family'),
        };
    }, [values.rows])

    const errorsDisplay = (errors && values.rows.length > 0)
        ? getErrorsForGroup('staff', errors) : [];

    // Add row helper function
    const addRow = (row: StaffRowType) => {
        setValues((prev) => {
            const newRows = [
                ...(prev.rows || []),
                row,
            ];
            const newValue: StaffData = {
                rows: newRows,
                ...calculateTotals(newRows),
            };
            onChange?.(newValue);
            return newValue;
        });
    };

    const rowChange = (row: StaffRowType, index: number) => {
        setValues((prev) => {
            const newRows = [
                ...(prev.rows || []),
            ];
            // Update
            newRows[index] = row;
            const newValue: StaffData = {
                rows: newRows,
                ...calculateTotals(newRows),
            };

            onChange?.(newValue);
            return newValue;
        });
    };

    const rowDelete = (index: number) => {
        setValues((prev) => {
            const newRows = [
                ...(without(prev.rows, index) || [])
            ];
            const newValue: StaffData = {
                rows: newRows,
                ...calculateTotals(newRows),
            };

            onChange?.(newValue);
            return newValue;
        });
    };

    const classes = useStyles();
    const typeClasses = useTypeStyles();
    return (
        <>
            {errorsDisplay.map((error, i) => (
                <Alert severity="warning" className={classes.error} key={`staff_error_${i}`}>
                    {error.message}
                </Alert>
            ))}
            <StaffDataLoad
                fiscalYear={fiscalYear}
                year={year}
                pharmacyId={pharmacyId}
                onLoaded={(rows) => {
                    const newValues = {
                        rows,
                        ...calculateTotals(rows),
                    };
                    setValues(newValues);
                    onChange?.(newValues);
                }}
            />
            {categoryKeys.map((key) => (
                <Box mb={4} key={key}>
                    <Divider className={classes.divider} />
                    <Typography variant="h4" gutterBottom className={typeClasses.yearlyText}>
                        {staffTypeCategories[key]} ({rowsByCatgeory[key].length})
                    </Typography>
                    {rowsByCatgeory[key].map((row, i) => (
                        <StaffRow
                            key={`${key}_${i}_${row.transmissionType}_${row.staffId}_${row.type}`}
                            value={row}
                            onChange={(update) => rowChange(update, row.id)}
                            onDelete={() => rowDelete(row.id)}
                            classes={classes}
                            startMonth={fiscalYear[0]?.getMonth() ?? 0}
                            endMonth={fiscalYear[1]?.getMonth() ?? 11}
                        />
                    ))}
                    <AddStaff
                        category={key}
                        onAdd={(type) => addRow({
                            type,
                            transmissionType: 'J',
                            staffId: 0,
                            sum: { months: 0, quota: 0 },
                        })}
                    />
                </Box>
            ))}
        </>
    );
}

export default StaffForm;
