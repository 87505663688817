import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = new UserValdiation(
    ['staff.trainee',
        '+', ['staff.employeeSkilled',
            '+', ['staff.merchant',
                '+', ['staff.cleaner',
                    '+', 'staff.otherEmployee']]]],
    { type: 'compare', compare: '>', value: 0 },
    'Sie haben Hilfspersonal erfasst, aber keinen Aufwendungen für Hilfskräfte in der GuV erfasst.',
    ['E8_LJ', 0, '='],
    'Personal_17',
);

export default rule;
