import React, { useState } from 'react';

import { BalanceSheet as BalanceSheetData } from '../../Lib/YearlyData';
import CompareableNumberInput from './CompareableNumberInput';
import CompareableNumbersHeader from './CompareableNumbersHeader';
import { Error } from '../../Lib/YearlyDataValidation';
import FieldError from './FieldError';
import { getErrorsForField } from '../../Lib/ValidationHelpers';

type BalanceKeys = keyof BalanceSheetData;

type BalanceSheetProps = {
    year: number,
    isCurrentYear: boolean,
    onChange?: (values: Record<BalanceKeys, number | undefined>) => void,
    initialValues?: Record<BalanceKeys, number | undefined>,
    compareValues?: BalanceSheetData,
    errors?: Array<Error>,
};

// Define fields available
export const BalanceFields: Record<BalanceKeys, string> = {
    B1_LJ: 'Buchwert Anlagevermögen',
    B2_LJ: 'davon Firmenwert / Konzessionswert',
    B3_LJ: 'Vorräte',
    B4_LJ: 'Forderungen aus Lieferungen und Leistungen',
    B5_LJ: 'Sonstige Forderungen und Ara',
    B6_LJ: 'Kassenbestand, Bankguthaben',
    B7_LJ: 'Eigenkapital inkl. Rücklagen',
    B8_LJ: 'Rückstellungen langfristig',
    B9_LJ: 'Sonstige Rückstellungen',
    B10_LJ: 'Verbindlichkeiten gg. Kreditinstituten',
    B11_LJ: 'Verbindlichkeiten aus Lieferungen und Leistungen',
    B12_LJ: 'Sonstige Verbindlichkeiten und PRA',
};

const BalanceSheet: React.FC<BalanceSheetProps> = ({
    year,
    onChange = () => {},
    initialValues,
    compareValues,
    errors = [],
}) => {
    // Keep track of values in this form
    const [, setValues] = useState<Record<BalanceKeys, number | undefined>>(
        // @ts-ignore
        Object.fromEntries(
            Object.keys(BalanceFields).map((key) => ([key, initialValues?.[key] ?? undefined]))
        )
    );

    // Value change callback for individual field
    const onValueChange = (newValue?: number, id?: string) => {
        setValues((prev) => {
            const nextValues = {
                ...prev,
                [id ?? 'unknown']: newValue,
            };
            onChange(nextValues);
            return nextValues;
        });
    };

    return (
        <>
            <CompareableNumbersHeader year={year} />
            {Object.keys(BalanceFields).map((id, i) => (
                <FieldError
                    key={id}
                    errors={getErrorsForField(`balance.${id}`, errors)}
                >
                    <CompareableNumberInput
                        id={id}
                        label={BalanceFields[id]}
                        compareValue={compareValues?.[id]}
                        onChange={onValueChange}
                        initialValue={initialValues?.[id]}
                    />
                </FieldError>
            ))}
        </>
    );
}

export default BalanceSheet;
