import { Container, CssBaseline, Theme, ThemeProvider } from '@material-ui/core';

import AppError from '../AppError';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Footer from './Footer';
import Header from './Header';
import Providers from './Providers';
import React from 'react';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import theme from './Theme/Theme';

const styles = (theme: Theme) => ({
    appBarSpacer: {
        marginBottom: 120,
        ...theme.mixins.toolbar,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 60,
        },
    },
});
const useStyles = makeStyles(styles);

// Get the version from env
const buildVersion = [process.env.REACT_APP_VERSION || 'none'];
if (process.env.REACT_APP_GIT_SHA) {
    buildVersion.push(process.env.REACT_APP_GIT_SHA);
}
const appVersion = buildVersion.join('-');

// Start our bugsnag
if (process.env.REACT_APP_BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_KEY,
        collectUserIp: false,
        appVersion,
        plugins: [new BugsnagPluginReact()],
    });
}

const ErrorBoundary = Bugsnag
    .getPlugin('react')!
    .createErrorBoundary(React);

type Props = {
    children: React.ReactChild,
};

function Layout({ children }: Props) {
    const classes = useStyles();
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary FallbackComponent={AppError}>
                    <Providers>
                        <div className={classes.appBarSpacer} />
                        <CssBaseline />
                        <Header />
                        {/* @ts-ingore */}
                        <Container maxWidth="lg">
                            {/* @ts-ingore */}
                            {children}
                        </Container>
                        <Footer />
                    </Providers>
                </ErrorBoundary>
            </ThemeProvider>
        </Router>
    );
}

export default Layout;
