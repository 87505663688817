import {
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import React, { useState } from 'react';

import useChangeEffect from '../../Hooks/useChangeEffect';

type OptionalBoolean = boolean | undefined;
export type YesNoInputProps = {
    id?: string,
    label: string,
    initialValue?: boolean,
    onChange?: (value?: OptionalBoolean, id?: string) => void,
    onFocused?: (event: React.FocusEvent<HTMLElement>) => void,
};

/**
 * Number input with a comparison and derived trend to a previous value
 */
const YesNoInput: React.FC<YesNoInputProps> = ({
    id,
    label,
    onChange,
    initialValue,
    onFocused = () => {},
}) => {
    // Value state reference
    const [value, setValue] = useState<OptionalBoolean>(initialValue);

    // Notify change
    useChangeEffect(() => onChange?.(value, id), [value]);

    return (
        <FormGroup style={{ marginBottom: '2em' }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={8}>
                    <InputLabel style={{ color: 'black' }}>
                        {label}
                    </InputLabel>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <RadioGroup
                        onFocus={onFocused}
                        style={{ justifyContent: 'flex-end' }}
                        row
                        name={id || label}
                        value={value === undefined ? 'none' : `${value}`}
                        onChange={({ target: { value } }) => setValue(value === 'true')}
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Ja"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="Nein"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
        </FormGroup>
    );
};

export default YesNoInput;
