/**
 * Crendential store type. Used by fetch client and others.
 */
interface CredentialStoreType {
    /**
     * Check whether or not a store has stored credentials
     */
    hasCredentials: () => Promise<boolean>,

    /**
     * Get any additional request headers from this storage
     */
    getCredentials: () => Promise<[string, string] | undefined>,

    /**
     * Get any additional request headers from this storage
     */
    getRequestHeaders: () => Promise<Record<string, string>>,

    /**
     * Store credentials
     */
    storeCredentials: (username: string, password: string) => Promise<void>,

    /**
     * Clear any credentials
     */
    clear: () => Promise<void>,
};

/**
 * A localStorage store with basic authentication method
 */
export class BasicLocalStorageCredentialStore implements CredentialStoreType {
    // A unique key to store in localStorage
    storeKey: string;

    // Create this store with a unique key to store the credentials in
    constructor(key: string) {
        this.storeKey = key;
    }

    // See interface
    hasCredentials = () => Promise.resolve((sessionStorage || localStorage).getItem(this.storeKey) !== null);

    // See interface
    getCredentials = () => {
        const storedValue = (sessionStorage || localStorage).getItem(this.storeKey);
        if (!storedValue) {
            return Promise.resolve(undefined);
        }

        return Promise.resolve(
            Buffer.from(storedValue, 'base64')
                .toString('utf8')
                .split(':') as [string, string]
        );
    };

    // See interface
    getRequestHeaders = () => Promise.resolve((sessionStorage || localStorage).getItem(this.storeKey))
        .then((stored: string | null) => (stored ? {
            Authorization: `Basic ${stored}`,
        } : {} as Record<string, string>));

    // See interface
    storeCredentials = (username: string, password: string) => {
        const authString = Buffer.from(`${username}:${password}`)
            .toString('base64');
        (sessionStorage || localStorage).setItem(this.storeKey, authString);
        return Promise.resolve();
    };

    clear = () => Promise.resolve((sessionStorage || localStorage).removeItem(this.storeKey));
};

/**
 * Application global storage for default credential store
 */
let defaultCredentialStore: CredentialStoreType | null = null;

/**
 * Setter method for default credential store
 */
export const setDefaultCredentialStore = (store: CredentialStoreType) => {
    defaultCredentialStore = store;
};

/**
 * Get the default credential store
 */
export const getDefaultCredentialStore = () => (defaultCredentialStore);
