import {
    Box,
    Typography,
} from '@material-ui/core';

import FormSuccess from '../Common/Form/FormSuccess';
import PharmacyDataForm from '../User/PharmacyDataForm';
import PharmacyService from '../../Lib/PharmacyService';
import RegistrationCodeForm from '../User/RegistrationCodeForm';
import SteppedForm from '../Common/Form/SteppedForm';
import UserDataForm from '../User/UserDataForm';
import UserService from '../../Lib/UserService';
import { getDefaults } from '../../Lib/Roles';
import useService from '../../Hooks/useService';
import validatePharmacy from '../../Lib/FormValidations/Pharmacy';
import { validateWithUsernameCheck } from '../../Lib/FormValidations/User';

const { mainRole } = getDefaults();

const SignUp = ({ onFinishedAction }: { onFinishedAction: () => void}) => {
    const service = useService<PharmacyService>('pharmacy');
    const userService = useService<UserService>('user');
    const validateUser = validateWithUsernameCheck(userService.getUsernameAvailable);

    return (
        <Box textAlign="center">
            <Typography variant="h2">
                Neu Registrieren
            </Typography>
            <SteppedForm
                stepConfig={{
                    Registrierungscode: RegistrationCodeForm,
                    Apothekendaten: PharmacyDataForm,
                    Benutzer: UserDataForm,
                }}
                validations={{
                    // @ts-ignore
                    Registrierungscode: () => ({}),
                    Apothekendaten: validatePharmacy,
                    // @ts-ignore
                    Benutzer: validateUser,
                }}
                // @ts-ignore
                onSubmit={(data) => service.register([
                    data[0],
                    {
                        ...data[1],
                        active: true,
                        title: data[0].title,
                        number: data[0].number,
                        dateOfBalance: data[1].dateOfBalance ?
                            new Date(Date.parse(data[1].dateOfBalance)) : null,
                    },
                    {
                        ...data[2],
                        role: mainRole,
                        active: true,
                        mainUser: true,
                    },
                ])}
                completeLabel="Registrierung abschließen"
            >
                <FormSuccess
                    title="Registrierung erledigt!"
                    text="Legen Sie gleich los mit Ihrem ApoStar Tara Report"
                    actionLabel="Zum Login"
                    onAction={onFinishedAction}
                />
            </SteppedForm>
        </Box>
    );
};

export default SignUp;
