import { getDefaults } from '../Roles';
import validateEmail from './validateEmail';

type UserValidationErrors = {
    username?: string,
    email?: string,
    password?: string,
    firstName?: string,
    lastName?: string,
    involvement?: string,
}


export const validatePasswords = (values: any): Pick<UserValidationErrors, 'password'> => {
    const errors : Pick<UserValidationErrors, 'password'> = {};

    if (!values.password) {
        errors.password = 'Bitte geben Sie eine Passwort an';
    } else if (values.password !== values.password_confirm) {
        errors.password = 'Die Passwörter stimmen nicht überein';
    } else if (!/^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d$*\-_[\]{}:'?()!]|\.(?!@)){8,16}$/.test(values.password)) {
        errors.password = 'Das Passwort muss mindestens 8 Zeichen lang sein und 3 der 4 folgenden Kategorien enthalten: Großbuchstaben, Kleinbuchstaben, Zahlen, Sonderzeichen ($*-_[]{}:\'?()!)';
    } else if (values.username && values.password.toLowerCase().includes(values.username.toLowerCase())) {
        errors.password = 'Das Passwort darf den Benutzernamen nicht enthalten';
    }

    return errors;
};

const validate = (values: any, withConfirmation: boolean = true, withPassword: boolean = true): UserValidationErrors => {
    const errors: UserValidationErrors = {};
    if (!values.username) {
        errors.username = 'Bitte geben Sie ein Benutzernamen an';
    } else if (!/^[0-9_a-zA-Z]*$/.test(values.username)) {
        errors.username = 'Benutzername darf nur Buchstaben und Zahlen oder einen Unterstrich ("_") enthalten';
    } else if (!/^(?!.*[_]stb$).*$/.test(values.username)) {
        const roleDefaults = getDefaults();
        if (values.involvement !== roleDefaults.accountantInvolvement) {
            errors.username = 'Benutzername darf nicht auf "_stb" enden';
        } else if (values.username.startsWith('A000000')) {
            errors.username = 'Bitte warten bis der Steuerberater Benutzername geladen wurde';
        }
    }

    if (!values.email) {
        errors.email = 'Bitte geben Sie eine Email-Adresse an';
    } else if (!validateEmail(values.email)) {
        errors.email = 'Diese Email-Adresse ist nicht gültig. Bitte geben Sie eine gültige Email-Adresse an';
    } else if (withConfirmation && values.email !== values.email_confirm) {
        errors.email = 'Die Email-Adressen stimmen nicht überein';
    }

    if (withPassword) {
        const passwordErrors = validatePasswords(values);
        if (passwordErrors.password) {
            errors.password = passwordErrors.password;
        }
    }

    if (!values.firstName) {
        errors.firstName = 'Bitte geben Sie ein Vornamen an';
    }

    if (!values.lastName) {
        errors.lastName = 'Bitte geben Sie ein Nachnamen an';
    }

    if (!values.involvement) {
        errors.involvement = 'Bitte wählen Sie eine Funktion für den Benutzer';
    }

    return errors;
}

export default validate;

// Async validation using a username checker method
export const validateWithUsernameCheck = (
    check: (username: string) => Promise<boolean>,
): (...a: Parameters<typeof validate>) => Promise<UserValidationErrors> => {
    return (values: any, withConfirmation: boolean = true, withPassword: boolean = true) => {
        const baseErrors = validate(values, withConfirmation, withPassword);
        if (Object.keys(baseErrors).length > 0) {
            return Promise.resolve(baseErrors);
        }

        return check(values.username)
            .then((result) => result ? {} : { username: 'Dieser Benutzername ist bereits vergeben' });
    }
}
