import ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import ajvJTD from 'ajv/dist/jtd';

const ajvInstance = new ajv({ allErrors: true, $data: true });
// register custom error support


export default ajvErrors(ajvInstance);

export const AjvJTD = new ajvJTD();
