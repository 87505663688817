import { useEffect, useState } from 'react';

import { SalesData } from '../../Lib/SalesDataMapper';
import StaffDataService from '../../Lib/StaffDataService';
import useNotifications from '../../Hooks/useNotifications';
import useService from '../../Hooks/useService';

/**
 * Hook arguments
 */
export type UseSalesDataProps = {
    /**
     * Callback when data is loaded
     */
    onLoaded: (data: SalesData) => void,
    /**
     * Current year
     */
    fiscalYear: [Date?, Date?],
    /**
     * Required pharmacy id
     */
    pharmacyId: number,
};

/**
 * Hook to load package info data from GKService
 */
const useSalesData = ({
    onLoaded,
    fiscalYear,
    pharmacyId,
}: UseSalesDataProps) => {
    const [load, setLoad] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const service = useService<StaffDataService>('staffData');
    const { addError, addSuccess } = useNotifications();

    // Request new data when load is finished and requested
    useEffect(() => {
        if (load > 1 && !isLoading) {
            if (!fiscalYear[0] || !fiscalYear[1]) {
                addError('Das Wirtschaftsjahr muss zuerst angegeben werden');
                return;
            }

            setIsLoading(true);
            service.getSalesInformation(pharmacyId, fiscalYear[0], fiscalYear[1])
                .then((result) => {
                    onLoaded(result);
                    addSuccess('Daten aus der Gehaltskasse geladen');
                })
                .catch(() => {
                    addError('Es konnten keine Daten aus der Gehaltskasse geladen werden');
                })
                .finally(() => setIsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);

    return {
        load: () => setLoad(Date.now()),
        isLoading,
    };
};

export default useSalesData;
