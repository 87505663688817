import ComparisonGroup, { GroupTypeEntity } from '../../Lib/ComparisonGroup';
import { KeysOfType, getModelByValue, getModelValue, getModelValueArray } from '../../Lib/Helper';
import React, { useEffect, useState } from 'react';

import { FormGroup } from '@material-ui/core';
import FormSelect from '../Common/FormSelect';
import MultiSelect from '../Common/MultiSelect';
import useCachedData from '../../Hooks/useCachedData';

/**
 * Type declarations
 */
type ObjectKeys<T> =
    T extends object ? (keyof T)[] :
    T extends number ? [] :
    T extends Array<any> | string ? string[] :
    never;

declare global {
    interface ObjectConstructor {
        keys<T>(o: T): ObjectKeys<T>
    }
}

/**
 * Form data consists of all entity types from ComparisonGroup model.
 */
type FormData = Partial<Pick<ComparisonGroup, KeysOfType<ComparisonGroup, Nullable<GroupTypeEntity>|GroupTypeEntity[]>>>;

/**
 * Props used in form
 */
export type FormProps = {
    /**
     * The model if editing
     */
    model?: ComparisonGroup | null,

    /**
     * Callback when form values change
     */
    onValuesChange: (values: Partial<ComparisonGroup>) => void,
};

/**
 * The component
 */
const Form = ({ model = null, onValuesChange = () => {} }: FormProps) => {
    // Null, not selceted value
    const nullValue = { begID: null, begBezeichnung: '' };

    // Use data for the selects from cache
    const selectData = useCachedData([
        'offizin',
        'privateShare',
        'highPriceShare',
        'openingHours',
        'pharmacyCount',
        'population',
        'state',
        'location',
    ]);

    // The form data, initial populated
    const [data, setData] = useState({
        offizin: getModelValue(model, 'offizin'),
        privateShare: getModelValue(model, 'privateShare'),
        highPriceShare: getModelValue(model, 'highPriceShare'),
        openingHours: getModelValue(model, 'openingHours'),
        pharmacyCount: getModelValue(model, 'pharmacyCount'),
        population: getModelValue(model, 'population'),
        state: getModelValue(model, 'state'),
        location: getModelValueArray(model, 'location'),
    });

    // Set a data
    const setDataForKey = (value: any, key: keyof ComparisonGroup) => {
        setData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Use side effect to notify change handler
    useEffect(() => {
        console.log("Data is:", data);
        onValuesChange(Object.keys(data).reduce((memo, key: keyof typeof data): FormData => ({
            // Complex case for typescript, we assign either null, entity or entity[] to key in
            // FormData which it cannot infer to the same type in dataState.
            // @ts-ignore
            [key]: (data[key] && getModelByValue(selectData[key], Array.isArray(data[key]) ? data[key].map((begID) => (begID)) : data[key])),
            ...memo,
        }), {}));
        // We really do only want to run this effect when data changes, not any other dep. See us
        // changeEffect hook for more detail. In this case we run it initially too to notify about
        // initial form data.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <form noValidate autoComplete="off">
            <FormGroup>
                <FormSelect
                    label="Offizinumsatz"
                    options={[nullValue, ...selectData.offizin]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.offizin}
                    onChange={(value) => setDataForKey(value?.begID, 'offizin')}
                />
                <FormSelect
                    label="Privatumsatz Anteil"
                    options={[nullValue, ...selectData.privateShare]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.privateShare}
                    onChange={(value) => setDataForKey(value?.begID, 'privateShare')}
                />
                <FormSelect
                    label="Hochpreisanteil"
                    options={[nullValue, ...selectData.highPriceShare]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.highPriceShare}
                    onChange={(value) => setDataForKey(value?.begID, 'highPriceShare')}
                />
            </FormGroup>
            <FormGroup>
                <FormSelect
                    label="Öffnungszeiten"
                    options={[nullValue, ...selectData.openingHours]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.openingHours}
                    onChange={(value) => setDataForKey(value?.begID, 'openingHours')}
                />
            </FormGroup>
            <FormGroup>
                <FormSelect
                    label="Apothekenanzahl"
                    options={[nullValue, ...selectData.pharmacyCount]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.pharmacyCount}
                    onChange={(value) => setDataForKey(value?.begID, 'pharmacyCount')}
                />
                <FormSelect
                    label="Einwohnerzahl"
                    options={[nullValue, ...selectData.population]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.population}
                    onChange={(value) => setDataForKey(value?.begID, 'population')}
                />
                <FormSelect
                    label="Bundesland"
                    options={[nullValue, ...selectData.state]}
                    valueKey="begID"
                    labelKey="begBezeichnung"
                    defaultValue={data.state}
                    onChange={(value) => setDataForKey(value?.begID, 'state')}
                />
            </FormGroup>
            <MultiSelect<GroupTypeEntity>
                title="Lage"
                items={selectData.location}
                labelKey="begBezeichnung"
                initialChecked={selectData.location.filter((loc: GroupTypeEntity) => data.location.includes(loc.begID))}
                onChange={(values) => setDataForKey(values.map((obj: GroupTypeEntity) => (obj.begID)), 'location')}
                exclusive={{
                    19: [20, 25],
                    20: [19, 26],
                    21: [26],
                    22: [26],
                    23: [26],
                    24: [26],
                    25: [26],
                    26: [19, 20, 21, 22, 23, 24, 25],
                }}
            />
        </form>
    );
};

export default Form;
