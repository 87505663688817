import {
    Box,
    BoxProps,
    Grid,
} from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

const NarrowContainer: React.FC<PropsWithChildren<BoxProps>> = ({
    children,
    ...boxProps
}) => (
    <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
            <Box {...boxProps}>
                {children}
            </Box>
        </Grid>
    </Grid>
);

export default NarrowContainer;
