import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = new UserValdiation(
    ['general.A19_LJ', '-', 'compare.general.A19_LJ'],
    { type: 'compare', compare: '=', value: 0 },
    'Ihre Angabe zur Eigenimmobilie erscheint nicht konsistent zum Vorjahr.',
);

export default rule;
