import {
    Box,
    Button,
} from '@material-ui/core';

import React from 'react';

export type SelectYearProps = {
    onSelect: (year: number) => void,
};

/**
 * Validation params for a year
 */
const SelectYear: React.FC<SelectYearProps> = ({ onSelect }) => {
    // Which year
    const currentYear = new Date().getFullYear();
    //const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1];
    const years = new Array(6).fill(1).map((_, i) => (currentYear - 4 + i));
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            width="100%"
        >
            {years.map((year) => (
                <Box p={1}>
                    <Button onClick={() => onSelect(year)} variant="outlined">
                        {year}
                    </Button>
                </Box>
            ))}
        </Box>
    );
};
export default SelectYear;
