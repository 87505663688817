import {
    Box,
    IconButton,
    StepIcon,
    Theme,
    Tooltip,
} from '@material-ui/core';
import {
    Field,
    FieldAttributes,
} from 'formik';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    helperFieldContainer: {
        marginRight: theme.spacing(-3),
        whiteSpace: 'nowrap',
    },
    helperFieldInput: {
        width: `calc(100% - ${theme.spacing(3)})`,
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    helperFieldHelp: {
        width: theme.spacing(3),
        verticalAlign: 'middle',
        display: 'inline-block',
        position: 'relative',
        top: '4px',

        '& .MuiStepIcon-root': {
            width: '1.25em',
            height: '1.25em',
        },
    },
    helpButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const useTooltipStyles = makeStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.primary.main,
    },
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: 2,
    },
}));

export type HelperFieldProps = FieldAttributes<any> & {
    help: string,
};

const HelperField: React.FC<HelperFieldProps> = ({
    help,
    ...fieldProps
}) => {
    const classes = useStyles();
    const tooltipClasses = useTooltipStyles();

    return (
        <Box component="div" className={classes.helperFieldContainer}>
            <Box component="div" className={classes.helperFieldInput}>
                <Field
                    {...fieldProps}
                />
            </Box>
            <Box component="div" className={classes.helperFieldHelp}>
                <Tooltip title={help} placement="right" arrow classes={tooltipClasses}>
                    <IconButton disableFocusRipple disableRipple className={classes.helpButton}>
                        <StepIcon icon="?" active />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default HelperField;
