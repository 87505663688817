import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = new UserValdiation(
    ['staff.director', '+', ['staff.pharmacist', '+', 'staff.aspirant']],
    { type: 'compare', compare: '>', value: 0 },
    'Sie haben Fachpersonal erfasst, aber keinen Aufwendungen für pharmazeutische Fachkräfte in der GuV erfasst.',
    ['E7_LJ', 0, '='],
    'Personal_15',
);

export default rule;
