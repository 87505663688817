import useNotifications from './useNotifications';

export type SaveTexts = {
    success?: string,
    error?: string,
};

const defaultTexts: Required<SaveTexts> = {
    success: 'Erfolgreich gespeichert',
    error: 'Speichern fehlgeschlagen',
};

const useSaveWithNotification = <T extends (...args: any[]) => any>(
    saveFn: T, notificationTexts: SaveTexts = {},
): T => {
    const { addControlledNotification, addSuccess, addError } = useNotifications();

    const save = (...args: Parameters<T>): ReturnType<T> => {
        const { hideNotification } = addControlledNotification('Lade...', 'loading', '');

        return saveFn(...args)
            .then(() => {
                addSuccess(notificationTexts.success || defaultTexts.success);
            })
            .catch((e: Error) => {
                console.error(e, "error from save");
                addError(`${notificationTexts.error || defaultTexts.error}: ${e.message}`);
            })
            .finally(() => {
                hideNotification();
            });
    };

    // TS is limited in function wrapping capabilities. No solution could be found presently. The
    // code above would suggest to satisfy the return type being the same signature as the wrapped
    // function call.
    // @ts-ignore
    return save;
};

export default useSaveWithNotification;
