import {
    Box,
    LinearProgress,
    Tooltip,
    Typography,
} from '@material-ui/core';

import AnimatedNumber from '../Common/AnimatedNumber';
import {
    Help as HelpIcon,
} from '@material-ui/icons';
import React from 'react';
import useTypeStyles from '../Common/Ui/useTypeStyles';

type DataCompletenessProps = {
    value: number,
    year: number,
};

const DataCompleteness: React.FC<DataCompletenessProps> = ({ value, year }) => {
    const classes = useTypeStyles();
    return (
        <Box p={2} pt={0}>
            <Typography variant="body1" className={classes.yearlyText} style={{ fontSize: 40 }}>
                <AnimatedNumber
                    number={value}
                    format={(val) => `${Math.round(val)}%`}
                />
            </Typography>
            <LinearProgress
                color="primary"
                classes={{ bar: classes.yearlyButton, colorPrimary: classes.yearlyBackgroundLight  }}
                value={value}
                variant="determinate"
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" className={classes.yearlyText}>
                    vollständig
                </Typography>
                <Tooltip
                    title={'Bitte eine "0" eingeben, wenn der Saldo einer Position null beträgt.'}
                    placement="bottom"
                    arrow
                >
                    <HelpIcon color="action" />
                </Tooltip>
            </Box>
        </Box>
    );
}

export default DataCompleteness;
