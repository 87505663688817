import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E21_LJ', '/', 'intermediates.total_output_LJ'],
        { type: 'bounds', ...params },
        'Sie haben Ihre Apotheke als Pachtapotheke deklariert, jedoch erscheint der Pachtaufwand unplausibel. Bitte prüfen Sie Ihre Eingaben.',
        ['general.A5_LJ', 'tenure'],
        'GuV_21',
    )
);

export default rule;
