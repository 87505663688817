import { Chip, makeStyles } from '@material-ui/core';

export type MainPharmacyIndicatorProps = {
    value: boolean,
};

const useStyles = makeStyles(() => ({
    main: {
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '1em',
    },
    branch: {
        backgroundColor: '#999999',
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '1em',
    },
}));

export const labels: Record<0 | 1, string> = {
    1: 'Hauptapotheke',
    0: 'Filialapotheke',
};

const MainPharmacyIndicator = ({ value }: MainPharmacyIndicatorProps) => {
    const classes = useStyles();
    return (
        <Chip className={classes[value ? 'main' : 'branch']} label={labels[value ? 1 : 0]} />
    );
};

export default MainPharmacyIndicator;
