import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = new UserValdiation(
    ['staff.trainee',
        '+', ['staff.employeeSkilled',
            '+', ['staff.merchant',
                '+', ['staff.cleaner',
                    '+', 'staff.otherEmployee']]]],
    { type: 'compare', compare: '=', value: 0 },
    'Sie haben einen Aufwand für Hilfspersonal in der GuV erfasst, aber keine Hilfskräfte.',
    ['E8_LJ', 0, '>'],
    'Personal_16',
);

export default rule;
