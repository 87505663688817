import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.gross_yield_LJ', '/', 'compare.intermediates.gross_yield_LJ'],
        { type: 'bounds', ...params },
        'Die Bruttogewinnveränderung weicht deutlich vom Vorjahr ab.',
        undefined,
        'GuV_19',
    )
);

export default rule;
