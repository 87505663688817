import { CircularProgress, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Alert } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import { NotificationType } from '../../Providers/NotificationProvider';

const alertSeverities = ['error', 'success', 'info'];

export type NotificationProps = {
    message: string,
    type?: NotificationType,
    actions?: Array<any>,
    onClose?: () => void,
    open?: boolean | null,
};

const Notification: React.FC<NotificationProps> = ({
    message,
    type = 'default',
    actions = [],
    onClose = () => {},
    open = null,
}) => {
    // Open state
    const [isOpen, setOpen] = useState<boolean>(true);

    // Closing handler
    const handleClose = (event?: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // Autohiding?
    const autoHide = open === null ? 3000 : null;

    // Initial open
    useEffect(() => {
        setOpen(open !== false);
    }, [open]);

    const iconProp: { icon?: React.ReactNode, action?: React.ReactNode } = {};
    if (type === 'loading') {
        iconProp.icon = (
            <CircularProgress
                variant="indeterminate"
                size={20}
                thickness={4}
                color="inherit"
            />
        );
    } else {
        iconProp.action = (
            <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>
        );
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={isOpen}
            autoHideDuration={autoHide}
            onClose={handleClose}
            action={iconProp.action || null}
        >
            {alertSeverities.includes(type) ? (
                <Alert
                    // TS cannot infer this, also it cannot handle casting the severities to
                    // literals bacause then .includes does not work anymore with arbitrary string
                    // which would be required in this context.
                    // @ts-ignore
                    severity={type === 'loading' ? 'info' : type}
                    variant="filled"
                    {...iconProp}
                >
                    {message}
                </Alert>
            ) : (
                <SnackbarContent message={message} />
            )}
        </Snackbar>
    );
}

export default Notification;
