import { Link, makeStyles } from '@material-ui/core';

export type MonthlyControlProps = {
    isActive: boolean,
    onActivate: () => void,
};

const useStyles = makeStyles(({ palette }) => ({
    active: {
        color: palette.success.main,
        fontWeight: 700,
        lineHeight: '1em',
    },
    inactive: {
        lineHeight: '1em',
    },
}));

export const labels: Record<0 | 1, string> = {
    1: 'aktiviert',
    0: 'monatlichen Tara-Report aktivieren',
};

const MonthlyControl = ({ isActive, onActivate }: MonthlyControlProps) => {
    const classes = useStyles();
    const props = isActive
        ? { className: classes.active, component: 'span' }
        : { className: classes.inactive, onClick: onActivate, component: 'button' };
    return (
        <Link {...props}>
            {labels[isActive ? 1 : 0]}
        </Link>
    );
};

export default MonthlyControl;
