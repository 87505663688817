export type GroupTypeEntity = {
    begID: number,
    begBezeichnung: string,
};

export default class ComparationGroup {
    id: Nullable<number> = null;

    offizin: Nullable<GroupTypeEntity> = null;

    privateShare: Nullable<GroupTypeEntity> = null;

    highPriceShare: Nullable<GroupTypeEntity> = null;

    openingHours: Nullable<GroupTypeEntity> = null;

    pharmacyCount: Nullable<GroupTypeEntity> = null;

    population: Nullable<GroupTypeEntity> = null;

    state: Nullable<GroupTypeEntity> = null;

    location: Array<GroupTypeEntity> = [];

    active: boolean = false;

    valid: boolean = false;

    title: string = '';

    constructor(
        offizin: Nullable<GroupTypeEntity> = null,
        privateShare: Nullable<GroupTypeEntity> = null,
        highPriceShare: Nullable<GroupTypeEntity> = null,
        openingHours: Nullable<GroupTypeEntity> = null,
        pharmacyCount: Nullable<GroupTypeEntity> = null,
        population: Nullable<GroupTypeEntity> = null,
        state: Nullable<GroupTypeEntity> = null,
        location: Array<GroupTypeEntity> = [],
        active: boolean = true,
        valid: boolean = true,
        title: string = '',
        id: Nullable<number> = null,
    ) {
        this.offizin = offizin;
        this.privateShare = privateShare;
        this.highPriceShare = highPriceShare;
        this.openingHours = openingHours;
        this.pharmacyCount = pharmacyCount;
        this.population = population;
        this.state = state;
        this.location = location;
        this.active = active;
        this.valid = valid;
        this.title = title;
        this.id = id;
    }
}
