import { createTheme } from '@material-ui/core/styles';
import { mainColorYearly } from '../Common/Ui/useTypeStyles';

export default createTheme({
    /**
     * Adjust the color palette
     */
    palette: {
        primary: {
            main: mainColorYearly,
        },
        secondary: {
            main: mainColorYearly,
        },
        context: {
            yearly: '#8D68A0',
            monthly: '#7195E4',
        },
    },
});
