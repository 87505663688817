import { RuleParamaters, ruleParameters } from '../YearlyDataValidation';

import { RuleParamaterInput } from '../../Components/ValidationParams/Definitions';

/**
 * Valid bounds node
 */
export const isValidBounds = (bounds: { low?: number, high?: number }): boolean => (
    bounds.low !== undefined && bounds.low !== null
    && bounds.high !== undefined && bounds.high !== null
);

/**
 * Valid param node
 */
export const isValidParam = (param?: RuleParamaterInput): boolean => (
    !(
        !param
        || !param.bounds
        || !isValidBounds(param.bounds)
        || (
            param.boundsCritical &&
            !isValidBounds(param.boundsCritical)
        )
    )
);

/**
 * Get errorneous entries from a values object
 */
export const getErrorneous = (values: any) => {
    return ruleParameters.filter((param) => !isValidParam(values[param]));
}

/**
 * Validate if all yearly params are present
 */
const validate = (values: any): values is RuleParamaters => {
    if (typeof values !== 'object') {
        return false;
    }
    let allValid = true;

    // Traverse all parameter key to see if they are all set
    ruleParameters.forEach((param) => {
        if (
            !isValidParam(values[param])
        ) {
            allValid = false;
        }
    });

    return allValid;
}

export default validate;
