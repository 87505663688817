import MasterDataSchema, { MasterDataApiEntity } from '../Schemas/Api/MasterData';

import { GroupTypeEntity } from './ComparisonGroup';
import SchemaMapper from './SchemaMapper';

export default class ComparisonGroupSchemaMapper
    extends SchemaMapper<GroupTypeEntity, MasterDataApiEntity> {

    schema = MasterDataSchema;

    mapToData(model: GroupTypeEntity) {
        return {
            lkuID: model.begID,
            lkuWert: model.begBezeichnung,
            lkuAktiv: 'J' as const,
        };
    }

    mapToModel(data: MasterDataApiEntity) {
        return {
            begID: data.lkuID,
            begBezeichnung: data.lkuWert,
        };
    }
}
