import FormSuccess from '../Common/Form/FormSuccess';
import PharmacyDataForm from '../User/PharmacyDataForm';
import PharmacyService from '../../Lib/PharmacyService';
import React from 'react';
import RegistrationCodeForm from '../User/RegistrationCodeForm';
import SteppedForm from '../Common/Form/SteppedForm';
import { useHistory } from 'react-router-dom';
import useService from '../../Hooks/useService';
import validatePharmacy from '../../Lib/FormValidations/Pharmacy';

const RegisterBranch : React.FC<{}> = () => {
    const service = useService<PharmacyService>('pharmacy');
    const history = useHistory();

    return (
        <SteppedForm
            stepConfig={{
                Registrierungscode: RegistrationCodeForm,
                Apothenkendaten: (props) => <PharmacyDataForm {...props} isMain={false} />,
            }}
            validations={{
                // @ts-ignore
                Registrierungscode: () => ({}),
                Apothenkendaten: (data) => validatePharmacy(data, true),
            }}
            // @ts-ignore
            onSubmit={(data) => service.register([
                data[0],
                {
                    ...data[1],
                    branch: true,
                    active: true,
                    title: data[0].title,
                    number: data[0].number,
                    dateOfBalance: data[1].dateOfBalance
                        ? new Date(Date.parse(data[1].dateOfBalance)) : null
                },
                undefined,
            ])}
            completeLabel="Filialapotheke registrieren"
        >
            <FormSuccess
                title="Neue Filialie registriert"
                text="Die Filialie wurde erfolgreich registriert. Sie können sofort Benutzer für diese Filiale hinzufügen"
                actionLabel="Zur Filialliste"
                onAction={() => history.push('/filialen')}
            />
        </SteppedForm>
    );
};

export default RegisterBranch;
