import {
    Column,
    FilterValue,
    Row,
    useGlobalFilter,
    usePagination,
    useTable,
} from 'react-table';
import {
    Paper,
    TablePagination,
    TextField,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import renderTable, { columnDefinitions } from './renderTable';

import ComparisonGroup from '../../Lib/ComparisonGroup';
import { debounce } from '../../Lib/Helper';
import renderList from './renderList';

// Function to filter by a keyword
const filterByKeyword = (
    rows: Array<Row<ComparisonGroup>>, columnIds: Array<string>, globalFilterValue: FilterValue,
) => {
    console.log(globalFilterValue);
    return rows.filter((row) => (
        String(row.original.title)
            .toLowerCase()
            .includes(String(globalFilterValue.term).toLowerCase())
    ));
};

export type ComparisonGroupsTableProps = {
    onQuickEditRow: (model: ComparisonGroup, active: boolean) => void,
    onEditRow: (model: any) => void,
    data: Array<ComparisonGroup>,
};

/**
 * Table of comparison groups
 */
const ComparisonGroupsTable = ({
    onQuickEditRow,
    onEditRow,
    data,
}: ComparisonGroupsTableProps) => {
    // Memoize data used, important
    const tableData = useMemo(
        () => data,
        [data],
    );
    const [pageCountState, /*setPageCountState*/] = useState(1);

    // Mobile or not
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Memoized column definitions
    const columns: Column<ComparisonGroup>[] = useMemo(
        () => columnDefinitions,
        [],
    );

    // Instance with table hook
    const tableInstance = useTable<ComparisonGroup>({
        columns,
        data: tableData,
        initialState: { pageIndex: 0, pageSize: 25, globalFilter: { term: '' } },
        pageCount: pageCountState,
        globalFilter: filterByKeyword,
    }, useGlobalFilter, usePagination);

    // Unwrap some properties
    const {
        getTableProps,
        getTableBodyProps,
        gotoPage,
        headerGroups,
        prepareRow,
        page: rows,
        setGlobalFilter,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    // Debounce filter triggering
    const filterSetter = debounce(setGlobalFilter, 400);

    return (
        <Paper elevation={0}>
            <Toolbar disableGutters>
                <div className="toolbar-primary">
                    <Typography variant="h6" component="div">
                        Vergleichsgruppen
                    </Typography>
                </div>
                <TextField
                    label="Stichwortsuche"
                    onChange={({ target: { value } }) => filterSetter({
                        ...globalFilter,
                        term: value,
                    })}
                />
            </Toolbar>
            {(isMobile ? renderList : renderTable)({
                getTableProps,
                getTableBodyProps,
                headerGroups,
                page: rows,
                prepareRow,
                setPageSize,
                setGlobalFilter,
                onEditRow,
                onQuickEditRow,
            })}
            <TablePagination
                component="div"
                count={tableData.length}
                page={pageIndex}
                onPageChange={(e, p) => gotoPage(p)}
                labelDisplayedRows={({ from, to, count }) => (`${from}-${to} / ${count !== -1 ? count : to}`)}
                rowsPerPage={pageSize}
                onRowsPerPageChange={({ target: { value } }) => setPageSize(parseInt(value))}
            />
        </Paper>
    );
};

export default ComparisonGroupsTable;
