import {
    ListItemIcon,
    MenuItem,
    Theme,
    Typography,
} from '@material-ui/core';

import React from 'react';
import {
    Warning as WarningIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export type FormMenuItemProps = {
    onClick: () => void,
    label: string,
};

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        backgroundColor: '#FFF1CD',
        color: '#D5151B',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        whiteSpace: 'normal',
        marginTop: theme.spacing(2),

        '&:hover': {
            backgroundColor: '#FFF1B5',
        },
    },
    icon: {
        color: '#FFB100',
    },
    text: {
        textTransform: 'none',
        color: 'inherit',
        fontWeight: 'normal',
        textDecoration: 'underline',
    }
}));

const FormMenuItem: React.FC<FormMenuItemProps> = ({
    onClick,
    label,
}) => {
    const classes = useStyles();

    return (
        <MenuItem
            onClick={onClick}
            className={classes.item}
        >
            <ListItemIcon>
                <WarningIcon className={classes.icon} />
            </ListItemIcon>
            <Typography variant="body1" color="textPrimary" className={classes.text}>
                {label}
            </Typography>
        </MenuItem>
    );
};

export default FormMenuItem;
