import CachingService from './CachingService';
import ComparisonGroupMapper from './ComparisonGroupMapper';
import ComparisonGroupService from './ComparisonGroupService';
import LookupService from './LookupService';
import PharmacyMapper from './PharmacyMapper';
import PharmacyService from './PharmacyService';
import ReportMapper from './ReportMapper';
import ReportService from './ReportService';
import StaffDataService from './StaffDataService';
import UserMapper from './UserMapper';
import UserService from './UserService';
import YearlyDataService from './YearlyDataService';

/**
 * Service provider
 */
/* eslint-disable class-methods-use-this */
export default class ServiceProvider {
    services: { [name: string]: any } = {};
    registered: { [name: string]: () => any } = {};
    cache: { [name: string]: any } = {};
    fullyInitialized: boolean = false;

    /**
     * Initialize the service provider
     */
    initialize() {
        this.services = {
            offizin: new CachingService('BenchmarkingGruppen/Offizinumsatz'),
            privateShare: new CachingService('BenchmarkingGruppen/Privatumsatz'),
            highPriceShare: new CachingService('BenchmarkingGruppen/Hochpreisanteil'),
            openingHours: new CachingService('BenchmarkingGruppen/Oeffnungszeiten'),
            pharmacyCount: new CachingService('BenchmarkingGruppen/Apothekenanzahl'),
            population: new CachingService('BenchmarkingGruppen/Einwohner'),
            state: new CachingService('BenchmarkingGruppen/Bundeslaender'),
            location: new CachingService('BenchmarkingGruppen/Lage'),
            software: new LookupService('Lookup/Software'),
            role: new LookupService('Lookup/Rolle'),
            involvement: new LookupService('Lookup/Funktion'),
            yearlyData: new YearlyDataService(),
            staffData: new StaffDataService(),
            report: new ReportService(new ReportMapper()),
        };

        this.registered = {
            comparisonGroup: () => this.initComparisonGroupService(),
            pharmacy: () => this.initPharmacyService(),
            user: () => this.initUserService(),
        };

        return this;
    }

    /**
     * Stub to load all required dependencies
     */
    loadAllRequiredDependencies() {
        const required = [
            'offizin',
            'privateShare',
            'highPriceShare',
            'openingHours',
            'pharmacyCount',
            'population',
            'state',
            'location',
            'software',
            'role',
            'involvement',
        ];
        return Promise.all(
            required.filter((key) => (!this.cache[key]))
                .map((key) => this.get(key).getAll()),
        )
            .then((fetchedData) => {
                this.cache = required.reduce<{ [key: string]: any }>((memo, key, i) => {
                    memo[key] = fetchedData[i];
                    return memo;
                }, {});
                return fetchedData;
            })
            .then((rollingResult) => {
                this.fullyInitialized = true;
                console.log("FULLY");
                return rollingResult;
            })
            .catch((e) => {
                throw e;
            });
    }

    /**
     * Get some service
     */
    get(serviceName: string) {
        if (!this.services[serviceName]) {
            this.loadService(serviceName);
        }

        return this.services[serviceName];
    }

    /**
     * Load a service
     */
    // eslint-disable-next-line no-unused-vars
    loadService(serviceName: string) {
        if (!this.registered[serviceName]) {
            throw Error(`Service with name ${serviceName} not found`);
        }
        this.services[serviceName] = this.registered[serviceName]();
    }

    initComparisonGroupService() {
        const mapper = new ComparisonGroupMapper(
            this.cache.offizin,
            this.cache.privateShare,
            this.cache.highPriceShare,
            this.cache.openingHours,
            this.cache.pharmacyCount,
            this.cache.population,
            this.cache.state,
            this.cache.location,
        );
        return new ComparisonGroupService(mapper);
    }

    initUserService() {
        const mapper = new UserMapper();
        return new UserService(mapper);
    }

    initPharmacyService() {
        const mapper = new PharmacyMapper(
            this.cache.openingHours,
            this.cache.pharmacyCount,
            this.cache.population,
            this.cache.state,
            this.cache.location,
            this.cache.software,
        );
        return new PharmacyService(mapper);
    }
}
