import {
    Card,
    CardContent,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useAuth, useAuthenticate } from '../../Hooks/useAuth';

import { Alert } from '@material-ui/lab';
import { ArrowRight as ArrowForward } from '../Common/Ui/Icons';
import CubeLogo from '../../Layout/CubeLogo';
import LoadingButton from '../Common/LoadingButton';
import React from 'react';
import { TextField } from 'formik-material-ui';
import useTwoColumnFormStyles from './useTwoColumnFormStyles';
import welcomeIlluSvg from '../../assets/apostar_welcome_illu.svg';

type SignInProps = {
    onInfoClicked: () => void,
    onPasswordLostClicked: () => void,
    onRegisterClicked: () => void,
};

const SignIn: React.FC<SignInProps> = ({
    onInfoClicked, onPasswordLostClicked, onRegisterClicked,
}) => {
    const classes = useTwoColumnFormStyles();
    const authenticate = useAuthenticate();
    const { error, isLoading } = useAuth();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            className={`${classes.fullGrid} ${classes.breakLayout}`}
        >
            <Grid item md className={[classes.infoColumn, classes.columnLg].join(' ')}>
                <CubeLogo onClick={() => {}} className={classes.logo} />
                <Card raised={false}>
                    <CardContent>
                        <Typography variant="h2" align="center" className={classes.spaceBottom}>
                            Login
                        </Typography>
                        <Typography variant="body2" align="center" color="textSecondary">
                            Sie haben noch keinen Account?{' '}
                            <Link
                                component="button"
                                variant="body2"
                                onClick={onRegisterClicked}
                            >
                                Hier neu registrieren
                            </Link>
                        </Typography>

                        {error && (<Alert severity="error">
                            {error.message}
                        </Alert>)}
                        <Formik
                            initialValues={{ username: '', password: '' }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    authenticate(values);
                                }, 400);
                            }}
                        >
                            {({ isSubmitting, submitForm }) => (
                                <Form>
                                    <Field
                                        type="text"
                                        name="username"
                                        label="Benutzer"
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <Field
                                        type="password"
                                        name="password"
                                        label="Passwort"
                                        component={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <div className={classes.spaceTop}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            // loadingPosition="start"
                                            loading={isLoading || isSubmitting}
                                            onClick={submitForm}
                                            type="submit"
                                            fullWidth
                                        >
                                            Login
                                        </LoadingButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <Typography
                            variant="body1"
                            align="center"
                            component="p"
                            className={classes.spaceTop}
                        >
                            <Link
                                component="button"
                                variant="body2"
                                onClick={onPasswordLostClicked}
                            >
                                Passwort vergessen
                            </Link>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md className={[classes.formColumn, classes.columnSm].join(' ')}>
                <div>
                    <Typography variant="h1" color="inherit" className={classes.lead}>
                        Herzlich Willkommen auf ApoStar!
                    </Typography>
                    <Typography component="p" variant="body1" color="inherit" className={classes.spaceBottom}>
                        Treffen Sie betriebswirtschaftliche Entscheidungen auf Basis konkreter
                        Kennzahlen von vergleichbaren Apotheken. Völlig vertraulich und anonym.
                    </Typography>
                    <Link
                        component="button"
                        variant="body1"
                        color="inherit"
                        onClick={onInfoClicked}
                        className={classes.leftAlign}
                    >
                        <ArrowForward />
                        Über Apostar
                    </Link>
                    <img
                        src={welcomeIlluSvg}
                        className={classes.pic} 
                        alt="Illustration Apotheker und Apothekerin"
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default SignIn;
