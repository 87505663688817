import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useTwoColumnFormStyles = makeStyles((theme: Theme) => ({
    fullGrid: {
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            flexDirection: 'column-reverse',
        },
    },
    columnLg: {
        minWidth: `${theme.breakpoints.width('md') * 2 / 3}px`,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
        },
    },
    columnSm: {
        minWidth: `${theme.breakpoints.width('md') * 1 / 3}px`,
    },
    infoColumn: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: '10%',
        paddingRight: '10%',

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            width: `calc(100% + ${theme.spacing(2)})`,
        },
    },
    formColumn: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fafafa',
        paddingTop: '5%',
        paddingBottom: '5%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flex: 1,
            paddingLeft: '10%',
            paddingRight: '10%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    breakLayout: {
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1299,
            overflow: 'auto',
        },
    },
    leftAlign: {
        textAlign: 'left',
    },
    spaceTop: {
        marginTop: theme.spacing(1),
    },
    spaceBottom: {
        marginBottom: theme.spacing(2),
    },
    lead: {
        marginBottom: theme.spacing(2),
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },
    logo: {
        alignSelf: 'flex-start',
        marginLeft: -40,
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    contentCenter: {
        textAlign: 'center',
    },
    dividerSpaced: {
        marginTop: 30,
        marginBottom: 30,
    },
    pic: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            width: '130%',
            display: 'block',
            marginLeft: '-15%',
        },
    },
}));

export default useTwoColumnFormStyles;
