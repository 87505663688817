import SalesDataMapper, { SalesData } from './SalesDataMapper';
import { localDate, urify } from './Helper';

import JSONFetch from './JSONFetch';
import StaffDataMapper from './StaffDataMapper';
import { StaffRow } from './YearlyData';

export default class StaffDataService {
    get(pharmacyId: number, year: number, start: Date, end: Date): Promise<Array<StaffRow>> {
        return JSONFetch({
            url: urify('/GetPersonalFromGK', {
                ApoId: pharmacyId,
                Jahr: year,
                WJBeginn: localDate(start)?.substring(0, 7).replace('-', ''),
                WJEnde: localDate(end)?.substring(0, 7).replace('-', ''),
            }),
            bodySerializer: () => '',
            responseParser: (new StaffDataMapper(pharmacyId, year)).parseArray,
        }).then((loadedStaff) => (
            loadedStaff.sort((a, b) => (a.type.localeCompare(b.type)))
        ));
    }

    getSalesInformation(pharmacyId: number, start: Date, end: Date): Promise<SalesData> {
        return JSONFetch({
            url: urify('/GetRezPckFromGK', {
                ApoId: pharmacyId,
                WJBeginn: localDate(start)?.substring(0, 7).replace('-', ''),
                WJEnde: localDate(end)?.substring(0, 7).replace('-', ''),
            }),
            bodySerializer: () => '',
            responseParser: (new SalesDataMapper()).parse,
        });
    }
}
