import { SkilledStaffMarkType, StaffMarkType } from '../../Schemas/Api/StaffData';
import { StaffType, staffTypeOptions } from '../YearlyData';

export const getCategory = (type: Readonly<StaffType>): keyof typeof staffTypeOptions => {
    return Object.keys(staffTypeOptions).find((key: keyof typeof staffTypeOptions) => (
        (Object.keys(staffTypeOptions[key]) as Array<StaffType>).includes(type)
    )) || 'employee';
};

export const CATEGORY_MARK: Record<StaffType, 'DG' | 'DN' | 'ME'> = {
    concessionaire: 'DG',
    ownerPharmacist: 'ME',
    ownerNonPharmacist: 'ME',
    director: 'DN',
    pharmacist: 'DN',
    aspirant: 'DN',
    trainee: 'DN',
    employeeSkilled: 'DN',
    merchant: 'DN',
    cleaner: 'DN',
    familyPharmacist: 'DG',
    familyNonPharmacist: 'DG',
};

export const SKILLED_MARKS: Record<StaffType, SkilledStaffMarkType> = {
    concessionaire: 'AL',
    ownerPharmacist: 'PH',
    ownerNonPharmacist: 'SP',
    director: 'AL',
    pharmacist: 'PH',
    aspirant: 'AS',
    trainee: 'SP',
    employeeSkilled: 'SP',
    merchant: 'SP',
    cleaner: 'SP',
    familyPharmacist: 'PH',
    familyNonPharmacist: 'SP',
};

const intermediateMapping: Record<StaffType, StaffMarkType> = {
    concessionaire: 'A52',
    ownerPharmacist: 'A63',
    ownerNonPharmacist: 'A64',
    director: 'A54',
    pharmacist: 'A55',
    aspirant: 'A56',
    trainee: 'A58',
    employeeSkilled: 'A60',
    merchant: 'A57',
    cleaner: 'A61',
    familyPharmacist: 'A53',
    familyNonPharmacist: 'A65',
};

export default intermediateMapping;
