import {
    FormGroup,
    Grid,
    Typography,
} from '@material-ui/core';

import React from 'react';

export type CompareableNumbersHeaderProps = {
    year: number,
};

const CompareableNumbersHeader: React.FC<CompareableNumbersHeaderProps> = ({ year }) => {
    return (
        <FormGroup style={{ marginBottom: '2em' }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={6} sm={4}>
                    <Typography color="textPrimary" style={{ textAlign: 'right' }}>
                        {year}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={4}>
                    <Typography color="textPrimary" style={{ textAlign: 'right' }}>
                        {year - 1}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={4} style={{ textAlign: 'right' }}>
                    <Typography color="textPrimary">
                        Entwicklung
                    </Typography>
                </Grid>
            </Grid>
        </FormGroup>
    );
};

export default CompareableNumbersHeader;
