import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.staff_cost_LJ', '/', 'compare.intermediates.staff_cost_LJ'],
        { type: 'bounds', ...params },
        'Ihre Angabe zum Personalaufwand erscheint nicht konsistent zum Vorjahr.',
        undefined,
        'GuV_16',
    )
);

export default rule;
