import { Box, Typography } from '@material-ui/core';

import React from 'react';

export type LabelValueProps = {
    label: React.ReactNode,
    value: React.ReactNode,
};

const LabelValue: React.FC<LabelValueProps> = ({ label, value }) => (
    <Box>
        <Typography component="span" display="block" variant="overline" color="textSecondary">
            {label}
        </Typography>
        <Typography component="span" display="block" variant="body1">
            {value}
        </Typography>
    </Box>
);

export default LabelValue;
