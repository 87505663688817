import UserMapper, { parseArrayJSON, parseJSON } from './UserMapper';

import JSONFetch from './JSONFetch';
import User from './User';
import { urify } from './Helper';

export default class UserService {
    // Mapper to use
    mapper: UserMapper;

    // The constructor
    constructor(mapper: UserMapper) {
        this.mapper = mapper;
    }

    getUsernameAvailable(username: string): Promise<boolean> {
        return JSONFetch({
            url: urify('/CheckDBEntity', { type: 'Benutzer', value: username }),
            bodySerializer: () => '',
            responseParser: JSON.parse
        }).then((response) => (!!response.returnValue));
    }

    getAll(pharmacyId: number): Promise<Array<User>> {
        return JSONFetch({
            url: `/BenutzerByApoID/${pharmacyId}`,
            responseParser: parseArrayJSON,
            bodySerializer: () => '',
        })
            .then((data) => data.map((item) => this.mapper.mapToModel(item)));
    }

    setActive(user: User, active = true): Promise<User> {
        user.active = active;

        return JSONFetch({
            method: 'put',
            url: '/UpdateBenutzer',
            body: user,
            bodySerializer: (data) => JSON.stringify(this.mapper.mapToData(data)),
        })
            .then(() => user);
    }

    lostPasswordRequest(email: string, pharmacyNumber: string): Promise<void> {
        return JSONFetch({
            url: urify('/GetPWChangeCode', { userMail: email, apoBetrNr: pharmacyNumber }),
            bodySerializer: () => '',
        })
            .then(() => {});
    }

    save(user: User): Promise<User> {
        return JSONFetch({
            method: user.id ? 'put' : 'post',
            url: user.id ? '/UpdateBenutzer' : '/InsertBenutzer',
            body: user,
            bodySerializer: (data) => JSON.stringify(this.mapper.mapToData(data)),
            // We provide a non empty response string always
            responseParser: (r) => (`${r}  `),
        })
            .then((data) => {
                if (user.id) {
                    return user;
                }
                const parsedResponse = parseJSON(data);
                if (!parsedResponse) {
                    throw new Error(`Invalid user response from server: ${parseJSON.message}`);
                }

                return this.mapper.mapToModel(parsedResponse);
            });
    }

    savePassword(user: User): Promise<void> {
        return JSONFetch({
            method: 'put',
            url: '/UpdateBenutzer',
            body: user,
            bodySerializer: (data) => JSON.stringify(this.mapper.mapToData(data)),
        })
            .then(() => {});
    }

    changePassword(code: string, newPassword: string): Promise<void> {
        return JSONFetch({
            method: 'put',
            url: `/ChangePassword?guid=${code}&pwd=${newPassword}`,
            bodySerializer: () => '',
        }).then(() => {});
    }
}
