import { CellObject, WorkBook } from 'xlsx';
import { PharmacyLeaderOccupation, PharmacyNightDutiesPerYear, YearlyData } from './YearlyData';

/**
 * All required sheets in the document
 */
export const requiredSheets = ['Input Allgemeine Daten', 'Input_Personal', 'ABV Ergebnis Finanz'];

/**
 * Internal cell value reader
 */
export const getCellValue = (
    cell?: CellObject, valueMap?: Record<string, string> | 'bool' | 'date'
) => {
    // Not set values
    const cellValue = cell?.v;
    if (cellValue === undefined || cellValue === null) {
        return undefined;
    }

    // Enum value map
    if (valueMap && typeof valueMap !== 'string') {
        if (typeof cellValue !== 'string') {
            throw Error('Invalid data type');
        }
        const value = Object.entries(valueMap)
            .find(([, readableValue]) => (readableValue === cellValue))?.[0];
        if (undefined === value) {
            throw Error('Invalid data type');
        }

        return value;
    }

    // Boolean types
    if ('bool' === valueMap) {
        // Direct boolean fields
        if (cellValue === true || cellValue === false) {
            return cellValue;
        }

        // Boolean per german char
        if (typeof cellValue === 'string' && ['J', 'N'].includes(cellValue)) {
            return cellValue === 'J';
        }

        throw Error('Invalid data type');
    }

    // Date handling
    if ('date' === valueMap) {
        const parsedDate = Date.parse(cell?.w ?? '');
        if (!parsedDate || isNaN(parsedDate)) {
            throw Error('Invalid data type');
        }
        return new Date(parsedDate);
    }

    // Numbers must be rounded
    if (typeof cellValue !== 'number') {
        throw Error('Invalid data type');
    }

    return Math.round(cellValue);
}

/**
 * Map excel cells to data points
 */
export const dataMap = {
    general: {
        sheet: 'Input Allgemeine Daten',
        valueMap: {
            A5_LJ: PharmacyLeaderOccupation,
            A9_LJ: PharmacyNightDutiesPerYear,
            A4_LJ: 'bool',
            A6_LJ: 'bool',
            A7_LJ: 'bool',
            A2_LJ: 'date',
            A3_LJ: 'date',
            A19_LJ: 'bool',
        },
        A2_LJ: 'C2',
        A3_LJ: 'C4',
        A4_LJ: 'C10',
        A6_LJ: 'C6',
        A7_LJ: 'C8',
        A5_LJ: 'C15',
        A19_LJ: 'C17',
        A9_LJ: 'C19',
        A13_LJ: 'C21',
        A14_LJ: 'C23',
        A15_LJ: 'C25',
        A17_LJ: 'C27',
    },
    balance: {
        sheet: 'ABV Ergebnis Finanz',
        B1_LJ: 'D3',
        B2_LJ: 'D5',
        B3_LJ: 'D7',
        B4_LJ: 'D9',
        B5_LJ: 'D11',
        B6_LJ: 'D13',
        B7_LJ: 'D15',
        B8_LJ: 'D17',
        B9_LJ: 'D19',
        B10_LJ: 'D21',
        B11_LJ: 'D23',
        B12_LJ: 'D25',
    },
    income: {
        sheet: 'ABV Ergebnis Finanz',
        E1_LJ: 'D31',
        E2_LJ: 'D33',
        E3_LJ: 'D35',
        E99_LJ: 'D37',
        E4_LJ: 'D39',
        E50_LJ: 'D41',
        E5_LJ: 'D43',
        E6_LJ: 'D45',
        E7_LJ: 'D47',
        E8_LJ: 'D49',
        E9_LJ: 'D51',
        E10_LJ: 'D53',
        E11_LJ: 'D55',
        E13_LJ: 'D57',
        E14_LJ: 'D59',
        E15_LJ: 'D61',
        E16_LJ: 'D63',
        E17_LJ: 'D65',
        E18_LJ: 'D67',
        E19_LJ: 'D69',
        E20_LJ: 'D71',
        E21_LJ: 'D73',
        E22_LJ: 'D75',
        E23_LJ: 'D77',
        E24_LJ: 'D79',
        E25_LJ: 'D81',
        E26_LJ: 'D83',
        E27_LJ: 'D85',
        E28_LJ: 'D87',
        E29_LJ: 'D89',
        E30_LJ: 'D92',
    },
};

// And for staff data
const staffRangeMap = {
    sheet: 'Input_Personal',
    merchant: { start: 3, end: 33, months: 'C', quota: 'D' },
    employeeSkilled: { start: 3, end: 33, months: 'G', quota: 'H' },
    cleaner: { start: 3, end: 33, months: 'K', quota: 'L' },
    trainee: { start: 3, end: 33, months: 'O', quota: 'P' },
};

export enum ParserErrorCode {
    Missing,
    DataType,
};
export type ParserWarnings = Array<{ key: string, code: ParserErrorCode }>;
export type WarningsData = { warnings: ParserWarnings };

/**
 * Read a complete workbook to yearly data map
 */
export const readApostarDataFile = (wb: WorkBook) => {
    const yearlyData: YearlyData & WarningsData = {
        general: {},
        income: {},
        balance: {},
        staff: { rows: [] },
        warnings: [],
    };

    // Parse all entries
    Object.entries(dataMap).forEach(([categoryKey, sheetMap]) => {
        // @ts-ignore
        const { sheet, valueMap = 1, ...cellMap } = sheetMap;
        Object.entries(cellMap).forEach(([dataKey, cellKey]) => {
            try {
                // @ts-ignore
                yearlyData[categoryKey][dataKey] = getCellValue(
                    wb.Sheets[sheet][cellKey],
                    valueMap[dataKey] !== 1 ? valueMap[dataKey] : undefined
                );

                // @ts-ignore
                if (null === yearlyData[categoryKey][dataKey] || undefined === yearlyData[categoryKey][dataKey]) {
                    yearlyData.warnings.push({ key: dataKey, code: ParserErrorCode.Missing });
                }
            } catch (e) {
                yearlyData.warnings.push({ key: dataKey, code: ParserErrorCode.DataType });
            }
        });
    });
    const { sheet, ...staffCategories } = staffRangeMap;
    Object.entries(staffCategories).forEach(([staffType, def]) => {
        for (let i = def.start; i <= def.end; i++) {
            const monthValue = getCellValue(wb.Sheets[sheet][`${def.months}${i}`]);
            const quotaValue = getCellValue(wb.Sheets[sheet][`${def.quota}${i}`]);
            // Might not be set, or partially set
            if (typeof monthValue !== 'number' || typeof quotaValue !== 'number') {
                // When partially set we emit a warning
                if ( undefined !== monthValue || undefined !== quotaValue ) {
                    yearlyData.warnings.push({ key: staffType, code: ParserErrorCode.DataType });
                }
                continue;
            }

            if (monthValue && quotaValue) {
                yearlyData.staff.rows.push({
                    type: (staffType as any),
                    transmissionType: 'J',
                    staffId: 0,
                    sum: {
                        months: monthValue,
                        quota: quotaValue,
                    },
                });
            }
        }
    });

    return yearlyData;
}
