import BranchIcon from '../../assets/apostar_icons_white_apotheke_filiale.svg';
import {
    CircularProgress,
} from '@material-ui/core';
import Pharmacy from '../../Lib/Pharmacy';
import PharmacyService from '../../Lib/PharmacyService';
import QuickAction from './QuickAction';
import React from 'react';
import RegisterBranchIcon from '../../assets/apostar_icons_white_apotheke_anlegen.svg';
import useAuth from '../../Hooks/useAuth';
import useLoad from '../../Hooks/useLoad';

export type BranchQuickActionProps = {
    className: string,
    navigateTo: (target: string) => void,
};

const BranchQuickAction = ({navigateTo, ...props }: BranchQuickActionProps) => {
    const { authentication } = useAuth();
    const { isLoading, data } = useLoad<PharmacyService, Array<Pharmacy>, never>(
        'pharmacy', (service: PharmacyService) => service.getBranches(authentication?.pharmacy?.id || 0),
    );

    const title = isLoading
        ? 'Lade...'
        : (data?.length ? 'Filialapotheken anzeigen' : 'Filialapotheke registrieren');
    let icon: any = () => (<CircularProgress color="inherit" style={{ width: 80, height: 80, padding: 20 }} />);
    if (!isLoading) {
        icon = data?.length ? BranchIcon : RegisterBranchIcon;
    }

    return (
        <QuickAction
            {...props}
            title={title}
            icon={icon}
            onClick={() => navigateTo(data?.length ? '/filialen' : '/filiale')}
        />
    );
};

export default BranchQuickAction;
