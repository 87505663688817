import { FieldInputProps, GenericFieldHTMLAttributes } from 'formik';
import React, { useMemo } from 'react';

import FormSelect from '../FormSelect';
import useCachedData from '../../../Hooks/useCachedData';

export type DataSelectProps = FieldInputProps<number> & GenericFieldHTMLAttributes & {
    type: string,
    label: string,
    error?: string,
    exclude?: Array<any>,
};

const nullValue = { begID: null, begBezeichnung: '-' };

const DataSelect = ({
    type,
    value,
    onChange,
    label,
    name,
    disabled,
    error,
    exclude,
}: DataSelectProps) => {
    const data = useCachedData([type]);
    const displayedData = useMemo(() => {
        const givenData = data?.[type] ?? [];
        if (exclude) {
            return [nullValue, ...givenData.filter(({ begID }: any) => (!exclude.includes(begID)))];
        }
        return [nullValue, ...givenData];
    }, [data, type, exclude]);

    return (
        <FormSelect
            label={label}
            options={displayedData}
            valueKey="begID"
            labelKey="begBezeichnung"
            defaultValue={value}
            onChange={(value) => onChange({
                target: { value, name },
            })}
            disabled={disabled}
            error={error}
        />
    );
};

export default DataSelect;
