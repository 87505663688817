import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        'staff.aspirant',
        { type: 'bounds', ...params },
        'Es dürfen maximal 20/10tel Aspiranten gemeldet werden.',
        undefined,
        'Personal_03',
    )
);

export default rule;
