import UserSchema, { UserApiEntity, UserArray } from '../Schemas/Api/User';

import Ajv from "ajv/dist/jtd"
import SchemaMapper from './SchemaMapper';
import User from './User';

/**
 * Compile a typed schema via AJV
 */
const ajv = new Ajv()
export const parseJSON = ajv.compileParser(UserSchema);
export const parseArrayJSON = ajv.compileParser(UserArray);

export default class UserMapper
    extends SchemaMapper<User, UserApiEntity> {
    schema = UserSchema;

    /**
     * Map json data to a user model
     */
    mapToModel = (data: UserApiEntity) => {
        return new User(
            data.benTitel,
            data.benVName,
            data.benNName,
            data.benUsername,
            data.benEmail,
            data.benFunktionID,
            data.benRolleID,
            data.benAktiv === 'J',
            data.benHauptbenutzer === 'J',
            data.benApoID,
            data.benID || null,
        );
    }

    /**
     * Map user model to json data
     */
    mapToData = (model: User) => {
        const activeJN: 'J' | 'N' = model.active ? 'J' : 'N';
        return {
            benTitel: model.title || '',
            benVName: model.firstName || '',
            benNName: model.lastName || '',
            benUsername: model.username,
            benEmail: model.email || '',
            benRolleID: model.role,
            benFunktionID: model.involvement,
            benAktiv: activeJN,
            benHauptbenutzer: model.mainUser ? 'J' : 'N',
            benID: model.id || 0,
            benPW: model.password || '',
            benApoID: model.pharmacyId,
        };
    };
}
