import {
    Button,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

import { ArrowRight } from '../Ui/Icons';
import React from 'react';

type FormSucccessProps = {
    title: string,
    text: string,
    onAction?: () => void,
    actionLabel?: string,
    container?: boolean,
};

const FormSucccess: React.FC<FormSucccessProps> = ({
    title,
    text,
    onAction,
    actionLabel,
    container = true,
}) => {
    const content = (
        <>
            <Typography variant="h3" component="div" color="textPrimary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                {text}
            </Typography>
            {onAction && (
                <Button color="primary" onClick={onAction}>
                    <ArrowRight /> {actionLabel}
                </Button>
            )}
        </>
    );

    if (!container) {
        return content;
    }

    return (
        <Card>
            <CardContent>
                {content}
            </CardContent>
        </Card>
    );
}

export default FormSucccess;
