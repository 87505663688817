import {
    Divider,
    Link,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { CheckCircle } from '@material-ui/icons';
import DefaultDialog from '../Common/Dialogs/DefaultDialog';
import HelperField from '../Common/Form/HelperField';
import LoadingButton from '../Common/LoadingButton';
import PharmacyData from '../../Lib/PharmacyData';
import PharmacyService from '../../Lib/PharmacyService';
import RequestRegistrationCodeForm from './RequestRegistrationCodeForm';
import { TextField } from 'formik-material-ui';
import { serverErrorMessage } from '../../Lib/Helper';
import { useFormikContext } from 'formik';
import useService from '../../Hooks/useService';

export type RegistrationCodeFormProps = {
    onCanFinishChanged: (canFinish: boolean) => void,
};

const RegistrationCodeForm = ({ onCanFinishChanged }: RegistrationCodeFormProps) => {
    const service = useService<PharmacyService>('pharmacy');
    const { validateForm, setValues, values, setErrors } = useFormikContext();

    const [pharmacy, setPharmacy] = useState<PharmacyData>();
    const [isLoadingPharmacy, setIsLoadingPharmacy] = useState(false);
    const [requestingCode, setRequestingCode] = useState(false);

    // When the pharmacy changes we can move on
    useEffect(() => onCanFinishChanged(!!pharmacy), [pharmacy, onCanFinishChanged]);

    // Load the pharmacy
    const loadPharmacy = () => {
        setIsLoadingPharmacy(true);
        validateForm()
            .then(() => service.checkRegistrationCode((values as any)?.pharmacyNumber, (values as any)?.registrationCode))
            .then((pharmacy) => {
                if (!pharmacy) {
                    throw new Error('Cannot map to pharmacy');
                }
                console.log("obtained", pharmacy);
                setPharmacy(pharmacy);
                setValues({
                    ...(values as any),
                    ...pharmacy,
                });
                return;
            })
            .catch((e) => {
                const defaultMsg = 'Es wurde keine passende Kombination aus Apothekennummer und Registrierungscode gefunden';
                setErrors({
                    registrationCode: serverErrorMessage(e, defaultMsg)
                });
            })
            .finally(() => setIsLoadingPharmacy(false));
    };

    if (pharmacy) {
        return (
            <>
                <Typography variant="h5" component="h5" color="textPrimary" gutterBottom>
                    Basisdaten
                </Typography>
                <CheckCircle style={{ fontSize: 120, margin: 40 }} color="primary" />

                <Typography variant="body2" component="p">
                    Forfahren mit:
                </Typography>
                <Typography variant="h6" color="primary">
                    {pharmacy.title}
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    {pharmacy.state}
                </Typography>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <Typography variant="body2" component="p" gutterBottom>
                    Sie werden die Registrierung für diese Apotheke durchführen.
                </Typography>

            </>
        );
    }

    return (
        <>
            <Typography variant="h5" component="h5" color="textPrimary" gutterBottom>
                Basisdaten
            </Typography>
            <HelperField
                help="Ihre Apotheke hat eine einzigartige Nummer."
                type="text"
                name="pharmacyNumber"
                label="Apothekennummer"
                component={TextField}
                margin="normal"
                fullWidth
            />
            <HelperField
                help="Sie haben per Post einen Code erhalten. Wenn nicht folgen Sie dem Link unterhalb des Feldes"
                name="registrationCode"
                label="Registrierungscode"
                component={TextField}
                margin="normal"
                fullWidth
            />

            <LoadingButton
                variant="contained"
                loading={isLoadingPharmacy}
                onClick={loadPharmacy}
                color="secondary"
            >
                überprüfen
            </LoadingButton>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <Typography variant="body2" component="p" gutterBottom>
                Sie benötigen einen Registrierungscode um fortfahren zu können.
            </Typography>

            <Typography variant="body2" component="p" gutterBottom>
                Sie haben noch keinen Code erhalten? <Link
                    component="a"
                    onClick={() => setRequestingCode(true)}
                >
                    Registrierungcode jetzt anfordern
                </Link>
            </Typography>

            <DefaultDialog
                open={requestingCode}
                onClose={() => setRequestingCode(false)}
                title="Registrierungs-Code anfordern"
            >
                <RequestRegistrationCodeForm
                    pharmacyNumber={(values as any)?.pharmacyNumber}
                    onFinished={() => setRequestingCode(false)}
                />
            </DefaultDialog>
        </>
    );
};

export default RegistrationCodeForm;
