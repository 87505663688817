import { Field, useFormikContext } from 'formik';
import { FormGroup, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

import DataSelect from '../Common/Form/DataSelect';
import EditDialog from '../Common/Dialogs/EditDialog';
import PharmacyService from '../../Lib/PharmacyService';
import { TextField } from 'formik-material-ui';
import User from '../../Lib/User';
import UserService from '../../Lib/UserService';
import { getDefaults } from '../../Lib/Roles';
import { serverErrorMessage } from '../../Lib/Helper';
import useAuth from '../../Hooks/useAuth';
import useDialog from '../../Hooks/useDialog';
import useNotifications from '../../Hooks/useNotifications';
import useService from '../../Hooks/useService';

export type UserDataFormProps = {
    model?: User,
    registerOther?: boolean,
    nextAccountantNumber?: number | false,
};

const { accountantInvolvement } = getDefaults();
const makeStbUsername = (number: number, pharmacyNumber: string = 'A000000') =>
    (`${pharmacyNumber}_${number.toString().padStart(2, '0')}_stb`);

const UserDataForm = ({
    model,
    registerOther = false,
    nextAccountantNumber = false,
}: UserDataFormProps) => {
    const { open, close, isOpen } = useDialog();
    const { authentication } = useAuth();
    const service = useService<UserService>('user');
    const pharmacyService = useService<PharmacyService>('pharmacy');
    const { addError, addSuccess } = useNotifications();
    const { values, setFieldValue } = useFormikContext();
    const [nonFixedUsername, setNonFixedUsername] = useState<String | null>(null);

    const pharmacyDataPromise = useMemo(() => (
        authentication?.pharmacyId
            ? pharmacyService.get(authentication!.pharmacyId) : Promise.reject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [authentication?.pharmacyId]);

    const savePassword = () => {
        service.lostPasswordRequest(authentication!.email!, authentication!.pharmacy.number)
            .then(() => addSuccess('Passwort wurde angefordert'))
            .catch((e) => addError(serverErrorMessage(e, 'Passwort konnte nicht angefordert werden')))
            .finally(() => close());
    };

    // Set a fixed username when accountant is selected, reset username to previous value when
    // unselected
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // @ts-ignore
        if (values.involvement === accountantInvolvement) {
            // @ts-ignore
            setNonFixedUsername(values.username);
            // @ts-ignore
            if (!model?.id) {
                const num = nextAccountantNumber || 1;
                setFieldValue('username', makeStbUsername(num));
                pharmacyDataPromise.then(({ number }) => setFieldValue('username', makeStbUsername(num, `A${number}`)));
            } else {
                console.log(values);
            }
        } else if (nonFixedUsername) {
            setFieldValue('username', nonFixedUsername);
            setNonFixedUsername(null);
        }

        // @ts-ignore
    }, [values.involvement]);

    // Details are conditional if a new user is created otherwise it is just a display
    const userDetails = model ? (
        <FormGroup>
            <Grid container>
                <Grid item sm={7}>
                    <Typography variant="body1" component="div" color="primary" gutterBottom>
                        {model.username}
                    </Typography>
                </Grid>
                <Grid item sm={5}>
                    <Link component="button" onClick={(e) => {
                        e.preventDefault();
                        open();
                    }}>Passwort ändern</Link>
                </Grid>
            </Grid>
            <Field
                type="email"
                name="email"
                label="Email-Adresse"
                component={TextField}
                margin="normal"
                fullWidth
            />
            <EditDialog
                title="Neues Passwort festlegen"
                open={isOpen}
                onClose={close}
                onSave={savePassword}
                saveText="Passwort Code anfordern"
            >
                <Typography component="p" variant="body1" color="textSecondary" gutterBottom>
                    Sie können einen Code anfordern, um ein neues Passwort zu setzen. Dieser wird an
                    Ihre Email-Adresse mit weiteren Anweisungen gesendet.
                </Typography>
            </EditDialog>
        </FormGroup>
    ) : (
        <FormGroup>
            <Field
                type="text"
                name="username"
                label="Benutzer"
                component={TextField}
                margin="normal"
                fullWidth
                disabled={nonFixedUsername !== null}
                InputLabelProps={nonFixedUsername !== null ? { shrink: true } : {}}
            />
            {registerOther ? null : (
                <>
                    <Field
                        type="password"
                        name="password"
                        label="Passwort"
                        component={TextField}
                        margin="normal"
                        fullWidth
                    />
                    <Field
                        type="password"
                        name="password_confirm"
                        label="Passwort bestätigen"
                        component={TextField}
                        margin="normal"
                        fullWidth
                    />
                </>
            )}
            <Field
                type="text"
                name="email"
                label="Email Adresse"
                component={TextField}
                margin="normal"
                fullWidth
            />
            <Field
                type="text"
                name="email_confirm"
                label="Email Adresse bestätigen"
                component={TextField}
                margin="normal"
                fullWidth
            />
        </FormGroup>
    );

    return (
        <>
            <Typography variant="h5" component="h5" color="textPrimary" gutterBottom>
                Benutzerdaten
            </Typography>
            {userDetails}
            <Typography variant="h6" component="h5" color="textPrimary">
                Personendaten
            </Typography>
            <Field
                type="text"
                name="title"
                label="Titel"
                component={TextField}
                margin="normal"
                fullWidth
            />
            <Field
                type="text"
                name="firstName"
                label="Vorname"
                component={TextField}
                margin="normal"
                fullWidth
            />
            <Field
                type="text"
                name="lastName"
                label="Nachname"
                component={TextField}
                margin="normal"
                fullWidth
            />
            <DataSelect
                name="involvement"
                label="Funktion"
                type="involvement"
                // @ts-ignore
                value={values.involvement}
                onBlur={() => {}}
                exclude={!nextAccountantNumber && model?.involvement !== accountantInvolvement ? [accountantInvolvement] : []}
                disabled={model?.involvement === accountantInvolvement}
                // @ts-ignore
                onChange={({ target: { value } }) => setFieldValue('involvement', value?.begID)}
            />
        </>
    );
};

export default UserDataForm;
