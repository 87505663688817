import {
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Table,
    TableBody,
    TableBodyProps,
    TableCell,
    TableHead,
    TableProps,
    TableRow,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import {
    HeaderGroup,
    Row,
} from 'react-table';

import React from 'react';

type ResponsiveTableProps<DataType extends object> = {
    tableProps: TableProps,
    tableBodyProps: TableBodyProps,
    headerGroups: Array<HeaderGroup<DataType>>
    prepareRow: (row: Row<DataType>) => void,
    rows: Array<Row<DataType>>,
    mobileView?: [number[], number[]],
    breakpoint?: 'xs' | 'sm' | 'md',
    menu?: (row: Row<DataType>) => React.ReactNode,
    menuHeader?: React.ReactElement | string,
    emptyView?: React.ReactElement,
}

/**
 * Responsive table component. Uses data from react-table to display either a table or a responsive
 * list.
 */
const ResponsiveTable = <T extends object>({
    tableProps,
    tableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    mobileView = [[0], [1]],
    breakpoint = 'sm',
    menu,
    menuHeader,
    emptyView,
}: ResponsiveTableProps<T>) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint));

    // Mobile view if breakpoint applies
    if (isMobile) {
        const renderIndexes = (row: Row<T>, indexes: Array<number>) => (
            indexes.map((i) => (
                <React.Fragment key={`row_render_${i}`}>
                    {row.cells[i].render('Cell')}
                    <span>&nbsp;</span>
                </React.Fragment>
            ))
        );

        return (
            // @ts-ignore
            <List {...tableProps} {...tableBodyProps}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <>
                            <ListItem {...row.getRowProps()}>
                                <ListItemText
                                    primaryTypographyProps={{ noWrap: true }}
                                    primary={renderIndexes(row, mobileView[0])}
                                    secondary={renderIndexes(row, mobileView[1])}
                                />
                                {menu && (
                                    <ListItemSecondaryAction>
                                        {menu(row)}
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                            <Divider />
                        </>
                    );
                })}
            </List>
        );
    }

    return (
        // @ts-ignore
        <Table {...tableBodyProps}>
            <TableHead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    <TableCell {...column.getHeaderProps({
                                        // @ts-ignore
                                        style: { textAlign: column.align || 'left' }
                                    })}>
                                        {// Render the header
                                            column.render('Header')}
                                    </TableCell>
                                ))}
                            {menu !== undefined && (
                                <TableCell>{menuHeader}</TableCell>
                            )}
                        </TableRow>
                    ))}
            </TableHead>
            {/* Apply the table body props */}
            <TableBody {...tableBodyProps}>
                {// Loop over the table rows
                    rows.map((row: any) => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                        // Apply the row props
                            <TableRow {...row.getRowProps()}>
                                {// Loop over the rows cells
                                    row.cells.map((cell: any) => {
                                        // Apply the cell props
                                        return (
                                            <TableCell {...cell.getCellProps({
                                                style: { textAlign: cell.column.align || 'left' }
                                            })}>
                                                {// Render the cell contents
                                                    cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                {menu !== undefined && (
                                    <TableCell style={{ width: 50 }} align="right">
                                        {menu(row)}
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    })}
                {emptyView}
            </TableBody>
        </Table>
    );
};

export default ResponsiveTable;
