import { Form, Formik, FormikProps } from 'formik';
import validateExisting, { validateWithUsernameCheck } from '../../Lib/FormValidations/User';

import FormikTouchErrors from '../Common/Form/FormikTouchErrors';
import LoadingButton from '../Common/LoadingButton';
import React from 'react';
import User from '../../Lib/User';
import UserDataForm from './UserDataForm';
import UserService from '../../Lib/UserService';
import { getDefaults } from '../../Lib/Roles';
import useAuth from '../../Hooks/useAuth';
import useService from '../../Hooks/useService';

const { accountantRole, accountantInvolvement, userRole } = getDefaults();

export type UserFormProps = {
    model?: User,
    buttonText?: string,
    onSubmit?: (data: User) => Promise<any> | void,
    formikRef?: React.Ref<FormikProps<Partial<User>>>,
    nextAccountantNumber?: number | false,
};

const toUserShape = (data: any, model?: User, pharmacyId?: number): User => ({
    username: data.username,
    email: data.email,
    title: data.title,
    firstName: data.firstName,
    lastName: data.lastName,
    role: model?.role ?? (data?.involvement === accountantInvolvement ? accountantRole : userRole),
    involvement: data?.involvement ?? 1,
    active: model?.active ?? true,
    mainUser: model?.mainUser ?? false,
    id: model?.id ?? null,
    pharmacyId: model?.pharmacyId ?? (pharmacyId ?? 0),
    password: data.password && data.password_confirm ? data.password : '',
});

const UserForm = ({
    model,
    buttonText,
    onSubmit,
    formikRef,
    nextAccountantNumber = false,
}: UserFormProps) => {
    const { authentication } = useAuth();
    const userService = useService<UserService>('user');

    // Validation with username
    const validate = model?.id
        ? validateExisting
        : validateWithUsernameCheck(userService.getUsernameAvailable);

    return (
        <Formik
            initialValues={model ? {
                username: model.username,
                email: model.email,
                title: model.title,
                firstName: model.firstName,
                lastName: model.lastName,
                involvement: model.involvement,
            } : {}}
            innerRef={formikRef}
            validateOnChange={false}
            // @ts-ingore
            validate={values => validate(values, !model, false)}
            onSubmit={(values, { setSubmitting }) => {
                if (onSubmit) {
                    const potentialPromise = onSubmit(toUserShape(values, model, authentication?.pharmacyId));
                    (potentialPromise || Promise.resolve()).finally(() => setSubmitting(false));
                    return;
                }
                setSubmitting(false);
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <FormikTouchErrors />
                    <UserDataForm
                        model={model}
                        registerOther={true}
                        nextAccountantNumber={nextAccountantNumber}
                    />
                    {buttonText && (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={isSubmitting}
                            onClick={submitForm}
                            fullWidth
                        >
                            speichern
                        </LoadingButton>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default UserForm;
