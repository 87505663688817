import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

import { formatNumber } from '../Helper'

/**
 * Checks for equality in balance sheet on assets and liabilities
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.assets_LJ', '/', 'intermediates.liabilities_LJ'],
        { type: 'bounds', ...params },
        (result, match, evaluator) => {
            const value = evaluator.result(['intermediates.assets_LJ', '-', 'intermediates.liabilities_LJ']);
            return (
                `Aktiva und Passiva Ihrer Bilanz stimmen nicht überein. ${
                    (value || 0) > 0
                        ? 'Die Aktiva übersteigen die Passiva um'
                        : 'Die Passiva übersteigen die Aktiva um'
                } ${formatNumber(Math.abs(value || 0))} EUR. Bitte prüfen Sie die Eingaben bei den Bilanzahlen.`
            );
        },
        undefined,
        'Bilanz_02',
    )
);

export default rule;
