export default class User {
    id: Nullable<number> = null;

    title: Nullable<string> = null;

    firstName: Nullable<string> = '';

    lastName: Nullable<string> = '';

    username: string = '';

    password?: Nullable<string> = null;

    email: Nullable<string> = null;

    involvement: number = 0;

    role: number = 0;

    active: boolean = false;

    mainUser: boolean = false;

    pharmacyId: number = 0;

    constructor(
        title: Nullable<string> = null,
        firstName: Nullable<string> = null,
        lastName: Nullable<string> = null,
        username: string = '',
        email: Nullable<string> = null,
        involvement: number = 1,
        role: number = 1,
        active: boolean = true,
        mainUser: boolean = false,
        pharmacyId: number = 0,
        id: Nullable<number> = null,
    ) {
        this.title = title;
        this.firstName = firstName;
        this.lastName = lastName;
        this.username = username;
        this.email = email;
        this.involvement = involvement;
        this.role = role;
        this.active = active;
        this.mainUser = mainUser;
        this.pharmacyId = pharmacyId;
        this.id = id;
    }
}
