import ComparisonGroupSchema, { ComparisonGroupApiEntity } from '../Schemas/Api/ComparisonGroup';

import { GroupTypeEntity } from './ComparisonGroup';
import SchemaMapper from './SchemaMapper';

export default class ComparisonGroupSchemaMapper
    extends SchemaMapper<GroupTypeEntity, ComparisonGroupApiEntity> {

    schema = ComparisonGroupSchema;

    mapToData(model: GroupTypeEntity) {
        return {
            begID: model.begID,
            begBezeichnung: model.begBezeichnung,
        };
    }

    mapToModel(data: ComparisonGroupApiEntity) {
        return {
            begID: data.begID,
            begBezeichnung: data.begBezeichnung,
        };
    }
}
