import ComparisonGroupSchemaMapper from './ComparisonGroupSchemaMapper';
import JSONFetch from './JSONFetch';

export default class CachingService {
    /* Entity name also used as endpoint */
    entityName: string = '';
    mapper = new ComparisonGroupSchemaMapper();

    constructor(entityName = '') {
        if (entityName) {
            this.entityName = entityName;
        }

        if (this.entityName.length < 1) {
            throw Error('Service needs entity');
        }
    }

    getAll() {
        return JSONFetch({
            url: this.entityName,
            bodySerializer: () => '',
            responseParser: this.mapper.parseArray,
        });
    }
}
