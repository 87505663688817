import {
    Card,
    CardContent,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import { mainColorMonthly, mainColorYearly } from '../Common/Ui/useTypeStyles';

import NarrowBox from '../Common/Ui/NarrowBox';
import React from 'react';
import { Theme } from '@material-ui/core';
import VideoEmbed from '../VideoEmbed';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    overline: {
        marginBottom: theme.spacing(2),
        textTransform: 'uppercase',
    },
    intro: {
        marginBottom: theme.spacing(4),
    },
    cardContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: 580,
    },
    monthly: {
        '& .MuiTypography-colorPrimary': {
            color: mainColorMonthly,
        },
    },
    yearly: {
        '& .MuiTypography-colorPrimary': {
            color: mainColorYearly,
        },
    },
}));

const About = () => {
    const classes = useStyles();

    return (
        <>
            <NarrowBox textAlign="center" className={classes.intro}>
                <Typography variant="h1">ÜBER APOSTAR</Typography>
                <Typography variant="body1">
                    Falls Sie allgemeine Fragen zu ApoStar oder der Registrierung haben, können Sie uns gerne kontaktieren:
                    <br />
                    <Link href="mailto://apostar@apothekerverband.at">apostar@apothekerverband.at</Link>
                    <br />
                    <Link href="tel://0043140414300">+43 1 40414 300</Link>
                </Typography>

                <VideoEmbed src="https://player.vimeo.com/video/986511530?h=aad6e2f592&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  />
            </NarrowBox>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <Typography color="primary" variant="subtitle2" className={classes.overline}>
                                Was ist das?
                            </Typography>
                            <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                                ApoStar ist die Serviceplattform, auf der Apotheken betriebswirtschaftlich analysiert werden können.
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                Hier können Sie sowohl den  ApoStar Tara Report, welcher monatlich Berichte generiert als auch den ApoStar Bilanz Vergleich, welcher Berichte auf Jahresbasis bereitstellt, durchführen. Informationen zu den jeweiligen Teilbereichen können Sie dieser Seite entnehmen.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card>
                        <CardContent className={[classes.cardContent, classes.monthly].join(' ')}>
                            <Typography color="primary" variant="subtitle2" className={classes.overline}>
                                Was ist der monatliche Apostar Tara Report?
                            </Typography>
                            <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                                Der monatliche ApoStar Tara Report ist eine eigenständiger Service-Teilbereich von ApoStar.
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                In diesem werden monatlich vollautomatisch die Verkaufsdaten der teilnehmenden Apotheker geladen, verarbeitet und entsprechende Berichte generiert. Diese Auswertungen betreffen Absatz-, Umsatz- und Deckungsbeitragsentwicklung und werden allen Teilnehmern monatlich zugesendet. Die Daten Ihrer Apotheke sind dabei geschützt – ein Rückschluss auf Ihren Betrieb ist dank eines Pseudonymisierungsverfahrens nicht möglich. Wie beim jährlichen ApoStar Bilanz Vergleich, können Sie auch beim monatlichen ApoStar Tara Report eine eigene Vergleichsgruppe für Ihre Berichte festlegen.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card>
                        <CardContent className={[classes.cardContent, classes.monthly].join(' ')}>
                            <Typography color="primary" variant="subtitle2" className={classes.overline}>
                                Was wird beim monatlichen Apostar Tara Report ausgewertet?
                            </Typography>
                            <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                                Der monatliche ApoStar Tara Report wertet betriebswirtschaftliche Kennzahlen aus vier Bereichen aus und setzt sie in Bezug zu vergleichbaren Apotheken:
                            </Typography>
                            <Typography variant="body2" component="div" gutterBottom>
                                — Kundenfrequenz<br />— Verkaufte Packungen<br />— Umsatz<br />— Vergütung als Differenz Nettoverkaufspreis zu AEP
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                Sie erhalten einen Überblicksbericht mit Analysen über alle Geschäftsbereiche und Sie können zusätzliche Detailauswertungen anstellen – gesondert zum Krankenkassen- und Privatbereich.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card>
                        <CardContent className={[classes.cardContent, classes.yearly].join(' ')}>
                            <Typography color="primary" variant="subtitle2" className={classes.overline}>
                                Was ist der jährliche ApoStar Bilanz Vergleich?
                            </Typography>
                            <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                                Der jährliche ApoStar Bilanz Vergleich ist eine eigenständiger Service-Teilbereich von ApoStar und
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                eine elektronische Jahresabschlussanalyse der wichtigsten betriebswirtschaftlichen Kennzahlen Ihrer Apotheke. Er gibt Auskunft über die wirtschaftliche Situation Ihres Betriebes im Vergleich zu anderen Apotheken . Aufbauend auf Ihrer individuellen Auswertung, erhalten Sie Hinweise auf Optimierungspotenziale oder eine Bestätigung des eingeschlagenen Weges.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card>
                        <CardContent className={[classes.cardContent, classes.yearly].join(' ')}>
                            <Typography color="primary" variant="subtitle2" className={classes.overline}>
                                Was wird beim jährlichen ApoStar Bilanz Vergleich ausgewertet?
                            </Typography>
                            <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                                Der jährliche ApoStar Bilanz Vergleich analysiert und erklärt insbesondere folgende Bereiche und setzt sie in Bezug zu vergleichbaren Apotheken:
                            </Typography>
                            <Typography variant="body2" component="div" gutterBottom>
                                — Die Entwicklung meiner Apotheke anhand von 4 Kennzahlen<br />— Gewinn- und Verlustrechnung<br />— Bilanzanalyse<br />— Liquiditätscheck<br />— Checkliste mit Verbesserungsmaßnahmen
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                Sie erhalten zahlreiche Aufstellungen und Überblicksberichte die Ihnen eine Auskunft über die wirtschaftliche Situation Ihres Betriebs im Vergleich zu anderen Apotheken gibt.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <Typography color="primary" variant="subtitle2" className={classes.overline}>
                                Sicherheit
                            </Typography>
                            <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                                Sind meine Daten bei ApoStar sicher?
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                — Die IT-Sicherheitsnormen wurden von einem IT-Ziviltechniker festgesetzt und entsprechen modernsten Standards.
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                 — Ihre Daten werden auf einem eigenen Server in einem Rechenzentrum in Düsseldorf (zertifiziert nach ISO-27001) gespeichert.
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                — Ein Pseudonymisierungsverfahren stellt sicher, dass weder der Apothekerverband noch Dritte Rückschlüsse auf Ihre Apotheke anstellen können.
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                — Betriebswirtschaftliche Vergleichswerte werden nur angezeigt, wenn sich mindestens zehn Apotheken in einer Vergleichsgruppe befinden.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default About;
