import { useCallback, useState } from 'react';

import useDialog from './useDialog';

// Properties for the edit dialog
export type UseEditDialogProps<T extends Object> = {
    title: string,
    editTitle?: string,
    openInitially?: boolean,
    onSave?: (model: Partial<T>) => void,
    closeOnSave?: boolean,
};

/**
 * Manage the state of an edit dialog. This class is based on the concept of headless ui, managing
 * the state for editing independently from a ui implementation.
 */
const useEditDialog = <T extends Object>({
    title,
    editTitle,
    openInitially = false,
    onSave = () => {},
    closeOnSave = true,
}: UseEditDialogProps<T>) => {
    // The state used within edit dialog
    const [model, setModel] = useState<T | null>(null);
    const [formData, setFormData] = useState<Partial<T>>({});
    const { isOpen, open, close } = useDialog(openInitially);

    // Opening the dialog with a potential model to edit
    const showDialog = (model: any = undefined) => {
        setModel(model);
        setFormData(model);
        open();
    };

    // Custom on save for dialog
    const onSaveCallback = useCallback(() => {
        if (closeOnSave) {
            close();
        }
        if (model) {
            // Copy to model
            Object.assign(model, formData);
            onSave(model);
            return;
        }
        onSave(formData || {});
    }, [closeOnSave, close, onSave, formData, model]);

    // Helper to access all properties needed by a dialog implementation
    const getEditDialogProps = useCallback(() => ({
        title: (model ? editTitle : title) || title,
        onClose: close,
        onSave: onSaveCallback,
        open: isOpen,
    }), [model, title, editTitle, close, isOpen, onSaveCallback]);

    const changeHandler = useCallback((changedValues: any) => {
        setFormData({
            ...formData,
            ...changedValues,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFormData]);

    // Named return
    return {
        getEditDialogProps,
        changeHandler,
        model,
        isOpen,
        open: showDialog,
        close,
    };
};

export default useEditDialog;
