import StaffDataSchema, {
    StaffDataApiEntity,
} from '../Schemas/Api/StaffData';
import {
    StaffRow,
    StaffType,
    Tuple,
} from './YearlyData';
import StaffTypeMapping, {
    CATEGORY_MARK,
    SKILLED_MARKS,
} from './Mappings/StaffTypes';

import SchemaMapper from './SchemaMapper';

// Mapping of type ids from api to internal ones
const TYPE_IDS: Record<string, StaffType> = {
    A52: 'concessionaire',
    A53: 'familyPharmacist',
    A54: 'director',
    A55: 'pharmacist',
    A56: 'aspirant',
    A57: 'merchant',
    A58: 'trainee',
    A60: 'employeeSkilled',
    A61: 'cleaner',
    A63: 'ownerPharmacist',
    A64: 'ownerNonPharmacist',
    A65: 'familyNonPharmacist',
}

/**
 * Helper method to get an array an exact tuple size no matter the input
 */
const arraySize = <T, U extends number>(input: Array<T>, length: U, fill: T): Tuple<T, U> =>
    input.concat((new Array(length)).fill(fill)).slice(0, length) as Tuple<T, U>;

/**
 * Object from string
 */
const getStaffDataFromString = (data: StaffDataApiEntity): Omit<StaffRow, 'type' | 'transmissionType' | 'staffId'> => {
    // YearlyData
    if (data.persSatzart === 'J') {
        return {
            sum: {
                quota: data.persJahreswert,
                months: data.persAnzMon,
            },
        };
    }

    const monthly = ([
        'persMon1',
        'persMon2',
        'persMon3',
        'persMon4',
        'persMon5',
        'persMon6',
        'persMon7',
        'persMon8',
        'persMon9',
        'persMon10',
        'persMon11',
        'persMon12',
    ] as const).map((key) => (data[key] || 0)) as Tuple<number, 12>;
    const months = monthly.filter((quota) => quota > 0).length;
    const quota = monthly.reduce((partialSum, a) => partialSum + a, 0) / months;

    return {
        sum: {
            months,
            quota,
        },
        monthly,
    };
}

export default class StaffDataMapper
    extends SchemaMapper<StaffRow, StaffDataApiEntity> {
    schema = StaffDataSchema;
    year: number;
    pharmacyId: number;

    constructor(pharmacyId: number, year: number) {
        super();
        this.year = year;
        this.pharmacyId = pharmacyId;
    }

    /**
     * Map json data to a user model
     */
    mapToModel = (data: StaffDataApiEntity) => {
        return {
            type: TYPE_IDS[data.persKurzBez],
            transmissionType: data.persSatzart,
            staffId: data.persLfdnr,
            ...getStaffDataFromString(data),
        };
    }

    /**
     * Map user model to json data
     */
    mapToData = (model: StaffRow) => {
        const monthData = Object.fromEntries(
            arraySize([], 12, 0).map((_, i) => [
                `persMon${i + 1}`, model.monthly?.[i] || 0,
            ])
        ) as Omit<StaffDataApiEntity, 'persApoID' | 'persWj' | 'persKurzBez' | 'persLfdnr' | 'persDGDN' | 'persFPers' | 'persSatzart' | 'persJahreswert' | 'persAnzMon'>;
        return {
            persApoID: this.pharmacyId,
            persWj: this.year,
            persLfdnr: model.staffId,
            persKurzBez: StaffTypeMapping[model.type],
            persDGDN: CATEGORY_MARK[model.type],
            persFPers: SKILLED_MARKS[model.type],
            persSatzart: model.transmissionType,
            persJahreswert: model.sum.quota,
            persAnzMon: model.sum.months,
            ...monthData,
        }
    };
}
