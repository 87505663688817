import React, { useEffect, useState } from 'react';

import { Add } from '@material-ui/icons';
import ComparisonGroup from '../../Lib/ComparisonGroup';
import ComparisonGroupService from '../../Lib/ComparisonGroupService';
import EditDialog from '../Common/Dialogs/EditDialog';
import { Fab } from '@material-ui/core';
import Form from './Form';
import LoadingIndicator from '../Common/Ui/LoadingIndicator';
import Table from './Table';
import { updateData } from '../../Lib/Helper';
import useEditDialog from '../../Hooks/useEditDialog';
import useSaveWithNotification from '../../Hooks/useSaveWithNotification';
import useService from '../../Hooks/useService';

const ComparisonGroups : React.FC<{}> = () => {
    // State used
    const [dataState, setDataState] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const Service = useService<ComparisonGroupService>('comparisonGroup');

    // Custom save handlers with notifications
    const save = useSaveWithNotification((data: ComparisonGroup) => (
        Service.save(data).then((received) => setDataState(updateData(received, dataState)))
    ));
    const setActive = useSaveWithNotification((model: ComparisonGroup, active: boolean) => {
        model.active = active;
        return Service.setActive(model, active).then(() => setDataState(updateData(model, dataState)));
    });

    // Load data here
    useEffect(() => {
        setIsLoading(true);
        Service.getAll()
            .then((fetchedData: Array<ComparisonGroup>) => setDataState(fetchedData))
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Manage dialog state
    const {
        open: showDialog,
        model: editModel,
        getEditDialogProps,
        changeHandler,
    } = useEditDialog({
        title: 'Neue Vergleichsgruppe',
        editTitle: 'Vergleichsgruppe bearbeiten',
        // This is not typed yet correctly because of complexities in wrapping functions. However
        // this is most likely to change significantly so typing will be done when rework will be
        // progressed
        // @ts-ignore
        onSave: save,
    });

    // Actions to hook up on item menu
    const onEditRow = (model: ComparisonGroup) => showDialog(model);
    const onQuickEditRow = (model: ComparisonGroup, active: boolean) => {
        setActive(model, active);
    };

    return (
        <>
            <Table
                onEditRow={onEditRow}
                onQuickEditRow={onQuickEditRow}
                data={dataState}
            />
            <EditDialog {...getEditDialogProps()}>
                <Form model={editModel} onValuesChange={changeHandler} />
            </EditDialog>
            <Fab
                color="secondary"
                aria-label="add"
                style={{ position: 'fixed', bottom: 20, right: 20 }}
                onClick={() => showDialog()}
            >
                <Add style={{ fontSize: 40 }} />
            </Fab>
            <LoadingIndicator isLoading={isLoading} />
        </>
    );
};

export default ComparisonGroups;
