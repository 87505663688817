import ValidationResultSchema, {
    ValidationResultApiEntity,
} from '../Schemas/Api/ValidationResult';

import { AjvJTD as Ajv } from './Ajv';

type ValidationResult = Array<{ rule: string, result: [number | undefined, boolean, boolean] }>;

export default class ValidationResultMapper {
    // Serializer for a single entity
    serializer?: (data: Array<ValidationResultApiEntity>) => string;

    pharmacyId: number;

    year: number;

    constructor(pharmacyId: number, year: number) {
        this.pharmacyId = pharmacyId;
        this.year = year;
    }

    /**
     * Get a serializer for an entity
     */
    getSerializer = () => {
        if (!this.serializer) {
            this.serializer = Ajv.compileSerializer({ elements: ValidationResultSchema });
        }
        return this.serializer;
    }

    /**
     * Directly serialize a model to a json string represenation
     */
    serialize = (model: ValidationResult) => {
        const data = model.map(({
            rule,
            result: [result, flaggedCritical, flaggedWarn],
        }) => {
            return {
                checkergKurzBez: rule,
                checkergApoID: this.pharmacyId,
                checkergWj: this.year,
                checkergWert: result === undefined || result === null || isNaN(result) ? null : result,
                checkergStatus: flaggedCritical ? 'K' : (flaggedWarn ? 'W' : (result === undefined ? 'N' : 'O')),
            };
        }).filter(
            ({ checkergKurzBez }) => (checkergKurzBez !== ''),
        ) as Array<ValidationResultApiEntity>;

        return this.getSerializer()(data);
    }
}
