import {
    PharmacyLeaderOccupation,
    PharmacyNightDutiesPerYear,
    YearlyData,
} from './YearlyData';
import YearlyDataSchema, {
    YearlyDataApiEntity,
} from '../Schemas/Api/YearlyData';
import {
    booleanToYNNullable,
    hasNullValue,
    nullable,
    nullifined,
    toApiDate,
} from './Helper';

import SchemaMapper from './SchemaMapper';

// Set keys, needed for typed search when accessing values
const occupationKeys = Object.keys(PharmacyLeaderOccupation);
const nightDutyKeys = Object.keys(PharmacyNightDutiesPerYear);

const validationResultMark = {
    none: 'O',
    warn: 'W',
    critical: 'K',
} as const;

export default class YearlyDataMapper
    extends SchemaMapper<YearlyData, YearlyDataApiEntity> {
    schema = YearlyDataSchema;

    /**
     * Map json data to a user model
     */
    mapToModel = (data: YearlyDataApiEntity) => {
        return {
            general: {
                A2_LJ: data.kpiA2 ? new Date(Date.parse(data.kpiA2)) : undefined,
                A3_LJ: data.kpiA3 ? new Date(Date.parse(data.kpiA3)) : undefined,
                A4_LJ: data.kpiA4 === 'J',
                A5_LJ: occupationKeys.find((key) => (PharmacyLeaderOccupation[key] === data.kpiA5)),
                A9_LJ: nightDutyKeys.find((key) => (PharmacyNightDutiesPerYear[key] === data.kpiA9)),
                A6_LJ: data.kpiA50 === 'J',
                A7_LJ: data.kpiA51 === 'J',
                A19_LJ: data.kpiA19 === 'J',
                A13_LJ: nullifined(data.kpiA13),
                A14_LJ: nullifined(data.kpiA14),
                A15_LJ: nullifined(data.kpiA15),
                A16_LJ: nullifined(data.kpiA16),
                A17_LJ: nullifined(data.kpiA17),
                A18_LJ: nullifined(data.kpiA18),
            },
            income: {
                E1_LJ: nullifined(data.kpiE1),
                E2_LJ: nullifined(data.kpiE2),
                E3_LJ: nullifined(data.kpiE3),
                E4_LJ: nullifined(data.kpiE4),
                E5_LJ: nullifined(data.kpiE5),
                E6_LJ: nullifined(data.kpiE6),
                E7_LJ: nullifined(data.kpiE7),
                E8_LJ: nullifined(data.kpiE8),
                E9_LJ: nullifined(data.kpiE9),
                E10_LJ: nullifined(data.kpiE10),
                E11_LJ: nullifined(data.kpiE11),
                E13_LJ: nullifined(data.kpiE13),
                E14_LJ: nullifined(data.kpiE14),
                E15_LJ: nullifined(data.kpiE15),
                E16_LJ: nullifined(data.kpiE16),
                E17_LJ: nullifined(data.kpiE17),
                E18_LJ: nullifined(data.kpiE18),
                E19_LJ: nullifined(data.kpiE19),
                E20_LJ: nullifined(data.kpiE20),
                E21_LJ: nullifined(data.kpiE21),
                E22_LJ: nullifined(data.kpiE22),
                E23_LJ: nullifined(data.kpiE23),
                E24_LJ: nullifined(data.kpiE51),
                E25_LJ: nullifined(data.kpiE25),
                E26_LJ: nullifined(data.kpiE26),
                E27_LJ: nullifined(data.kpiE27),
                E28_LJ: nullifined(data.kpiE28),
                E29_LJ: nullifined(data.kpiE29),
                E30_LJ: nullifined(data.kpiE52),
                E50_LJ: nullifined(data.kpiE50),
                E99_LJ: nullifined(data.kpiE53),
            },
            balance: {
                B1_LJ: nullifined(data.kpiB1),
                B2_LJ: nullifined(data.kpiB2),
                B3_LJ: nullifined(data.kpiB3),
                B4_LJ: nullifined(data.kpiB4),
                B5_LJ: nullifined(data.kpiB5),
                B6_LJ: nullifined(data.kpiB6),
                B7_LJ: nullifined(data.kpiB7),
                B8_LJ: nullifined(data.kpiB8),
                B9_LJ: nullifined(data.kpiB9),
                B10_LJ: nullifined(data.kpiB10),
                B11_LJ: nullifined(data.kpiB11),
                B12_LJ: nullifined(data.kpiB12),
            },
            staff: { rows: [] },
        };
    }

    /**
     * Map user model to json data
     */
    mapToData = (model: YearlyData) => {
        const data: Omit<YearlyDataApiEntity, 'kpiKzFortschritt'> = {
            kpiApoID: model.pharmacy?.id || 0,
            kpiWj: model.year || 0,
            kpiKzRumpfjahr: 'N' as const,
            kpiKzStatus: validationResultMark[model.validationResult || 'none'],
            kpiA2: toApiDate(model.general.A2_LJ),
            kpiA3: toApiDate(model.general.A3_LJ),
            kpiA50: booleanToYNNullable(model.general.A6_LJ),
            kpiA51: booleanToYNNullable(model.general.A7_LJ),
            kpiA4: booleanToYNNullable(model.general.A4_LJ),
            kpiA5: model.general.A5_LJ ? PharmacyLeaderOccupation[model.general.A5_LJ] : null,
            kpiA19: booleanToYNNullable(model.general.A19_LJ),
            kpiA9: model.general.A9_LJ ? PharmacyNightDutiesPerYear[model.general.A9_LJ] : null,
            kpiA13: nullable(model.general.A13_LJ),
            kpiA14: nullable(model.general.A14_LJ),
            kpiA15: nullable(model.general.A15_LJ),
            kpiA16: nullable(model.general.A16_LJ),
            kpiA17: nullable(model.general.A17_LJ),
            kpiA18: nullable(model.general.A18_LJ),
            kpiA52: model.staff.concessionaire || null,
            kpiA53: model.staff.familyPharmacist || null,
            kpiA54: model.staff.pharmacist || null,
            kpiA55: model.staff.familyPharmacist || null,
            kpiA56: model.staff.aspirant || null,
            kpiA57: model.staff.merchant || null,
            kpiA58: model.staff.trainee || null,
            kpiA60: model.staff.employeeSkilled || null,
            kpiA61: model.staff.cleaner || null,
            kpiA63: model.staff.ownerPharmacist || null,
            kpiA64: model.staff.ownerNonPharmacist || null,
            kpiA65: model.staff.familyNonPharmacist || null,
            kpiB1: nullable(model.balance.B1_LJ),
            kpiB2: nullable(model.balance.B2_LJ),
            kpiB3: nullable(model.balance.B3_LJ),
            kpiB4: nullable(model.balance.B4_LJ),
            kpiB5: nullable(model.balance.B5_LJ),
            kpiB6: nullable(model.balance.B6_LJ),
            kpiB7: nullable(model.balance.B7_LJ),
            kpiB8: nullable(model.balance.B8_LJ),
            kpiB9: nullable(model.balance.B9_LJ),
            kpiB10: nullable(model.balance.B10_LJ),
            kpiB11: nullable(model.balance.B11_LJ),
            kpiB12: nullable(model.balance.B12_LJ),
            kpiB13: null,
            kpiE1: nullable(model.income.E1_LJ),
            kpiE2: nullable(model.income.E2_LJ),
            kpiE3: nullable(model.income.E3_LJ),
            kpiE4: nullable(model.income.E4_LJ),
            kpiE5: nullable(model.income.E5_LJ),
            kpiE6: nullable(model.income.E6_LJ),
            kpiE7: nullable(model.income.E7_LJ),
            kpiE8: nullable(model.income.E8_LJ),
            kpiE9: nullable(model.income.E9_LJ),
            kpiE10: nullable(model.income.E10_LJ),
            kpiE11: nullable(model.income.E11_LJ),
            kpiE13: nullable(model.income.E13_LJ),
            kpiE14: nullable(model.income.E14_LJ),
            kpiE15: nullable(model.income.E15_LJ),
            kpiE16: nullable(model.income.E16_LJ),
            kpiE17: nullable(model.income.E17_LJ),
            kpiE18: nullable(model.income.E18_LJ),
            kpiE19: nullable(model.income.E19_LJ),
            kpiE20: nullable(model.income.E20_LJ),
            kpiE21: nullable(model.income.E21_LJ),
            kpiE22: nullable(model.income.E22_LJ),
            kpiE23: nullable(model.income.E23_LJ),
            kpiE51: nullable(model.income.E24_LJ),
            kpiE25: nullable(model.income.E25_LJ),
            kpiE26: nullable(model.income.E26_LJ),
            kpiE27: nullable(model.income.E27_LJ),
            kpiE28: nullable(model.income.E28_LJ),
            kpiE29: nullable(model.income.E29_LJ),
            kpiE52: nullable(model.income.E30_LJ),
            kpiE50: nullable(model.income.E50_LJ),
            kpiE53: nullable(model.income.E99_LJ),
        };

        return {
            kpiKzFortschritt: (hasNullValue(data) ? 'H' as const : 'V' as const),
            ...data,
        };
    };
}
