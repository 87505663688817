import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E21_LJ', '/', 'intermediates.total_output_LJ'],
        { type: 'bounds', ...params },
        'Sie haben einen Pachtaufwand erfasst, Ihre Apotheke jedoch nicht als Pachtapotheke deklariert.',
        ['general.A5_LJ', 'tenure', '!'],
        'GuV_22',
    )
);

export default rule;
