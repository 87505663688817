import ComparisonGroup, { GroupTypeEntity } from './ComparisonGroup';
import { KeysOfType, booleanToYN, getModelValue } from './Helper';
import {
    SaveValidComparisonCombination,
    ValidComparisonCombination,
    ValidComparisonCombinationApiEntity,
    ValidComparisonCombinationSavingApiEntity,
} from '../Schemas/Api/ComparisonCombination';
import userCombinationSchema, {
    UserComparisonCombinationApiEntity,
} from '../Schemas/Api/UserComparisonCombination';

import { AjvJTD } from './Ajv';
import { JTDParser } from 'ajv/dist/jtd';
import SchemaMapper from './SchemaMapper';

export default class ComparisonGroupMapper
    extends SchemaMapper<ComparisonGroup, ValidComparisonCombinationApiEntity> {
    schema = ValidComparisonCombination;
    userComparisonCombinationParser?: JTDParser<UserComparisonCombinationApiEntity>;

    offizinData: Array<GroupTypeEntity>;
    privateShareData: Array<GroupTypeEntity>;
    highPriceShareData: Array<GroupTypeEntity>;
    openingHoursData: Array<GroupTypeEntity>;
    pharmacyCountData: Array<GroupTypeEntity>;
    populationData: Array<GroupTypeEntity>;
    stateData: Array<GroupTypeEntity>;
    locationData: Array<GroupTypeEntity>;

    saveSerializer?: (data: ValidComparisonCombinationSavingApiEntity) => string;

    dataKeys = {
        offizin: 'offizinData',
        privateShare: 'privateShareData',
        highPriceShare: 'highPriceShareData',
        openingHours: 'openingHoursData',
        pharmacyCount: 'pharmacyCountData',
        population: 'populationData',
        state: 'stateData',
        location: 'locationData',
    } as const;

    constructor(
        offizinData: Array<GroupTypeEntity>,
        privateShareData: Array<GroupTypeEntity>,
        highPriceShareData: Array<GroupTypeEntity>,
        openingHoursData: Array<GroupTypeEntity>,
        pharmacyCountData: Array<GroupTypeEntity>,
        populationData: Array<GroupTypeEntity>,
        stateData: Array<GroupTypeEntity>,
        locationData: Array<GroupTypeEntity>,
    ) {
        super();
        this.offizinData = offizinData;
        this.privateShareData = privateShareData;
        this.highPriceShareData = highPriceShareData;
        this.openingHoursData = openingHoursData;
        this.pharmacyCountData = pharmacyCountData;
        this.populationData = populationData;
        this.stateData = stateData;
        this.locationData = locationData;
    }

    serializeForSave = (model: ComparisonGroup) => {
        if (!this.saveSerializer) {
            this.saveSerializer = AjvJTD.compileSerializer(SaveValidComparisonCombination);
        }
        return this.saveSerializer(this.mapToSaveData(model));
    };

    mapToModel = (data: ValidComparisonCombinationApiEntity) => {
        return new ComparisonGroup(
            this.single(this.findValueFromData('offizin', data.IDListe)),
            this.single(this.findValueFromData('privateShare', data.IDListe)),
            this.single(this.findValueFromData('highPriceShare', data.IDListe)),
            this.single(this.findValueFromData('openingHours', data.IDListe)),
            this.single(this.findValueFromData('pharmacyCount', data.IDListe)),
            this.single(this.findValueFromData('population', data.IDListe)),
            this.single(this.findValueFromData('state', data.IDListe)),
            this.findValueFromData('location', data.IDListe),
            data.aktiv === 'J',
            data.gueltig === 'J',
            data.kombiListe,
            data.bekNr,
        )
    };

    mapToSaveData = (model: ComparisonGroup): ValidComparisonCombinationSavingApiEntity => {
        const { aktiv, gueltig, bekNr, IDListe } = this.mapToData(model);
        return { aktiv, gueltig, bekNr, ids: IDListe.replace(/,,/g, ';') };
    };

    mapToData = (model: ComparisonGroup) => {
        const ids = ([
            'offizin', 'privateShare', 'highPriceShare',
            'openingHours', 'pharmacyCount', 'population', 'state',
        ] as const).map((key) => (getModelValue(model, key))).filter((value) => (value !== null));
        const all = ids.concat(model.location.map((location) => (location.begID)).sort());

        return {
            IDListe: all.join(',,'),
            aktiv: booleanToYN(model.active),
            gueltig: 'J' as const,
            bekNr: model.id || 0,
            kombiListe: '',
        };
    };

    serializeSetActive = (active: boolean) => {
        return JSON.stringify({ aktiv: booleanToYN(active) });
    };

    findValueFromData(
        key: KeysOfType<ComparisonGroup, Nullable<GroupTypeEntity>|GroupTypeEntity[]>,
        dataString: string,
    ): Array<GroupTypeEntity> {
        const data = this[this.dataKeys[key]];
        const matchedItems = dataString
            .replace(/^,+|,+$/g, '')
            .split(',,')
            .filter((value) => (value !== ''))
            .map((id) => (data.find((item) => (item.begID === Number.parseInt(id, 10)))))
            .filter((value) => (value !== undefined));

        // Undefined from data.find in map is filtered out in the last step, TS does not infer this,
        // so we need to cast here.
        return matchedItems as unknown as GroupTypeEntity[];
    }

    single(items: Array<GroupTypeEntity>): GroupTypeEntity | null {
        return items.length > 0 ? items[0] : null;
    }

    getUserComparisonCombinationParser() {
        if (!this.userComparisonCombinationParser) {
            this.userComparisonCombinationParser = AjvJTD.compileParser(userCombinationSchema);
        }
        return this.userComparisonCombinationParser;
    }
}
