import {
    Box,
    FormControl,
    IconButton,
    MenuItem,
    Select,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
    Add,
} from '@material-ui/icons';
import {
    staffTypeOptions,
} from '../../Lib/YearlyData';

/**
 * The properties
 */
type AddStaffProps<TCategory extends keyof typeof staffTypeOptions> = {
    category: TCategory,
    onAdd?: (type: keyof typeof staffTypeOptions[TCategory]) => void,
    buttonClassName?: string,
};

/**
 * Enter staff related data
 */
const AddStaff = <TCategory extends keyof typeof staffTypeOptions>({
    onAdd,
    category,
    buttonClassName,
}: AddStaffProps<TCategory>) => {
    // @ts-ignore
    const availableKeys: Array<keyof typeof staffTypeOptions[TCategory]> =
        Object.keys(staffTypeOptions[category]);
    const [value, setValue] =
        useState<keyof typeof staffTypeOptions[TCategory]>(availableKeys[0]);

    return (
        <Box alignItems="center" display="flex">
            <FormControl size="small">
                <Select
                    variant="outlined"
                    value={value}
                    onChange={({ target: { value }}) => setValue(value as keyof typeof staffTypeOptions[TCategory])}
                    style={{ padding: 0 }}
                >
                    {Object.keys(staffTypeOptions[category]).map((key) => (
                        <MenuItem key={key} value={key}>
                            {
                                // @ts-ignore
                                staffTypeOptions[category][key]
                            }
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconButton onClick={() => {
                if (value && onAdd) {
                    onAdd(value);
                }
            }} color="primary">
                <Add />
            </IconButton>
        </Box>
    );
}

export default AddStaff;
