export default class PharmacyData {
    number: Nullable<string> = null;

    title: Nullable<string> = null;

    state: Nullable<string> = null;

    active: boolean = false;

    constructor(
        number: Nullable<string> = null,
        title: Nullable<string> = null,
        state: Nullable<string> = null,
        active: boolean = true,
    ) {
        this.number = number;
        this.title = title;
        this.state = state;
        this.active = active;
    }
}
