import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';

import ItemMenu from '../Common/ItemMenu';
import ItemStatus from '../Common/ItemStatus';
import { TableRenderProps } from './renderTable';
import {
    nestedBenchmarkingGroupAccessor,
} from '../../Lib/Helper';

const listRender = ({
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    onQuickEditRow,
    onEditRow,
}: TableRenderProps) => (
    <List {...getTableProps()} {...getTableBodyProps()}>
        {page.map((row) => {
            prepareRow(row);
            return (
                <>
                    <ListItem {...row.getRowProps()}>
                        <ListItemText
                            primary={nestedBenchmarkingGroupAccessor({ value: (row.original.offizin || undefined) })}
                            secondary={nestedBenchmarkingGroupAccessor({ value: row.original.privateShare || undefined })}
                        />
                        <ListItemSecondaryAction>
                            <Grid container spacing={3} alignItems="center" wrap="nowrap">
                                <Grid item xs style={{ textAlign: 'center' }}>
                                    <Typography variant="body2" color="textSecondary">aktiv</Typography>
                                    <ItemStatus status={row.original.active} />
                                </Grid>
                                <Grid item xs style={{ textAlign: 'center' }}>
                                    <Typography variant="body2" color="textSecondary">gültig</Typography>
                                    <ItemStatus status={row.original.valid} />
                                </Grid>
                                <Grid item xs style={{ textAlign: 'center' }}>
                                    <ItemMenu
                                        onEdit={() => onEditRow(row.original)}
                                        onActiveChange={(isActive) => onQuickEditRow(row.original, isActive)}
                                        active={row.original.active}
                                    />
                                </Grid>
                            </Grid>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </>
            );
        })}
    </List>
);

export default listRender;
