import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type MasterDataApiEntity = {
    lkuID: number,
    lkuWert: string,
    lkuAktiv: 'J' | 'N',
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<MasterDataApiEntity> = {
    properties: {
        lkuID: { type: 'int32' },
        lkuWert: { type: 'string' },
        lkuAktiv: { enum: ['J', 'N'] },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const MasterDataArray: JTDSchemaType<Array<MasterDataApiEntity>> = {
    elements: schema,
};
