import {
    BasicLocalStorageCredentialStore,
    setDefaultCredentialStore,
} from '../Lib/CredentialStore';

import { Credentials } from '../Providers/DataProvider';
import UserWithPharmacy from '../Lib/UserWithPharmacy';
import createAuthContext from '../Providers/AuthProvider';
import { useContext } from 'react';

/**
 * Create a credentials store and use as default
 */
const credentialsStore = new BasicLocalStorageCredentialStore('TokApo');
setDefaultCredentialStore(credentialsStore);

/**
 * Export the contexts
 */
export const {
    useAuth,
    AuthContext,
    AuthContextProvider,
} = createAuthContext<UserWithPharmacy, Credentials>();

export function useAuthenticate(): (credentials: Credentials) => void {
    return useContext(AuthContext).signIn;
}

// re-export as default as well
export default useAuth;
