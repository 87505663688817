import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['general.A17_LJ', '/', 'compare.general.A17_LJ'],
        { type: 'bounds', ...params },
        'Die Veränderung der abgesetzte Privatpackungen pro Jahr weicht stark vom Vorjahr ab. Bitte prüfen Sie Ihre Angaben!',
        undefined,
        'Struktur_05',
    )
);

export default rule;
