import Form from './UserForm';
import NarrowContainer from '../Common/Ui/NarrowContainer';
import React from 'react';
import User from '../../Lib/User';
import UserService from '../../Lib/UserService';
import useAuth from '../../Hooks/useAuth';
import useSaveWithNotification from '../../Hooks/useSaveWithNotification';
import useService from '../../Hooks/useService';

const UserStandaloneForm : React.FC<{}> = () => {
    const { authentication } = useAuth();
    const Service = useService<UserService>('user');

    // Custom save handlers with notifications
    const save = useSaveWithNotification((data: User) => (
        Service.save(data)
            .then(() => {
                if (authentication) {
                    Object.keys(data).forEach((key: keyof User) => {
                        // @ts-ignore
                        authentication[key] = data[key];
                    });
                }
            })
    ));

    return (
        <NarrowContainer>
            <Form
                model={authentication ?? undefined}
                buttonText="Daten aktualisieren"
                onSubmit={save}
            />
        </NarrowContainer>
    );
};

export default UserStandaloneForm;
