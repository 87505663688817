import { useEffect, useState } from 'react';

import Bugsnag from '@bugsnag/js';
import useService from './useService';

const useLoad = <T, U, V>(serviceName: string, load: (service: T, filter?: V) => Promise<U>, filter?: V): {
    isLoading: boolean, data?: U, updateData: (update: U) => void, reload: () => void, isError: boolean, error?: string,
} => {
    const service = useService<T>(serviceName);
    const [reloadTs, setReloadTs] = useState<number>(Date.now());
    const [isLoading, setIsLoading] = useState(false);
    const [dataState, setDataState] = useState<U>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (isLoading) return;
        setIsLoading(true);
        setError(undefined);
        load(service, filter)
            .then((data) => {
                setDataState(data);
            })
            .catch((e) => {
                Bugsnag.notify(e);
                setError(e.message);
                setDataState(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service, reloadTs, filter])

    const updateData = (update: U) => setDataState(update);

    return {
        isLoading,
        isError: error !== undefined,
        error,
        data: dataState,
        updateData,
        reload: () => setReloadTs(Date.now()),
    };
}

export default useLoad;
