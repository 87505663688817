import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

import { formatNumber } from '../Helper'

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E30_LJ', '/', 'intermediates.yearly_profit_LJ'],
        { type: 'bounds', ...params },
        (result, match, evaluator) => {
            const incomeInput = evaluator.raw('income.E30_LJ');
            const diff = evaluator.result(['intermediates.yearly_profit_LJ', '-', 'income.E30_LJ']) || 0;
            const profit = incomeInput + diff;
            const sign = diff < 0 ? '-' : '+';
            return `Der von Ihnen in diesem Feld manuell erfasste Jahresüberschuss weicht vom Jahresüberschuss iHv. ${formatNumber(profit)} EUR, der aufgrund Ihrer Erträge und Aufwendungen berechnet wurde, um ${sign} ${formatNumber(Math.abs(diff))} EUR ab.`;
        },
        undefined,
        'GuV_20',
    )
);

export default rule;
