import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Step,
    StepLabel,
    Stepper,
} from '@material-ui/core';
import { Form, Formik, FormikErrors } from 'formik';
import React, { useCallback, useState } from 'react';

import {
    Alert,
} from '@material-ui/lab';
import FormikTouchErrors from './FormikTouchErrors';
import LoadingButton from '../LoadingButton';
import { serverErrorMessage } from '../../../Lib/Helper';

type SteppedFormProps = React.PropsWithChildren<{
    stepConfig: Record<string, React.FC<any>>,
    validations?: Record<string, <T extends any>(values: T) => FormikErrors<T>>,
    onSubmit?: (data: Array<any>) => Promise<void>,
    nextStepLabel?: string,
    prevStepLabel?: string,
    completeLabel?: string,
}>;

const SteppedForm: React.FC<SteppedFormProps> = ({
    stepConfig,
    children,
    validations = {},
    onSubmit = () => Promise.resolve(),
    nextStepLabel = 'nächster Schritt',
    prevStepLabel = 'Schritt zurück',
    completeLabel = 'abschließen',
}) => {
    const steps = Object.keys(stepConfig);
    const [valueCache, setValueCache] = useState<Array<any>>([]);

    const [activeStep, setActiveStep] = useState(0);
    const [submitError, setSubmitError] = useState<string>();
    const [canFinishCurrentStep, setCanFinishCurrentStep] = useState(true);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const isLastStep = activeStep === steps.length - 1;

    const renderStepContent = useCallback((step: number) => {
        const ComponentToRender = stepConfig[steps[step]];
        return <ComponentToRender onCanFinishChanged={setCanFinishCurrentStep} />;
    }, [steps, stepConfig]);

    // Handlers
    function handleBack() {
        setActiveStep(activeStep - 1);
    }

    // Display children once completed
    const formContent = (isLastStep && isCompleted)
        ? (<>{children}</>)
        : (
            <Card elevation={0}>
                <CardContent>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={(values) => {
                            const errors = validations[steps[activeStep]]
                                ? validations[steps[activeStep]](values)
                                : {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, setTouched, setValues }) => {
                            // Copy to value cache
                            valueCache[activeStep] = values;
                            setValueCache([...valueCache]);

                            // And process
                            if (isLastStep) {
                                setSubmitError(undefined);
                                onSubmit(valueCache)
                                    .then(() => {
                                        setIsCompleted(true);
                                    })
                                    .catch(formError => {
                                        setSubmitError(serverErrorMessage(formError, 'Daten konnten nicht übermittelt werden', true));
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            } else {
                                setTimeout(() => {
                                    setActiveStep(activeStep + 1);
                                    setTouched({});
                                    setValues({});
                                    setSubmitting(false);
                                }, 2000);
                            }
                        }}
                    >
                        {({ isSubmitting, submitForm }) => (
                            <Form>
                                <FormikTouchErrors />
                                {renderStepContent(activeStep)}
                                {submitError ? (
                                    <Alert severity="error">{submitError}</Alert>
                                ) : null}
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    loading={isSubmitting}
                                    disabled={!canFinishCurrentStep}
                                    onClick={submitForm}
                                    fullWidth
                                >
                                    {isLastStep ? completeLabel : nextStepLabel}
                                </LoadingButton>
                                <Button
                                    color="secondary"
                                    onClick={handleBack}
                                    disabled={activeStep === 0 || isSubmitting}
                                >
                                    {prevStepLabel}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        );

    return (
        <Box textAlign="center">
            <Grid container justifyContent="center">
                <Grid item md={8}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item md={6}>
                    {formContent}
                </Grid>
            </Grid>
        </Box>
    );
};

export default SteppedForm;
