import ComparisonGroup from '../Lib/ComparisonGroup';
import ComparisonGroupService from '../Lib/ComparisonGroupService';
import { updateData } from '../Lib/Helper';
import useEditDialog from './useEditDialog';
import useSaveWithNotification from './useSaveWithNotification';
import useService from './useService';
import { useState } from 'react';

const useFilteredComparisonGroups = () => {
    // State used
    const [dataState, setDataState] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();
    const Service = useService<ComparisonGroupService>('comparisonGroup');

    // Custom save handlers with notifications
    const save = useSaveWithNotification((data: ComparisonGroup) => (
        Service.save(data).then(() => setDataState(updateData(data, dataState)))
    ));
    const setActive = useSaveWithNotification((model: ComparisonGroup, active: boolean) => {
        model.active = active;
        return Service.setActive(model, active).then(() => setDataState(updateData(model, dataState)));
    });

    // Load data here
    const onFetchData = () => {
        setIsLoading(true);
        Service.getAll()
            .then((fetchedData: Array<ComparisonGroup>) => setDataState(fetchedData))
            .catch(e => {
                setError(e.message);
                setDataState([]);
            })
            .finally(() => setIsLoading(false));
    };

    // Manage dialog state
    const editDialog = useEditDialog({
        title: 'Neue Vergleichsgruppe',
        editTitle: 'Vergleichsgruppe bearbeiten',
        // This is not typed yet correctly because of complexities in wrapping functions. However
        // this is most likely to change significantly so typing will be done when rework will be
        // progressed
        // @ts-ignore
        onSave: save,
    });

    // Actions to hook up on item menu
    const onEdit = (model: ComparisonGroup) => editDialog.open(model);
    const onQuickEdit = (model: ComparisonGroup, active: boolean) => {
        setActive(model, active);
    };

    return {
        data: dataState,
        isLoading,
        onFetchData,
        editDialog,
        error,
        actions: { onEdit, onQuickEdit },
    };
};

export default useFilteredComparisonGroups;
