import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type ValidationResultApiEntity = {
    checkergKurzBez: string,
    checkergApoID: number,
    checkergWj: number,
    checkergWert: number,
    checkergStatus: 'O' | 'W' | 'K' | 'N',
};


/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<ValidationResultApiEntity> = {
    properties: {
        checkergKurzBez: { type: 'string' },
        checkergApoID: { type: 'int32' },
        checkergWj: { type: 'int32' },
        checkergWert: { type: 'int32' },
        checkergStatus: { enum: ['O', 'W', 'K', 'N'] },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const ValidationResultArray: JTDSchemaType<Array<ValidationResultApiEntity>> = {
    elements: schema,
};
