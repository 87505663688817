import { Redirect, Route, RouteProps } from 'react-router-dom';

import React from 'react';
import useAuth from '../Hooks/useAuth';

export type RestrictedRouteProps = RouteProps & { allowAuthenticated?: boolean };

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
    children,
    allowAuthenticated = false,
    ...rest
}) => {
    const { isAuthenticated } = useAuth();
    return (
        <Route {...rest}>
            {
                (isAuthenticated && !allowAuthenticated)
                    ? <Redirect to={{ pathname: "/dashboard" }} />
                    : children
            }
        </Route>
    );
};

export default RestrictedRoute;
