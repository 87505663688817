import {
    Link,
    Typography,
} from '@material-ui/core';

import NarrowBox from '../Common/Ui/NarrowBox';
import React from 'react';

const Contact = () => {
    return (
        <>
            <NarrowBox textAlign="center">
                <Typography variant="h1">KONTAKT</Typography>
                <Typography variant="body1">
                    Falls Sie allgemeine Fragen zu ApoStar oder der Registrierung haben, können Sie uns gerne kontaktieren:
                    <br />
                    <Link href="mailto://apostar@apothekerverband.at">apostar@apothekerverband.at</Link>
                    <br />
                    <Link href="tel://0043140414300">+43 1 40414 300</Link>
                </Typography>
            </NarrowBox>
        </>
    );
}

export default Contact;
