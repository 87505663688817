import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type ComparisonGroupApiEntity = {
    begID: number,
    begBezeichnung: string,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<ComparisonGroupApiEntity> = {
    properties: {
        begID: { type: 'int32' },
        begBezeichnung: { type: 'string' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const ComparisonGroupArray: JTDSchemaType<Array<ComparisonGroupApiEntity>> = {
    elements: schema,
};
