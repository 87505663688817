import YearlyPharmacySchema, {
    YearlyPharmacyApiEntity,
} from '../Schemas/Api/YearlyPharmacy';

import { AjvJTD as Ajv } from './Ajv';
import { JTDParser } from 'ajv/dist/jtd';
import Pharmacy from './Pharmacy';
import RawPharmacy from './RawPharmacy';

const dataMap: Record<string, keyof Pharmacy> = {
    S1: 'state',
    S2: 'pharmacyCount',
    S3: 'population',
    S4: 'openingHours',
    S5: 'location',
} as const;

export default class YearlyPharmacyMapper {
    // Serializer for a single entity
    serializer?: (data: Array<YearlyPharmacyApiEntity>) => string;
    parser?: JTDParser<Array<YearlyPharmacyApiEntity>>;

    pharmacyId: number;
    pharmacy: RawPharmacy;

    year: number;

    constructor(pharmacy: RawPharmacy, year: number) {
        this.pharmacy = pharmacy;
        this.pharmacyId = pharmacy.id!;
        this.year = year;
    }

    /**
     * Get a serializer for an entity
     */
    getSerializer = () => {
        if (!this.serializer) {
            this.serializer = Ajv.compileSerializer({ elements: YearlyPharmacySchema });
        }
        return this.serializer;
    }

    /**
     * Get a serializer for an entity
     */
    getParser = () => {
        if (!this.parser) {
            this.parser = Ajv.compileParser({ elements: YearlyPharmacySchema });
        }
        return this.parser;
    }

    /**
     * Directly serialize a model to a json string represenation
     */
    serialize = (model: Pharmacy) => {
        const entries = [
            ['S2', model.pharmacyCount?.begID],
            ['S3', model.population?.begID],
            ['S4', model.openingHours?.begID],
            ...model.location.map(({ begID }) => (['S5', begID]))
        ];

        const data = entries.map(([key, value]) => ({
            ApoABVJahrKurzBez: key,
            ApoABVJahrApoID: this.pharmacyId,
            ApoABVJahrWj: this.year,
            ApoABVJahrBegID: value,
        })).filter(({ApoABVJahrBegID}) => (ApoABVJahrBegID !== undefined)) as Array<YearlyPharmacyApiEntity>;

        return this.getSerializer()(data);
    }

    /**
     * Directly parse a string to the destination array of models
     */
    parseArray = (data: string) => {
        const parser = this.getParser();
        const parsed = parser(data);
        if (!parsed) {
            const detail = data.substring((parser.position || 0) - 10, (parser.position || 0) + 10);
            throw new Error(`Parsing failed, ${parser.message} at ${parser.position}: ${detail}`);
        }

        const pharmacy = new Pharmacy(
            this.pharmacy.number,
            this.pharmacy.title,
            this.pharmacy.foundingYear,
            null, null, null, null, { begID: this.pharmacy.state!, begBezeichnung: '' }, [],
            this.pharmacy.active,
            this.pharmacy.monthlySubscribed,
            this.pharmacy.transferGK,
            this.pharmacy.dateOfBalance ?
                new Date(Date.parse(this.pharmacy.dateOfBalance)) : null,
            this.pharmacyId,
        );
        pharmacy.location = parsed
            .filter(({ ApoABVJahrKurzBez }) => (ApoABVJahrKurzBez === 'S5'))
            .map(({ ApoABVJahrBegID }) => ({ begID: ApoABVJahrBegID, begBezeichnung: '' }));

        parsed
            .filter(({ ApoABVJahrKurzBez }) => (ApoABVJahrKurzBez !== 'S5'))
            .forEach(({ ApoABVJahrBegID, ApoABVJahrKurzBez }) => {
                // @ts-ignore
                pharmacy[dataMap[ApoABVJahrKurzBez]] = { begID: ApoABVJahrBegID, begBezeichnung: '' };
            });
        return pharmacy;
    }
}
