import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RuleParamaters, ruleParameters } from '../../Lib/YearlyDataValidation';

import {
    Alert,
} from '@material-ui/lab';
import LoadingButton from '../Common/LoadingButton';
import ParamInputs from './ParamInputs';
import { RuleParamatersInput } from './Definitions';
import SelectYear from './SelectYear';
import YearlyDataService from '../../Lib/YearlyDataService';
import useNotifications from '../../Hooks/useNotifications';
import useService from '../../Hooks/useService';

export type ValidationParamsProps = {};
// Validate form data
const isComplete = (input: RuleParamatersInput): input is RuleParamaters => {
    const invalid = ruleParameters.filter((key) => (
        !input[key]
        || !input[key].bounds
        || !input[key].boundsCritical
        || input[key].bounds?.low === undefined
        || input[key].bounds?.high === undefined
        || input[key].boundsCritical?.low === undefined
        || input[key].boundsCritical?.high === undefined
    ))
    console.log("not valud", invalid, input, invalid.map((key) => input[key]));

    return invalid.length === 0;
}

/**
 * Validation params for a year
 */
const ValidationParams: React.FC<ValidationParamsProps> = () => {
    // Service is used
    const service = useService<YearlyDataService>('yearlyData');

    // Which year
    const [year, setYear] = useState<number | undefined>(undefined);

    // Loading and data state
    const [loading, setLoading] = useState(false);
    const [loadedData, setLoadedData] = useState<RuleParamatersInput | undefined>(undefined);
    const [inputData, setInputData] = useState<RuleParamatersInput | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const { addSuccess, addError } = useNotifications();

    // Handle saving
    const handleSave = () => {
        // Year must be given
        if (!year) {
            return;
        }

        // Assert error free
        if (inputData && isComplete(inputData)) {
            setLoading(true);
            setError(undefined);
            service.saveRuleParameters(year, inputData)
                .then(() => addSuccess('Rechenparameter wurden gespeichert'))
                .catch(() => addError('Speichern ist fehlgeschlagen'))
                .finally(() => setLoading(false));
            return;
        }

        // Set the error
        setError('Alle Parameter müssen ausfüllt werden');
    }

    // Autoload when year is changed
    useEffect(() => {
        if (year === undefined) {
            return;
        }

        setLoading(true);
        setError(undefined);
        service.loadRuleParamters(year)
            .then((parameters) => {
                setLoadedData(parameters);
                setInputData(parameters);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    const action = year === undefined ? null : (
        <Box display="flex" alignItems="center">
            <Typography>{year}</Typography>
            <Button variant="outlined" component="button" onClick={() => setYear(undefined)}>
                ändern
            </Button>
        </Box>
    );

    return (
        <Card>
            <CardHeader title="Rechenparameter anpassen" />
            <CardContent>
                {action}
                {year === undefined
                    ? (<SelectYear onSelect={setYear} />)
                    : (loading ? (<CircularProgress />) : (
                        <>
                            <ParamInputs initialValues={loadedData} onChange={setInputData} />
                            <Box display="flex">
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    speichern
                                </LoadingButton>
                                {error && (<Alert severity="error">{error}</Alert>)}
                            </Box>
                        </>
                    ))
                }
            </CardContent>
        </Card>
    );
};
export default ValidationParams;
