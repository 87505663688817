import {
    Button,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Properties for {@link VideoEmbed}
 */
export type VideoEmbedProps = {
    src: string,
    preview?: string,
};

/**
 * Styles used in {@link VideoEmbed}
 */
const useStyles = makeStyles((theme: Theme) => ({
    videoContainer: {
        position: 'relative',
        width: 560,
        height: 315,
    },
    videoOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity .5s ease',
    },
    accepted: {
        opacity: 0,
        pointerEvents: 'none',
    },
}));

const previewHtml = '<!html doctype><style>*{padding:0;margin:0}</style><img src="https://via.placeholder.com/350x150">';

/**
 * Embed a video in a gdpr compliant manner using a click to activate mechanism.
 */
const VideoEmbed = ({ src, preview = previewHtml }: VideoEmbedProps) => {
    const classes = useStyles();
    const [accepted, setAccepted] = useState(false);

    const previewResource = `${process.env.PUBLIC_URL}/videopreview.html`;

    return (
        <div className={classes.videoContainer}>
            <div className={[classes.videoOverlay, accepted ? classes.accepted : ''].join(' ')}>
                <Typography variant="h4" color="inherit" gutterBottom>Externer Inhalt</Typography>
                <Typography variant="body1" gutterBottom>
                    Dieser Video-Inhalt wird von vimeo.com bereitgestellt. Durch Aktivierung tauscht Ihr Browser keine Daten mit diesen Netzwerken aus.
                </Typography>
                <Button variant="contained" onClick={() => setAccepted(true)}>
                    Akzeptieren & externen Inhalt aktivieren
                </Button>
            </div>
            <iframe
                width="560px"
                height="315px"
                src={accepted ? src : previewResource}
                allow="encrypted-media"
                allowFullScreen
                frameBorder={0}
                title="Video über ApoStar auf Vimeo"
            ></iframe>
        </div>
    );
}

export default VideoEmbed;
