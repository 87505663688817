import React, { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

import { serverErrorMessage } from '../Lib/Helper';

// Context type definition
interface AuthContextType<T extends any, U extends any> {
    authentication: T | null,
    isAuthenticated: boolean,
    isLoading: boolean,
    error: Error | null,
    signIn: (credentials: U) => Promise<void>,
    signOut: () => Promise<void>,
};

interface AuthContextProviderProps<T extends any, U extends any> extends PropsWithChildren<{
    onSignIn: (credentials: U) => Promise<T>,
    onSignOut: () => Promise<void>,
}> {};

// An error for out of context access
const ProviderAccessErrorCb = () => {
    throw new Error('You can only use setAuthenticated wihtin AuthContextProvider');
};

const createAuthContext = <T extends any, U extends any>(): {
    useAuth: () => AuthContextType<T, U>,
    AuthContext: React.Context<AuthContextType<T, U>>,
    AuthContextProvider: React.FC<AuthContextProviderProps<T, U>>,
} => {
    const AuthContext = createContext<AuthContextType<T, U>>({
        authentication: null,
        isAuthenticated: false,
        isLoading: false,
        error: null,
        signIn: ProviderAccessErrorCb,
        signOut: ProviderAccessErrorCb,
    });

    type AuthContextProviderProps = PropsWithChildren<{
        onSignIn: (credentials: U) => Promise<T>,
        onSignOut: () => Promise<void>,
    }>;

    const AuthContextProvider = ({ children, onSignIn, onSignOut }: AuthContextProviderProps) => {
        const [authentication, setAuthentication] = useState<T | null>(null);
        const [error, setError] = useState<Error | null>(null);
        const [loading, setLoading] = useState<boolean>(false);

        const contextValue = {
            authentication: authentication,
            isAuthenticated: !!authentication,
            isLoading: loading,
            error: error,
            signIn: useCallback(
                (credentials: U) => {
                    setLoading(true);
                    return onSignIn(credentials)
                        .then((auth: T) => {
                            setError(null);
                            setAuthentication(auth);
                        })
                        .catch((e) => setError(new Error(serverErrorMessage(e, 'Ungültige Logindaten'))))
                        .finally(() => setLoading(false));
                },
                [onSignIn, setAuthentication],
            ),
            signOut: useCallback(
                () => {
                    setLoading(true);
                    return onSignOut()
                        .then(() => setAuthentication(null))
                        .catch((signOutError) => setError(signOutError))
                        .finally(() => setLoading(false));
                },
                [onSignOut, setAuthentication]
            ),
        };
        return (
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>
        );
    };

    const useAuth = (): AuthContextType<T, U> => {
        return useContext(AuthContext);
    };

    return {
        useAuth,
        AuthContext,
        AuthContextProvider,
    };
};

export default createAuthContext;
