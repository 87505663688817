import SalesDataSchema, {
    SalesDataApiEntity,
} from '../Schemas/Api/SalesData';

import SchemaMapper from './SchemaMapper';

export type SalesData = { recipeCount: number, packageCount: number };

export default class SalesDataMapper
    extends SchemaMapper<SalesData, SalesDataApiEntity> {
    schema = SalesDataSchema;

    /**
     * Map json data to a user model
     */
    mapToModel = (data: SalesDataApiEntity) => {
        return {
            recipeCount: data.kpiA16,
            packageCount: data.kpiA18,
        };
    }

    /**
     * Map user model to json data
     */
    mapToData = (model: SalesData) => {
        return {
            kpiA16: model.recipeCount,
            kpiA18: model.packageCount,
        };
    };
}
