import {
    Box,
    CardContent,
    Divider,
    Paper,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { MONTH_NAMES, monthFormat } from '../../Lib/Helper';
import React, { useState } from 'react';
import ReportsFilter, { Filters, defaultFilter } from './ReportsFilter';

import Report from '../../Lib/Report';
import ReportService from '../../Lib/ReportService';
import Table from './Table';
import TableEmptyState from '../Common/Ui/TableEmptyState';
import TableRowLoader from '../Common/Ui/TableRowLoader';
import { labels } from './PeriodTag';
import useAuth from '../../Hooks/useAuth';
import useLoad from '../../Hooks/useLoad';

const useStyles = makeStyles(({
    palette: { primary },
    spacing,
}) => ({
    divider: {
        backgroundColor: primary.main,
        height: 2,
        margin: `${spacing(2)} 0`,
    },
}));

const dateLabel = (date?: Date, year: boolean = false) => (
    !date || year
        ? (
            !date || (date.getFullYear() < (new Date().getFullYear() - 20))
                ? '' : date.getFullYear()
        )
        : (date.getFullYear() >= (new Date().getFullYear() - 20))
            ? monthFormat(date)
            : MONTH_NAMES[date.getMonth()]
)
const filterLabel = (filter: Filters) => (
    filter.period === 'recent'
        ? 'Zuletzt hinzugefügt'
        : `${labels[filter.period]} ${dateLabel(filter.date, filter.period === 'yearly')}`
);

const ReportsArchive = () => {
    const classes = useStyles();
    const { authentication } = useAuth();

    const [filter, setFilter] = useState<Filters>(defaultFilter);
    const { isLoading, data, error } = useLoad<ReportService, Array<Report>, Filters>(
        'report',
        (service, f) => {
            return (
                !f || f.period === 'recent'
                    ? service.getRecent(authentication?.pharmacyId || 0, f?.type || 'pharmacy')
                    : service.getAll(authentication?.pharmacyId || 0, f.type, f.period, f.date, f.dateMode)
            );
        },
        filter,
    );

    return (
        <>
            <Box textAlign="center" p={2}>
                <Typography variant="h2" gutterBottom>
                    Berichtsarchiv
                </Typography>
            </Box>
            <Paper elevation={0}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        Auswahl
                    </Typography>
                    <Divider className={classes.divider} />
                    <ReportsFilter onSubmit={setFilter}/>
                </CardContent>
                <CardContent>
                    <Typography variant="h6" component="div">
                        {filterLabel(filter)}
                    </Typography>
                    <Divider className={classes.divider} />
                    {
                        isLoading
                            ? <TableRowLoader rowCount={10} colCount={5} withTable />
                            : (
                                <Table
                                    data={data || []}
                                    emptyView={<TableEmptyState state={{ isLoading, data, error }} colCount={5} />}
                                    pharmacyId={authentication?.pharmacyId}
                                />
                            )
                    }
                </CardContent>
            </Paper>
        </>
    );
};

export default ReportsArchive;
