import {
    Box,
    Grid,
    Link,
    OutlinedInput,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    StaffRow,
    Tuple,
    staffTypeByKey,
    staffTypeOptions,
} from '../../Lib/YearlyData';

import NumberFormat from 'react-number-format';

type StaffRowProps = {
    value: StaffRow,
    onChange?: (value: StaffRow) => void,
    onDelete?: () => void,
    startMonth?: number,
    endMonth?: number,
    classes: {
        row: string,
        labelCol: string,
        rowActions: string,
        rowAction: string,
        numberInput: string,
        monthLabel: string,
        monthItems: string,
    },
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'] as const;

const StaffRowCmp: React.FC<StaffRowProps> = ({
    value: { type, staffId, monthly, sum },
    onChange,
    onDelete,
    classes,
    startMonth = 0,
    endMonth = 11,
}) => {
    // The state of the row
    const [main, setMain] = useState<StaffRow['sum']>(sum);
    const [detail, setDetail] = useState<Tuple<number, 12> | undefined>(monthly);
    useEffect(() => setDetail(monthly), [monthly]);

    // Custom setter helper
    const setDetailMonth = (index: number, value: number) => {
        return setDetail((prev) => {
            if (!prev) {
                return;
            }

            // The new detail
            const newDetail: Tuple<number, 12> = [...prev] as Tuple<number, 12>;
            newDetail[index] = value;

            // Calculate the sums
            const monthCount = newDetail.filter((value) => (value && value > 0)).length;
            const monthSum = newDetail.reduce((memo, value) => (memo + (value || 0)), 0);
            const newSum = { months: monthCount, quota: monthSum / monthCount};

            onChange?.({ type, staffId, sum: newSum, monthly: newDetail, transmissionType: 'M' });
            setMain(newSum);
            return newDetail;
        });
    };

    const updateSum = (update: Partial<StaffRow['sum']>) => {
        setMain((prev) => {
            const newSum = { ...prev, ...update };
            onChange?.({ type, staffId, sum: newSum, monthly: undefined, transmissionType: 'J' });
            return newSum;
        })
    }

    // We limit the quota
    const limit = Object.keys(staffTypeOptions.employee).includes(type as any) ? 10 : 20;

    // Calculating the display monthes
    const monthes = Array.from(Array(Math.min(12, (endMonth - startMonth + 12) % 12 + 1))
        .keys())
        .map((_, i) => ((i + startMonth) % 12));

    return (
        <>
            <Grid container className={classes.row} alignItems="center">
                <Grid item xs={3}>
                    <Typography>
                        {staffTypeByKey[type]}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    {
                        detail !== undefined
                            ? (
                                <Grid container spacing={1} className={classes.monthItems}>

                                    {monthes.map((nr, i) => (
                                        <Grid item xs={1} key={`month_${nr}`}>
                                            <Typography className={classes.monthLabel}>{monthNames[nr]}</Typography>
                                            <NumberFormat
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={0}
                                                allowNegative={false}
                                                onValueChange={({ floatValue }) => setDetailMonth(i, floatValue || 0)}
                                                defaultValue={detail?.[i]}
                                                value={detail?.[i]}
                                                inputProps={{ className: classes.numberInput }}
                                                placeholder=""
                                                customInput={OutlinedInput}
                                                isAllowed={({ floatValue }) => (!floatValue || floatValue <= limit)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                            : (
                                <Grid container alignItems="center">
                                    <Grid item xs={4} className={classes.labelCol}>
                                        <Typography color="textSecondary">
                                            Dienstdauer<br />
                                            (Monate)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <NumberFormat
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            decimalScale={0}
                                            allowNegative={false}
                                            onValueChange={({ floatValue }) => updateSum({ months: floatValue || 0 })}
                                            defaultValue={main.months}
                                            value={main.months}
                                            inputProps={{ className: classes.numberInput }}
                                            placeholder=""
                                            customInput={OutlinedInput}
                                            isAllowed={({ floatValue }) => (!floatValue || floatValue <= 12)}
                                        />
                                    </Grid>
                                    <Grid item xs={4} className={classes.labelCol}>
                                        <Typography color="textSecondary">
                                            Dienstausmaß<br />
                                            (Zehntel)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <NumberFormat
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            decimalScale={1}
                                            allowNegative={['familyPharmacist', 'familyNonPharmacist'].includes(type)}
                                            onValueChange={({ floatValue }) => updateSum({ quota: floatValue || 0 })}
                                            defaultValue={main.quota}
                                            value={main.quota}
                                            inputProps={{ className: classes.numberInput }}
                                            placeholder=""
                                            customInput={OutlinedInput}
                                            isAllowed={({ floatValue }) => (!floatValue || Math.abs(floatValue) <= limit)}
                                        />
                                    </Grid>
                                </Grid>
                            )
                    }
                </Grid>
                <Box className={classes.rowActions}>
                    <Link
                        onClick={() => onDelete?.()}
                        component="button"
                        className={classes.rowAction}
                    >
                        löschen
                    </Link>
                    {
                        detail !== undefined ? null : (
                            <Link
                                onClick={() => {
                                    setDetail(new Array(12).fill(0) as Tuple<number, 12>);
                                }}
                                component="button"
                                className={classes.rowAction}
                            >
                                monatsweise Eingabe
                            </Link>
                        )
                    }
                </Box>
            </Grid>
        </>
    );
}

export default StaffRowCmp;
