import {
    Avatar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    ChevronRight as ChevronRightIcon,
    Person as PersonIcon,
} from '@material-ui/icons';
import { RoleType, hasAnyRole } from '../Lib/Roles';

import { GroupTypeEntity } from '../Lib/ComparisonGroup';
import Involvement from '../Components/Common/Involvement';
import React from 'react';
import UserWithPharmacy from '../Lib/UserWithPharmacy';

const useStyles = makeStyles(() => ({
    drawer: {
        minWidth: '300px',
    },
    monthly: {
        paddingTop: 0,
        paddingBottom: 0,
        borderLeft: '8px solid #7195E4',
        '& .MuiListSubheader-root': {
            color: '#7195E4',
            paddingLeft: 8,
        },
        '& .MuiListItem-root': {
            paddingLeft: 8,
        },
    },
    yearly: {
        paddingTop: 0,
        paddingBottom: 0,
        borderLeft: '8px solid #8D68A0',
        '& .MuiListSubheader-root': {
            color: '#8D68A0',
            paddingLeft: 8,
        },
        '& .MuiListItem-root': {
            paddingLeft: 8,
        },
    },
}));

type MenuNode = {
    label: string,
    path?: string,
    roles?: Array<RoleType>,
    children?: Array<MenuNode>,
    className?: keyof ReturnType<typeof useStyles>,
};

/**
 * Menu structure
 */
const MENU_STRUCTURE: Array<MenuNode> = [{
    label: 'Startseite',
    path: '/dashboard',
}, {
    label: 'Admin-Stammdaten',
    path: '/stammdaten',
}, {
    label: 'Benutzer',
    path: '/benutzer',
    roles: ['Hauptbenutzer'],
}, {
    label: 'Apothekendaten',
    path: '/apotheke',
    roles: ['Hauptbenutzer'],
}, {
    label: 'Filialapotheken',
    path: '/filialen',
    roles: ['Hauptbenutzer'],
}, {
    label: 'Berichte',
    path: '/berichte',
    roles: ['Benutzer', 'Hauptbenutzer'],
}, {
    label: 'monatlicher ATR',
    className: 'monthly',
    children: [{
        label: 'Vergleichsgruppe festlegen',
        path: '/monatlich/vergleichsgruppe',
        roles: ['Benutzer', 'Hauptbenutzer'],
    }],
}, {
    label: 'jährlicher ABV',
    className: 'yearly',
    children: [{
        label: 'Dateneingabe',
        path: '/jaehrlich',
        roles: ['Steuerberater', 'Hauptbenutzer'],
    }, {
        label: 'Vergleichsgruppe festlegen',
        path: '/jaehrlich/vergleichsgruppe',
        roles: ['Benutzer', 'Hauptbenutzer'],
    }],
}, {
    label: 'Vergleichsgruppen verwalten',
    path: '/manage-comparison-groups',
    roles: ['Apothekerverband'],
}, {
    label: 'Rechenparameter',
    path: '/manage-params',
    roles: ['Apothekerverband'],
}, {
    label: '',
    children: [{
        label: 'FAQ',
        path: '/faq',
    }, {
        label: 'Kontakt',
        path: '/kontakt',
    }],
}];

/**
 * Properties for menu component
 */
export interface MenuProps {
    open: boolean,
    onClose: () => void,
    onSignOut: () => void,
    onNavigate: (path: string) => void,
    authentication: UserWithPharmacy,
    roles: Array<GroupTypeEntity>,
};

const renderMenu = (
    node: Array<MenuNode>,
    onNavigate: (path: string) => void,
    checkRole: (roles: Array<RoleType>, path: string) => boolean,
    classes: ReturnType<typeof useStyles>,
): Array<React.ReactNode> => (
    node.map(({ label, path = '', children = undefined, roles, className }) => {
        if (children) {
            const items = renderMenu(children, onNavigate, checkRole, classes);
            return items.length < 1 ? undefined : (
                <List key={label} className={className ? classes[className] : ''}>
                    <Divider />
                    <ListSubheader>
                        {label}
                    </ListSubheader>
                    {items}
                </List>
            );
        }
        return (
            (roles && !checkRole(roles, path)) ? undefined : (
                <ListItem button onClick={() => onNavigate(path)} key={path}>
                    <ListItemText primary={label} />
                </ListItem>
            )
        );
    }).filter((node) => (node !== undefined))
);

/**
 * The main menu
 */
export default function Menu({
    open,
    onClose,
    onSignOut,
    onNavigate,
    authentication,
    roles,
}: MenuProps) {
    const classes = useStyles();
    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            PaperProps={{ className: classes.drawer}}
        >
            <div>
                <IconButton onClick={onClose}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <Divider />
            <ListItem>
                <ListItemIcon><Avatar><PersonIcon /></Avatar></ListItemIcon>
                <ListItemText
                    primary={authentication.username}
                    secondary={(
                        <>
                            <Typography variant="body2" color="textSecondary">
                                {authentication.pharmacy.title}
                            </Typography>
                            <Involvement value={authentication.involvement} />
                        </>
                    )}
                />
            </ListItem>
            <ListItem button key="signout" onClick={() => {
                onClose();
                onSignOut();
            }}>
                <ListItemText primary="Logout" />
            </ListItem>
            <Divider />
            <List>
                {renderMenu(
                    MENU_STRUCTURE,
                    onNavigate,
                    (required, path) => {
                        if (path === '/monatlich/vergleichsgruppe' && !authentication.pharmacy.monthlySubscribed) {
                            return false;
                        }
                        return hasAnyRole(authentication, required, roles);
                    },
                    classes,
                )}
                <Divider />
            </List>
        </Drawer>
    );
}
