import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E20_LJ', '/', 'intermediates.total_output_LJ'],
        { type: 'bounds', ...params },
        'Wird Ihre Apotheke in einer Eigenimmobilie betrieben, wenn ja, dann prüfen Sie bitte den Mietaufwand.',
        ['general.A19_LJ', true],
        'GuV_23',
    )
);

export default rule;
