import { Field, Form, Formik } from 'formik';
import {
    Link,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
    Alert,
} from '@material-ui/lab';
import FormSuccess from '../Common/Form/FormSuccess';
import LoadingButton from '../Common/LoadingButton';
import { TextField } from 'formik-material-ui';
import UserService from '../../Lib/UserService';
import { serverErrorMessage } from '../../Lib/Helper';
import { useHistory } from 'react-router-dom';
import useService from '../../Hooks/useService';
import { validatePasswords } from '../../Lib/FormValidations/User';

export type RedeeemPasswordCodeProps = {
    onCancel?: () => void,
    code?: string,
};

const RedeeemPasswordCode: React.FC<RedeeemPasswordCodeProps> = ({ onCancel, code = '' }) => {
    const [error, setError] = useState<string | undefined>();
    const [finished, setFinished] = useState(false);
    const service = useService<UserService>('user');
    const history = useHistory();

    return (
        <>
            {error && (
                <Alert color="error">{error}</Alert>
            )}
            {finished ? (
                <FormSuccess
                    title="Passwort wurde zurückgesetzt"
                    text="Sie können sich ab sofort mit Ihrem neuen Passwort einloggen"
                    container={false}
                    onAction={() => history.push('/')}
                    actionLabel="zum Login"
                />
            ) : (
                <Formik
                    initialValues={{ code, password: '', password_confirm: '' }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, { setSubmitting }) => {
                        const errors = validatePasswords(values);
                        if (Object.keys(errors).length > 0) {
                            setError(errors.password);
                            setSubmitting(false);
                            return;
                        }
                        setError('');

                        service.changePassword(values.code, values.password)
                            .then(() => setFinished(true))
                            .catch((e) => setError(serverErrorMessage(e, 'Passwort konnte nicht zurückgesetzt werden, überprüfen sie den Code und das Passwort!', true)))
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting, submitForm }) => (
                        <Form>
                            <Typography>
                                Wenn Sie einen Code haben können sie diesen hier einlösen.
                            </Typography>
                            <Field
                                type="text"
                                name="code"
                                label="Code"
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Field
                                type="password"
                                name="password"
                                label="Neues Passwort"
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Field
                                type="password"
                                name="password_confirm"
                                label="Neues Passwort bestätigen"
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                // loadingPosition="start"
                                loading={isSubmitting}
                                onClick={submitForm}
                                fullWidth
                            >
                                Passwort zurücksetzen
                            </LoadingButton>
                            {onCancel && (
                                <Link
                                    component="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCancel();
                                    }}
                                    style={{ width: '100%', padding: 20 }}
                                >
                                    abbrechen
                                </Link>
                            )}
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
export default RedeeemPasswordCode;
