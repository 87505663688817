/**
 * The report period
 */
export type PeriodType = 'monthly' | 'yearly' | 'all';

/**
 * The report type
 */
export type ReportType = 'general' | 'pharmacy';

/**
 * Report model class
 */
export default class Report {
    /**
     * File base name
     */
    filename: string = '';

    /**
     * Complete web path (url)
     */
    filepath: string = '';

    /**
     * The files timestamp
     */
    timestamp: Date;

    /**
     * Report type
     */
    type: ReportType = 'general';

    /**
     * The period
     */
    period: PeriodType = 'monthly';

    constructor(
        filepath: string,
        timestamp: Date,
        period?: PeriodType,
        type?: ReportType,
    ) {
        this.filepath = filepath;
        this.filename = filepath.split('/').reverse()[0];
        this.timestamp = timestamp;

        if (period) {
            this.period = period;
        }
        if (type) {
            this.type = type;
        }
    }
}
