import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E2_LJ', '/', 'compare.income.E2_LJ'],
        { type: 'bounds', ...params },
        'Ihre Angabe zum Krankenkassenumsatz erscheint nicht konsistent zum Vorjahr.',
        undefined,
        'GuV_07',
    )
);

export default rule;
