import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E11_LJ', '/', ['income.E7_LJ', '+', 'income.E8_LJ']],
        { type: 'bounds', ...params },
        'Der gesetzliche Sozialaufwand bewegt sich je nach Personalstruktur im Rahmen von 25%-32% der Lohn- und Gehaltskosten. Bitte prüfen Sie die Eingaben im Personalaufwand.',
        undefined,
        'GuV_01',
    )
);

export default rule;
