import { GroupTypeEntity } from './ComparisonGroup';
import User from './User';

// All roles
const roles = [
    'Superuser', 'Apothekerverband', 'Hauptbenutzer', 'Benutzer', 'Steuerberater',
] as const;

export default roles;

// A type identifier
export type RoleType = typeof roles[number];

// Default role configurations
export const getDefaults = () => {
    if (
        !process.env.REACT_APP_ACCOUNTANT_ROLE_ID
        ||!process.env.REACT_APP_ACCOUNTANT_INVOLVEMENT_ID
        || !process.env.REACT_APP_DEFAULT_ROLE_ID
        || !process.env.REACT_APP_MAIN_ROLE_ID
    ) {
        throw new Error('Default roles not configured');
    }

    return {
        accountantRole: parseInt(process.env.REACT_APP_ACCOUNTANT_ROLE_ID, 10),
        accountantInvolvement: parseInt(process.env.REACT_APP_ACCOUNTANT_INVOLVEMENT_ID, 10),
        userRole: parseInt(process.env.REACT_APP_DEFAULT_ROLE_ID, 10),
        mainRole: parseInt(process.env.REACT_APP_MAIN_ROLE_ID, 10),
    };
}

// Check a role from a user
const hasRole = (user: User, role: RoleType, fetchedRoles: Array<GroupTypeEntity> = []) => {
    const desiredId = fetchedRoles.find((match) => (match.begBezeichnung === role));
    if (!desiredId) {
        console.warn('cannot find role', role);
    }

    return user.role === desiredId?.begID;
};

// Check one role of many
// Check a role from a user
const hasAnyRole = (user: User, role: RoleType[], fetchedRoles: Array<GroupTypeEntity> = []) => {
    const desiredIds = fetchedRoles
        .filter((match) => (role.includes(match.begBezeichnung as any)))
        .map((lookup) => (lookup.begID));

    return desiredIds.includes(user.role);
};


export {
    hasRole,
    hasAnyRole,
};
