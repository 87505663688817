import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = new UserValdiation(
    ['staff.director', '+', ['staff.pharmacist', '+', 'staff.aspirant']],
    { type: 'compare', compare: '=', value: 0 },
    'Sie haben einen Aufwand für pharmazeutische Fachkräfte in der GuV erfasst, aber keine Fachkräfte.',
    ['E7_LJ', 0, '>'],
    'Personal_14',
);

export default rule;
