import { Button, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';

import FormSuccess from '../Common/Form/FormSuccess';
import LoadingButton from '../Common/LoadingButton';
import PharmacyService from '../../Lib/PharmacyService';
import { TextField } from 'formik-material-ui';
import { serverErrorMessage } from '../../Lib/Helper';
import useService from '../../Hooks/useService';

export type RequestRegistrationCodeFormProps = {
    onFinished?: () => void,
    pharmacyNumber?: string,
};

const RequestRegistrationCodeForm = ({
    onFinished,
    pharmacyNumber = '',
}: RequestRegistrationCodeFormProps) => {
    const service = useService<PharmacyService>('pharmacy');
    const [done, setDone] = useState(false);

    if (done) {
        return (
            <FormSuccess
                title="Registrierungscode Versandt"
                text="Der Registrierungscode wurde an Ihr Gehaltskassenpostfach versandt! Aufgrund technischer Schwierigkeiten kann der Versand im Moment ein paar Stunden in Anspruch nehmen!"
                actionLabel="zurück"
                onAction={() => onFinished?.()}
                container={false}
            />
        );
    }

    return (
        <Formik
            initialValues={{ pharmacyNumber }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
                const errors = {};
                if (values.pharmacyNumber.length < 1) {
                    // @ts-ignore
                    errors.pharmacyNumber = 'Bitte einen Apothekenbetriebsnummer angeben';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                service.requestRegistrationCode(values.pharmacyNumber)
                    .then(() => setDone(true))
                    .catch((e) => {
                        setErrors({ pharmacyNumber: serverErrorMessage(e, 'Code konnte nicht angefordert werden', true) });
                    })
                    .finally(() => {
                        setSubmitting(false);
                    })
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <>
                    <Typography variant="body2" component="p" gutterBottom>
                        Sie benötigen einen Registrierungscode um fortfahren zu können. Geben Sie hier Ihre
                        Apothekennummer an, um einen Registrierungscode anzufordern.
                    </Typography>
                    <Typography variant="body2" component="p" gutterBottom>
                        Der Registrierungscode wird an Ihr <strong>Gehaltskassenpostfach</strong> gesendet.
                    </Typography>
                    <Field
                        component={TextField}
                        label="Apothekennummer"
                        fullWidth
                        name="pharmacyNumber"
                    />
                    <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={() => handleSubmit()}
                        color="secondary"
                    >
                        überprüfen
                    </LoadingButton>
                    <Button onClick={() => onFinished?.()}>abbrechen</Button>
                </>
            )}
        </Formik>
    );
};

export default RequestRegistrationCodeForm;
