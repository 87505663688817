import {
    Link,
    Typography,
    makeStyles,
} from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles((theme) => ({
    logoCube: {
        verticalAlign: 'middle',
        height: 90,
        [theme.breakpoints.down('sm')]: {
            height: 42,
            marginLeft: '-30%',
            marginTop: '-5%',
            marginBottom: '-5%',
        },
    },
    logoWord: {
        verticalAlign: 'middle',
        marginTop: 10,
        height: 40,
        [theme.breakpoints.down('sm')]: {
            height: 27,
            marginTop: 5,
        },
    },
    providedLine: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        fontSize: 12,
        '& .MuiLink-root': {
            fontWeight: '700',
        },
    },
    rightPart: {},
    container: {
        display: 'flex',
        alignItems: 'center',
    },
}))

/**
 * Layouts header, display an app bar and a global menu.
 */
export default function LogoCube({
    onClick,
    className,
}: { onClick: () => void, className?: string }) {
    // Main UI
    const classes = useStyles();
    return (
        <div className={[classes.container, className || ''].join(' ')}>
            <Link component="button" onClick={onClick}>
                <img src={`${process.env.PUBLIC_URL}/av_logo_red.png`} alt="logo" className={classes.logoCube} />
            </Link>
            <span className={classes.rightPart}>
                <Link component="button" onClick={onClick}>
                    <img src={`${process.env.PUBLIC_URL}/apostar_logo.svg`} alt="logo"  className={classes.logoWord} />
                </Link>
                <Typography className={classes.providedLine}>
                    Ein Service des <Link href="https://www.apothekerverband.at/" target="_blank" color="inherit">Österreichischen Apothekerverbands</Link>
                </Typography>
            </span>
        </div>
    );
}
