import { DataProviderContext } from '../Providers/DataProvider';
import { useContext } from 'react';

const useCachedData = <T extends Array<string>>(keys: T): { [K in T[any]]: any } => {
    const cache = useContext(DataProviderContext).getCache();
    // TS is righfully complaining about the empty object not conforming to our required return
    // object. I do not yet have a solution to that.
    // @ts-ignore
    return keys.reduce((memo, key) => {
        // @ts-ignore
        Object.assign(memo, { [key]: cache[key] });
        return memo;
    }, {});
};

export default useCachedData;
