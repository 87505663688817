import { useEffect, useRef } from 'react';

/**
 * Hook to run when one dependency changes but not on initial render. Otherwise same as reacts
 * internal useEffect
 */
const useChangeEffect = (callback: () => any, deps: Array<any>) => {
    // A ref that helps us determine the first run
    const didMount = useRef(false);

    // We want to disable exhaustive-deps here, because the effect is used as a subscriber to
    // changes in value only. Changes to the onChange should not trigger this call. Alternately
    // one could move the logic in custom state setter and omit this effect completely.
    // @see https://stackoverflow.com/a/58959298/1171541

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (didMount.current) {
            callback();
        } else {
            didMount.current = true;
        }
    }, deps);
    /* eslint-ensable react-hooks/exhaustive-deps */
};

export default useChangeEffect;
