import * as Xlsx from 'xlsx';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    SvgIcon,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import React, { useCallback, useState } from 'react';
import {
    WarningsData,
    readApostarDataFile,
    requiredSheets
} from '../../Lib/XlsxDataReader';

import {
    Alert,
} from '@material-ui/lab';
import { ReactComponent as IconUpload } from '../../assets/apostar_icons_download.svg';
import StaffDataService from '../../Lib/StaffDataService';
import UploadFileStatus from './UploadFileStatus';
import { YearlyData } from '../../Lib/YearlyData';
import { calculateTotals } from './StaffForm';
import useService from '../../Hooks/useService';
import useTypeStyles from '../Common/Ui/useTypeStyles';

const useStyles = makeStyles(({ palette }) => ({
    alertActive: {
        borderColor: palette.success.main,
        backgroundColor: palette.success.light,
    },
    alertInactive: {
        borderColor: palette.info.light,
    },
}));

/**
 * Properties for {@link Upload} component
 */
export type UploadProps = {
    /**
     * Handler for uploaded data
     */
    onUpload: (data: YearlyData) => void,

    /**
     * Current data
     */
    current: YearlyData,

    /**
     * The current year number
     */
    year: number,

    /**
     * The current pharmacies id
     */
    pharmacyId: number,

    /**
     * Card action
     */
    action?: React.ReactNode,
};

// Error messages
const dropErrorMessages: Partial<Record<ErrorCode, string>> = {
    [ErrorCode.FileInvalidType]: 'Die Datei ist nicht im erlaubten .xlsx Format.',
    [ErrorCode.TooManyFiles]: 'Nur eine Datei zum Upload erlaubt.',
};

// Determine the given year from given dates
const getYearFromEndDate = (date: Date) => {
    // First half, zero-indexed
    if (date.getMonth() <= 5) {
        return date.getFullYear() - 1;
    }

    return date.getFullYear();
};

// Get the month span between 2 dates
const getMonthSpan = (start: Date, end: Date) => {
    const yearSpan = end.getFullYear() - start.getFullYear();
    const monthSpan = end.getMonth() - start.getMonth();
    return yearSpan * 12 + 1 + monthSpan;
};

/**
 * Upload component
 */
const Upload: React.FC<UploadProps> = ({
    onUpload,
    current,
    year: currentYear,
    pharmacyId,
    action,
}) => {
    // Internal state
    const [error, setError] = useState<string>();
    const [data, setData] = useState<YearlyData & WarningsData>();
    const [lastUpload, setLastUpload] = useState<number>();
    const [filename, setFilename] = useState<string>();

    // Used for data loading
    const service = useService<StaffDataService>('staffData');

    // Handle dropped files
    const onDrop = useCallback((
        acceptedFiles: File[],
        fileRejections: FileRejection[]
    ) => {
        setData(undefined);
        // Guard against multifiles
        if ((fileRejections.length + acceptedFiles.length) > 1) {
            setError(dropErrorMessages[ErrorCode.TooManyFiles]);
            return;
        }
        if (acceptedFiles.length === 1) {
            setError(undefined);
            setFilename(acceptedFiles[0].name);
            const reader = new FileReader();
            reader.onload = (evt) => { // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target?.result;
                const wb = Xlsx.read(bstr, { type: 'binary' });

                // Check if uploaded data seems correct
                const missingSheets = requiredSheets
                    .some((sheetName) => (!wb.SheetNames.includes(sheetName)));
                if (missingSheets) {
                    setError('Die Datei enthält eine andere Struktur als die Vorlage. Bitte laden Sie eine korrekte Datei!');
                    return;
                }

                // Read the data
                const yearlyData = readApostarDataFile(wb);

                // Check if dates are valid and correct year
                if (!yearlyData.general.A2_LJ || !yearlyData.general.A3_LJ) {
                    setError('Wirtschaftsjahr konnte nicht aus Datei gelesen werden, es kann daher nicht verarbeitet werden.');
                    return;
                } else {
                    // Must be a valid time span within one year
                    const timeSpan = getMonthSpan(yearlyData.general.A2_LJ, yearlyData.general.A3_LJ);
                    if (timeSpan > 12 || timeSpan < 1) {
                        setError('Das Wirtschaftsjahr darf maximal 12 Monate umfassen.');
                        return;
                    }

                    const foundYear = getYearFromEndDate(yearlyData.general.A3_LJ);
                    if (foundYear !== currentYear) {
                        setError(`Das Wirtschaftsjahr stimmt nicht mit der erlaubten Periode für ${currentYear} überein.`);
                        return;
                    }
                }

                // Move data to internal state
                setData(yearlyData);
            };
            reader.readAsBinaryString(acceptedFiles[0]);
        } else {
            setError(fileRejections[0].errors.map(e => dropErrorMessages[e.code as ErrorCode]).join(', '));
        }
    }, [currentYear]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        noClick: true,
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        maxFiles: 1,
    });

    // Handle upload button click
    const handleUpload = () => {
        if (data && data.general.A2_LJ && data.general.A3_LJ) {
            Promise.all([
                service.getSalesInformation(pharmacyId, data.general.A2_LJ, data.general.A3_LJ),
                service.get(pharmacyId, currentYear, data.general.A2_LJ, data.general.A3_LJ)
            ])
                .then(([salesData, staffData]) => {
                    const allStaffRows = [
                        ...data.staff.rows,
                        ...staffData,
                    ];
                    const combinedData = {
                        ...data,
                        general: {
                            ...data.general,
                            A16_LJ: salesData.recipeCount,
                            A18_LJ: salesData.packageCount,
                        },
                        staff: {
                            rows: allStaffRows,
                            ...calculateTotals(allStaffRows),
                        },
                    };

                    onUpload({ ...combinedData, pharmacy: current.pharmacy });
                    setLastUpload(Date.now());
                })
                .catch((e) => {
                    setError(`Bei der Verarbeitung ist leider ein Fehler aufgetreten: ${e.message}`);
                })
                .finally(() => {
                    setData(undefined);
                    setFilename(undefined);
                });
        }
    };

    const classes = useStyles();
    const typeClasses = useTypeStyles();
    return (
        <Card {...getRootProps()}>
            <CardHeader
                title="Upload Excel-Datei"
                action={action}
                titleTypographyProps={{ className: typeClasses.yearlyText }}
            />
            <CardContent>
                <Typography variant="body1">
                    Sie haben die Möglichkeit Excel-Daten in einem fix vorgegebenen Format zu importieren.
                    Wenn Sie die hierfür notwendige Vorlage noch nicht haben, aber diese Funktion
                    verwenden möchten, können Sie sich gerne an die Wirtschaftsabteilung des Apothekerverbands
                    wenden.
                </Typography>
            </CardContent>
            <CardContent>
                <Alert
                    severity={isDragActive ? 'success' : 'info'}
                    icon={<SvgIcon color="inherit"><IconUpload color="inherit" /></SvgIcon>}
                    // @ts-ignore
                    className={classes[`alert${isDragActive ? 'Active' : 'Inactive'}`]}
                >
                    <Typography variant="body1" gutterBottom>
                        Legen Sie eine passende Datei hier ab, oder...
                    </Typography>
                    <input {...getInputProps()} />
                    <Button type="button" onClick={open} variant="outlined" size="small">
                        Datei von Computer auswählen
                    </Button>
                </Alert>
            </CardContent>
            <Divider />
            <CardContent>
                <Typography variant="h5" gutterBottom className={typeClasses.yearlyText}>
                    Ergebnis Prüfung Upload
                </Typography>
                <UploadFileStatus
                    filename={filename}
                    data={data}
                    uploaded={lastUpload}
                    error={error}
                />

                <Box display="flex" flexDirection="row" justifyContent="space-between" pt={4}>
                    <Alert severity="info">
                        Achtung: Bisher erfasste Daten werden überschrieben
                    </Alert>
                    <Button
                        disabled={data === undefined}
                        color="primary"
                        variant="contained"
                        onClick={handleUpload}
                        className={typeClasses.yearlyButton}
                    >
                        Diese Daten übernehmen
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Upload;
