import {
    Alert,
    Skeleton,
} from '@material-ui/lab';
import {
    ArrowDownward,
    ArrowUpward,
    CheckCircle,
    ImportExport,
    WarningRounded,
} from '@material-ui/icons';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    TablePagination,
    Typography,
} from '@material-ui/core';
import ComparisonGroup, { GroupTypeEntity } from '../../Lib/ComparisonGroup';
import React, { useEffect, useMemo, useState } from 'react';
import { getModelValue, getModelValueArray } from '../../Lib/Helper';

import { ComparisonGroupData } from '../../Schemas/ComparisonGroup';
import ComparisonGroupForm from '../ComparisonGroups/Form';
import ComparisonGroupService from '../../Lib/ComparisonGroupService';
import EditDialog from '../Common/Dialogs/EditDialog';
import GroupItem from '../ComparisonGroups/ListItem';
import ItemStatus from '../Common/ItemStatus';
import { KeysOfType } from '../../Lib/Helper';
import LabelValue from '../Common/LabelValue';
import ListLoader from '../Common/Ui/ListLoader';
import { filterDetailed } from '../../Lib/ComparisonGroupFilter';
import useAuth from '../../Hooks/useAuth';
import useChangeEffect from '../../Hooks/useChangeEffect';
import useEditDialog from '../../Hooks/useEditDialog';
import useFilteredComparisonGroups from '../../Hooks/useFilteredComparisonGroups';
import useLoad from '../../Hooks/useLoad';
import useSaveWithNotification from '../../Hooks/useSaveWithNotification';
import useService from '../../Hooks/useService';
import useTypeStyles from '../Common/Ui/useTypeStyles';

type GTKeys = KeysOfType<ComparisonGroup, Nullable<GroupTypeEntity>>;

const comparisonGroupLabels = (group?: ComparisonGroup) =>  {
    if (!group) {
        return '';
    }

    const keys: Array<GTKeys> = [
        'offizin',
        'privateShare',
        'highPriceShare',
        'openingHours',
        'pharmacyCount',
        'population',
        'state',
    ];
    const labels: Array<string> = [
        'Offizinumsatz',
        'Privatanteil',
        'Hochpreisanteil',
        'Öffnungszeiten',
        'Apothekenanzahl',
        'Einwohnerzahl',
        'Bundesland',
    ];

    // Get ui for values
    const values = keys.map<Nullable<JSX.Element>>((dataKey: GTKeys, i) => (
        group[dataKey] ? <LabelValue
            label={labels[i]}
            value={group[dataKey]?.begBezeichnung}
        /> : null
    ));

    // Location values
    group.location.forEach((location) => values.push((
        <LabelValue
            label="Lage"
            value={location?.begBezeichnung}
        />
    )))

    // Add the item status
    values.push((
        <LabelValue
            label="Status"
            value={(
                <>
                    <ItemStatus status={group.active} />
                    {group.active ? 'aktiv' : 'inaktiv'}
                </>
            )}
        />
    ));

    return values;
};

const PAGE_SIZE = 15;

export type ChooseComparisonGroupProps = {
    type: 'monthly' | 'yearly',
};

const ChooseComparisonGroup : React.FC<ChooseComparisonGroupProps> = ({ type }) => {
    // Service and data management
    const { authentication } = useAuth();
    const {
        isLoading: isLoadingCurrent,
        data: current,
        updateData: updateCurrent,
        reload,
        error: currentLoadError,
    } = useLoad<ComparisonGroupService, number, never>(
        'comparisonGroup',
        (service) => (service.getCurrent(authentication?.id ?? 0, type)),
    );

    // When type changes without leaving page we need to reload current group
    useChangeEffect(reload, [type]);

    const {
        data,
        isLoading,
        onFetchData,
        error,
    } = useFilteredComparisonGroups();
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The pagination
    const [page, setPage] = useState(0);

    // Filtering
    const [filter, setFilter] = useState<ComparisonGroupData>({ location: [] });
    const setFilterValues = (model: Partial<ComparisonGroup>) => {
        setPage(0);
        setFilter({
            offizin: getModelValue(model, 'offizin') || undefined,
            privateShare: getModelValue(model, 'privateShare') || undefined,
            highPriceShare: getModelValue(model, 'highPriceShare') || undefined,
            openingHours: getModelValue(model, 'openingHours') || undefined,
            pharmacyCount: getModelValue(model, 'pharmacyCount') || undefined,
            population: getModelValue(model, 'population') || undefined,
            state: getModelValue(model, 'state') || undefined,
            location: getModelValueArray(model as ComparisonGroup, 'location'),
        });
    };

    // Memoize the displayed items derived from data and filter
    const [displayedGroups, displayedGroupsLength] = useMemo(() => {
        if (!data) {
            return [[], 0];
        }

        const toDisplay =  filterDetailed(data, filter);

        return [toDisplay.slice(
            page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE
        ), toDisplay.length];
    }, [data, filter, page]);

    // Memoize the selected group when everything is loaded
    const selectedGroup = useMemo(() => {
        if (!data) {
            return undefined;
        }
        return data.find((item) => (current === item.id));
    }, [data, current]);

    const service = useService<ComparisonGroupService>('comparisonGroup');
    const saveChange = useSaveWithNotification((userId, group) => {
        return service.setCurrent(userId, group, type)
            .then(() => updateCurrent(group.id));
    })

    // Manage dialog state
    const {
        open,
        model,
        getEditDialogProps,
    } = useEditDialog<ComparisonGroup>({
        title: 'Vergleichsgruppe festlegen',
        editTitle: 'Vergleichsgruppe ändern',
        onSave: (data) => {
            saveChange(authentication?.id, data)
                .then(() => console.log('should reload'));
        },
    });

    const classes = useTypeStyles();

    return (
        <>
            <Card className={classes[type]}>
                <CardHeader title="Ihre aktuelle Vergleichsgruppe" />
                <CardContent>
                    {selectedGroup ? (
                        <Grid container spacing={0}>
                            <Grid item md={!selectedGroup?.active ? 8 : 12} xs={12}>
                                <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" gridGap={1}>
                                    {isLoadingCurrent
                                        ? [0,0,0,0].map(
                                            () => <LabelValue label={<Skeleton />} value={<Skeleton />} />
                                        )
                                        : comparisonGroupLabels(selectedGroup)
                                    }
                                </Box>
                            </Grid>
                            {!selectedGroup?.active
                                ? (
                                    <Grid item md={4} xs={12}>
                                        <Alert color="warning" icon={<WarningRounded />}>
                                            Diese Vergleichsgruppe ist inaktiv. Sie bekommen nur Vergleiche
                                            über alle Vergleichsgruppen.
                                        </Alert>
                                    </Grid>
                                )
                                :  ''
                            }
                        </Grid>
                    ) : (
                        currentLoadError
                            ? (
                                <Typography component="p" variant="body1" color="error">
                                    {currentLoadError}
                                </Typography>
                            )
                            : (
                                <Typography component="p" variant="body1">
                                    Bitte wählen Sie aus der unten angeführten Liste die Kriterien Ihrer Vergleichsgruppe aus.
                                </Typography>
                            )
                    )}
                </CardContent>
            </Card>
            <Box mt={3} mb={3}>
                <Grid container spacing={3}>
                    <Grid item md={4}>
                        <Typography component="p" variant="body2" gutterBottom>
                            Bei ApoStar können Sie selbst entscheiden, mit welcher Vergleichsgruppe die
                            Zahlen Ihrer Apotheke in Bezug gesetzt werden. Standardmäßig vergleichen Sie sich
                            mit Apotheken aus Ihrem Bundesland. Eine Änderung der am Auswertungsbericht angeführten
                            Vergleichsgruppe wird wie folgt durchgeführt:
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Typography component="p" variant="body2" gutterBottom>
                            Identifizieren Sie mit nachfolgender Filterfunktion, welche Vergleichsgruppe
                            für Sie relevant ist. z.B. Lage & Umfeld: "Landapotheke" kombiniert mit
                            Wirtschaftliche Größen "Offizinumsatz EUR 1,5 bis 2,5 Mio.". Passende
                            Vergleichgruppen werden Ihnen rechts davon angezeigt.
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Typography component="p" variant="body2" gutterBottom>
                            Wählen Sie eine Vergleichgruppe aus der Liste um diese als aktiv zu setzen.
                        </Typography>
                        <Typography component="p" variant="body2" gutterBottom>
                            Bitte beachten Sie, dass die Änderung der Vergleichsgruppe erst für den nächsten
                            Berichtversand wirkam wird.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Alert color="info">
                        <Typography component="p" variant="body2">
                            Bitte wählen Sie Ihre favorisierte Vergleichsgruppe aus. Bitte beachten Sie
                            aber, dass in der ersten Auswertung mangels ausreichender Teilnehmer die
                            Österreich Gruppe automatisch zugeordnet werden kann. Sobald mindestens
                            10 Teilnehmer in Ihrer Vergleichsgruppe sind, wird Ihnen diese angezeigt.
                        </Typography>
                    </Alert>
                </Grid>
            </Box>
            <Grid container spacing={3}>
                <Grid item md={4} xs>
                    <Card>
                        <CardContent>
                            <Typography
                                component="h2"
                                variant="h5"
                                gutterBottom
                                // @ts-ignore
                                className={classes[`${type}Text`]}
                            >
                                Vergleichsgruppe festlegen
                            </Typography>
                            <ComparisonGroupForm onValuesChange={setFilterValues}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={8}>
                    <Card>
                        <List>
                            {isLoading
                                ? (
                                    <ListLoader rowCount={10} />
                                )
                                : displayedGroups.length > 0
                                    ? displayedGroups.map((group) => (
                                        <React.Fragment key={`group_${group.id}`}>
                                            <ListItem button onClick={() => open(group)} selected={selectedGroup?.id === group.id}>
                                                <GroupItem
                                                    title={group.title}
                                                    active={group.active}
                                                    valid={group.valid}
                                                />
                                                {selectedGroup?.id === group.id ? (
                                                    <ListItemSecondaryAction>
                                                        <CheckCircle color="secondary" fontSize="large" />
                                                        <Typography variant="body2" color="textSecondary">gewählt</Typography>
                                                    </ListItemSecondaryAction>
                                                ) : ''}
                                            </ListItem>
                                            <Divider component="li" variant="middle" />
                                        </React.Fragment>
                                    ))
                                    : (
                                        <CardContent>
                                            {error
                                                ? (
                                                    <Typography variant="body1" color="error" align="center">
                                                        {error}
                                                    </Typography>
                                                )
                                                : (
                                                    <Typography variant="body1" color="textSecondary" align="center">
                                                        keine passenden Vergleichsgruppen gefunden
                                                    </Typography>
                                                )
                                            }
                                        </CardContent>
                                    )
                            }
                        </List>
                        <TablePagination
                            component="div"
                            count={displayedGroupsLength}
                            page={page}
                            onPageChange={(e, p) => setPage(p)}
                            rowsPerPage={PAGE_SIZE}
                            rowsPerPageOptions={[]}
                        />
                    </Card>
                </Grid>
            </Grid>
            <EditDialog {...getEditDialogProps()}>
                <Typography component="p" variant="body1" color="textSecondary" gutterBottom>
                    Wollen Sie die Vergleichsgruppe festlegen und die vorherige verwerfen?
                </Typography>
                <Paper variant="outlined">
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: 'green' }}>
                                <ArrowUpward color="inherit" fontSize="large" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={model?.title} />
                    </ListItem>
                </Paper>
                <Box textAlign="center" margin={2} color="text.secondary">
                    <ImportExport color="inherit" fontSize="large" />
                </Box>
                <Paper variant="outlined">
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: 'red' }}>
                                <ArrowDownward color="inherit" fontSize="large" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={selectedGroup?.title} />
                    </ListItem>
                </Paper>
            </EditDialog>
        </>
    );
};

export default ChooseComparisonGroup;
