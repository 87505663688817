import {
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    StepIcon,
    Tooltip,
    Typography,
    makeStyles,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';

import NumberFormat from 'react-number-format';
import TrendIndicator from './TrendIndicator';
import { formatNumber } from '../../Lib/Helper';
import useChangeEffect from '../../Hooks/useChangeEffect';

const useTooltipStyles = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.primary.main,
    },
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: 2,
    },
    icon: {
        padding: 0,
    },
    svg: {
        width: 20,
        height: 20,
    },
}));

// Fields with allowed negative input
const NEGATIVE_ENABLED_FIELDS: Record<string, string> = {
    'E9_LJ': 'Negative Werte bitte mit einem Minus erfassen',
    'E10_LJ': 'Negative Werte bitte mit einem Minus erfassen',
    'E27_LJ': 'Finanzerträge sind mit einem Minus zu erfassen',
    'E28_LJ': 'Negative Werte bitte mit einem Minus erfassen',
    'E30_LJ': 'Negative Werte bitte mit einem Minus erfassen',
    'B7_LJ': 'Negative Werte bitte mit einem Minus erfassen',
};

/**
 * Properties for component
 */
export type CompareableNumberInputProps = {
    /**
     * Field id, optional only passed with change handler
     */
    id?: string,

    /**
     * Label of the field
     */
    label: string,

    /**
     * Optional tooltip help text to show after the label
     */
    tooltip?: string,

    /**
     * Compare with a value
     */
    compareValue?: number,

    /**
     * Initial value
     */
    initialValue?: number,

    /**
     * Use live value to update internal value, must be set to same as initialValue to be valid on
     * first render
     */
    liveValue?: number,

    /**
     * Change handler
     */
    onChange?: (value?: number, id?: string) => void,

    /**
     * Focused handler
     */
    onFocused?: (event: React.FocusEvent<HTMLElement>) => void,

    /**
     * Type of number input
     */
    type?: 'money' | 'm2' | 'pcs' | 'number',

    /**
     * Trend calculation type, percentage, absolute or none at all
     */
    trend?: 'pct' | 'abs' | 'none',
};

// Constant adornment
const eurAdornment = <InputAdornment position="start">EUR</InputAdornment>;
const adornmentLabels = { pcs: 'Stk', m2: 'm2', number: '' };

/**
 * Number input with a comparison and derived trend to a previous value
 */
const CompareableNumberInput: React.FC<CompareableNumberInputProps> = ({
    id,
    label,
    initialValue,
    compareValue,
    onChange,
    type = 'money',
    trend = 'pct',
    liveValue,
    tooltip,
    onFocused = (event: React.FocusEvent<HTMLElement>) => {
        if (
            event.relatedTarget
            && event.target.getBoundingClientRect().top > (window.innerHeight - 100)
        ) {
            window.scrollBy(0, 80);
        }
    },
}) => {
    // Value state reference
    const [value, setValue] = useState(initialValue !== undefined ? initialValue.toString() : '');

    // Derived from value, we have a numeric representation and the change compared to given compare
    // value.
    const numeralValue = useMemo(() => (value.length > 0 ? parseFloat(value) : undefined), [value]);
    const change = useMemo(() => {
        return (numeralValue !== undefined) && (compareValue !== undefined)
            ? (trend === 'pct'
                ? (((numeralValue / compareValue) || 1) - 1) * 100
                : numeralValue - compareValue)
            : 0;
    }, [numeralValue, compareValue, trend]);

    // Notify change
    useChangeEffect(() => onChange?.(numeralValue, id), [numeralValue]);
    useChangeEffect(() => setValue(liveValue?.toString() ?? ''), [liveValue]);
    const hasLiveValue = useMemo(() => (liveValue === initialValue), [liveValue, initialValue]);

    // Use adornments depending on input type
    const adornmentProps = type === 'money' ? {
        startAdornment: eurAdornment,
    } : {
        endAdornment: <InputAdornment position="end">{adornmentLabels[type]}</InputAdornment>,
    };

    // Trend indication
    const trendIndicator = trend !== 'none' && numeralValue !== undefined && compareValue !== undefined
        ? (
            <TrendIndicator
                value={change}
                unit={trend === 'pct' ? '%' : ''}
            />
        ) : null;

    const tooltipClasses = useTooltipStyles();
    const tooltipText = NEGATIVE_ENABLED_FIELDS[(id || '')]
        ? NEGATIVE_ENABLED_FIELDS[(id || '')] : tooltip;

    return (
        <FormGroup style={{ marginBottom: '2em' }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4}>
                    <InputLabel style={{ color: 'black' }}>
                        {label}{' '}
                        {tooltipText && (
                            <Tooltip title={tooltipText} placement="bottom" arrow classes={tooltipClasses}>
                                <IconButton disableFocusRipple disableRipple className={tooltipClasses.icon}>
                                    <StepIcon icon="?" active classes={{ root: tooltipClasses.svg }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </InputLabel>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <NumberFormat
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={0}
                        isAllowed={({ floatValue }) => (floatValue || 0) <= 999999999}
                        allowNegative={!!NEGATIVE_ENABLED_FIELDS[(id || '')]}
                        onValueChange={
                            ({ floatValue }) => setValue(floatValue?.toString() ?? '')
                        }
                        defaultValue={initialValue}
                        inputProps={{ style: { textAlign: 'right' }}}
                        onFocus={onFocused}
                        placeholder=""
                        fullWidth
                        {...adornmentProps}
                        {...(hasLiveValue ? { value } : {})}
                        customInput={OutlinedInput}
                    />
                </Grid>
                <Grid item xs={3} sm={2} style={{ textAlign: 'right' }}>
                    <Typography color="textSecondary">
                        {formatNumber(compareValue)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={2} style={{ textAlign: 'right' }}>
                    {trendIndicator}
                </Grid>
            </Grid>
        </FormGroup>
    );
};

export default CompareableNumberInput;
