import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

import React from 'react';

type InfoboxProps = {
    name: string,
    classes: { cardStretch: string, cardEmpty: string, innerCardHeader: string, newsMeta: string },
};

const Infobox = ({
    name,
    classes,
}: InfoboxProps) => {
    const hours = new Date().getHours();
    const greeting = hours <= 11
        ? 'Guten Morgen'
        : hours <= 17
            ? 'Guten Tag'
            : 'Guten Abend';

    return (
        <Card className={[classes.cardStretch/*, classes.cardEmpty*/].join(' ')}>
            <CardContent>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.innerCardHeader}
                >
                    {greeting},
                    <br />
                    {name}
                </Typography>
                <Typography variant="body1" component="p">
                    Auf ApoStar kann ab sofort sowohl der monatliche ApoStar Tara Report als auch der jährliche ApoStar Bilanz Vergleich anhand Ihrer Wirtschaftsdaten erstellt werden. Sie können somit die Daten Ihrer Apotheke mit denen von anderen Apotheken vergleichen. Diese Erkenntnisse können wertvolle Informationen für Ihre betriebswirtschaftlichen Entscheidungen darstellen.
                </Typography>
                <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                    className={classes.newsMeta}
                >
                    26.06.2024 | Team Apostar
                </Typography>
            </CardContent>
        </Card>
    );
};

export default Infobox;
