import Bugsnag from '@bugsnag/js';
import ErrorResponseMapper from './ErrorResponseMapper';
import { JTDParser } from 'ajv/dist/jtd';
import { getDefaultCredentialStore } from './CredentialStore';

// Conditional base url
export const BASE_URL = process.env.REACT_APP_BASE_URL || (process.env.NODE_ENV === 'production'
    ? 'https://www.apostar.at:7533/'
    : 'http://localhost:3000/api/');

/**
 * Options for fetch.
 */
export interface JSONFetchOptions<T, U extends string | {}> {
    url: string,
    method?: 'get' | 'post' | 'put' | 'delete',
    body?: U,
    responseParser?: JTDParser<T>,
    bodySerializer: U extends undefined ? never : (data: U) => string,
};

/**
 * Error response mapper to check for custom server error reponses including speaking error messages
 */
const errorResponseMapper = new ErrorResponseMapper();
export class CustomResponseError extends Error {
    isCustomResponseError = true;
};

/**
 * Custom fetch client. Works with throwing errors instead of resolving with invalid status codes
 * for invalid http status codes in repsonse. Also takes care of parsing data received from the
 * server, with a potential custom parser and serializer.
 */
export default function JSONFetch<T,U>({
    url,
    method = 'get',
    body,
    responseParser,
    bodySerializer,
}: JSONFetchOptions<T, U>): Promise<T> {
    const credentialStore = getDefaultCredentialStore();
    return (credentialStore ? credentialStore.getRequestHeaders() : Promise.resolve({}))
        .then((authHeaders) => {
            const fetchHeaders = new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...authHeaders
            });
            let bodyOptions = body ? { method, body: bodySerializer(body) } : { method };

            // For testing
            if (process.env.NODE_ENV === 'development' && method !== 'get') {
                console.log("[DEV] overwrite body", bodyOptions);
                bodyOptions = { method: 'get' };
            }

            const fetchOptions = {
                headers: fetchHeaders,
                ...bodyOptions
            };
            return fetch(`${BASE_URL}${url}`, fetchOptions);
        })
        .then((fetchResponse) => {
            // In case an error status code is returned
            if (fetchResponse.status >= 400) {
                return fetchResponse.text()
                    .then(errorResponseMapper.parse)
                    .then(errorResponse => {
                        throw new CustomResponseError(`Fehler: ${errorResponse.Fehlermessage} (Fehlercode: ${errorResponse.Fehlercode})`);
                    })
                    .catch((e) => {
                        if (e instanceof CustomResponseError) {
                            throw e;
                        }
                        throw new Error(`Error fetching ${url}. Server responded with a ${fetchResponse.status} code.`);
                    });

            }

            // A 'no-content' response was received return an empty response
            if (fetchResponse.status === 204) {
                return '';
            }

            // Parsing function for data received, could be a promise itself
            if(responseParser) {
                return fetchResponse.text()
                    .then((raw) => {
                        if (raw.includes('Fehlercode')) {
                            const customError = errorResponseMapper.parse(raw);
                            throw new CustomResponseError(`Fehler: ${customError.Fehlermessage} (Fehlercode: ${customError.Fehlercode})`);
                        }
                        return responseParser(raw);
                    })
                    .then((parsed) => {
                        if(!parsed) {
                            throw new Error(`Parsing response from server failed, ${responseParser.message} at ${responseParser.position}`);
                        }
                        return parsed;
                    });
            }
            return fetchResponse.json().catch((e) => {
                if (process.env.NODE_ENV !== 'development') throw e;
            });
        })
        .catch((e) => {
            Bugsnag.notify(e);
            throw e;
        });
}
