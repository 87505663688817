import { useEffect, useMemo, useRef } from 'react';

import { useFormikContext } from 'formik';

/**
 * Effect to touch all fields automatically if an error occurs on them. Typically used because
 * submitting a formik form without touching all fields results in error messages not displayed.
 * This is the way formik works. By using this hook, you can auto touch them when the validation is
 * run.
 */
export const useFormikTouchErrors = () => {
    /**
     * Use formik context required.
     */
    const formik = useFormikContext();
    const formikErrorKeys = useMemo(() => Object.keys(formik.errors), [formik.errors]);
    const submitCountRef = useRef(0);

    /**
     * Use an effect when errors change and set touched accordingly. Be aware, only works for flat
     * data.
     */
    useEffect(() => {
        /**
         * Guard this against the submit count. So it only runs once after submission. This prevents
         * displaying all error messages when one field is blurred and validations run and the form
         * is not yet submitted.
         */
        if (submitCountRef.current < formik.submitCount && Object.keys(formik.errors).length > 0) {
            submitCountRef.current = formik.submitCount;
            formik.setTouched({
                ...formik.touched,
                ...Object.fromEntries(
                    formikErrorKeys.map((fieldName) => ([fieldName, true])),
                ),
            }, false);
        }
        /**
         * We deliberately ignore formik.touched and formik.setTouched, both are only utitlities in
         * this case and we explicitly do not want the effect to run when those change. Especially
         * formik.touched would result in an infinate loop bacause we are setting touched inside
         * this hook.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.submitCount, formikErrorKeys]);
};

export default useFormikTouchErrors;
