import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type ReportApiEntity = {
    arbApoKennung: string,
    arbTyp1: 'JABV' | 'MABV' | 'ABV' | 'ATR' | 'ASR',
    arbTyp2: string,
    arbTyp3: string,
    arbZeitraum: string,
    arbBenchKombi: string,
    arbPfad: string,
    arbDatum: string,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<ReportApiEntity> = {
    properties: {
        arbApoKennung: { type: 'string' },
        arbTyp1: { enum: ['JABV', 'MABV', 'ABV', 'ATR', 'ASR'] },
        arbTyp2: { type: 'string' },
        arbTyp3: { type: 'string' },
        arbZeitraum: { type: 'string' },
        arbBenchKombi: { type: 'string' },
        arbPfad: { type: 'string' },
        arbDatum: { type: 'string' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const ReportArray: JTDSchemaType<Array<ReportApiEntity>> = {
    elements: schema,
};
