import Report, { PeriodType } from '../Lib/Report';
import reportSchema, { ReportApiEntity } from '../Schemas/Api/Report';

import SchemaMapper from './SchemaMapper';

const periodTypes: Record<ReportApiEntity['arbTyp1'], PeriodType> = {
    MABV: 'monthly',
    ABV: 'yearly',
    ATR: 'monthly',
    JABV: 'yearly',
    ASR: 'all'
} as const;

export default class ComparisonGroupMapper
    extends SchemaMapper<Report, ReportApiEntity> {
    schema = reportSchema;

    mapToModel = (data: ReportApiEntity) => {
        return new Report(
            data.arbPfad,
            new Date(Date.parse(data.arbDatum)),
            periodTypes[data.arbTyp1],
            data.arbTyp2 === 'Privat' ? 'pharmacy' : 'general',
        );
    };

    mapToData = (model: Report) => {
        throw new Error('Report cannot be serialized');
    };
}
