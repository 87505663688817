import {
    ArithmeticExpression,
    Error,
} from './YearlyDataValidation';

/**
 * Filter error messages from fields
 */
export function getErrorsForField(field: string, errors: Array<Error>): Array<Error> {
    return errors.filter((error) => (
        error.affectedFields.includes(field)
    ));
}

/**
 * Filter error messages for a group
 */
export function getErrorsForGroup(group: string, errors: Array<Error>): Array<Error> {
    return errors.filter((error) => (
        error.affectedFields.findIndex((field) => (field.startsWith(`${group}.`))) !== -1
    ));
}

/**
 * Filter an array for unique values
 */
function unique<T,>(arrayWithDuplicates: Array<T>): Array<T> {
    return arrayWithDuplicates.filter((n, i) => arrayWithDuplicates.indexOf(n) === i);
};

/**
 * Helper type for resolved expression type strings
 */
type ExpressionTypeString = `${string}.${string}` | `compare.${string}.${string}`;

/**
 * Get all field identifiers from an artihmetic expression
 */
export default function getFieldsFromExpression(expression: ArithmeticExpression): Array<ExpressionTypeString> {
    // Mathematical number, no field
    if (typeof expression === 'number' || expression === null) {
        return [];
    }

    // Identifier
    if (typeof expression === 'string') {
        return [expression];
    }

    // Get all fields from left hand and right hand side
    return unique([
        ...getFieldsFromExpression(expression[0]),
        ...getFieldsFromExpression(expression[2]),
    ]);
}

/**
 * Get all field identifiers from an artihmetic expression
 */
export function getFieldsFromExpressionResolved(
    expression: ArithmeticExpression,
    intermediates: Record<string, ArithmeticExpression>,
): Array<string> {
    const baseFields = getFieldsFromExpression(expression);
    const remainingBaseFields = baseFields.filter((field) => !field.startsWith('intermediates.'));
    const intermediateFields = baseFields.filter((field) => field.startsWith('intermediates.'));

    if (intermediateFields.length === 0) {
        return baseFields;
    }

    // Resolve the intermediates
    const intermediatesResolved = intermediateFields.reduce<Array<string>>((memo, field) => {
        const intermediateType = field.replace('intermediates.', '');
        return [
            ...memo,
            ...getFieldsFromExpressionResolved(
                intermediates[intermediateType],
                intermediates,
            ),
        ];
    }, []);

    return unique([
        ...remainingBaseFields,
        ...intermediatesResolved,
    ]);
}
