import { CircularProgress, Container, Typography } from '@material-ui/core';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import ServiceProvider from '../Lib/ServiceProvider';
import { getDefaultCredentialStore } from '../Lib/CredentialStore';
import useAuth from '../Hooks/useAuth';

export type Credentials = {
    username: string,
    password: string,
};

export const DataProviderContext = React.createContext({
    getService: (service: string) => { },
    getCache: () => { },
});

export default function DataProvider({ children }: PropsWithChildren<{}>) {
    // If the provider is ready or lodaing
    const [ready, setReady] = useState<boolean>(false);

    // The servides currently loaded
    const [services, _setErrorBoundaryHacky] = useState<ServiceProvider>(new ServiceProvider());

    // Authentication given or not
    const { authentication, signIn } = useAuth();

    // Services, load dependencies while showing a loading indicator as well a potential authenticated
    // use
    useEffect(() => {
        services.initialize()
            .loadAllRequiredDependencies()
            .then(() => getDefaultCredentialStore()?.getCredentials())
            .then((credentials: undefined | [string, string]) => {
                if (!credentials) {
                    return null;
                }
                return signIn({ username: credentials[0], password: credentials[1] });
            })
            .catch((initError) => {
                /**
                 * We use state setter to pass async/event handler to global error boundary, because
                 * we cannot continue without having all dependency data loaded here.
                 * {@see https://medium.com/@danhuang1202/catch-error-from-event-handler-in-react-error-boundary-f36ec58786af}
                 */
                _setErrorBoundaryHacky(() => {
                    throw initError;
                });
            })
            .finally(() => setReady(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Create the context for this provider
    const contextValue = {
        getService: (name: string) => services!.get(name),
        getCache: () => services!.cache,
    };

    // Content is either initalizing or the default children
    let content;
    if ((authentication || services) && !ready) {
        content = (
            <Container style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <CircularProgress />
                <Typography variant="h6">
                    Initializing app...
                </Typography>
            </Container>
        );
    } else {
        content = children;
    }

    return (
        <DataProviderContext.Provider value={contextValue}>
            {content}
        </DataProviderContext.Provider>
    );
}
