import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.gross_yield_LJ', '/', 'intermediates.total_output_LJ'],
        { type: 'bounds', ...params },
        'Aufgrund der Aufwandsstruktur erscheint eine Einkaufsgemeinschaft nicht plausibel. Bitte prüfen Sie Ihre Eingabe ob Sie als Träger einer Einkaufsgemeinschaft fungieren!',
        ['general.A6_LJ', true],
        'GuV_10',
    )
);

export default rule;
