import {
    IntermediateDefintions
} from './YearlyDataValidation';

/* eslint-disable indent */
const intermediateCalculations: IntermediateDefintions = {
    // Calculate all assets
    assets_LJ: [
        'balance.B1_LJ', '+', [
            'balance.B3_LJ', '+', [
                'balance.B4_LJ', '+', [
                    'balance.B5_LJ', '+',
                    'balance.B6_LJ']]]],

    // Calculate all liabilities
    liabilities_LJ: [
        'balance.B7_LJ', '+', [
            'balance.B8_LJ', '+', [
                'balance.B9_LJ', '+', [
                    'balance.B10_LJ', '+', [
                        'balance.B11_LJ', '+',
                        'balance.B12_LJ']]]]],

    // Operating expenses
    operating_expenses_LJ: [
        'income.E13_LJ',
        '+', ['income.E14_LJ',
        '+', ['income.E15_LJ',
        '+', ['income.E16_LJ',
        '+', ['income.E17_LJ',
        '+', ['income.E18_LJ',
        '+', ['income.E19_LJ',
        '+', ['income.E26_LJ',
        '+', ['income.E20_LJ',
        '+', ['income.E21_LJ',
        '+', ['income.E22_LJ',
        '+', ['income.E23_LJ',
        '+', ['income.E24_LJ',
        '+', ['income.E25_LJ',
        '+', 'income.E27_LJ']]]]]]]]]]]]],
    ],

    total_output_LJ: [
        'income.E1_LJ',
        '+', ['income.E2_LJ',
        '+', ['income.E4_LJ',
        '+', ['income.E5_LJ',
        '+', 'income.E50_LJ']]]
    ],

    offizin_revenue_LJ: [
        'income.E1_LJ', '+', 'income.E2_LJ',
    ],

    gross_yield_LJ: [
        'intermediates.total_output_LJ', '-', 'income.E6_LJ'
    ],

    staff_cost_LJ: [
        'income.E7_LJ',
        '+', ['income.E8_LJ',
        '+', ['income.E9_LJ',
        '+', ['income.E10_LJ',
        '+', 'income.E11_LJ']]],
    ],

    // Yearly profit
    yearly_profit_LJ: [
        'intermediates.total_output_LJ',
        '-', ['intermediates.staff_cost_LJ',
        '+', ['intermediates.operating_expenses_LJ',
        '+', 'income.E6_LJ']],
    ],

    // After annuity payment
    yearly_profit_after_annuity_LJ: [
        'intermediates.yearly_profit_LJ', '-', 'income.E29_LJ'
    ],

    cf_LJ: [
        'intermediates.yearly_profit_LJ',
        '+', ['income.E18_LJ',
        '+', 'income.E19_LJ'],
    ],
};
/* eslint-enable indent */

export default intermediateCalculations;
