import './App.css';

import Layout from './Layout/Layout';
import React from 'react';
import Routes from './Routes';

function App() {
    return (
        <Layout>
            <Routes />
        </Layout>
    );
}

export default App;
