import {
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core';

import { MoreHoriz } from '@material-ui/icons';
import React from 'react';

type UserMenuProps = {
    onEdit: () => void,
    onActiveChange: (active: boolean) => void,
    active: boolean,
    canChangeActive?: boolean,
};

const UserMenu: React.FC<UserMenuProps> = ({ active, onEdit, onActiveChange, canChangeActive = true }) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (cb: () => void) => {
        setAnchorEl(null);
        cb();
    };
    const activeLabel = active ? 'deaktivieren' : 'aktivieren';

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                classes={{ root: 'btn-unstyled' }}
            >
                <MoreHoriz />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem disabled={!canChangeActive} onClick={() => handleClose(() => onActiveChange(!active))}>{activeLabel}</MenuItem>
                <MenuItem onClick={() => handleClose(onEdit)}>bearbeiten</MenuItem>
            </Menu>
        </div>
    );
}

export default UserMenu;
