import { GroupTypeEntity } from './ComparisonGroup';
import RawPharmacy from './RawPharmacy';

export default class Pharmacy {
    id: Nullable<number> = null;

    number: string = '';

    title: string = '';

    foundingYear: Nullable<number>;

    openingHours: Nullable<GroupTypeEntity> = null;

    pharmacyCount: Nullable<GroupTypeEntity> = null;

    population: Nullable<GroupTypeEntity> = null;

    software: Nullable<GroupTypeEntity> = null;

    state: Nullable<GroupTypeEntity> = null;

    location: Array<GroupTypeEntity> = [];

    active: boolean = false;

    monthlySubscribed: boolean = false;

    transferGK: boolean = false;

    branch: boolean = false;

    mainPharmacyId: Nullable<number> = null;

    dateOfBalance: Nullable<Date> = null;

    constructor(
        number: string,
        title: string,
        foundingYear: Nullable<number> = null,
        openingHours: Nullable<GroupTypeEntity> = null,
        pharmacyCount: Nullable<GroupTypeEntity> = null,
        population: Nullable<GroupTypeEntity> = null,
        state: Nullable<GroupTypeEntity> = null,
        software: Nullable<GroupTypeEntity> = null,
        location: Array<GroupTypeEntity> = [],
        active: boolean = true,
        monthlySubscribed: boolean = false,
        transferGK: boolean = false,
        dateOfBalance: Nullable<Date> = null,
        id: Nullable<number> = null,
    ) {
        this.number = number;
        this.title = title;
        this.foundingYear = foundingYear;
        this.openingHours = openingHours;
        this.pharmacyCount = pharmacyCount;
        this.population = population;
        this.state = state;
        this.software = software;
        this.location = location;
        this.active = active;
        this.monthlySubscribed = monthlySubscribed;
        this.transferGK = transferGK;
        this.dateOfBalance = dateOfBalance;
        this.id = id;
    }

    setIsBranchOf(id: number) {
        this.branch = true;
        this.mainPharmacyId = id;
    }

    getRaw(): RawPharmacy {
        return new RawPharmacy(
            this.number,
            this.title,
            this.foundingYear,
            this.openingHours?.begID,
            this.pharmacyCount?.begID,
            this.population?.begID,
            this.state?.begID,
            this.software?.begID,
            this.location.map(({ begID}) => (begID)),
            this.active,
            this.monthlySubscribed,
            this.transferGK,
            this.dateOfBalance?.toISOString(),
            this.id,
        );
    }
}
