import { Button, Typography } from '@material-ui/core';

import ActivateMonthly from './ActivateMonthly';
import NarrowContainer from '../Common/Ui/NarrowContainer';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { hasRole } from '../../Lib/Roles';
import useAuth from '../../Hooks/useAuth';
import useCachedData from '../../Hooks/useCachedData';

const Terms = () => {
    const { authentication, signOut } = useAuth();
    const { role } = useCachedData(['role']);

    if (!authentication || authentication.pharmacy.transferGK) {
        return (
            <Redirect to="/" />
        );
    }

    if (!hasRole(authentication, 'Hauptbenutzer', role)) {
        return (
            <NarrowContainer>
                <Typography variant="h3" gutterBottom>
                    Das Portal für diese Apotheke ist derzeit gesperrt
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die neuen Nutzungsbedingungen für das neue Apostar Portal müssen erst durch
                    den Hauptbenutzer dieser Apotheke akzeptiert werden.
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Bitte wenden Sie sich an Ihren Apothekenleiter.
                </Typography>
                <Button onClick={signOut} variant="contained" color="primary">
                    Portal verlassen
                </Button>
            </NarrowContainer>
        );
    }

    return (
        <ActivateMonthly
            type="transferGK"
            onClose={(unsuccessful) => {
                if (unsuccessful) {
                    signOut();
                }
            }}
            open={true}
        />
    );
};

export default Terms;
