import { YearlyData } from './YearlyData';
import getFieldsFromExpression from './ValidationHelpers';
import { getIn } from 'formik';

/**
 * Interface for validation errors
 */
export interface Error {
    message: string,
    affectedFields: Array<string>,
    emphasized?: boolean,
};

/**
 * Interface for validation errors
 */
export interface ErrorMessageOnly {
    message: string,
};

/**
 * Intermediate definitions to specify named calculations as intermediate values
 */
export interface IntermediateDefintions extends Readonly<Record<string, ArithmeticExpression>> {};

/**
 * Validation data interface
 */
export interface YearlyValidationData<IntermediateDataCalculations extends IntermediateDefintions>
    extends YearlyData {
    // Intermediates to calculate
    intermediates: Record<keyof IntermediateDataCalculations, number | undefined>,

    /* The data to compare with */
    compare?: YearlyData & { intermediates: IntermediateDataCalculations },
};

/**
 * Definitions of arithmetic operations and comparisons
 */
export type ArithmeticOperator = '+' | '-' | '/' | '*';
export type ComparationOperator = '<' | '<=' | '>' | '>=' | '=' | '!';

/**
 * Arithmetic expression type, any expression that we can arithmetically evaluate
 */
export type ArithmeticExpression =
    `${string}.${string}` |
    `compare.${string}.${string}` |
    number |
    [
        ArithmeticExpression,
        ArithmeticOperator,
        ArithmeticExpression,
    ];

/**
 * A rule parameter is a definition of bounds, that will be used in validations
 */
export type RuleParamater = {
    /**
     * Normal bounds to trigger a warning
     */
    bounds: { low: number, high: number },
    /**
     * A critical error to trigger critical
     */
    boundsCritical?: { low: number, high: number },
};

/**
 * These are available rules as array
 */
export const ruleParameters = [
    'legalSocialExpense',
    'salesRelationLastYear',
    'privateSalesRelationLastYear',
    'privateSalesRelationOfficin',
    'insuranceSalesRelationOfficin',
    'insuranceSalesRelationLastYear',
    'workingAreasRelationLastYear',
    'officeAreasRelationLastYear',
    'salesBuyingSyndicate',
    'rentalExpense',
    'tenureExpense',
    'materialExpenseRelationLastYear',
    'materialExpenseRelationSales',
    'staffExpenseRelationSales',
    'staffExpenseRelationLastYear',
    'expensesRelationSales',
    'profitRelationSales',
    'profitRelationLastYear',
    'profit',
    'tenureExpenseRelationSales',
    'tenureExpenseWithoutTenure',
    'rentalExpenseOwnership',
    'stockRelationLastYear',
    'balanceEquality',
    'customerCountRelationLastYear',
    'prescriptionCountRelationLastYear',
    'privatePackageSalesRelationLastYear',
    'insurancePackageSalesRelationLastYear',
    'quotaConcessionaire',
    'familiaryTotalQuota',
    'apirantTotalQuota',
    'staffExpenseRelationProfit',
    'salaryDirector',
    'salaryPharmacist',
    'salaryAspirant',
    'salaryMerchant',
    'salaryTrainee',
    'salaryEmployeeSkilled',
    'salaryEmployeeUnskilled',
    'salaryCleaner',
    'salaryOtherEmployee',
] as const;

/**
 * Rule parameter definitions type
 */
export type RuleParamaters = Record<typeof ruleParameters[number], RuleParamater>;

/**
 * Types of validations we can perform. Currently: within bounds or arithmetic comparison
 */
export type ValidationMatch = {
    type: 'bounds',
    bounds: { low: number, high: number },
    boundsCritical?: { low: number, high: number },
} | {
    type: 'compare',
    compare: ComparationOperator,
    value: ArithmeticExpression,
};

/**
 * Data evaluator.
 */
export class DataEvaluator<Intermediates extends IntermediateDefintions> {
    /**
     * Data given
     */
    data: YearlyValidationData<Intermediates>;

    constructor(data: YearlyData, compareData: YearlyData | undefined, intermediates: Intermediates) {
        this.data = {
            ...data,
            // We implicitly ignore empty object here, it gets correctly populated in calcu
            // @ts-ignore
            intermediates: {},
        };

        this.calculateIntermediates(intermediates);

        // Last year calculations are done with separate instance of self
        if (compareData) {
            const compare = new DataEvaluator<Intermediates>(compareData, undefined, intermediates);
            // @ts-ignore
            this.data.compare = compare.data;
        }
    }

    /**
     * Calculation of intermediate values
     */
    calculateIntermediates(definitions: Intermediates) {
        // Calculate and store every intermediate
        const definedIntermediates = Object.keys(definitions);
        definedIntermediates.forEach((key) => {
            // @ts-ignore
            this.data.intermediates[key] = this.result(definitions[key]);
        });
    }

    /**
     * Just get a raw value
     */
    raw(key: string): any {
        return getIn(this.data, key);
    }

    /**
     * Get the result for an arithmetic expression, undefined if not possible because not all
     * required values are given
     */
    result(calculation: ArithmeticExpression): number | undefined {
        // Simple number evaluation
        if (typeof calculation === 'number' || calculation === null) {
            return calculation;
        }

        // String resolves to a value from the data
        if (typeof calculation === 'string') {
            const value = getIn(this.data, calculation);
            return value;
        }

        // Unpack the operation
        const [leftSide, operator, rightSide] = calculation;
        const leftResult = this.result(leftSide);
        const rightResult = this.result(rightSide);

        // A mathematical operation is possible when both expressions can be evaluated
        if (undefined !== leftResult && undefined !== rightResult) {
            if (operator === '+') {
                return leftResult + rightResult;
            }
            if (operator === '-') {
                return leftResult - rightResult;
            }
            if (operator === '/') {
                return leftResult / rightResult;
            }
            if (operator === '*') {
                return leftResult * rightResult;
            }
        }

        return undefined;
    }
}

/**
 * A validation rule validates data, based on an "arithmetic expression" (in array notation), and
 * matches it with certain criteria.
 */
export interface ValidationRule {
    // The underlying calculation
    calculation: ArithmeticExpression;

    // The match that is required
    match: ValidationMatch;

    // The error to be reported
    error: ErrorMessageOnly;

    // The result
    validatedResult?: number;

    // If this validation is internally as errorneous (critical error)
    validatedFlagged: boolean;

    // Id to identify this rule later
    id: string;

    /**
     * Validate from an evaluator
     */
    validate(evaluator: DataEvaluator<any>): boolean;
}

type CustomErrorType = { high: string, low: string } | ((result: number, match: ValidationMatch, evaluator: DataEvaluator<any>) => string);

/**
 * Common validation abstraction
 */
export abstract class Validation implements ValidationRule {
    abstract calculation: ArithmeticExpression;
    abstract match: ValidationMatch;
    abstract error: ErrorMessageOnly;
    customError?: CustomErrorType = undefined;
    dependency?: [string, any, ComparationOperator?] = undefined;
    validatedResult?: number;
    validatedFlagged: boolean = false;
    id: string = '';

    // Comparation
    compare(operator: ComparationOperator, a: number, b: number) {
        return (operator !== '=' || a === b)
            && (operator !== '<' || a < b)
            && (operator !== '<=' || a <= b)
            && (operator !== '>' || a > b)
            && (operator !== '>=' || a >= b)
            && (operator !== '!' || a !== b);
    }

    /**
     * Validation function evaluation expression and matches it
     */
    validate(evaluator: DataEvaluator<any>): boolean {
        // Reset any prior values
        this.validatedFlagged = false;
        this.validatedResult = undefined;

        // Check if a dependency is given and check that dependency
        if (this.dependency) {
            const precheck = evaluator.raw(this.dependency[0]);
            if (
                undefined === precheck
                || (this.dependency.length === 2 && precheck !== this.dependency[1])
                // @ts-ignore
                || (this.dependency.length === 3 && !this.compare(this.dependency[2], precheck, this.dependency[1]))
            ) {
                return true;
            }
        }

        // Calculate the arithmetic result, if evaluation is not possible validation passes
        const calcResult = evaluator.result(this.calculation);
        this.validatedResult = calcResult;
        if (calcResult === undefined || isNaN(calcResult) || !isFinite(calcResult)) {
            // Ensure undefined also for NaN
            this.validatedResult = undefined;
            return true;
        }

        // Bounds
        if (this.match.type === 'bounds') {
            const { bounds, boundsCritical } = this.match;

            // Bounds invalid must save a flag that this rule is invalid
            if (boundsCritical) {
                if (!((undefined === boundsCritical.low || calcResult >= boundsCritical.low)
                    && (undefined === boundsCritical.high || calcResult <= boundsCritical.high))
                ) {
                    this.validatedFlagged = true;
                };
            }

            // Adjust saved error if error message is fluid
            if (this.customError) {
                if (typeof this.customError === 'function') {
                    this.error.message = this.customError(calcResult, this.match, evaluator);
                } else if (undefined !== bounds.low && calcResult < bounds.low) {
                    this.error.message = this.customError.low;
                } else if (undefined !== bounds.high && calcResult > bounds.low) {
                    this.error.message = this.customError.high;
                }
            }

            return (undefined === bounds.low || calcResult >= bounds.low)
                && (undefined === bounds.high || calcResult <= bounds.high);
        }

        // We compare with a result, could be undefined which means pass, see above
        const { compare, value } = this.match;
        const matchResult = evaluator.result(value);
        if (matchResult === undefined) {
            return true;
        }

        // Get the result
        const validationResult = (compare !== '=' || calcResult === matchResult)
            && (compare !== '<' || calcResult < matchResult)
            && (compare !== '<=' || calcResult <= matchResult)
            && (compare !== '>' || calcResult > matchResult)
            && (compare !== '>=' || calcResult >= matchResult)
            && (compare !== '!' || calcResult !== matchResult);

        // Custom error
        if (!validationResult && typeof this.customError === 'function') {
            this.error.message = this.customError(calcResult, this.match, evaluator);
        }

        return validationResult;
    }
}

// @ts-ignore
export class UserValdiation extends Validation {
    constructor(
        calculation: ArithmeticExpression,
        match: ValidationMatch,
        errorMessage: string | CustomErrorType,
        dependency?: [string, any, ComparationOperator?],
        id?: string,
    ) {
        super();
        // @ts-ignore
        this.calculation = calculation;
        // @ts-ignore
        this.match = match;
        // @ts-ignore
        this.error = {
            message: typeof errorMessage === 'string' ? errorMessage : '',
        };

        // Bounds error message
        if (typeof errorMessage !== 'string') {
            this.customError = errorMessage;
        }

        this.dependency = dependency;
        this.id = id || '';
    }
}

/**
 * Validator class, constructs a validator with a given ruleset to validate yearly data entity.
 */
class Validator<Intermediates extends IntermediateDefintions> {
    // Storage for errors durint a validation
    errors: Array<Error> = [];

    // Calculated results in the same order as rules, in format: [result, critical, warning]
    results: Array<[number | undefined, boolean, boolean]> = [];

    // Array of rules to check
    rules: Array<ValidationRule> = [];

    // Intermediate definitions
    intermediates: Intermediates;

    // Intermediate definitions
    intermediateResults?: Record<keyof Intermediates, number | undefined>;

    constructor(intermediates: Intermediates) {
        this.intermediates = intermediates;
    }

    addRule(rule: ValidationRule) {
        this.rules.push(rule);
    }

    /**
     * Main validation function. Returns boolean for performance and simplicity.
     */
    validate(data: YearlyData, compareData?: YearlyData): boolean {
        const evaluator = new DataEvaluator(data, compareData, this.intermediates);

        // Clear errors
        this.errors = [];

        // Normal validations
        this.results = this.rules.map((rule) => {
            const passedValidation = rule.validate(evaluator);
            if (!passedValidation) {
                this.errors.push({
                    ...rule.error,
                    affectedFields: getFieldsFromExpression(rule.calculation),
                });
            }
            return [rule.validatedResult, rule.validatedFlagged, !passedValidation];
        });

        // Empty field warnings
        this.emptyFields(data.income, 'income').forEach((err) => this.errors.push(err));
        this.emptyFields(data.balance, 'balance').forEach((err) => this.errors.push(err));

        this.intermediateResults = evaluator.data.intermediates;

        return this.errors.length === 0;
    }

    /**
     * Add some errors for empty fields if the rest of the form has significant progress. This is to
     * increase awareness that all fields must be completed.
     */
    emptyFields(values: Record<string, any>, keyPrefix: string): Array<Error> {
        const fieldValues = Object.entries(values);
        const progress = fieldValues.filter(([, v]) => (v !== undefined)).length / fieldValues.length;

        // No warnings prior to treshold
        if (progress < 0.75) { return []; }

        return fieldValues
            .filter(([, v]) => (v === undefined))
            .map(([key]) => ({
                message: 'WICHTIG! Bitte eine „0“ eingeben, wenn der Saldo dieser Position null beträgt. Die Daten werden ansonsten nicht als vollständig interpretiert!“',
                affectedFields: [`${keyPrefix}.${key}`],
                emphasized: true,
            }))
    }

    /**
     * True if any validation reports a critical error
     */
    hasCriticalErrors(): boolean {
        return this.results.some(([_, criticalFlagged]) => criticalFlagged);
    }

    /**
     * Get all the results
     */
    getResults(): Array<{ rule: string, result: [number | undefined, boolean, boolean] }> {
        // How to get name?
        return this.rules.map((rule, i) => ({
            rule: rule.id,
            result: this.results[i],
        }));
    }
}

export default Validator;
