import {
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

import NarrowBox from '../Common/Ui/NarrowBox';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    card: {
        marginBottom: theme.spacing(3),
    },
}));

const Faq = () => {
    const classes = useStyles();
    return (
        <>
            <NarrowBox>
                <Typography variant="h1" align="center">FAQ</Typography>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Welche Art von Produkten werden auf ApoStar angeboten?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            ApoStar beheimatet sowohl den monatlichen ApoStar Tara Report als auch den jährlichen ApoStar Bilanz Vergleich. Der bis zum Geschäftsjahr 2020 auf Papier erhobene Apothekenbetriebsvergleich wird dadurch ersetzt.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Muss ich, wenn ich registriert bin beide Produkte nutzen?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Nein, wenn Sie sich für den monatlichen ApoStar Tara Report angemeldet haben, dann läuft dieser automatisiert im Hintergrund ab. Für den jährlichen ApoStar Bilanz Vergleich müssen Sie Ihre Daten aktiv eingeben.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Was muss ich machen, um am jährlichen ApoStar Bilanz Vergleich teilnehmen zu können?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Sie müssen, sobald die Daten Ihres abgelaufenen Geschäftsjahres vorliegen, diese in den jeweiligen Bereichen (Allgemeine Daten, Personaldaten, GuV, Bilanz) erfassen. Sie können auch vorerst die Bilanzdaten frei lassen und diese nacherfassen. Weiters steht Ihnen und Ihrer Steuerberaterin oder Ihrem Steuerberater ab sofort auch ein Uploadfile zur schnelleren Datenübermittlung zur Verfügung. Eine Vorlage kann bei der Wirtschaftsabteilung des Apothekerverbands angefragt werden.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Welche Daten werden automatisiert erfasst?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Die Personaldaten der pharmazeutischen Fachkräfte, die Rezeptanzahl und die Krankenkassenpackungen werden automatisiert übermittelt. Sie können diese durch Klicken eines „Ladebuttons“ abrufen.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Sind meine Daten bei ApoStar sicher?
                        </Typography>
                        <Typography variant="body2" component="ul" gutterBottom>
                            <Typography component="li" variant="inherit">Die IT-Sicherheitsnormen wurden von einem IT-Ziviltechniker festgesetzt und entsprechen modernsten Standards.</Typography>
                            <Typography component="li" variant="inherit">Ihre Daten werden auf einem eigenen Server in einem Rechenzentrum in Düsseldorf (zertifiziert nach ISO-27001) gespeichert.</Typography>
                            <Typography component="li" variant="inherit">Ein Pseudonymisierungsverfahren stellt sicher, dass weder der Apothekerverband noch Dritte Rückschlüsse auf Ihre Apotheke anstellen können.</Typography>
                            <Typography component="li" variant="inherit">Ihr individueller Registrierungscode wird Ihnen durch einen Notar zugestellt. Nur Sie können sich registrieren, Dritte können nicht auf Ihre Daten zugreifen.</Typography>
                            <Typography component="li" variant="inherit">Betriebswirtschaftliche Vergleichswerte werden nur angezeigt, wenn sich mindestens zehn Apotheken in einer Vergleichsgruppe befinden.</Typography>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Was wird beim monatlichen ApoStar Tara Report ausgewertet?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Beim monatlichen ApoStar Tara Report werden betriebswirtschaftliche Kennzahlen aus vier Bereichen ausgewertet und dies in Bezug zu vergleichbaren Apotheken gesetzt:
                        </Typography>
                        <Typography variant="body2" component="ul" gutterBottom>
                            <Typography component="li" variant="inherit">Kundenfrequenz</Typography>
                            <Typography component="li" variant="inherit">Verkaufte Packungen</Typography>
                            <Typography component="li" variant="inherit">Umsatz</Typography>
                            <Typography component="li" variant="inherit">Vergütung als Differenz Nettoverkaufspreis zu AEP</Typography>
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Sie erhalten einen Überblicksbericht mit Analysen über alle Geschäftsbereiche und Sie können zusätzliche Detailauswertungen anstellen – gesondert zum Krankenkassen- und Privatbereich.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Was wird beim jährlichen ApoStar Bilanz Vergleich ausgewertet?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Der ApoStar Bilanz Vergleich analysiert und erklärt insbesondere:
                        </Typography>
                        <Typography variant="body2" component="ul" gutterBottom>
                            <Typography component="li" variant="inherit">Gewinn und Umsatzrentabilität</Typography>
                            <Typography component="li" variant="inherit">Wareneinsatz und Rohertrag</Typography>
                            <Typography component="li" variant="inherit">Personalkennzahlen</Typography>
                            <Typography component="li" variant="inherit">Sachaufwand</Typography>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Kann ich auch die Vorjahresdaten beim jährlichen ApoStar Bilanz Vergleich eingeben?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Wenn Sie im Vorjahr noch keine Daten erfasst haben, können Sie diese nacherfassen. Bereits in Vorjahren eingegeben Daten werden gespeichert und bei der Eingabe des aktuellen Jahres als Vorjahresdaten angezeigt. Somit ist bei regelmäßiger Teilnahme nur das aktuelle Jahr zu erfassen.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Kann ich meine Steuerberaterin oder meinen Steuerberater die Daten eingeben lassen?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Ja, Sie können Ihre Steuerberaterin oder Ihren Steuerberater als zusätzlichen Benutzer anlegen. Dieser bekommt dann einen personalisierten Zugang zu ApoStar Sie/Er kann sich auch einem Upload-File bedienen und dadurch wertvolle Zeit bei der Eingabe sparen und sich diesbezüglich jederzeit gerne an den Apothekerverband wenden.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1" component="h2" color="primary" gutterBottom>
                            Wem nutzt die Erfassung der Daten?
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Neben Ihrem individuellen Nutzen dienen Ihre ApoStar-Daten der gesamten Apothekerschaft:
                        </Typography>
                        <Typography variant="body2" component="p" gutterBottom>
                            Nehmen Sie mit Ihrer Apotheke teil, tragen Sie zur Steigerung der Aussagekraft der Ergebnisse bei – denn je mehr Betriebe in den Vergleich einfließen, desto treffsicherer die Analyse. Zudem liefert ApoStar unverzichtbare Daten für unsere Arbeit als Interessenvertretung: Das kann Verhandlungen mit dem Dachverband oder Kollektivvertragsverhandlungen betreffen oder DM und die Forderung nach einer Ausweitung der ärztlichen Notabgabestellen für Medikamente. Es gibt eine Vielzahl an äußeren Einflüssen, die die Lebensfähigkeit unserer Betriebe bedrohen. Wollen wir in dieser politischen Auseinandersetzung reüssieren, brauchen wir valides Datenmaterial, mit dem wir unsere Standpunkte und Forderungen begründen können – gegenüber politischen Parteien, Ministerien oder dem Hauptverband. Dazu liefert uns ApoStar die Grundlage.
                        </Typography>
                    </CardContent>
                </Card>
            </NarrowBox>
        </>
    );
}

export default Faq;
