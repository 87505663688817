import React, { useMemo } from 'react';

import { Chip } from '@material-ui/core';
import useCachedData from '../../Hooks/useCachedData';

export type InvolvementProps = {
    value: Nullable<number>,
};

const Involvement: React.FC<InvolvementProps> = ({ value }) => {
    const { involvement } = useCachedData(['involvement']);
    const displayInvolvement = useMemo(() => (
        (involvement || []).find((item: { begID: number}) => (item.begID === value))
    ), [value, involvement]);

    return displayInvolvement ? (
        <Chip size="small" label={displayInvolvement.begBezeichnung} />
    ) : null;
};

export default Involvement;
