import {
    RuleParamater,
    RuleParamaters,
} from './YearlyDataValidation';
import RuleParamatersSchema, {
    RuleParamatersApiEntity,
} from '../Schemas/Api/RuleParamaters';

import SchemaMapper from './SchemaMapper';

const FIELD_MAP = {
    checksGuV_01: 'legalSocialExpense',
    checksGuV_03: 'salesRelationLastYear',
    checksGuV_04: 'privateSalesRelationLastYear',
    checksGuV_05: 'privateSalesRelationOfficin',
    checksGuV_06: 'insuranceSalesRelationOfficin',
    checksGuV_07: 'insuranceSalesRelationLastYear',
    checksGuV_10: 'salesBuyingSyndicate',
    checksGuV_11: 'rentalExpense',
    checksGuV_12: 'tenureExpense',
    checksGuV_13: 'materialExpenseRelationLastYear',
    checksGuV_14: 'materialExpenseRelationSales',
    checksGuV_15: 'staffExpenseRelationSales',
    checksGuV_16: 'staffExpenseRelationLastYear',
    checksGuV_17: 'expensesRelationSales',
    checksGuV_18: 'profitRelationSales',
    checksGuV_19: 'profitRelationLastYear',
    checksGuV_20: 'profit',
    checksGuV_21: 'tenureExpenseRelationSales',
    checksGuV_22: 'tenureExpenseWithoutTenure',
    checksGuV_23: 'rentalExpenseOwnership',
    checksBilanz_01: 'stockRelationLastYear',
    checksBilanz_02: 'balanceEquality',
    checksStruktur_01: 'workingAreasRelationLastYear',
    checksStruktur_02: 'officeAreasRelationLastYear',
    checksStruktur_03: 'customerCountRelationLastYear',
    checksStruktur_04: 'prescriptionCountRelationLastYear',
    checksStruktur_05: 'privatePackageSalesRelationLastYear',
    checksStruktur_06: 'insurancePackageSalesRelationLastYear',
    checksPersonal_01: 'quotaConcessionaire',
    checksPersonal_02: 'familiaryTotalQuota',
    checksPersonal_03: 'apirantTotalQuota',
    checksPersonal_04: 'staffExpenseRelationProfit',
    checksPersonal_05: 'salaryDirector',
    checksPersonal_06: 'salaryPharmacist',
    checksPersonal_07: 'salaryAspirant',
    checksPersonal_08: 'salaryMerchant',
    checksPersonal_09: 'salaryTrainee',
    checksPersonal_10: 'salaryEmployeeSkilled',
    checksPersonal_11: 'salaryEmployeeUnskilled',
    checksPersonal_12: 'salaryCleaner',
    checksPersonal_13: 'salaryOtherEmployee',
} as const;

/**
 * Object from string
 */
const getRuleParamterFromString = (data: string): RuleParamater => {
    const partValues = { min: 0, max: 0, minw: 0, maxw: 0 };
    const parts = data.split('|');
    parts.forEach((part) => {
        const keyValue = part.split(':');
        // @ts-ignore
        if (Object.keys(partValues).includes(keyValue[0].trim()) && keyValue[1]) {
            // @ts-ignore
            partValues[keyValue[0].trim()] = parseFloat(keyValue[1].trim());
        }
    });

    return {
        bounds: { low: partValues.minw, high: partValues.maxw },
        boundsCritical: { low: partValues.min, high: partValues.max },
    };
}

/**
 * Object from string
 */
const serializeRuleParameter = (data: RuleParamater, year: number): string => {
    return [
        data.boundsCritical?.low ?? 0,
        data.boundsCritical?.high ?? 0,
        data.bounds.low,
        data.bounds.high,
        year,
        year,
    ].join(';');
}

export default class RuleParamatersMapper
    extends SchemaMapper<RuleParamaters, RuleParamatersApiEntity> {
    schema = RuleParamatersSchema;
    year: number;

    constructor(year: number) {
        super();
        this.year = year;
    }

    /**
     * Map json data to a user model
     */
    mapToModel = (data: RuleParamatersApiEntity) => {
        return Object.fromEntries(
            Object.entries(FIELD_MAP).map(([
                apiParameter, ruleParameter,
            ]) => ([
                // @ts-ignore
                ruleParameter, getRuleParamterFromString(data[apiParameter])
            ]))
        ) as RuleParamaters;
    }

    /**
     * Map user model to json data
     */
    mapToData = (model: RuleParamaters) => {
        const mapped = Object.fromEntries(
            Object.entries(FIELD_MAP).map(([
                apiParameter, ruleParameter,
            ]) => ([
                apiParameter, serializeRuleParameter(model[ruleParameter], this.year)
            ]))
        );

        return {
            checksWj: this.year,
            checksGuV_02: '',
            checksGuV_08: '',
            checksGuV_09: '',
            ...mapped,
        } as RuleParamatersApiEntity;
    };
}
