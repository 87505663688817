import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['staff.familyPharmacist', '+', 'staff.familyNonPharmacist'],
        { type: 'bounds', ...params },
        'Die Eingabe der 10tel bezüglich familiärer Mittätigkeit erscheint unplausibel.',
        undefined,
        'Personal_02',
    )
);

export default rule;
