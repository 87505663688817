import {
    Button,
    FormLabel,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { PeriodType, ReportType } from '../../Lib/Report';
import React, { useState } from 'react';

import {
    Autocomplete,
} from '@material-ui/lab';
import FileIcon from '../../assets/apostar_icons_meineapotheke.svg';
import FormSelect from '../Common/FormSelect';
import { MONTH_NAMES } from '../../Lib/Helper';

export type Filters = {
    type: ReportType,
    period: PeriodType | 'recent',
    date: Date,
    dateMode: 'yearMonth' | 'year' | 'month',
};

export type ReportsFilterProps = {
    onChange?: (values: Filters) => void,
    onSubmit?: (values: Filters) => void,
};

export const defaultFilter: Filters = {
    type: 'pharmacy',
    period: 'recent',
    date: new Date(),
    dateMode: 'yearMonth',
};

const currentYear = new Date().getFullYear();

const ReportsFilter = ({ onChange, onSubmit }: ReportsFilterProps) => {
    const [filterValues, setFilterValues] = useState<Filters>(defaultFilter);
    const setValue = <T extends keyof Filters>(value: Filters[T], key: T) => {
        setFilterValues((prev) => {
            const newFilters = {...prev, [key]: value };
            if (!newFilters.date && newFilters.period !== 'recent') {
                newFilters.date = new Date();
            } else if (newFilters.dateMode !== 'yearMonth' &&  newFilters.period !== 'monthly') {
                newFilters.dateMode = 'yearMonth';
                newFilters.date.setFullYear(new Date().getFullYear());
            }
            onChange?.(newFilters);
            return newFilters;
        });
    };

    const yearOptions = new Array(20).fill(0).map((_, i) => ({
        ID: currentYear - i,
        name: `${currentYear - i}`,
    }));

    const monthOptions = new Array(12).fill(0).map((_, i) => ({
        ID: i,
        name: MONTH_NAMES[i],
    }));

    const setDateValue = (value?: number, year: boolean = false) => {
        const date = filterValues.date || new Date();
        if (year) {
            setValue(!value ? 'month' : 'yearMonth', 'dateMode');
            date.setFullYear(value ? value : (currentYear - yearOptions.length - 1));
        } else {
            setValue(!value ? 'year' : 'yearMonth', 'dateMode');
            if (value) date.setMonth(value);
        }
        setValue(date, 'date');
    };

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} sm={6} md={3}>
                <FormLabel>Berichtstyp</FormLabel>
                <FormSelect
                    label=""
                    /* @ts-ignore */
                    options={[{
                        ID: 'pharmacy',
                        name: 'eigene Berichte',
                    }, {
                        ID: 'general',
                        name: 'allgemeine Berichte',
                    }] as const}
                    defaultValue="pharmacy"
                    onChange={(value) => value && setValue(value?.ID, 'type')}
                    renderOption={({ ID, name }) => (
                        ID === 'pharmacy'
                            ? (
                                <Typography variant="inherit">
                                    {name} <img src={FileIcon} alt="icon" style={{ height: 24, verticalAlign: 'middle' }} />
                                </Typography>
                            )
                            : name
                    )}
                    disableClearable={true}
                    margin="none"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormLabel>Quelle</FormLabel>
                <FormSelect
                    label=""
                    /* @ts-ignore */
                    options={[{
                        ID: 'recent',
                        name: 'Letzte Aussendungen',
                    }, {
                        ID: 'yearly',
                        name: 'Jährlicher ABV',
                    }, {
                        ID: 'monthly',
                        name: 'Monatlicher ABV',
                    }, {
                        ID: 'all',
                        name: 'Kombinierter ABV',
                    }] as const}
                    defaultValue={'recent'}
                    onChange={(value) => value && setValue(value?.ID, 'period')}
                    disableClearable={true}
                    margin="none"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormLabel>Jahr</FormLabel>
                <Autocomplete
                    id="report-filter-year"
                    defaultValue={yearOptions[0]}
                    options={yearOptions}
                    getOptionSelected={(a, b) => (a.ID === b.ID)}
                    getOptionLabel={(option: { name: string }) => option.name || 'keine Auswahl'}
                    onChange={(_, selected) => setDateValue(selected?.ID, true)}
                    disabled={filterValues.period === 'recent'}
                    renderInput={(params) => {
                        if (filterValues.period === 'recent') {
                            // @ts-ignore
                            params.inputProps.value = '---';
                        } else {
                            // @ts-ignore
                            params.inputProps.value =
                                (currentYear - yearOptions.length - 1) === filterValues.date.getFullYear()
                                    ? ''
                                    : filterValues.date.getFullYear();
                        }
                        return <TextField {...params} label="" variant="outlined" margin="none" />
                    }}
                    disableClearable={filterValues.period !== 'monthly' || filterValues.dateMode === 'year'}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormLabel>Monat</FormLabel>
                <Autocomplete
                    id="report-filter-month"
                    defaultValue={monthOptions[filterValues.date.getMonth()]}
                    options={monthOptions}
                    getOptionSelected={(a, b) => (a.ID === b.ID)}
                    getOptionLabel={(option: { name: string }) => option.name || 'keine Auswahl'}
                    onChange={(_, selected) => setDateValue(selected?.ID)}
                    disabled={filterValues.period !== 'monthly'}
                    renderInput={(params) => {
                        if (filterValues.period !== 'monthly') {
                            // @ts-ignore
                            params.inputProps.value = '---';
                        }
                        return <TextField {...params} label="" variant="outlined" margin="none" />
                    }}
                    disableClearable={filterValues.dateMode === 'month'}
                />
            </Grid>
            {onSubmit && (
                <Grid item>
                    <Button onClick={() => onSubmit(filterValues)} variant="contained" color="primary">
                        Berichte anzeigen
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default ReportsFilter;
