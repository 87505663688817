import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.operating_expenses_LJ', '/', 'intermediates.total_output_LJ'],
        { type: 'bounds', ...params },
        {
            high: 'Der Betriebsaufwand im Verhältnis zum Umsatz erscheint unplausibel niedrig. Bitte prüfen Sie nochmals Ihre Aufwendungen und Umsätze.',
            low: 'Der Betriebsaufwand im Verhältnis zum Umsatz erscheint unplausibel hoch. Bitte prüfen Sie nochmals Ihre Aufwendungen und Umsätze.',
        },
        undefined,
        'GuV_17',
    )
);

export default rule;
