import {
    Card,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
    makeStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Helper function to filter an array not to include values from another array
 */
function not(a: Array<any>, b: Array<any>) {
    return a.filter((value) => b.indexOf(value) === -1);
}

/**
 * Helper function to for intersection in 2 arrays
 */
function intersection(a: Array<any>, b: Array<any>) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

/**
 * Combine 2 arrays uniquely
 */
function union(a: Array<any>, b: Array<any>) {
    return [...a, ...not(b, a)];
}

export type MultiSelectProps<T extends Record<string, any>> = {
    title: string,
    items: Array<T>,
    hasSelectAll?: boolean,
    initialChecked?: Array<T>,
    labelKey: keyof T,
    onChange: (selectedItems: Array<T>) => void,
    exclusive: { [key: number]: Array<number> },
};

const useStyles = makeStyles((theme: Theme) => ({
    narrowList: {
        '&.MuiList-root > .MuiListItem-gutters': {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    },
    narrowHead: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

const MultiSelect = <T extends Record<string, any>>({
    title,
    items,
    hasSelectAll = false,
    initialChecked = [],
    labelKey = 'name',
    onChange = () => {},
}: MultiSelectProps<T>) => {
    const classes = useStyles();

    // Checked state
    const [checked, setChecked] = useState(initialChecked);

    // A count on the checked variables
    const numberOfChecked = (items: Array<T>) => intersection(checked, items).length;

    // Toggle all handling
    const handleToggleAll = (items: Array<T>) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    // Single toggle handling
    const handleToggle = (value: T) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    // Change trigger
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        /**
         * Implicitly ignored at this point. We want to notify onChange when checked changes, not
         * when a new onChange is passed.
         */
        onChange(checked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    return (
        <Card>
            <CardHeader
                avatar={hasSelectAll ? (
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                ) : null}
                title={title}
                titleTypographyProps={{ variant: 'body1' }}
                className={classes.narrowHead}
                subheader={`${numberOfChecked(items)}/${items.length} ausgewählt`}
            />
            <Divider />
            <List dense component="div" role="list" className={classes.narrowList}>
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value[labelKey]}-label`;

                    return (
                        <ListItem key={labelId} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value[labelKey]} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
};

export default MultiSelect;
