import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type RuleParamatersApiEntity = {
    checksWj: number,
    checksGuV_01: string,
    checksGuV_02: string,
    checksGuV_03: string,
    checksGuV_04: string,
    checksGuV_05: string,
    checksGuV_06: string,
    checksGuV_07: string,
    checksGuV_08: string,
    checksGuV_09: string,
    checksGuV_10: string,
    checksGuV_11: string,
    checksGuV_12: string,
    checksGuV_13: string,
    checksGuV_14: string,
    checksGuV_15: string,
    checksGuV_16: string,
    checksGuV_17: string,
    checksGuV_18: string,
    checksGuV_19: string,
    checksGuV_20: string,
    checksGuV_21: string,
    checksGuV_22: string,
    checksGuV_23: string,
    checksBilanz_01: string,
    checksBilanz_02: string,
    checksStruktur_01: string,
    checksStruktur_02: string,
    checksStruktur_03: string,
    checksStruktur_04: string,
    checksStruktur_05: string,
    checksStruktur_06: string,
    checksPersonal_01: string,
    checksPersonal_02: string,
    checksPersonal_03: string,
    checksPersonal_04: string,
    checksPersonal_05: string,
    checksPersonal_06: string,
    checksPersonal_07: string,
    checksPersonal_08: string,
    checksPersonal_09: string,
    checksPersonal_10: string,
    checksPersonal_11: string,
    checksPersonal_12: string,
    checksPersonal_13: string,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<RuleParamatersApiEntity> = {
    properties: {
        checksWj: { type: 'int32' },
        checksGuV_01: { type: 'string' },
        checksGuV_02: { type: 'string' },
        checksGuV_03: { type: 'string' },
        checksGuV_04: { type: 'string' },
        checksGuV_05: { type: 'string' },
        checksGuV_06: { type: 'string' },
        checksGuV_07: { type: 'string' },
        checksGuV_08: { type: 'string' },
        checksGuV_09: { type: 'string' },
        checksGuV_10: { type: 'string' },
        checksGuV_11: { type: 'string' },
        checksGuV_12: { type: 'string' },
        checksGuV_13: { type: 'string' },
        checksGuV_14: { type: 'string' },
        checksGuV_15: { type: 'string' },
        checksGuV_16: { type: 'string' },
        checksGuV_17: { type: 'string' },
        checksGuV_18: { type: 'string' },
        checksGuV_19: { type: 'string' },
        checksGuV_20: { type: 'string' },
        checksGuV_21: { type: 'string' },
        checksGuV_22: { type: 'string' },
        checksGuV_23: { type: 'string' },
        checksBilanz_01: { type: 'string' },
        checksBilanz_02: { type: 'string' },
        checksStruktur_01: { type: 'string' },
        checksStruktur_02: { type: 'string' },
        checksStruktur_03: { type: 'string' },
        checksStruktur_04: { type: 'string' },
        checksStruktur_05: { type: 'string' },
        checksStruktur_06: { type: 'string' },
        checksPersonal_01: { type: 'string' },
        checksPersonal_02: { type: 'string' },
        checksPersonal_03: { type: 'string' },
        checksPersonal_04: { type: 'string' },
        checksPersonal_05: { type: 'string' },
        checksPersonal_06: { type: 'string' },
        checksPersonal_07: { type: 'string' },
        checksPersonal_08: { type: 'string' },
        checksPersonal_09: { type: 'string' },
        checksPersonal_10: { type: 'string' },
        checksPersonal_11: { type: 'string' },
        checksPersonal_12: { type: 'string' },
        checksPersonal_13: { type: 'string' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const RuleParamatersArray: JTDSchemaType<Array<RuleParamatersApiEntity>> = {
    elements: schema,
};
