import Validator, { RuleParamaters, ValidationRule } from './YearlyDataValidation';
import { YearlyData, YearlySaveData } from './YearlyData';

import IntermediatesMapper from './IntermediatesMapper';
import JSONFetch from './JSONFetch';
import RawPharmacy from './RawPharmacy';
import RuleParamatersMapper from './RuleParamatersMapper';
import StaffDataMapper from './StaffDataMapper';
import ValidationResultMapper from './ValidationResultsMapper';
import YearlyDataMapper from './YearlyDataMapper';
import YearlyPharmacyMapper from './YearlyPharmacyMapper';
import createValidationsWithParams from './YearlyDataValidations/createValidationsWithParams';
import intermediateCalculations from './Intermediates';
import validate from './FormValidations/YearlyDataParamters';

const mapper = new YearlyDataMapper();

/**
 * Service class for yearly data entities
 */
export default class YearlyDataService {
    // Validator instance
    validators: Record<number, Validator<typeof intermediateCalculations>> = {};

    /**
     * Get a validator for a certain year.
     */
    getValidator(year: number): Promise<Validator<typeof intermediateCalculations>> {
        // Load once and cache
        if (!this.validators[year]) {
            return this.loadRuleParamters(year)
                .then((parameters) => {
                    /**
                     * Validate if all params are given. If not throw an error because then
                     * validator is not valid.
                     */
                    if (!validate(parameters)) {
                        throw new Error('7182: Parameters could not be loaded');
                    }

                    const rules = createValidationsWithParams(parameters);
                    const validator = new Validator(intermediateCalculations);
                    rules.forEach((rule: ValidationRule) => validator.addRule(rule));

                    this.validators[year] = validator;
                    return this.validators[year];
                });
        }

        return Promise.resolve(this.validators[year]);
    }

    /**
     * Load rule paramters for a given year
     */
    loadRuleParamters(year: number): Promise<RuleParamaters> {
        return JSONFetch({
            url: `/CheckStammByWJ?Jahr=${year}`,
            responseParser: (new RuleParamatersMapper(year)).parse,
            bodySerializer: () => '',
        });
    }

    /**
     * Get data for a certain year
     */
    get(pharmacy: RawPharmacy, year: number): Promise<YearlyData> {
        const pharmacyId = pharmacy.id!;
        const staffMapper = new StaffDataMapper(pharmacyId, year);
        const pharmacyMapper = new YearlyPharmacyMapper(pharmacy, year);
        return Promise.all([
            JSONFetch({
                url: `/KpiWerteByApoIDWJ?ApoID=${pharmacyId}&Jahr=${year}`,
                responseParser: mapper.parseArray,
                bodySerializer: () => '',
            }),
            JSONFetch({
                url: `/KPIPersonal?ApoID=${pharmacyId}&Jahr=${year}`,
                responseParser: staffMapper.parseArray,
                bodySerializer: () => '',
            }),
            JSONFetch({
                url: `/ApothekeByIDABVJahr?ApoID=${pharmacyId}&Jahr=${year}`,
                responseParser: pharmacyMapper.parseArray,
                bodySerializer: () => '',
            }),
        ])
            .then(([arrayData, staffData, pharmacy]) => {
                if (arrayData.length === 1) {
                    return {
                        ...arrayData[0],
                        staff: { rows: staffData },
                        pharmacy,
                    };
                }
                return {
                    isEmpty: true,
                    general: {},
                    balance: {},
                    income: {},
                    staff: { rows: [] },
                };
            });
    }

    /**
     * Save data for a certain year
     */
    save(data: YearlySaveData, pharmacy: RawPharmacy, year: number): Promise<YearlySaveData> {
        const pharmacyId = pharmacy.id!;
        const intermediatesMapper = new IntermediatesMapper(pharmacyId, year);
        const resultsMapper = new ValidationResultMapper(pharmacyId, year);
        const staffMapper = new StaffDataMapper(pharmacyId, year);
        const yearlyPharmacyMapper = new YearlyPharmacyMapper(pharmacy, year);

        const combinedData = `{
            "KPIWerte": ${mapper.serialize(data)},
            "CalcErgebnis": ${intermediatesMapper.serialize(data.intermediates)},
            "CheckErgebnis": ${resultsMapper.serialize(data.validationResults)},
            "KpiPersonal": ${staffMapper.serializeArray(data.staff.rows)},
            "ApoABVJahr": ${yearlyPharmacyMapper.serialize(data.pharmacy)}
        }`;

        return JSONFetch({
            url: `/InsertUpdateKpiAll?apo_id=${pharmacyId}&jahr=${year}`,
            responseParser: () => data,
            bodySerializer: (a) => a,
            body: combinedData,
            method: 'post',
        }).then(() => (data));
    }

    saveRuleParameters(year: number, data: RuleParamaters): Promise<void> {
        return JSONFetch({
            url: `/InsertUpdateCheckStamm`,
            responseParser: () => {},
            bodySerializer: (new RuleParamatersMapper(year)).serialize,
            body: data,
            method: 'post',
        });
    }
}
