import {
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import React, { useState } from 'react';

import useChangeEffect from '../../Hooks/useChangeEffect';

export type SelectInputProps<T extends Record<string, string>> = {
    id?: string,
    label: string,
    initialValue?: keyof T,
    options: T,
    onChange?: (value?: keyof T, id?: string) => void,
    onFocused?: (event: React.FocusEvent<HTMLElement>) => void,
};

/**
 * Number input with a comparison and derived trend to a previous value
 */
const SelectInput = <T extends Record<string, string>>({
    id,
    label,
    initialValue,
    onChange,
    options,
    onFocused = () => {},
}: SelectInputProps<T>) => {
    // Value state reference
    const [value, setValue] = useState<keyof T | undefined>(initialValue);

    // Notify change
    useChangeEffect(() => onChange?.(value, id), [value]);

    return (
        <FormGroup style={{ marginBottom: '2em' }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={8}>
                    <InputLabel style={{ color: 'black' }}>
                        {label}
                    </InputLabel>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Select
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={({ target: { value }}) => setValue(value as keyof T)}
                        onFocus={onFocused}
                    >
                        <MenuItem value="">
                            <em>keine Angabe</em>
                        </MenuItem>
                        {Object.keys(options).map((key) => (
                            <MenuItem key={key} value={key}>
                                {options[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </FormGroup>
    );
};

export default SelectInput;
