import { YearlyData } from './YearlyData';

// Get the default fiscal dates based on yearly data
export const getDefaultFiscalYearDates = (data: YearlyData, year: number) => {
    // Get current years date of balance
    const refDate = data.pharmacy?.dateOfBalance
        ? new Date(data.pharmacy.dateOfBalance.getTime())
        : new Date(year, 11, 31, 12);

    // In first half of year it spills to next year
    const dateOfBalance = new Date(
        refDate.getMonth() < 6 ? year + 1 : year,
        refDate.getMonth() + 1,
        0,
    );

    const dateBegin = new Date(
        dateOfBalance.getFullYear() - 1,
        dateOfBalance.getMonth() + 1,
        1,
    );

    return {
        A2_LJ: dateBegin,
        A3_LJ: dateOfBalance,
    };
};

/**
 * Get the current active year as far as data collection is concerned. It is defined as being the
 * year before the current calendar year after 01.03., and 2 years back before.
 */
export const getCollectionYear = (date: Date = new Date()) => {
    return date.getFullYear() - (date.getMonth() < 2 ? 2 : 1);
};
