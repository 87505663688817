import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Theme,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
    Alert,
} from '@material-ui/lab';
import Dialog from '../Common/Dialogs/DefaultDialog';
import {
    StaffData,
} from '../../Lib/YearlyData';
import StaffDataService from '../../Lib/StaffDataService';
import {
    dateFormat,
} from '../../Lib/Helper';
import { makeStyles } from '@material-ui/core/styles';
import useService from '../../Hooks/useService';
import useTypeClasses from '../Common/Ui/useTypeStyles';

/**
 * The properties
 */
type StaffDataLoadProps = {
    pharmacyId: number,
    year: number,
    fiscalYear: [Date?, Date?],
    onLoaded?: (rows: StaffData['rows']) => void,
};

const INACTIVE_MESSAGE = 'Bitte wählen erst ihr Wirtschaftsjahr in den Allgemeinen Daten';

const useStyles = makeStyles((theme: Theme) => ({
    info: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 * Enter staff related data
 */
const StaffDataLoad: React.FC<StaffDataLoadProps> = ({
    onLoaded = () => {},
    year,
    fiscalYear,
    pharmacyId,
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const service = useService<StaffDataService>('staffData');

    // Load the data
    const loadData = () => {
        // Guard against invalid call
        if (!fiscalYear[0] || !fiscalYear[1]) {
            return;
        }

        setLoading(true);
        service.get(pharmacyId, year, fiscalYear[0], fiscalYear[1])
            .then((final) => onLoaded(final))
            .finally(() => {
                setOpen(false);
                setLoading(false);
            });
    };

    const typeClasses = useTypeClasses();
    const classes = useStyles();
    return (
        <>
            <Alert severity="info" className={classes.info}>
                <Typography>
                    Es werden die Personaldaten der Pharmazeutischen Gehaltskasse für folgenden
                    Zeitraum geladen. Daten für PKA und Hilfskräfte müssen weiterhin manuell erfasst
                    werden. Bitte zuerst die Gehaltskassedaten für die Pharmazeuten laden und danach
                    die Daten für PKA und Hilfskräfte manuell erfassen!
                </Typography>
                <Typography gutterBottom variant="h6" color="inherit">
                    {
                        !fiscalYear[0] || !fiscalYear[1]
                            ? ''
                            : `${dateFormat(fiscalYear[0])} - ${dateFormat(fiscalYear[1])}`
                    }
                </Typography>
                <Grid container spacing={4} alignItems="center">
                    <Grid item sm={6}>
                        <Typography>
                            Diese Daten stehen ab dem 15. des Folgemonats zum Laden bereit.
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={typeClasses.yearlyButton}
                            onClick={() => setOpen(true)}
                            disabled={!fiscalYear[0] || !fiscalYear[1]}
                        >
                            Gehaltskassedaten laden
                        </Button>
                        <Typography variant="body2" color="textSecondary">
                            {!fiscalYear[0] || !fiscalYear[1] ? INACTIVE_MESSAGE : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Alert>
            {open && (
                <Dialog
                    title="Daten werden überschrieben"
                    onClose={() => (loading || setOpen(false))}
                >
                    {loading ? (
                        <Box display="flex" alignItems="center" flexDirection="column" m={5}>
                            <Typography>
                                Einen Moment Geduld bitte, ihre Anfrage wird verarbeitet
                            </Typography>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box pt={2} pb={2}>
                            <Typography gutterBottom>
                                Wenn Sie Daten aus der Gehaltskasse laden werden alle bisherig
                                erfassten Personaldaten überschrieben. Normalerweise stehen diese
                                Daten ab dem 15. des übernächsten Monats bereit.
                            </Typography>
                            <Box flexDirection="row" display="flex" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={loadData}
                                >
                                    Daten jetzt überschreiben
                                </Button>
                                <Button
                                    onClick={() => setOpen(false)}
                                >
                                    abbrechen
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Dialog>
            )}
        </>
    );
}

export default StaffDataLoad;
