import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparion combination entity types.
 */

/**
 * The typed entity from api
 */
export type ComparisonCombinationApiEntity = {
    bekID: number,
    bekNr: number,
    bekPos: number,
    bekGruppeID: number,
    bekAktiv: 'J' | 'N',
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<ComparisonCombinationApiEntity> = {
    properties: {
        bekID: { type: 'int32' },
        bekNr: { type: 'int32' },
        bekPos: { type: 'int32' },
        bekGruppeID: { type: 'int32' },
        bekAktiv: { enum: ['J', 'N'] },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const ComparisonCombinationArray: JTDSchemaType<Array<ComparisonCombinationApiEntity>> = {
    elements: schema,
};

/**
 * The typed entity from api for combination with validity information
 */
export type ValidComparisonCombinationApiEntity = {
    bekNr: number,
    kombiListe: string,
    IDListe: string,
    aktiv: 'J' | 'N',
    gueltig: 'J' | 'N',
};

/**
 * The typed entity from api for combination with validity information
 */
export type ValidComparisonCombinationSavingApiEntity = {
    bekNr: number
    ids: string,
    aktiv: 'J' | 'N',
    gueltig: 'J' | 'N',
};

/**
 * Typed schema for the entity
 */
export const ValidComparisonCombination: JTDSchemaType<ValidComparisonCombinationApiEntity> = {
    properties: {
        bekNr: { type: 'int32' },
        kombiListe: { type: 'string' },
        IDListe: { type: 'string' },
        aktiv: { enum: ['J', 'N'] },
        gueltig: { enum: ['J', 'N'] },
    },
};

/**
 * Typed schema for the entity
 */
export const SaveValidComparisonCombination: JTDSchemaType<ValidComparisonCombinationSavingApiEntity> = {
    properties: {
        bekNr: { type: 'int32' },
        ids: { type: 'string' },
        aktiv: { enum: ['J', 'N'] },
        gueltig: { enum: ['J', 'N'] },
    },
};

/**
 * Export array form as well
 */
export const ValidComparisonCombinationArray: JTDSchemaType<Array<ValidComparisonCombinationApiEntity>> = {
    elements: ValidComparisonCombination,
};


/**
 * The typed entity from api for user defined combination
 */
export type ComparisonCombinationByUserApiEntity = {
    benID: number,
    benchKombiNr: string,
};

/**
 * Typed schema for the entity
 */
export const ByUser: JTDSchemaType<ComparisonCombinationByUserApiEntity> = {
    properties: {
        benID: { type: 'int32' },
        benchKombiNr: { type: 'string' },
    },
};
