import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for pharmacy entity types.
 */

/**
 * The typed entity from api
 */
export type PharmacyApiEntity = {
    apoID: number,
    apoBetrnr: string,
    apoBezeichnung: string,
    apoGruendungsjahr: number | null,
    apoApoAnzID: number | null,
    apoEinwAnzID: number | null,
    apoBundeslandID: number | null,
    apoOeffnungsdauerID: number | null,
    apoSoftwareID: number | null,
    apoLageIds: string | null,
    apoAktiv: 'J' | 'N',
    apoFilapo?: 'J' | 'N' | null,
    apoHauptApoID?: number | null,
    apoAbvMonatlich: 'J' | 'N',
    apoGhkTransfer: 'J' | 'N',
    apoBilanzstichtag: string | null,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<PharmacyApiEntity> = {
    properties: {
        apoID: { type: 'int32' },
        apoBetrnr: { type: 'string' },
        apoBezeichnung: { type: 'string' },
        apoGruendungsjahr: { type: 'int16', nullable: true },
        apoApoAnzID: { type: 'int32', nullable: true },
        apoEinwAnzID: { type: 'int32', nullable: true },
        apoBundeslandID: { type: 'int32', nullable: true },
        apoOeffnungsdauerID: { type: 'int32', nullable: true },
        apoSoftwareID: { type: 'int32', nullable: true },
        apoLageIds: { type: 'string', nullable: true },
        apoAktiv: { enum: ['J', 'N'] },
        apoAbvMonatlich: { enum: ['J', 'N'] },
        apoGhkTransfer: { enum: ['J', 'N'] },
        apoBilanzstichtag: { type: 'string', nullable: true },
    },
    optionalProperties: {
        apoFilapo: { enum: ['J', 'N'], nullable: true },
        apoHauptApoID: { type: 'int32', nullable: true }
    },
};

export default schema;

/**
 * Export array form as well
 */
export const PharmacyArray: JTDSchemaType<Array<PharmacyApiEntity>> = {
    elements: schema,
};
