import {  } from '@material-ui/icons';

import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

import React from 'react';

export type LoadingButtonProps = ButtonProps & {
    loading: boolean,
    loadingPosition?: 'start' | 'end' | 'replace',
};

const LoadingButton: React.FC<LoadingButtonProps> = ({
    loading,
    disabled,
    loadingPosition = 'replace',
    children,
    ...buttonProps
}) => {
    return (
        <Button
            disabled={loading || disabled}
            {...buttonProps}
        >
            {loading && loadingPosition !== 'end' ? <CircularProgress size={14} /> : ''}
            {(!loading || loadingPosition !== 'replace') && <span>{children}</span>}
            {loading && loadingPosition === 'end' ? <CircularProgress size={14} /> : ''}
        </Button>
    );
}

export default LoadingButton;
