import {
    Box,
    Typography,
} from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

import { Error } from '../../Lib/YearlyDataValidation';
import {
    Warning as WarningIcon,
} from '@material-ui/icons';

type FieldErrorProps = PropsWithChildren<{
    errors?: Array<Error>,
}>;

const FieldError: React.FC<FieldErrorProps> = ({
    children,
    errors = [],
}) => {
    if (errors.length < 1) {
        return (<>{children}</>);
    }

    return (
        <Box style={{
            backgroundColor: '#FFF1CD',
            marginLeft: '-3em',
            marginRight: '-3em',
            paddingLeft: '3em',
            paddingRight: '3em',
            paddingTop: '.25em',
            paddingBottom: '.25em',
            marginTop: '.25em',
            marginBottom: '.25em',
            position: 'relative',
        }}>
            {children}
            <WarningIcon style={{
                position: 'absolute',
                left: 10,
                top: 15,
                color: '#ECA300',
            }} />
            <Typography variant="body2" color="error" style={{ marginTop: '-2em' }}>
                {errors.map((error) => (error.message)).join(', ')}
            </Typography>
        </Box>
    );
}

export default FieldError;
