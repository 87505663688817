import { UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = new UserValdiation(
    ['general.A6_LJ', '-', 'compare.general.A6_LJ'],
    { type: 'compare', compare: '=', value: 0 },
    'Ihre Angabe zur Einkaufsgemeinschaft erscheint nicht konsistent zum Vorjahr.',
    undefined,
    'GuV_25',
);

export default rule;
