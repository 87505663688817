import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';

import { Alert } from '@material-ui/lab';
import { ArrowLeft } from '../Common/Ui/Icons';
import { CustomResponseError } from '../../Lib/JSONFetch';
import DefaultDialog from '../Common/Dialogs/DefaultDialog';
import LoadingButton from '../Common/LoadingButton';
import React from 'react';
import RedeemPasswordCode from './RedeemPasswordCode';
import { TextField } from 'formik-material-ui';
import UserService from '../../Lib/UserService';
import useDialog from '../../Hooks/useDialog';
import { useLocation } from 'react-router-dom';
import useService from '../../Hooks/useService';
import useTwoColumnFormStyles from './useTwoColumnFormStyles';

type LostPasswordProps = {
    onBackClicked: () => void,
};

const LostPassword: React.FC<LostPasswordProps> = ({ onBackClicked }) => {
    const classes = useTwoColumnFormStyles();
    const service = useService<UserService>('user');

    const { search } = useLocation();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);

    const { open, isOpen, close } = useDialog(params.get('guid') !== null);

    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="stretch"
                className={`${classes.fullGrid} ${classes.breakLayout}`}
            >
                <Grid item md className={[classes.formColumn, classes.columnLg].join(' ')}>
                    <Card>
                        <CardContent className={classes.contentCenter}>
                            <Typography variant="h2" className={classes.spaceBottom}>
                                Passwort vergessen
                            </Typography>
                            <Formik
                                initialValues={{ email: '', pharmacyNumber: '' }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validate={values => {
                                    const errors = {};
                                    if (values.email.length < 1) {
                                        // @ts-ignore
                                        errors.email = 'Bitte einen Benutzer angeben';
                                    }
                                    if (values.pharmacyNumber.length < 1) {
                                        // @ts-ignore
                                        errors.pharmacyNumber = 'Bitte einen Apothekenbetriebsnummer angeben';
                                    }
                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                    service.lostPasswordRequest(values.email, values.pharmacyNumber)
                                        .catch((e) => {
                                            if (e instanceof CustomResponseError) {
                                                setErrors({ email: e.message });
                                            }
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                        })
                                }}
                            >
                                {({ isSubmitting, submitForm, submitCount, isValid }) => (
                                    submitCount > 0 && !isSubmitting && isValid
                                        ? (
                                            <Alert color="success">
                                                Wenn ein Benutzer gefunden wurde, haben wir Ihnen eine
                                                Email gesendet mit einem Link um ihr Passwort
                                                zurückzusetzen.
                                            </Alert>
                                        ) : (
                                            <Form>
                                                <Field
                                                    type="text"
                                                    name="email"
                                                    label="Email-Adresse"
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <Field
                                                    type="text"
                                                    name="pharmacyNumber"
                                                    label="Apothekenbetriebsnummer"
                                                    component={TextField}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <div className={classes.spaceTop}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        loading={isSubmitting}
                                                        onClick={submitForm}
                                                        fullWidth
                                                    >
                                                        Passwort zurücksetzen
                                                    </LoadingButton>
                                                </div>
                                            </Form>
                                        ))}
                            </Formik>
                            <Divider className={classes.dividerSpaced} />
                            <Typography variant="body1" gutterBottom>
                                Wenn Sie bereits einen Code haben, können Sie hier ein neues Passwort
                                setzen
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => open()}
                            >
                                Code einlösen
                            </Button>
                            <Divider className={classes.dividerSpaced} />
                            <Link
                                component="button"
                                variant="body1"
                                onClick={onBackClicked}
                            >
                                <ArrowLeft /> zurück zum Login
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md className={[classes.infoColumn, classes.columnSm].join(' ')}>
                    <div>
                        <Typography variant="h1" color="inherit" className={classes.lead}>
                            Passwort zurücksetzen
                        </Typography>
                        <Typography component="p" variant="body1" color="inherit">
                            Sie erhalten per Email einen einmalig gültigen Code. Diesen Code können
                            Sie kopieren und nach klicken auf „Code einlösen“ öffnet sich eine
                            Seite, auf dem Sie den Code einfügen können. Danach vergeben Sie bitte
                            ein neues Passwort.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            {isOpen && (
                <DefaultDialog title="Passwort zurücksetzen" onClose={close}>
                    <RedeemPasswordCode onCancel={close} code={params.get('guid') || ''} />
                </DefaultDialog>
            )}
        </>
    );
};

export default LostPassword;
