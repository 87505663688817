import { BalanceFields } from './BalanceSheet';
import { GeneralFields } from './GeneralForm';
import { IncomeFields } from './IncomeSheet';
import { YearlyData } from '../../Lib/YearlyData';
import { useMemo } from 'react';

const useProgress = (data: YearlyData) => {
    return useMemo(() => ({
        general: {
            done: Object.values(data.general)
                .filter((value) => (value !== undefined && value !== null)).length,
            total: Object.keys(GeneralFields).length,
        },
        income: {
            done: Object.values(data.income)
                .filter((value) => (value !== undefined && value !== null)).length,
            total: Object.keys(IncomeFields).length,
        },
        balance: {
            done: Object.values(data.balance)
                .filter((value) => (value !== undefined && value !== null)).length,
            total: Object.keys(BalanceFields).length,
        },
    }), [data]);
};

export default useProgress;
