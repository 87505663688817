import apiSchema, { PharmacyApiEntity } from '../Schemas/Api/Pharmacy';

import RawPharmacy from './RawPharmacy';
import SchemaMapper from './SchemaMapper';
import { booleanToYN } from './Helper';

export default class RawPharmacyMapper extends SchemaMapper<RawPharmacy, PharmacyApiEntity> {
    schema = apiSchema;

    /**
     * Map json data to a Pharmacy model
     */
    mapToModel(data: PharmacyApiEntity) {
        return new RawPharmacy(
            data.apoBetrnr,
            data.apoBezeichnung,
            data.apoGruendungsjahr,
            data.apoOeffnungsdauerID,
            data.apoApoAnzID,
            data.apoEinwAnzID,
            data.apoBundeslandID,
            data.apoSoftwareID,
            data.apoLageIds?.split(';').map(parseInt).filter((v) => (!isNaN(v))) ?? [],
            data.apoAktiv === "J",
            data.apoAbvMonatlich === "J",
            data.apoGhkTransfer === "J",
            data.apoBilanzstichtag,
            data.apoID || null,
        );
    }

    /**
     * Map Pharmacy model to json data
     */
    mapToData = (model: RawPharmacy): PharmacyApiEntity => {
        return {
            apoBetrnr: model.number,
            apoBezeichnung: model.title,
            apoGruendungsjahr: model.foundingYear || null,
            apoID: model.id || 0,
            apoOeffnungsdauerID: model.openingHours || null,
            apoApoAnzID: model.pharmacyCount || null,
            apoEinwAnzID: model.population || null,
            apoBundeslandID: model.state || null,
            apoSoftwareID: model.software || null,
            apoAktiv: booleanToYN(model.active),
            apoLageIds: model.location.join(';'),
            apoAbvMonatlich: booleanToYN(model.monthlySubscribed),
            apoGhkTransfer: booleanToYN(model.transferGK),
            apoBilanzstichtag: model.dateOfBalance || null,
        };
    };
}
