import ErrorResponseSchema, { ErrorResponse } from '../Schemas/Api/ErrorResponse';

import SchemaMapper from './SchemaMapper';

export default class ErrorResponseMapper
    extends SchemaMapper<ErrorResponse, ErrorResponse> {

    schema = ErrorResponseSchema;

    mapToData(model: ErrorResponse) {
        return model;
    }

    mapToModel(data: ErrorResponse) {
        return data;
    }
}
