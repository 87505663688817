import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

/**
 * The typed entity from api
 */
export type IntermediateResultApiEntity = {
    CalcergKurzBez: string,
    CalcergApoID: number,
    CalcergWj: number,
    CalcergWert: number,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<IntermediateResultApiEntity> = {
    properties: {
        CalcergKurzBez: { type: 'string' },
        CalcergApoID: { type: 'int32' },
        CalcergWj: { type: 'int32' },
        CalcergWert: { type: 'int32' },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const IntermediateResultArray: JTDSchemaType<Array<IntermediateResultApiEntity>> = {
    elements: schema,
};
