import {
    Link,
    Typography,
} from '@material-ui/core';

import NarrowBox from '../Common/Ui/NarrowBox';
import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    overline: {
        marginBottom: theme.spacing(2),
        textTransform: 'uppercase',
    },
    intro: {
        marginBottom: theme.spacing(4),
        '& ol[start]': {
            counterIncrement: 'olo',
            listStyle: 'none',
            '&> li::before': {
                content: 'counter(olo) ". "',
                float: 'left',
                marginLeft: '-40px',
            },
        },
        '& ul + ul': {
            paddingLeft: 80,
        },
    },
    sub: {
        counterReset: 'olc',
        listStyle: 'none',

        '& li': {
            counterIncrement: 'olc',
        },
        '& li::before': {
            content: 'counter(olo) "." counter(olc) ". "',
            float: 'left',
            marginLeft: '-40px',
        },
    },
    table: {
        border: '1px solid #444',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        '& td': {
            border: '1px solid #444',
            padding: theme.spacing(0.25),
            '& ul': {
                marginTop: 0,
                marginBottom: 0,
                textAlign: 'left',
                '& li': {
                    textAlign: 'left',
                },
            },
        },
    },
}));

const About = () => {
    const classes = useStyles();

    return (
        <>
            <NarrowBox textAlign="center" className={classes.intro}>
                <Typography variant="h1" gutterBottom>Nutzungsbedingungen</Typography>
                <Typography variant="body1" gutterBottom>
                    Falls Sie allgemeine Fragen zu ApoStar oder der Registrierung haben, können Sie uns gerne kontaktieren:
                    <br />
                    <Link>apostar@apothekerverband.at</Link>
                    <br />
                    <Link>+43 1 40414 300</Link>
                </Typography>

                <div>
                    <Typography variant="h3" gutterBottom>
                        Allgemeine Nutzungsbedingungen<br />
                        der Website <a href="http://www.apostar.at/">www.apostar.at</a>
                    </Typography>
                    <Typography align="justify" gutterBottom>
                        Die Plattform <strong>www.apostar.at</strong> wird vom Österreichischer
                        Apothekerverband - Interessenvertretung der selbständigen Apotheker,
                        1090 Wien, Spitalgasse 31, als Diensteanbieter, Medieninhaber und
                        Herausgeber (im Folgenden „<em>der Betreiber“</em>) betrieben.
                    </Typography>
                    <ol start={1}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Präambel</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Mit der Anmeldung als Nutzer (im Folgenden „Nutzer“) auf der
                                Plattform des Betreibers, abzurufen unter                <strong>https://www.apostar.at</strong><em> </em>(im Folgenden
                                kurz: die Plattform)<em> </em>akzeptieren Sie diese
                                nachstehenden allgemeinen Nutzungsbedingungen für die Nutzung
                                der Plattform (im Folgenden „ANB“). Davon abweichende
                                Regelungen bedürfen der Schriftform. Als Nutzer sind
                                ausschließlich natürliche Personen zugelassen, welche in
                                Österreich eine Apotheke betreiben. Nutzer sind berechtigt,
                                Dritte zu benennen, die für den Nutzer Eingaben vornehmen
                                dürfen (Subnutzer).
                            </Typography>
                        </li>
                    </ol>
                    <ol start={2}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Leistungsumfang:</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Plattform-Bereiche
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Die Plattform besteht aus zwei separat nutzbaren Bereichen:
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Jährlicher ApoStar Bilanz Vergleich – kurz J-ABV
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Monatlicher ApoStar Tara Report – kurz M-ATR (Optional)
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Beide Bereiche liefern unterschiedliche Auswertungen anhand
                                unterschiedlicher Datenquellen. Die Punkte 2.4 als auch 8.3 kommen nur
                                vorbehaltlich einer etwaigen Zustimmung des Nutzers zur Freischaltung
                                des M-ATR-Bereichs zur Anwendung.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Jährlicher AposStar Bilanz Vergleich:
                            </Typography>

                            <Typography align="justify" gutterBottom>
                                Der Nutzer hat das Recht, über die Plattform betriebswirtschaftliche
                                Auswertungen (Kennzahlen) seines Betriebes inklusive Kennzahlenwerte
                                von Vergleichsgruppen abzurufen. Basis dieser Daten sind die Bilanz und
                                GuV-Daten, die der Nutzer per manueller Eingabe auf der Plattform
                                bestätigt.
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer stimmt zu, dass die Pharmazeutische Gehaltskasse Daten des
                                Apothekenbetriebs des Nutzers über Personalmeldungen in
                                pseudonymisierter Form der Plattform zur Verfügung stellen darf. Es
                                werden ausschließlich Daten bezüglich der Zehntel-Meldungen je Monat
                                und je Typ (Dienstgeber, Miteigentümer, Pharmazeut, Aspirant). Weiters
                                stimmt der Nutzer zu, dass die Pharmazeutische Gehaltskasse Daten über
                                die Anzahl an Packungsabgaben und Rezeptanzahl zur Verfügung stellen
                                darf.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Monatlicher ApoStar Tara Report:
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Vorbehaltlich der Zustimmung zur Freischaltung des M-ATR durch den
                                Nutzer, hat dieser das Recht, über die Plattform
                                betriebswirtschaftliche Auswertungen (Kennzahlen) seines Betriebes
                                inklusive Kennzahlenwerte von Vergleichsgruppen abzurufen. Basis dieser
                                Kennzahlen bilden die
                                <br/>
                                absatz-/umsatzrelevanten Einzelgeschäftsfälle der teilnehmenden
                                Apotheken (im Folgenden kurz: Verkaufsdaten). Im Gegenzug ist der
                                Nutzer verpflichtet, eigene Verkaufsdaten der Plattform bereitzustellen
                                und dazu gemäß Punkt 8.3 zustimmen, dass der Betreiber die Daten direkt
                                aus der Apothekensoftware (mit Hilfe des Apothekensoftwarehersteller)
                                abrufen kann. Dem Nutzer dürfen hier keine Mehrkosten entstehen.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer stimmt zu, dass die von ihm bereitgestellten
                                Daten im Sinne dieser ANB verwendet werden.
                            </Typography>
                        </li>
                    </ol>
                    <ol start={3}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Registrierung von Nutzern:</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Bevor der Nutzer die Plattform nutzen kann, muss sich der
                                Nutzer registrieren. Die Registrierung ist ausschließlich über
                                die Plattform möglich und wird mit der Annahme dieser
                                Nutzungsbedingungen abgeschlossen.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Die Registrierung erfolgt durch den Nutzer, wobei vom
                                Betreiber ein Nachweis über den Apothekenbetrieb des
                                Nutzers gefordert wird. Dies erfolgt über einen
                                Registrierungscode, der von einem Dienstleister für jeden
                                Apothekenbetrieb individuell erstellt und jederzeit auf der
                                Registrierungsseite der Plattform vom Nutzer angefordert
                                werden kann.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer ist verpflichtet, alle Daten, die zur
                                Registrierung notwendig sind, wahrheitsgemäß und
                                vollständig dem Betreiber bekanntzugeben.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Sollten sich an den vom Nutzer bekanntgegeben Daten
                                Änderungen ergeben, verpflichtet sich der Benutzer, diese
                                Änderungen umgehend dem Betreiber bekannt zu geben.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer hat keinen Anspruch auf Registrierung. Durch die
                                Registrierung erhält der Nutzer keinen Rechtsanspruch auf
                                Nutzung der Plattform. Der Betreiber<em> </em>behält sich
                                das Recht vor, Nutzer ohne Angabe von Gründen nicht zu
                                registrieren und/oder bestehende Nutzer zu löschen oder zu
                                sperren. Allfällige Ansprüche des Nutzers daraus sind
                                ausgeschlossen.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer verpflichtet sich, sein Passwort geheim zu
                                halten und das Passwort nicht an Dritte weiterzugeben.
                                Ebenso verpflichtet sich der Betreiber, das Passwort geheim
                                zu halten.
                            </Typography>
                        </li>
                    </ol>
                    <ol start={4}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Rechte und Pflichten der Nutzer:</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer verpflichtet sich, die Plattform nur innerhalb der
                                technisch vorgesehenen Möglichkeiten zu nutzen. Der Nutzer
                                verpflichtet sich, jegliche Handlungen zu unterlassen, die die
                                Funktionalität oder den Betrieb der Plattform, sowie der Hard-
                                und Software des Betreibers gefährden. Insbesondere sind dem
                                Nutzer Handlungen untersagt, Schwachstellen des Systems oder
                                des Netzwerks des Betreibers zu scannen oder zu testen,
                                Sicherheitssysteme oder Zugangssysteme der angebotenen
                                Dienstleistung zu umgehen oder Schadprogramme in das System des
                                Betreibers einzubinden.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer hat das Recht, Auswertungen und Informationen,
                                die er im Rahmen der Plattform einsehen und herunterladen
                                kann, für den Betrieb der eigenen Apotheke zu nutzen und zu
                                verwenden. Eine entgeltliche Weitergabe an Dritte ist
                                unzulässig.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer ist verpflichtet aktuelle, korrekte und
                                vollständige Informationen über Verkäufe dem Betreiber
                                bereitzustellen.
                            </Typography>
                        </li>
                    </ol>
                    <ol start={5}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Rechte und Pflichten des Betreibers:</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber stellt sicher, dass Dritte die vom Nutzer
                                bereitgestellten Daten nicht auf den Nutzer zurückführen
                                können. Eine Auswertung der Daten durch den Betreiber selbst
                                ist jedenfalls zulässig, wobei durch Einbeziehung eines
                                externen Dienstleisters eine Pseudonymisierung der Daten vorab
                                sichergestellt wird.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber hat das Recht, die vom Nutzer
                                bereitgestellten Daten auf jede erdenkliche Art zu nutzen
                                und zu verwerten, sofern eine Rückführbarkeit auf den
                                Nutzer nicht möglich ist – hiervon ausgenommen ist das
                                Recht auf kommerzielle Nutzung – siehe Punkt 5.3. Weiters
                                wird der Betreiber die vom Nutzer bereitgestellten Daten so
                                pseudonymisieren und gegebenenfalls gruppieren, dass die
                                Daten keiner konkreten Apotheke zugeordnet werden können.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber verpflichtet sich die bereitgestellten Daten
                                keiner kommerziellen Nutzung zuzuführen.
                            </Typography>
                        </li>
                    </ol>
                    <ol start={6}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Gewährleistungs- und Haftungsausschluss:</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber<em> </em>haftet weder für eigene Handlungen noch
                                für Handlungen von Erfüllungsgehilfen (mit Ausnahme von
                                Personenschäden), sofern diese nicht grob fahrlässig oder
                                vorsätzlich verschuldet wurden. Der Ersatz von Schäden aller
                                Art aus Ansprüchen Dritter ist ausgeschlossen.
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Alle Ansprüche des Nutzers sowie Schadenersatzansprüche wegen
                                unmittelbarer und mittelbarer Schäden, Vermögensschäden und entgangenem
                                Gewinn sind ausgeschlossen, sofern den Betreiber<em> </em>kein grobes
                                Verschulden angelastet werden kann.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber<em> </em>übernimmt keinerlei Haftung für
                                Schäden, die aus einer Weitergabe der Daten des Nutzers
                                oder Daten Dritter entstehen.<em></em>
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber<em> </em>haftet weder für die Speicherung,
                                die Übermittlung, noch die korrekte Darstellung der
                                übertragenen Inhalte. Weiters übernimmt der Betreiber                    <em> </em>keine Haftung für Aktualität, Korrektheit oder
                                Qualität sowie jegliche Auswirkungen durch Gebrauch oder
                                Missbrauch der dargestellten Plattform.
                            </Typography>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber<em> </em>übernimmt schließlich keine Haftung und leistet
                                keine Gewähr für die durchgehende Verfügbarkeit des Dienstes sowie für
                                Schäden aus allfälligen Datenverlusten.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Betreiber haftet nicht für technisch bedingte
                                Unterbrechungen, Netzausfälle oder sonstige Störungen des
                                Portals. Die Haftung für Datenverlust ist ausgeschlossen.
                                Sämtliche im Rahmen des Portals angebotenen Leistungen
                                stehen im Ausmaß von 99%, gerechnet auf ein Jahr zur
                                Verfügung (kurz: die Verfügbar-keit). Der Betreiber wird –
                                sofern möglich – Wartungsarbeiten zwischen 20 Uhr und 6 Uhr
                                Früh durchzuführen. Angekündigte Wartungsarbeiten schmälern
                                die Verfügbarkeit nicht.
                            </Typography>
                        </li>
                    </ol>
                    <ol start={7}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>Gerichtsstand:</strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Es gilt österreichisches Recht. Die Anwendung des UN-Kaufrechts
                                und von Verweisungsbestimmungen auf materielles ausländisches
                                Recht ist ausgeschlossen. Für Streitigkeiten aus und im
                                Zusammenhang mit diesen Bedingungen und/oder der Nutzung der
                                Plattform<em> </em>ist ausschließlich das für Wien-Innere Stadt
                                sachlich zuständige Gericht zuständig.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Änderungen oder Ergänzungen dieser AGB bedürfen zu ihrer
                                Wirksamkeit der Schriftform. Dies gilt auch für das Abgehen
                                vom Schriftformerfordernis.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Sollten einzelne Bestimmungen dieser AGB nichtig,
                                undurchsetzbar und/oder ungültig sein oder werden, hat dies
                                nicht die Nichtigkeit, Undurchsetzbarkeit und/oder
                                Ungültigkeit der gesamten AGB zur Folge hat. Die Parteien
                                verpflichten sich für diesen Fall, anstelle der nichtigen,
                                undurchsetzbaren und/oder ungültigen Bestimmungen eine
                                Regelung zu vereinbaren, die dem mit der nichtigen,
                                un-durchsetzbaren und/oder ungültigen Regelung verfolgten
                                Zweck wirtschaftlich am Nächsten kommt.
                            </Typography>
                        </li>
                    </ol>
                    <ol start={8}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                <strong>
                                    Telefonische Kontaktaufnahme und Kontaktaufnahme per
                                    E-Mail:
                                </strong>
                            </Typography>
                        </li>
                    </ol>
                    <ol className={classes.sub}>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer erklärt sich mit einer telefonischen
                                Kontaktaufnahme, sowie der Kontaktaufnahme per E-Mail durch den
                                Betreiber zu Zwecken der Information und Werbung über dessen
                                Produkte und Produktweiterentwicklungen sowie über Neuheiten
                                einverstanden.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer kann diese Zustimmung jederzeit durch E-Mail an <strong>apostar@apothekerverband.at</strong> oder einen Anruf
                                unter <strong>01/40414</strong> widerrufen.
                            </Typography>
                        </li>
                        <li>
                            <Typography align="justify" gutterBottom>
                                Der Nutzer stimmt im Falle der Zustimmung zur Nutzung des
                                M-ATR zu, dass der Betreiber die für die Plattform
                                notwendigen Daten, direkt aus der Apothekensoftware des
                                Nutzers erheben darf. Der Nutzer stimmt zu, dass der
                                Betreiber über den Apothekensoftwarehersteller Zugriff auf
                                die entsprechende Schnittstelle erhält und folgende
                                Datensätze auslesen darf:
                            </Typography>
                        </li>
                    </ol>
                    <table className={classes.table}>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography align="justify">
                                        <strong>Datensatzfeld</strong>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Apothekenbetriebsnummer als überbetriebliches
                                                eindeutiges Identifizierungsmerkmal der
                                                Apotheke
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Filialapotheken mit „F01_“ Kennzeichnen. Das
                                                „F01_“ wird als Präfix vorangestellt
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Die Apothekenbetriebsnummer wird in weiterer
                                                Folge gegenüber dem Betreiber pseudonymisiert
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Apotheken Software(-Anbieter)
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Eindeutige Geschäftsfallnummer für Ermittlung
                                                Kundenfrequenz
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Zeitstempel Geschäftsfall
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Pharmazentralnummer_sonstige Produktnummer
                                                abgegebenes Produkt/Leistung
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Sammelnummer für Magistrale Zubereitung
                                                (9999998)
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Sammelnummer Rest (9999999)
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography>
                                                Bezeichnung abgegebenes Produkt/Leistung
                                                inkl. Packungseinheit/-größe.
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Anzahl abgegebene Produktanzahl im Rahmen des
                                                Einzelgeschäftsfalles (Menge)
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Apothekeneinstandspreis (AEP) zum
                                                Abgabezeitpunkt
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Einkaufsvorteile (sowohl bar/natural) werden
                                                somit nicht erfasst.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Wenn PHZN vorhanden: Lt.
                                                Warenverzeichnis/Ergänzungssortiment.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Wenn PHZN nicht vorhanden: Den von den
                                                Apotheken selbst in der Apothekensoftware
                                                hinterlegten AEP (sofern angelegt) .
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Wenn kein AEP verfügbar/hinterlegt (z.B. bei
                                                verrechneten Dienstleistungen, selbst
                                                angelegten Artikel ohne AEP,
                                                Rabattgutscheine,…), bleibt das Feld leer
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Gültiger Krankenkassenpreis exkl. Umsatzsteuer
                                                in € zum Abgabezeitpunkt für das abgegebene
                                                Produkt/Leistung
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Unabhängig davon, ob Abgabe tatsächlich auf
                                                Kosten der Krankenkasse erfolgte.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Krankenkassenpreis gemäß WVZ I, II, III für
                                                dort enthaltene Produkte/Leistungen (inkl.
                                                Heilbehelfe).
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Für auf Kosten Begünstigter Bezieher erbrachte
                                                Leistungen, die nicht im Warenverzeichnis
                                                enthalten sind (Magistrale Zubereitung,
                                                sonstige Taxansätze), die Werte/Taxansätze
                                                gemäß Rezeptverrechnung.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Wenn kein KKP verfügbar (Ergänzungssortiment,
                                                überwiegender Teil des WVZ III), muss das Feld
                                                leer bleiben.
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Gültiger Apothekenverkaufspreis (privat) exkl.
                                                Umsatzsteuer (= AVP netto) in € zum
                                                Abgabezeitpunkt für das abgegebene
                                                Produkt/Leistung (=„Listenpreis Privatabgabe“)
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Unabhängig davon, ob Abgabe tatsächlich auf
                                                Privat erfolgte.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                AVP netto bzw. unverbindliche
                                                Verkaufspreisempfehlung netto gemäß
                                                elektronischem Warenverzeichnis I, II, III bzw.
                                                Ergänzungssortiment („Warenverzeichnis V“).
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Bei unverbindlichen Verkaufspreisempfehlungen,
                                                die eine Ober- und Untergrenze umfassen, stets
                                                den Mittelwert. Apothekenindividuelle
                                                Einstellungen bei WVZ III / Ergänzungssortiment
                                                (obere/untere Grenze der unverbindlichen
                                                Preisbänder gem. Druckwerk) bleiben daher in
                                                diesem Datensatzfeld unberücksichtigt.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Bei nicht im
                                                Warenverzeichnis/Ergänzungssortiment geführten
                                                Produkten/Leistungen (z.B. Hausspezialitäten,
                                                selbst hergestellte Nahrungsergänzungsmittel
                                                etc.), den von der Apotheke im
                                                Warenwirtschaftssystem hinterlegten AVP netto
                                                bzw. UVP netto
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Ist-Nettoverkaufspreis (exkl. Umsatzsteuer) in
                                                € des abgegebenen Produkts/Leistung
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                das ist der tatsächliche netto Verkaufspreis
                                                nach Berücksichtigung von Barrabatten (sofern
                                                direkt zuordenbar)
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Wenn keine Barrabatte bei Privatabgaben gewährt
                                                werden oder ein Rabatt als eigener Artikel
                                                angelegt wurde (z.B. Rabattgutschein),
                                                entspricht der Ist-Nettoverkaufspreis dem AVP
                                                netto. Werden Barrabatte bei Privatabgaben
                                                gewährt, entspricht der Ist-Nettoverkaufspreis
                                                den um den gewährten Rabatt reduzierten
                                                Listenpreis/AVP netto.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Bei Produkten/Leistungen, die über die
                                                Rezeptabrechnung abgerechnet werden
                                                (Krankenkassenbereich = Geschäftsfallart mit
                                                Ausprägung AG), entspricht der
                                                Ist-Nettoverkaufspreis stets dem
                                                Krankenkassenpreis (gilt auch für Heilbehelfe,
                                                wo auf Länderebene mit den Krankenkassen ev.
                                                abweichende Erstattungspreise vereinbart
                                                wurden). Ein allfälliger Sondernachlass bleibt
                                                unberücksichtigt.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Wenn der
                                                Krankenkassenpreis/Ist-Nettoverkaufspreis erst
                                                im Zuge der Rezeptverrechnung festgesetzt wird
                                                (z.B. bei Magistraler Zubereitung), wird der
                                                Taxansatz gemäß Rezeptverrechnung benötigt.
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Umsatzsteuersatz
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Es wird der Prozentsatz übergeben. z.B. 20 für
                                                20%
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Geschäftsfallart
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Unterscheidung Abgabe auf Kosten begünstigte
                                                Bezieher, Privat trotz Rezeptvorlage (unter
                                                Erstattungsgrenze), Privat trotz Rezeptvorlage
                                                (andere Gründe), Privat
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Lieferschein Kennzeichen
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Inkludiert werden alle Lieferscheine,
                                                unabhängig ob diese über ein Taramodul oder
                                                anderes Lieferscheinerstellungsmodul des
                                                Warenwirtschaft-programms erzeugt und dem
                                                Apothekenverrechnungskreis zugeordnet werden.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Sieht die Apothekensoftware die Möglichkeit
                                                vor, ein Kennzeichen für Lieferschein
                                                Sondergeschäfte (LS) zu setzen (Zweck: diese
                                                Geschäftsfälle, sollen in Standardauswertungen
                                                nicht berücksichtigt werden wie z.B. Verkäufe
                                                an andere Apotheken etc.), können diese
                                                Geschäftsfälle entweder nicht an die
                                                Schnittstelle übergeben werden, oder würden vom
                                                Apothekerverband bei den
                                                betriebswirtschaftlichen Auswertungen
                                                ausgeschieden werden. Vom Apothekerverband
                                                bevorzugt, wird die Nicht-Übergabe von
                                                derartigen Lieferschein-Sonderverkäufen an die
                                                Schnittstelle. Für den Fall, dass ein
                                                derartiges Kennzeichen/Funktion in der
                                                Apothekensoftware nicht implementiert ist,
                                                fällt das Ausprägungsmerkmal nicht an.
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography align="justify">
                                        Abholergeschäftsfälle
                                    </Typography>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Ansatz 1: Es werden nur Teile der Abholergeschäftsfälle an die Schnittstelle übergeben, die direkt die Abgabe betreffen
                                                (nicht jedoch die vorgelagerten Abholer-Geschäftsfälle wie die Erstellung von Abholerpositionen (unabhängig ob mit oder ohne
                                                Anzahlung) oder die Auflösung der Position,
                                                weil keine Abholung erfolgte etc.). Dies setzt
                                                voraus, dass dem an die Schnittstelle
                                                übergebene Abholer-Geschäftsfall beim Datenfeld
                                                Ist-Nettoverkaufspreis auch allfällige
                                                vorgelagerte Teilzahlungen inkludiert sind,
                                                wenn es sich um eine private Abgabe handelte.
                                                Bei Abgabe auf Kosten begünstigter Bezieher
                                                (Rezeptverrechnung), ist der
                                                Ist-Nettoverkaufspreis ohnehin mit dem
                                                Krankenkassenpreis ident.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Ansatz 2: Es werden neben dem Abgabe-Abholergeschäftsfall auch die vorgelagerten Abholergeschäftsfälle an die Schnittstelle übergeben mit entsprechenden Kennzeichen.
                                            </Typography>
                                        </li>
                                    </ul>
                                    <Typography align="justify">
                                        Beide Ansätze sind mit den unten angeführten Ausprägungen umsetzbar. Jeder Softwarehersteller muss sich auf eine der beiden Ansätze festlegen.
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Kennzeichen Abholergeschäftsfälle
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Abholernummer
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Eindeutige Identifikationsnummer um
                                                zusammenhängende Abholer Geschäftsfälle zu
                                                erkennen.
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Kennzeichen Rezepteinsätze
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Rezepteinsatznummer
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Kennzeichen Arzneimittel
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Arzneimitteldefinition für das Benchmarking
                                                umfasst:
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Warenverzeichnis I exklusive Heilbehelfe,
                                                Hilfsmittel, sonstige Mittel (= 4. Alphabet des
                                                WVZ I in der Druckversion…wird in der
                                                elektronischen Form als Warenverzeichnis I/III
                                                bezeichnet?)
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Warenverzeichnis II
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Jene Teile des Warenverzeichnis III, für die
                                                ein Arzneimittelkennzeichen (Z) hinterlegt ist
                                                (Arzneitees)
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Magistrale Zubereitung
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Homöopathika des Ergänzungssortiments
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Erstattungskennzeichen abgegebene Produkte
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Für Produkte/Leistungen des Warenverzeichnis I.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Kennzeichen lt. Warenverzeichnis.
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Rezeptzeichen
                                            </Typography>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <Typography align="justify">
                                                Für Produkte/Leistungen der Warenverzeichnisse
                                                I und II.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography align="justify">
                                                Kennzeichen lt. Warenverzeichnis (ohne
                                                Warnhinweise).
                                            </Typography>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </NarrowBox>
        </>
    );
}

export default About;
