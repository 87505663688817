import {
    Box,
    Grid,
    Theme,
} from '@material-ui/core';

import BranchQuickAction from './BranchQuickAction';
import CreateUserIcon from '../../assets/apostar_icons_white_benutzer_anlegen.svg';
import Infobox from './Infobox';
import Monthly from './Monthly';
import PharmacyDataIcon from '../../assets/apostar_icons_white_apotheke_datenaendern.svg';
import QuickAction from './QuickAction';
import React from 'react';
import UserDataIcon from '../../assets/apostar_icons_white_benutzer_datenaendern.svg';
import Yearly from './Yearly';
import bgImage from '../../assets/frontpage-background.jpg';
import { hasAnyRole } from '../../Lib/Roles';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from '../../Hooks/useAuth';
import useCachedData from '../../Hooks/useCachedData';
import { useHistory } from 'react-router-dom';

const styles = (theme: Theme) => ({
    gridView: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: theme.spacing(3),
    },
    iconCard: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        '& .MuiIcon-root': {
            height: 80,
            color: 'inherit',
        },

        '& .MuiCardContent-root': {
            textAlign: 'center',
            padding: theme.spacing(1),
        },

        '& .MuiTypography-h6': {
            lineHeight: 1.2,
            fontSize: 16,
        },
    },
    cardStretch: {
        height: '100%',
    },
    cardEmpty: {
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '& .MuiTypography-root': {
            color: theme.palette.common.white,
        },
    },
    centerCard: {
        '& .MuiTypography-root': {
            textAlign: 'center',
        },
    },
    spacedGrid: {
        marginBottom: theme.spacing(1),
    },
    innerCardHeader: {
        marginBottom: theme.spacing(2),
    },
    newsMeta: {
        marginTop: theme.spacing(1),
    },
});
const useStyles = makeStyles(styles);



const Dashboard = ({ onLink }: { onLink: () => void }) => {
    const classes = useStyles();
    const { push: navigateTo } = useHistory();

    // Required authentication data
    const { authentication } = useAuth();
    const { role: roles } = useCachedData(['role']);

    // Check for permissions
    const hasMonthlySubscribed = authentication && authentication.pharmacy.monthlySubscribed;
    const isMainUser = authentication && hasAnyRole(authentication, ['Hauptbenutzer'], roles);
    const isUser = authentication && hasAnyRole(authentication, ['Benutzer'], roles);
    const isAccountant = authentication && hasAnyRole(authentication, ['Steuerberater'], roles);
    const name = authentication
        ? [authentication.title, authentication.firstName, authentication.lastName].filter(Boolean).join(' ')
        : '';

    return (
        <>
            <Grid container spacing={3} alignItems="stretch" className={classes.spacedGrid}>
                <Grid item md>
                    <Infobox
                        name={name}
                        classes={classes}
                    />
                </Grid>
                <Grid item md>
                    <Box className={classes.gridView}>
                        <QuickAction
                            title="Benutzer-Stammdaten aktualisieren"
                            icon={UserDataIcon}
                            className={classes.iconCard}
                            onClick={() => navigateTo('/stammdaten')}
                        />
                        {isMainUser && (
                            <>
                                <QuickAction
                                    title="Weitere Benutzer anlegen"
                                    icon={CreateUserIcon}
                                    className={classes.iconCard}
                                    onClick={() => navigateTo('/benutzer')}
                                />
                                <QuickAction
                                    title="Stammdaten Ihrer Apotheke aktualisieren"
                                    icon={PharmacyDataIcon}
                                    className={classes.iconCard}
                                    onClick={() => navigateTo('/apotheke')}
                                />
                                <BranchQuickAction
                                    className={classes.iconCard}
                                    navigateTo={navigateTo}
                                />
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs>
                    <Yearly
                        navigateTo={navigateTo}
                        isAccountant={!!isAccountant}
                        isMainUser={!!isMainUser}
                        isUser={!!isUser}
                        classes={classes}
                    />
                </Grid>
                <Grid item xs>
                    <Monthly
                        navigateTo={navigateTo}
                        isUser={!!isUser}
                        isMainUser={!!isMainUser}
                        active={!!hasMonthlySubscribed}
                        classes={classes}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;
