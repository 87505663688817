import { JTDSchemaType } from 'ajv/dist/jtd';
/**
 * File contains JSON Type Definitions for comparison group entity types.
 */

type BooleanApiField = 'J' | 'N';

/**
 * The typed entity from api
 */
export type YearlyDataApiEntity = {
    kpiApoID: number,
    kpiWj: number,
    kpiKzRumpfjahr: BooleanApiField,
    kpiKzFortschritt: 'E' | 'H' | 'V',
    kpiKzStatus: 'O' | 'W' | 'K',
    kpiA2: string | null,
    kpiA3: string | null,
    kpiA50: BooleanApiField | null,
    kpiA51: BooleanApiField | null,
    kpiA4: BooleanApiField | null,
    kpiA5: string | null,
    kpiA19: BooleanApiField | null,
    kpiA9: string | null,
    kpiA13: number | null,
    kpiA14: number | null,
    kpiA15: number | null,
    kpiA16: number | null,
    kpiA17: number | null,
    kpiA18: number | null,
    kpiA52: number | null,
    kpiA53: number | null,
    kpiA54: number | null,
    kpiA55: number | null,
    kpiA56: number | null,
    kpiA57: number | null,
    kpiA58: number | null,
    kpiA60: number | null,
    kpiA61: number | null,
    kpiA63: number | null,
    kpiA64: number | null,
    kpiA65: number | null,
    kpiB1: number | null,
    kpiB2: number | null,
    kpiB3: number | null,
    kpiB4: number | null,
    kpiB5: number | null,
    kpiB6: number | null,
    kpiB7: number | null,
    kpiB8: number | null,
    kpiB9: number | null,
    kpiB10: number | null,
    kpiB11: number | null,
    kpiB12: number | null,
    kpiB13: number | null,
    kpiE1: number | null,
    kpiE2: number | null,
    kpiE3: number | null,
    kpiE4: number | null,
    kpiE50: number | null,
    kpiE53: number | null,
    kpiE5: number | null,
    kpiE6: number | null,
    kpiE7: number | null,
    kpiE8: number | null,
    kpiE9: number | null,
    kpiE10: number | null,
    kpiE11: number | null,
    kpiE13: number | null,
    kpiE14: number | null,
    kpiE15: number | null,
    kpiE16: number | null,
    kpiE17: number | null,
    kpiE18: number | null,
    kpiE19: number | null,
    kpiE20: number | null,
    kpiE21: number | null,
    kpiE22: number | null,
    kpiE23: number | null,
    kpiE51: number | null,
    kpiE25: number | null,
    kpiE26: number | null,
    kpiE27: number | null,
    kpiE28: number | null,
    kpiE29: number | null,
    kpiE52: number | null,
};

/**
 * Typed schema for the entity
 */
const schema: JTDSchemaType<YearlyDataApiEntity> = {
    properties: {
        kpiApoID: { type: 'int32' },
        kpiWj: { type: 'int32' },
        kpiKzRumpfjahr: { enum: ['J', 'N'] },
        kpiKzFortschritt: { enum: ['E', 'H', 'V'] },
        kpiKzStatus: { enum: ['O', 'W', 'K'] },
        kpiA2: { type: 'string', nullable: true },
        kpiA3: { type: 'string', nullable: true },
        kpiA50: { enum: ['J', 'N'], nullable: true },
        kpiA51: { enum: ['J', 'N'], nullable: true },
        kpiA4: { enum: ['J', 'N'], nullable: true },
        kpiA5: { type: 'string', nullable: true },
        kpiA19: { enum: ['J', 'N'], nullable: true },
        kpiA9: { type: 'string', nullable: true },
        kpiA13: { type: 'int32', nullable: true },
        kpiA14: { type: 'int32', nullable: true },
        kpiA15: { type: 'int32', nullable: true },
        kpiA16: { type: 'int32', nullable: true },
        kpiA17: { type: 'int32', nullable: true },
        kpiA18: { type: 'int32', nullable: true },
        kpiA52: { type: 'int32', nullable: true },
        kpiA53: { type: 'int32', nullable: true },
        kpiA54: { type: 'int32', nullable: true },
        kpiA55: { type: 'int32', nullable: true },
        kpiA56: { type: 'int32', nullable: true },
        kpiA57: { type: 'int32', nullable: true },
        kpiA58: { type: 'int32', nullable: true },
        kpiA60: { type: 'int32', nullable: true },
        kpiA61: { type: 'int32', nullable: true },
        kpiA63: { type: 'int32', nullable: true },
        kpiA64: { type: 'int32', nullable: true },
        kpiA65: { type: 'int32', nullable: true },
        kpiB1: { type: 'int32', nullable: true },
        kpiB2: { type: 'int32', nullable: true },
        kpiB3: { type: 'int32', nullable: true },
        kpiB4: { type: 'int32', nullable: true },
        kpiB5: { type: 'int32', nullable: true },
        kpiB6: { type: 'int32', nullable: true },
        kpiB7: { type: 'int32', nullable: true },
        kpiB8: { type: 'int32', nullable: true },
        kpiB9: { type: 'int32', nullable: true },
        kpiB10: { type: 'int32', nullable: true },
        kpiB11: { type: 'int32', nullable: true },
        kpiB12: { type: 'int32', nullable: true },
        kpiB13: { type: 'int32', nullable: true },
        kpiE1: { type: 'int32', nullable: true },
        kpiE2: { type: 'int32', nullable: true },
        kpiE3: { type: 'int32', nullable: true },
        kpiE4: { type: 'int32', nullable: true },
        kpiE50: { type: 'int32', nullable: true },
        kpiE5: { type: 'int32', nullable: true },
        kpiE6: { type: 'int32', nullable: true },
        kpiE7: { type: 'int32', nullable: true },
        kpiE8: { type: 'int32', nullable: true },
        kpiE9: { type: 'int32', nullable: true },
        kpiE10: { type: 'int32', nullable: true },
        kpiE11: { type: 'int32', nullable: true },
        kpiE13: { type: 'int32', nullable: true },
        kpiE14: { type: 'int32', nullable: true },
        kpiE15: { type: 'int32', nullable: true },
        kpiE16: { type: 'int32', nullable: true },
        kpiE17: { type: 'int32', nullable: true },
        kpiE18: { type: 'int32', nullable: true },
        kpiE19: { type: 'int32', nullable: true },
        kpiE20: { type: 'int32', nullable: true },
        kpiE21: { type: 'int32', nullable: true },
        kpiE22: { type: 'int32', nullable: true },
        kpiE23: { type: 'int32', nullable: true },
        kpiE51: { type: 'int32', nullable: true },
        kpiE25: { type: 'int32', nullable: true },
        kpiE26: { type: 'int32', nullable: true },
        kpiE27: { type: 'int32', nullable: true },
        kpiE28: { type: 'int32', nullable: true },
        kpiE29: { type: 'int32', nullable: true },
        kpiE52: { type: 'int32', nullable: true },
        kpiE53: { type: 'int32', nullable: true },
    },
};
export default schema;

/**
 * Export array form as well
 */
export const YearlyDataArray: JTDSchemaType<Array<YearlyDataApiEntity>> = {
    elements: schema,
};
