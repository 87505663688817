import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { RuleParamaterInput, RuleParamatersInput } from './Definitions';

import Param from './Param';
import VALIDATION_PARAMS from './Definitions';
import { ruleParameters } from '../../Lib/YearlyDataValidation';

/**
 * Properties of component
 */
type ParamInputsProps = {
    onChange?: (values: RuleParamatersInput) => void,
    initialValues?: RuleParamatersInput,
};

const pristineValues = (): RuleParamatersInput => (
    ruleParameters.reduce((memo, key) => ({
        ...memo,
        [key]: { bounds: {}, boundsCritical: {} },
    }), {}) as RuleParamatersInput
);

/**
 * Income sheet form component
 */
const ParamInputs: React.FC<ParamInputsProps> = ({
    initialValues,
    onChange = () => {},
}) => {
    // Keep track of values in this form
    const [, setValues] = useState<RuleParamatersInput>(
        initialValues || pristineValues()
    );

    // Value change callback for individual field
    const onValueChange = (newValue: RuleParamaterInput, id: keyof RuleParamatersInput) => {
        setValues((prev) => {
            prev[id] = newValue;
            onChange(prev);
            return prev;
        });
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3} />
                <Grid item xs={4}>
                    <Typography variant="h6">Warnung</Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <Typography variant="h6">Kritischer Fehler</Typography>
                </Grid>
            </Grid>
            {VALIDATION_PARAMS.map<React.ReactElement>(({ id, label, numType }) => (
                <Param
                    label={label}
                    id={id}
                    numType={numType}
                    initialValue={initialValues?.[id]}
                    onChange={(value) => onValueChange(value, id)} />
            ))}
        </>
    );
}

export default ParamInputs;
