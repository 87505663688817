import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['general.A13_LJ', '/', 'compare.general.A13_LJ'],
        { type: 'bounds', ...params },
        'Die Veränderung der Betriebsräumlichkeiten weicht stark vom Vorjahr ab. Bitte prüfen Sie Ihre Angaben!',
        undefined,
        'Struktur_01',
    )
);

export default rule;
