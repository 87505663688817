import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for plausibleness in buying syndicate data
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['intermediates.staff_cost_LJ', '/', 'intermediates.gross_yield_LJ'],
        { type: 'bounds', ...params },
        {
            low: 'Der Personalaufwand erscheint im Verhältnis zum Bruttoertrag unplausibel hoch',
            high: 'Der Personalaufwand erscheint im Verhältnis zum Bruttoertrag unplausibel niedrig',
        },
        undefined,
        'Personal_04',
    )
);

export default rule;
