import { RuleParamater, UserValdiation } from '../YearlyDataValidation';

/**
 * Checks for difference in stock at start and end
 */
const rule = (params: RuleParamater) => (
    new UserValdiation(
        ['income.E2_LJ', '/', 'intermediates.offizin_revenue_LJ'],
        { type: 'bounds', ...params },
        'Bitte prüfen Sie Ihre Privat und Krankenkassenumsätze, das Verhältnis erscheint unplausibel.',
        undefined,
        'GuV_06',
    )
);

export default rule;
